import { Input } from '@common'

export const ColumnFilter = ({
    column: {
      filterValue, 
      setFilter,
    },
   }) => {
    return (
      <Input  
        width='auto'
        value={filterValue || ""}
        onChange={(e:any) => { 
          setFilter(e.target.value || undefined);
        }} 
      />
    );
   }
    