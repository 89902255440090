import { get } from '@api';

export const getDeliveriesDriverDashboard = async (driverId: string) => {
    try {
        const res = await get(`/deliveries?driverId=${driverId}&status=assigned&status=for_pickup&status=picked_up&status=on_going`)
        return res.data.results;
    } catch (err) {
        throw err;
    }
}

export const getDeliveriesRentalItem = async (driverId: string) => {
    try {
        const res = await get(`/deliveries/rental-items?driverId=${driverId}&status=time_in&status=assigned&status=on_going`)
        return res.data;
    } catch (err) {
        throw err;
    }
}