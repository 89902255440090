/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { RootState, Dispatch } from "@store";

import { OPEN_ORDERS, COMPLETED_ORDERS, TAB } from "@constants";
import { SearchShop, Loader, Table } from "@components";

import { NoData, Container, Span } from "@common";

import { COLUMNS } from "./column";

import { useQuery } from "@utils";
import { Button, message, Tabs } from "antd";
import { useMediaQuery, useTheme } from "@mui/material";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useIsWindowActive } from "@hooks";

export const ShopOrdersPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const isWindowActive = useIsWindowActive();
  const query = useQuery();
  const tab: any = query.get("tab");
  const history = useHistory();
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const [status, setStatus] = useState(TAB[tab]) as any[];
  const [activeTab, setActiveTab] = useState(tab);
  const [isData, setIsData] = useState(false);

  const [searchData, setSearchData] = useState([
    {
      filter: "status",
      value: "all",
    },
  ]);

  var RECONNECT_TIMER: any;

  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { TabPane } = Tabs;

  const { isLoading } = useSelector((state: RootState) => state.Table);
  const { original_data: mainData, data }: any = useSelector(
    (state: RootState) => state.Table.tables.shopOrders
  );
  const completedData: any[] = useSelector(
    (state: RootState) => state.Table.tables.shopOrdersCompleted.data
  ).reverse();

  const filters: any = useSelector(
    (state: RootState) => state.Table.tables.shopOrdersCompleted?.filters
  );

  const columns = useMemo(() => COLUMNS, []);
  const maxSearchInputCount = 2;

  const searchCompleted = () => {
    dispatch.Table.getCompletedOrders({
      table: "shopOrdersCompleted",
      type: "mart",
    });
  };

  const setFilters = () => {
    if (activeTab === "openOrders") {
      setIsData(true);
      let result: any[] = mainData;
      searchData
        ?.filter((s) => s?.value !== "")
        ?.map((search) => {
          switch (search.filter) {
            case "status":
              result = result?.filter((order) => {
                if (
                  order?.status.search(
                    new RegExp(search.value === "all" ? "" : search.value, "i")
                  ) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "orderCode":
              result = result?.filter((order) => {
                if (
                  order?.orderCode?.search(new RegExp(search.value, "i")) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "store":
              result = result?.filter((order) => {
                if (
                  order?.pickup?.locationAddress.search(
                    new RegExp(search.value, "i")
                  ) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            default:
              result = [];
              break;
          }
        });
      dispatch.Table.setTableData({ table: "shopOrders", data: result });
      // closeLoader();
    } else {
      let stringKeyword = "";
      searchData
        ?.filter((s) => s.value !== "")
        ?.forEach((search) => {
          const string =
            search?.value === "all"
              ? "&status[]=delivered&status[]=canceled"
              : `&${
                  search?.filter === "orderCode"
                    ? "orderNumber"
                    : search?.filter
                }=${search?.value}`;
          stringKeyword = stringKeyword + string;
        });
      setIsFirstSearch(false);
      dispatch.Table.updateKeyword({
        table: "shopOrdersCompleted",
        value: stringKeyword,
      });
      searchCompleted();
    }
  };

  useEffect(() => {
    if (activeTab === "completedOrders") {
      if (isFirstSearch) {
        dispatch.Table.resetState();
      } else {
        searchCompleted();
      }
      // closeLoader();
    }
  }, [filters]);

  useEffect(() => {
    if (activeTab === "openOrders") {
      setStatus(OPEN_ORDERS);
      setSearchData([{ filter: "status", value: "all" }]);
      history.push("/parcel-management/shop-orders?tab=openOrders");
    }

    if (activeTab === "completedOrders") {
      setStatus(COMPLETED_ORDERS);
      setSearchData([{ filter: "status", value: "all" }]);
      history.push("/parcel-management/shop-orders?tab=completedOrders");
    }
  }, [activeTab]);

  useEffect(() => {
    // closeLoader();
  }, [completedData, data]);

  useEffect(() => {
    if (searchData?.length !== 1 || searchData[0].value !== "all") {
      setFilters();
    }
  }, [mainData]);

  useEffect(() => {
    if (data.length > 0 || tab === "completedOrders")
      dispatch.Table.updateState({ isLoading: false });
    if (isWindowActive) {
      if (tab === "openOrders") {
        RECONNECT_TIMER = setTimeout(() => {
          dispatch.Table.subscribeToFirebase({
            name: "deliveries",
            table: "shopOrders",
            tab,
            type: "mart",
          });
        }, 2000);
      }
    } else {
      if (tab === "openOrders") {
        dispatch.Table.unsubscribed();
        clearTimeout(RECONNECT_TIMER);
      }
    }

    return () => {
      dispatch.Table.updateState({ isLoading: true });
      dispatch.Table.unsubscribed();
    };
  }, [isWindowActive, tab]);

  return (
    <div className="relative">
      <Loader isLoading={isLoading} />
      <Container border="grey" padding="none">
        <div className="p-4">
          <Span margin="mt-4" weight="bold" size="2xl">
            All Shop Orders
          </Span>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          >
            <TabPane tab="Open Orders" key="openOrders" />
            <TabPane tab="Completed Orders" key="completedOrders" />
          </Tabs>
        </div>

        <div className="p-4 w-full flex flex-row items-center">
          {searchData?.map((search, index) => (
            <SearchShop
              search={search}
              searchData={searchData}
              setSearchData={setSearchData}
              setFilters={setFilters}
              tab={tab}
              index={index}
            />
          ))}

          {!matchDownSm && (
            <Button
              type="primary"
              size="large"
              icon={
                <SearchOutlined
                  style={{ display: "inline-flex", alignItems: "center" }}
                />
              }
              onClick={setFilters}
            >
              Search
            </Button>
          )}

          <div className="px-2">
            <Button
              type="primary"
              size="large"
              className="px-2"
              icon={
                <PlusOutlined
                  style={{ display: "inline-flex", alignItems: "center" }}
                />
              }
              onClick={() => {
                if (maxSearchInputCount === searchData?.length)
                  return message.error("Already reach search limit!");
                setSearchData([
                  ...searchData,
                  { filter: "pleaseSelect", value: "" },
                ]);
              }}
            >
              Add Search
            </Button>
          </div>
        </div>

        <div className="overflow-auto">
          {data?.length < 0 ? (
            <NoData />
          ) : (
            <Table
              columns={columns}
              data={tab === "openOrders" ? data : completedData}
              dataType="shop-orders"
              sortName="createdAt"
              descSort={true}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
