/* eslint-disable import/no-anonymous-default-export */
import { Span } from '@common'     
import Stops from './Stops';
import { formatDate } from "@utils";
 
export default ({stops, expressId, status, driver, thirdPartyDriver, arrivedAtDropOffAt}: any) => {  
    return (<>
            {stops?.sort((a, b) => a?.sequenceNo - b?.sequenceNo)?.map((dropOff, index) => (
                <Stops key={index} dropOff={dropOff} expressId={expressId} status={status} driver={driver} thirdPartyDriver={thirdPartyDriver}/>
            ))}

        {arrivedAtDropOffAt && (
        <>
          <hr className="mx-4" />

          <div className="flex justify-end items-center px-8 pb-4">
            <Span>Arrived at <strong>{formatDate(arrivedAtDropOffAt)}</strong></Span>
          </div>
        </>
      )}
    </>
    )
}
