import { Dispatch } from '@store';
import { useDispatch } from 'react-redux';
 
import { CHANGE_TO_RENTAL, RENTAL_ASSIGN_STATUS } from '@constants';
import { Span, SpanContainer } from '@common' 
import { Modal } from 'antd';
import { updateToNextStatusRental } from '@api';

type Props = {
    setUpdateStatusModal: Function,
    updateStatusModal: boolean,
    orderId: string | null,
    status: string
}

export const UpdateStatusRental = ({ setUpdateStatusModal, updateStatusModal, orderId, status, ...props }: Props) => {

    const dispatch = useDispatch<Dispatch>();
    const { confirm } = Modal;

    const handleUpdateStatus = async () => {
        setUpdateStatusModal(false);
        try {
            dispatch.UI.setIsLoading(true);
            const res = await updateToNextStatusRental(orderId, CHANGE_TO_RENTAL[status]);
            if (res?.status === 200) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: `Status successfully updated`, type: 'Success' })
            }
        } catch (err: any) {
            console.log(JSON.stringify(err));
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.message ?? 'Unable to update status!'
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        }
    } 

    const showConfirm = () => {
        confirm({
            title: 'Change delivery status',
            content: (
                <Span>Are you sure you want to change the status  
                    <SpanContainer color='green-1'> {RENTAL_ASSIGN_STATUS[CHANGE_TO_RENTAL[status]]}</SpanContainer> ? 
                </Span> 
            ),
            onOk() {
                handleUpdateStatus();
            },
            onCancel() {
                setUpdateStatusModal(false)
            }
        })
    }

    return (
        <>
            {updateStatusModal && showConfirm()}
        </>
    );
}   