/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import { cleanObject, onlyNumberKey, removeSCAndCapitalize } from "@utils";

import { Span, Container } from "@common";
import "../../containers/DriverManagement/AllDrivers/Driver.css";
import { Dispatch, RootState } from "@store";
import { DRIVERGROUP, RESTRICTION_CODE } from "@constants";

import {
  provinceData,
  cityData,
  clusterData,
  area,
  clustercities, 
} from "@components";

import {
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Button,
  Popconfirm,
  message,
  Rate,
  Modal,
  Row,
  Col,
  Table,
} from "antd";
import {
  DeleteOutlined,
  LinkOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Vehicle, UploadPhoto, BackDrop } from "@components";
import {
  addRestrictionCode,
  addVehicle,
  deleteDoc,
  deleteRestrictionCode,
  deleteVehicle,
  updateDriver,
  updateVehicle,
  uploadDriverDocs,
  uploadProfile,
  validateVehicles,
} from "@api";
import "./edit.scss";
import Upload, {  UploadProps } from "antd/lib/upload";
const { Option } = Select;

type Props = {
  driverId: string;
  table: string;
  getData: Function;
};

type RequiredMark = boolean | "optional";

const documentTypes = [
  "copyOrCr",
  "copyDriverLicense",
  "selfieLicense",
  "photoPlateNumber",
  "vehicleInsurance",
  "notOwnedLetter",
  "deedSale",
  "barangayClearance",
  "nbiClearance",
];

const friendlyNames = {
  copyOrCr: "Copy of OR/CR",
  copyDriverLicense: "Driver License Copy",
  selfieLicense: "Selfie with License",
  photoPlateNumber: "Photo of Plate Number",
  vehicleInsurance: "Vehicle Insurance",
  notOwnedLetter: "Not Owned Letter",
  deedSale: "Deed of Sale",
  barangayClearance: "Barangay Clearance",
  nbiClearance: "NBI Clearance",
};

export interface DocumentListType {
  docId: number;
  docType: string;
  filePath: string;
  sequence: number;
}

export const EditDriver = ({ driverId, getData, table, ...props }: Props) => {
  const gridLayout = {
    lg: { span: 8 },
    md: { span: 12 },
    xs: { span: 24 },
  };

  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  const [docsForm]: any = Form.useForm();

  const drivers = useSelector(
    (state: RootState) => state.Table.tables[table].data
  );
  const D = drivers?.filter((driver) => driver?.driverId === driverId)[0];
  const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);

  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(false);
  const [activeVehicleIndex, setActiveVehicleIndex] = useState(null);
  const [isLoading, setisloading] = useState(false);
  const [deletedVehicle, setDeletedVehicle] = useState([]);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [driverDetails, setDriverDetails] = useState({}) as any;
  const [updateDriverDocs, setUpdateDriverDocs] = useState(undefined) as any;
  const [docsList, setDocsList] = useState<Array<DocumentListType>>([]);
  const [listRemoveDoc, setListRemoveDoc] = useState([]) as any;
  const [addedRestrictionCode, setAddedRestrictionCode] = useState([]) as any;
  const [deletedRestrictionCode, setDeletedRestrictionCode] = useState(
    []
  ) as any;

  const error = (errMsg) => {
    message.error(errMsg);
  };

  const showDocumentModal = () => {
    setIsDocumentModalVisible(true);
  };

  const onSubmitDocs = (values) => {
    const docs = cleanObject(values);
    setUpdateDriverDocs(docs || undefined);
    setIsDocumentModalVisible(false);
  };

  const onCancelDocs = () => {
    docsForm.resetFields();
    setIsDocumentModalVisible(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadConfig: UploadProps = useMemo(
    () => ({
      maxCount: 1,
      beforeUpload: () => false,
      accept: ".jpg,.jpeg,.png,.pdf",
    }),
    []
  );

  const handleUpdateDriverDocs = async () => {
    if (updateDriverDocs) {
      try {
        const formData = new FormData();
        if (!updateDriverDocs) return;
        for (const key in updateDriverDocs) {
          if (updateDriverDocs[key]?.length) {
            switch (key) {
              case "copyOrCr":
                formData.append(
                  "copy_or_cr",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              case "copyDriverLicense":
                formData.append(
                  "copy_driver_license",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              case "selfieLicense":
                formData.append(
                  "selfie_holding_license",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              case "photoPlateNumber":
                formData.append(
                  "photo_vehicle_showing_plate_number",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              case "vehicleInsurance":
                formData.append(
                  "vehicle_insurance",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              case "notOwnedLetter":
                formData.append(
                  "not_owned_vehicle_authorization_letter",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              case "deedSale":
                formData.append(
                  "deed_sale_if_bought_from_pre_owner",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              case "barangayClearance":
                formData.append(
                  "barangay_clearance",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              case "nbiClearance":
                formData.append(
                  "nbi_clearance",
                  updateDriverDocs[key][0].originFileObj
                );
                break;
              default:
                break;
            }
          }
        }

        await uploadDriverDocs(driverId, formData);
      } catch (error) {
        console.error(error);
      }
    }

    setUpdateDriverDocs(undefined);
  };

  const handleAddVehicle = async () => {
    const vehiclesToAdd = driverDetails?.vehicles?.filter((v) => !v?.vehicleId);
    if (vehiclesToAdd?.length < 1) return;
    const result = await vehiclesToAdd?.map(async (v) => {
      try {
        await addVehicle(driverId, v);
      } catch (err: any) {
        const errMsg =
          err?.response?.data.message ?? "Unable to update vehicle!";
        setisloading(false);
        error(errMsg);
        return false;
      }
    });

    if ((await Promise.all(result)).includes(false)) {
      return Promise.reject("Unable to update vehicle!");
    }
  };

  const handleUpdateVehicle = async () => {
    const vehiclesToUpdate = driverDetails?.vehicles?.filter(
      (v) => v?.vehicleId
    );
    const changesResult = vehiclesToUpdate?.map((v) => {
      const oriVehicle = D?.vehicles?.find(
        (ov) => ov?.vehicleId === v?.vehicleId
      );
      const arrUpdVehicle = Object.entries(v)?.map(
        (entry) => `${entry[0]}:${entry[1]}`
      );
      const arrOriVehicle = Object.entries(oriVehicle)?.map(
        (entry) => `${entry[0]}:${entry[1]}`
      );
      const noChangesResult = arrUpdVehicle
        ?.map((v) => (arrOriVehicle?.includes(v) ? false : true))
        .every((res) => !res);

      return noChangesResult;
    });
    const noChanges = changesResult.every((res) => res);
    if (vehiclesToUpdate?.length < 1 || noChanges) return;
    const result = await vehiclesToUpdate?.map(async (v) => {
      try {
        await updateVehicle(driverId, v?.vehicleId, v);
      } catch (err: any) {
        const errMsg =
          err?.response?.data.message ?? "Unable to update vehicle!";
        setisloading(false);
        error(errMsg);
        return false;
      }
    });

    if ((await Promise.all(result)).includes(false)) {
      return Promise.reject("Unable to update vehicle!");
    }
  };

  const handleDeleteVehicle = async () => {
    if (deletedVehicle?.length < 1) return;
    const result = await deletedVehicle?.map(async (v) => {
      try {
        await deleteVehicle(driverId, v);
      } catch (err: any) {
        const errMsg =
          err?.response?.data.message ?? "Unable to update vehicle!";
        setisloading(false);
        error(errMsg);
        return false;
      }
    });

    if ((await Promise.all(result)).includes(false)) {
      return Promise.reject("Unable to update vehicle!");
    }
  };

  const handleUpdateDriver = async () => {
    try {
      setisloading(true);
      await handleUpdateDriverDocs();
      await handleUpdateVehicle();
      await handleAddVehicle();
      await handleDeleteVehicle();
      await handleRestrictionCodeOnChange();

      delete driverDetails.vehicles;
      driverDetails.phoneNumber = `+63${driverDetails.phoneNumber}`;
      driverDetails.emergencyContact.phoneNumber = `+63${driverDetails?.emergencyContact?.phoneNumber}`;
      const res = await updateDriver(driverId, driverDetails);
      if ([201, 200].includes(res.status)) {
        setisloading(false);
        if (file) {
          await uploadProfile(driverId, { file, url: imageUrl });
        }
        dispatch.UI.updateFormId({ target: "editDriver", value: null });
        dispatch.UI.setAlert({ message: "Driver updated!", type: "Success" });
        setDriverDetails({});
        getData();
      }
    } catch (err: any) {
      const errMsg = err?.response?.data.message ?? "Unable updated driver!";
      setisloading(false);
      error(errMsg);
    }
  };

  const handleRestrictionCodeOnChange = async () => { 
    const handleAddRestrictionCode = async (value) => {
      try {
        await addRestrictionCode(driverId, value);
      } catch (err: any) {
        const errMsg =
          err?.response?.data.message ?? "Unable to add restriction code!";
        setisloading(false);
        error(errMsg);
        return false;
      }
    };

    const handleDeleteRestrictionCode = async (id) => {
      try {
        await deleteRestrictionCode(driverId, id);
      } catch (err: any) {
        const errMsg =
          err?.response?.data.message ?? "Unable to delete restriction code!";
        setisloading(false);
        error(errMsg);
        return false;
      }
    };

    if (addedRestrictionCode.length > 0) {
      const driverRestrictionCodes =
        D?.driverRestrictionCode.map((v) => v.code) || [];
      await Promise.all(
        addedRestrictionCode.map(async (value) => {
          if (!driverRestrictionCodes.includes(value.code)) {
            await handleAddRestrictionCode(value);
          }
        })
      );
    }

    if (deletedRestrictionCode.length > 0) {
      const driverRestrictionCodes =
        D?.driverRestrictionCode?.map((v) => v.code) || [];
      const codesToDelete = deletedRestrictionCode
        .filter((code) => driverRestrictionCodes.includes(code.code))
        .map((code) => {
          const item = D.driverRestrictionCode.find(
            (v) => v.code === code.code
          );
          return item ? item.id : null;
        })
        .filter((id) => id !== null);

      await Promise.all(
        codesToDelete.map(async (id) => {
          await handleDeleteRestrictionCode(id);
        })
      );
    }
  };

  const onSelectRestrictionCode = (selectedValues) => {
    setAddedRestrictionCode((prevAddedCodes) => [
      ...prevAddedCodes,
      { code: selectedValues },
    ]);
    setDeletedRestrictionCode((prevDeletedCodes) =>
      prevDeletedCodes.filter((v) => v?.code !== selectedValues)
    );
  };

  const onDeSelectRestrictionCode = (selectedValues) => {
    setDeletedRestrictionCode((prevDeletedCodes) => [
      ...prevDeletedCodes,
      { code: selectedValues },
    ]);
    setAddedRestrictionCode((prevAddedCodes) =>
      prevAddedCodes.filter((v) => v?.code !== selectedValues)
    );
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setDriverDetails({
      ...driverDetails,
      [name]: value,
    });
  };

  // ADDRESS
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const [cities, setCities] = useState(cityData[provinceData[0]]);
  const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);

  const handleProvinceChange = (value) => {
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
    setDriverDetails({ ...driverDetails, province: value });
  };

  const onSecondCityChange = (value) => {
    setSecondCity(value);
    setDriverDetails({ ...driverDetails, deliveryCity: value });
  };

  const [clusters, setClusters] = useState(area[clusterData[0]]);
  const [secondArea, setArea] = useState(area[clusterData[0]][0]);
  const [clustercity, setClusterCity] = useState(
    clustercities[area[clusterData[0]][0]]
  );

  const handleClusterChange = (value) => {
    setClusters(area[value]);
    setArea(area[value][0]);
    setDriverDetails({ ...driverDetails, deliveryCluster: value });
  };

  const onSecondAreaChange = (value) => {
    setArea(value);
    setClusterCity(clustercities[value]);
    setDriverDetails({ ...driverDetails, deliveryArea: value });
  };

  useEffect(() => {
    const details = {
      driverId: D?.driverId,
      address: D?.driverProfile?.address,
      city: D?.driverProfile?.city,
      codCapable: D?.codCapable,
      deliveryArea: D?.deliveryArea,
      deliveryCity: D?.deliveryCity,
      deliveryCluster: D?.deliveryCluster,
      driverGroup: D?.driverProfile?.driverGroup,
      driverStatus: D?.driverStatus,
      email: D?.email,
      firstName: D?.driverProfile?.firstName,
      lastName: D?.driverProfile?.lastName,
      licenseRestriction: D?.licenseRestriction,
      middleName: D?.driverProfile?.middleName,
      phoneNumber: D?.phoneNumber.replace("+63", ""),
      province: D?.driverProfile?.province,
      status: D?.status,
      vehicles: D?.vehicles,
      rating: D?.driverProfile?.rate,
      emergencyContact: {
        name: D?.emergencyContact?.name,
        phoneNumber: D?.emergencyContact?.phoneNumber.replace("+63", ""),
        relationship: D?.emergencyContact?.relationship,
        barangayContactNumber: D?.emergencyContact?.barangayContactNumber,
      },
      driverRestrictionCode:
        D?.driverRestrictionCode.map(
          (restrictionCode) => restrictionCode.code
        ) || [],
    };
    setDriverDetails(details);
    setDocsList(D?.driverDocuments || []);
    setAddedRestrictionCode([]);
    setDeletedRestrictionCode([]);

    form.setFieldsValue({
      ...details,
      emergencyname: D?.emergencyContact?.name,
      emergencycontactNumber: D?.emergencyContact?.phoneNumber.replace(
        "+63",
        ""
      ),
      emergencyrelationship: D?.emergencyContact?.relationship,
      emergencybarangayContactNumber:
        D?.emergencyContact?.barangayContactNumber,
    });

    if (driverId) {
      try {
        (async () => {
          await validateVehicles(driverId, { vehicles: D?.vehicles });
        })();
      } catch (err) {
        console.log(err);
      }
    }

    docsForm.resetFields();
    setUpdateDriverDocs(undefined);
  }, [driverId]);



  const handleRemoveDoc = async () => { 
      setisloading(true); 
      try {
        const list = {
          "docIds": listRemoveDoc
        } 
        await deleteDoc(driverId, list);
        dispatch.UI.setAlert({ message: "Document remove!", type: "Success" });
      } catch (err: any) { 
      const errMsg = err?.response?.data.message ?? "Unable updated driver!";
        setisloading(false);
        error(errMsg);
        return false;
      }  
      dispatch.UI.updateFormId({ target: "editDriver", value: null });
      setisloading(false); 
      getData();
  };
   

  return (
    <Drawer
      title="Edit Driver"
      placement="right"
      width={500}
      onClose={() => {
        dispatch.UI.updateFormId({ target: "editDriver", value: null });
      }}
      visible={driverId ? true : false}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in the profile information?"
              onConfirm={() => {
                dispatch.UI.updateFormId({ target: "editDriver", value: null });
              }}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Update Driver
            </Button>
          </Space>
        </div>
      }
    >
      <BackDrop /> 
      <UploadPhoto
        file={file}
        setFile={setFile}
        defaultImageurl={D?.driverProfile?.picture}
      />

      <div className="pb-8 pt-4">
        <Span color="lightgrey">Or you can upload your image source below</Span>
        <Input
          placeholder="Image Source"
          onChange={(e: any) => setImageUrl(e?.target?.value)}
        />
      </div>

      <Container border="grey" margin="mb-2">
        <div className="flex justify-between">
          <Span weight="semi" margin="my-auto">
            Driver Status
          </Span>

          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={driverDetails?.driverStatus === "active" ? true : false}
            onChange={(v) =>
              setDriverDetails({
                ...driverDetails,
                driverStatus: v ? "active" : "inactive",
              })
            }
          />
        </div>

        <div className="flex justify-between mt-4">
          <Span weight="semi" margin="my-auto">
            System Status
          </Span>

          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={driverDetails?.status === "active" ? true : false}
            onChange={(v) =>
              setDriverDetails({
                ...driverDetails,
                status: v ? "active" : "inactive",
              })
            }
          />
        </div>
      </Container> 

      <Form
        onFinish={handleUpdateDriver}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
        requiredMark={requiredMark}
      >
        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Profile
          </Span>

          <Form.Item
            label="Driver Group"
            name="driverGroup"
            rules={[{ required: true }]}
          >
            <Select
              className="w-full"
              placeholder="Driver Group"
              onChange={(v) =>
                setDriverDetails({ ...driverDetails, driverGroup: v })
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {DRIVERGROUP?.map((type, index) => (
                <Option key={index} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="First Name"
              onChange={onChange}
              name="firstName"
            />
          </Form.Item>

          <Form.Item label="Middle Name" name="middleName">
            <Input
              placeholder="Middle Name (Optional)"
              onChange={onChange}
              name="middleName"
            />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Last Name"
              onChange={onChange}
              name="lastName"
            />
          </Form.Item>

          <Form.Item label="Driver Rating" name="rating">
            <Rate
              disabled
              count={D?.driverProfile?.rate}
              className="driver_rate"
            />
          </Form.Item>
        </div>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Contact Details
          </Span>

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true }]}
          >
            <Input
              addonBefore="+63"
              placeholder="Phone Number"
              maxLength={10}
              onChange={onChange}
              onKeyPress={onlyNumberKey}
              name="phoneNumber"
            />
          </Form.Item>

          <Form.Item label="Email" name="email">
            <Input
              placeholder="Email"
              onChange={onChange}
              type="email"
              name="email"
            />
          </Form.Item>
        </div>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Emergency Contact Details
          </Span>

          <Form.Item
            label="Name"
            name="emergencyname"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Name"
              name="name"
              onChange={(e) => {
                setDriverDetails({
                  ...driverDetails,
                  emergencyContact: {
                    ...driverDetails?.emergencyContact,
                    name: e?.target.value,
                  },
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="emergencycontactNumber"
            rules={[{ required: true }]}
          >
            <Input
              addonBefore="+63"
              placeholder="Phone Number"
              maxLength={10}
              onKeyPress={onlyNumberKey}
              name="phoneNumber"
              onChange={(e) => {
                setDriverDetails({
                  ...driverDetails,
                  emergencyContact: {
                    ...driverDetails?.emergencyContact,
                    phoneNumber: e?.target.value,
                  },
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Relationship"
            name="emergencyrelationship"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Relationship"
              name="relationship"
              onChange={(e) => {
                setDriverDetails({
                  ...driverDetails,
                  emergencyContact: {
                    ...driverDetails?.emergencyContact,
                    relationship: e?.target.value,
                  },
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Barangay Contact Number"
            name="emergencybarangayContactNumber"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Barangay Contact Number"
              name="barangayContactNumber"
              onChange={(e) => {
                setDriverDetails({
                  ...driverDetails,
                  emergencyContact: {
                    ...driverDetails?.emergencyContact,
                    barangayContactNumber: e?.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </div>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Address
          </Span>

          <Form.Item
            label="Province"
            name="province"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="Province"
              onChange={handleProvinceChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option value="" selected disabled hidden>
                City
              </Option>
              {provinceData
                .sort((a, b) => a.localeCompare(b))
                .map((province) => (
                  <Option key={province} value={province}>
                    {province}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="City"
            name="deliveryCity"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="City"
              onChange={onSecondCityChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {cities
                .sort((a, b) => a.localeCompare(b))
                .map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="House No., Street, Barangay" name="address">
            <Input
              placeholder="House No., Street, Barangay"
              onChange={onChange}
              name="address"
            />
          </Form.Item>
        </div>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Delivery Details
          </Span>

          <Form.Item
            label="Cluster"
            name="deliveryCluster"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="Cluster"
              onChange={handleClusterChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {clusterData.map((clusterr) => (
                <Option key={clusterr} value={clusterr}>
                  {clusterr}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Area"
            name="deliveryArea"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="Area"
              onChange={onSecondAreaChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {clusters
                .sort((a, b) => a.localeCompare(b))
                .map((area) => (
                  <Option key={area} value={area}>
                    {area}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="City" name="city" rules={[{ required: true }]}>
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="City"
              onChange={(v) => setDriverDetails({ ...driverDetails, city: v })}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {clustercity
                .sort((a, b) => a.localeCompare(b))
                .map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>

        <Container padding="none">
          <div className="flex justify-between">
            <Span margin="my-auto">COD capable</Span>

            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={driverDetails?.codCapable}
              onChange={(v) =>
                setDriverDetails({ ...driverDetails, codCapable: v })
              }
            />
          </div>
        </Container>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Registered Vehicle
          </Span>

          <Form.Item
            label="Driver's License Restriction"
            name="driverRestrictionCode"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Driver's License Restriction"
              getPopupContainer={triggerNode => triggerNode.parentElement}
              mode="multiple"
              allowClear 
              onSelect={onSelectRestrictionCode}
              onDeselect={onDeSelectRestrictionCode}
              options={[...RESTRICTION_CODE]}
            />
          </Form.Item>
        </div>

        {driverDetails?.vehicles?.map((vehicle, index) => (
          <Vehicle
            driverDetails={driverDetails}
            setDriverDetails={setDriverDetails}
            index={index}
            activeVehicleIndex={activeVehicleIndex}
            setActiveVehicleIndex={setActiveVehicleIndex}
            vehicle={vehicle}
            form={form}
            deletedVehicle={deletedVehicle}
            setDeletedVehicle={setDeletedVehicle}
          />
        ))}

        <div className="mt-6">
          <Button
            ghost
            type="primary"
            icon={
              <PlusOutlined
                style={{ display: "inline-flex", alignItems: "center" }}
              />
            }
            onClick={() =>
              setDriverDetails({
                ...driverDetails,
                vehicles: [
                  ...driverDetails?.vehicles,
                  {
                    registeredPlateNumber: "",
                    status: "",
                    vehicleModel: "",
                    vehicleType: "",
                  },
                ],
              })
            }
          >
            Add Vehicle
          </Button>
        </div>

        {D?.driverDocuments?.length > 0 && (
          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              DRIVER DOCUMENTS
            </Span> 
            <Table
              pagination={false} 
              rowKey="docId" 
              rowSelection={{ 
                  type: 'checkbox',
                  onChange: (selectedRowKeys: React.Key[]) => {  
                    setListRemoveDoc(selectedRowKeys);  
                },}
              }
              columns={[
                {
                  title: 'Document Type',
                  key: 'docType', 
                  className: 'p-0',  
                  render: (doc: DocumentListType) => (  
                    <a
                          href={doc?.filePath}
                          target="_blank"
                          className="text-black-2 cursor-pointer hover:text-primary-main transition-all"
                        >
                          <div className="flex justify-between">
                            <span>{removeSCAndCapitalize(doc?.docType, "_")}</span>
                            <LinkOutlined className="my-auto" />
                          </div>
                        </a>
                  )
                }, 
              ]}
              size="small"
              dataSource={docsList}
            />
          </div>
        )}

        <div className="flex justify-between pt-2">
          <Button
            ghost
            type="primary"
            icon={
              <PlusOutlined
                style={{ display: "inline-flex", alignItems: "center" }}
              />
            }
            onClick={showDocumentModal}
          >
            Add Document
          </Button>
          {D?.driverDocuments?.length > 0 && (
            <Popconfirm
              placement="top"
              title="Are you sure to delete these document?"
              onConfirm={handleRemoveDoc}
              okText="Yes"
              cancelText="No"
            > 
              <Button
                danger
                type="primary"
              disabled={!listRemoveDoc.length}
                icon={
                  <DeleteOutlined
                    style={{ display: "inline-flex", alignItems: "center" }}
                  />
                } 
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </div>

        <Modal
          title="Upload Document"
          visible={isDocumentModalVisible}
          centered
          onOk={() => docsForm.submit()}
          onCancel={onCancelDocs}
          width={800}
          footer={[
            <Button key="1" onClick={onCancelDocs}>
              Cancel
            </Button>,
            <Button
              key="2"
              type="primary"
              onClick={() => docsForm.submit()} 
            >
              <span className="text-capitalize">Save</span>
            </Button>,
          ]}
        >
          <Form form={docsForm} onFinish={onSubmitDocs} layout="vertical">
            <Row>
              {documentTypes.map((docType) => (
                <Col
                {...gridLayout}
                  key={docType} 
                >
                  <Form.Item
                    name={docType}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    label={friendlyNames[docType]} 
                    className="w-full" 
                  >
                    <Upload 
                      showUploadList={true}
                      {...uploadConfig}
                    >
                      <Button icon={<UploadOutlined />} type="dashed">
                        Click to Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Form>
        </Modal>
      </Form>
    </Drawer>
  );
};
