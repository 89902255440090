import { Span } from "@common";
import { CSVLink } from "react-csv";
// import "./CSVLink.scss";

type Props = {
  data: any;
  headers: any;
  filename: any;
};

export const CSVLinkContent = ({ data, headers, filename }: Props) => {
  return (
    <>
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        className="w-full"
      >
        <Span color="white">Export Option</Span>
      </CSVLink>
    </>
  );
};
