import { get, post } from '@api';

export const authenticateViaPassword = async ({ email, password }: any) => {
    try {
      let res = await post(`/login`, {
        email,
        password
      });
  
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  
export const getUserCredentials = async () => {
    try{    
        // let config: any = {
        //     headers:{
        //         'Content-Type':'application/json'
        //     }
        // }
        // config.headers.Authorization = `Bearer ${token}`;
        const res = await get(`/me`);
        return res.data;
    }catch(err){
        throw err;
    }
}