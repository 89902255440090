import Stops from './Stops';
 
export default ({stops, expressId, status, driver, thirdPartyDriver}: any) => {  
    return (<>
            {stops?.sort((a, b) => a?.sequenceNo - b?.sequenceNo)?.map((dropOff, index) => (
                <Stops key={index} dropOff={dropOff} expressId={expressId} status={status} driver={driver} thirdPartyDriver={thirdPartyDriver} />
            ))}
    </>
    )
}
