import { useDispatch } from 'react-redux';
import { Dispatch } from '@store';
 
import { Span } from '@common'
import { Modal } from 'antd'; 

type Props = {
    setOptionModal: Function,
    optionModal : boolean,
    id: string,
}

export const OptionModal = ({ setOptionModal, optionModal, id, ...props }: Props) => {
 
    const dispatch = useDispatch<Dispatch>();
    const { confirm } = Modal;

    const handledelete = async () => {
        setOptionModal(false);
        dispatch.UI.updateFormId({
            target: "deleteCalendar",
            value: id
        })
    }

    const handleEdit = async () => {
        setOptionModal(false);
        dispatch.UI.updateFormId({
            target: "editCalendar",
            value: id
        })
    }

    const showConfirm = () => {
        confirm({
            title: 'Menu',
            content: (
                <Span>Choose action</Span>
            ),
            onOk() {
                handledelete();
            },
            onCancel() {
                handleEdit();
            },
            okType: 'danger',
            okText: 'Delete',
            cancelText: 'Edit'
        })
    }

    return (
        <>
            {optionModal && showConfirm()}
        </>
    );
}   