/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RootState, Dispatch } from "@store";
import { useSelector, useDispatch } from "react-redux";

import {
  GridContainer,
  SubContainer,
  Span,
  SpanContainer,
  Button,
} from "@common";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { IoRefresh } from "react-icons/io5";
import Pickup from "./Dropdown/Pickup";
import DropOff from "./Dropdown/DropOff";
import Map from "../Map/index";

import {
  AssignedDriverModal,
  UpdateStatusModal,
  CancelModal,
  Loader,
} from "@components";

import {
  CHANGE_TO,
  COMPLETED_ORDERS,
  DONT_SHOW_CHANGE_TO,
  EXPRESS,
  FAILED_ORDERS_STATUS,
  PAYMENTSTATUS,
  PAYMENT_METHODS,
  VEHICLE,
} from "@constants";

import { Collapse } from "@mui/material";
import { formatDateV2, formatDecimalPrice, formatPhoneNumber } from "@utils";
import { Badge, Empty } from "antd";
import { CheckSquareFilled, InteractionFilled } from "@ant-design/icons";
import {
  getCustomerInformation,
  getDeliveryInfo,
  syncOrderToFireStore,
} from "@api";
import HistoryStatusBuy from "./HistoryStatusBuy";
import { useIsWindowActive } from "@hooks";
import { useLoadScript } from "@react-google-maps/api";
import { isEmpty } from "lodash";
import OrderItems from "./Dropdown/OrderItems";

let UNSUBSCRIBE: Unsubscribe;
const REACT_APP_API_MAP_KEY = process.env.REACT_APP_API_MAP_KEY!;

export const EzBuyServiceViewPage = ({ id }: any) => {
  useLoadScript({
    googleMapsApiKey: REACT_APP_API_MAP_KEY,
  });

  const isWindowActive = useIsWindowActive();
  const params: any = useParams();
  const tab = params?.tab;
  const history = useHistory();
  const pathName = useLocation().pathname;

  const [data, setData] = useState<any>({});
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [customer, setCustomerInfo] = useState<any>({});
  const [driverData, setDriverData] = useState([]) as any;

  const dispatch = useDispatch<Dispatch>();
  const prevRoute = useSelector((state: RootState) => state.UI.currentRoute);

  const isAssignOrReAssign =
    data?.driver?.driverProfile?.driverId || data?.thirdPartyDriver
      ? "Re-assign Driver"
      : "Assign Driver";

  const [pickUp, setPickUp] = useState(true);
  const [dropOff, setDropOff] = useState(true);
  const [orderItems, setOrderItems] = useState(true);
  const [map, setMap] = useState(false);
  const [assignedDriverModal, setAssignedDriverModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePickUp = (e: any) => {
    e.preventDefault();
    setPickUp(!pickUp);
  };

  const toggleDropOff = (e: any) => {
    e.preventDefault();
    setDropOff(!dropOff);
  };

  const toggleOrderItems = (e: any) => {
    e.preventDefault();
    setOrderItems(!orderItems);
  };

  const toggleMap = (e: any) => {
    e.preventDefault();
    setMap(!map);
  };

  const handleAssignedDriver = (e) => {
    e.preventDefault();
    setAssignedDriverModal(true);
  };

  const refresh = () => {
    getDeliveryData();
  };

  const db = getFirestore();

  const handleResync = async () => {
    try {
      setLoading(true);
      const orderCode = data?.order?.orderCode;
      const res = await syncOrderToFireStore(orderCode);
      if ([200, 201].includes(res?.status)) {
        getDeliveryData();
        setLoading(false);
        dispatch.UI.setAlert({
          message: "Order successfully resync",
          type: "Success",
        });
      }
    } catch (err: any) {
      setLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to resync order!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  useEffect(() => {
    if (data?.order) {
      (async () => {
        const res = await getCustomerInformation(data?.order?.orderCode);
        setCustomerInfo(res);
      })();
    }
  }, [data?.order]);

  useEffect(() => {
    if (isWindowActive) {
      if (tab === "openOrders") {
        const q = query(
          collection(db, "deliveries"),
          where("deliveryId", "==", id)
        );

        // UNSUBSCRIBE = onSnapshot(q, (onSnapshot) => {
        //   getDeliveryData();
        // });
        UNSUBSCRIBE = onSnapshot(q, (doc) => {
          const listData = (doc?.docs || [])?.map((ref) => {
            const data = ref.data();
            return data;
          });
          setDriverData(listData);
          getDeliveryData();
        });
      } else {
        getDeliveryData();
      }
    } else {
      setMap(false);
      if (UNSUBSCRIBE) UNSUBSCRIBE();
    }
    return () => {
      if (UNSUBSCRIBE) UNSUBSCRIBE();
    };
  }, [pathName, isWindowActive, id]);

  const getDeliveryData = async () => {
    try {
      setLoading(true);
      const res = await getDeliveryInfo(id);
      setData(res.results[0]);
      setLoading(false);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const renderOrderFare = useMemo(() => {
    if (!data?.order) return null;

    let orderDetail = data.order;
    if (data?.deliveryType === "buy" && data?.order?.feeDetails) {
      orderDetail = {
        ...data?.order,
        ...data?.order.feeDetails,
      };
    }

    const voucherTitle = orderDetail.voucherCode
      ? `(Voucher Code: ${orderDetail.voucherCode})`
      : "";

    let availabelFees = [
      {
        label: "Additional Stop Charge",
        value: orderDetail.additionalStopFee,
        key: "additionalStop",
      },
      {
        label: "Afterhours Charge",
        value: orderDetail.afterHoursSurchargeFee,
        key: "afterHoursSurcharge",
      },
      {
        label: "Cash Handling Fare",
        value: orderDetail.cashHandlingFee,
        key: "cashHandling",
      },
      {
        label: "Insulated Box",
        value: orderDetail.insulatedBoxFee,
        key: "insulatedBox",
      },
      {
        label: "Purchase Service",
        value: orderDetail.purchaseServiceFee,
        key: "purchaseService",
      },
      {
        label: "Overweight Handling Fare",
        value: orderDetail.overweightHandlingFee,
        key: "overweightHandling",
      },
      {
        label: "Premium Service Fare",
        value: orderDetail.premiumServiceFee,
        key: "premiumService",
      },
      {
        label: "Remittance Fare",
        value: orderDetail.remittanceFee,
        key: "remittance",
      },
      {
        label: "Queueing",
        value: orderDetail.queueingFee,
        key: "queueingService",
      },
      {
        label: "Helper Fee",
        value: orderDetail.helperFee,
        key: "helper",
      },
      {
        label: "Holiday Surcharge Fee",
        value: orderDetail.holidaySurchargeFee,
        key: "holidaySurcharge",
      },
      {
        label: "Consolidate the delivery",
        value: orderDetail.consolidatedDiscountAmount,
        key: "consolidateDelivery",
        isNegative: true,
      },
      {
        label: "EZ Buy Service Fee",
        value: data?.order?.feeDetails?.merchantServiceFee,
        key: "merchantServiceFee",
      },
      {
        label: "Driver Tip",
        value: orderDetail.tip,
        key: "tip",
      },
      {
        label: `Discount ${voucherTitle}`,
        value: orderDetail.discount,
        key: "discount",
        isNegative: true,
      },
    ];

    const { options } = data?.order;

    if (isEmpty(options)) {
      availabelFees = [...availabelFees].filter((item) => Boolean(item.value));
    } else {
      availabelFees = [...availabelFees].filter(
        (item) =>
          options.some((option) => item.key === option) || Boolean(item.value)
      );
    }

    return availabelFees.map((item, idx) => (
      <div className="px-4 flex items-center justify-between" key={idx}>
        <Span transform="capitalize">{item.label}</Span>
        {item.isNegative ? (
          <Span>
            <Span>- ₱ {` ${formatDecimalPrice(item.value)}`}</Span>
          </Span>
        ) : (
          <Span>
            <Span>₱ {` ${formatDecimalPrice(item.value)}`}</Span>
          </Span>
        )}
      </div>
    ));
  }, [data]);

  return (
    <>
      <Loader isLoading={loading} />
      {data && data?.order ? (
        <div className="bg-white rounded border-gray p-6">
          <GridContainer grid="five" padding="none">
            <div>
              <div className="bg-brown-1 p-8 text-center rounded-md my-4">
                <Span weight="semi" size="2xl" color="white">
                  ₱ &nbsp;
                  {formatDecimalPrice(data?.order?.totalCharge ?? 0)}
                </Span>
                <Span color="white">Total Amount</Span>
              </div>

              <div className="mb-2">
                <SpanContainer padding="pr-2">Status:</SpanContainer>
                <SpanContainer color="brown" weight="bold">
                  {EXPRESS[data?.status]}
                </SpanContainer>
              </div>

              {data?.paymentDetails?.paymentStatus && (
                <div className="my-4">
                  <span
                    className={
                      data?.paymentDetails?.paymentStatus ===
                        "payment_success" ||
                      data?.paymentDetails?.paymentStatus === "refund_success"
                        ? "tag-green"
                        : "tag-red"
                    }
                  >
                    {PAYMENTSTATUS[data?.paymentDetails?.paymentStatus]}
                  </span>
                </div>
              )}

              {!DONT_SHOW_CHANGE_TO.includes(data?.status) ? (
                <span
                  className="p-1 border-[1px] border-primary-main truncate text-sm text-primary-main cursor-pointer"
                  onClick={() => setUpdateStatusModal(true)}
                >
                  Change to <strong>{EXPRESS[CHANGE_TO[data?.status]]}</strong>
                </span>
              ) : (
                FAILED_ORDERS_STATUS.includes(data?.status) && (
                  <>
                    <div className="my-2">
                      <Span padding="pr-2">Cancel reason:</Span>
                      <Span weight="semi">{data?.cancelReason}</Span>
                    </div>
                    <div className="mb-2">
                      <Span padding="pr-2">Cancel at:</Span>
                      <Span weight="semi">
                        {formatDateV2(data?.cancelledAt)}
                      </Span>
                    </div>
                  </>
                )
              )}

              <SubContainer border="grey" padding="p-2">
                <Span weight="semi" color="brown" transform="uppercase">
                  Customer Details
                </Span>

                <Span margin="my-2">Order Number:</Span>
                <span className="text-sm font-semibold p-1 border-[1px] border-[#b7eb8f] bg-[#f6ffed]">
                  {data?.order?.orderCode}
                </span>

                <Span margin="mt-2">Contact Name:</Span>
                <Span weight="semi" transform="capitalize">
                  {customer?.firstName ?? ""} {customer?.middleName ?? ""}{" "}
                  {customer?.lastName ?? ""}
                </Span>

                <Span margin="mt-2">Contact No:</Span>
                <Span weight="semi" transform="capitalize">
                  {formatPhoneNumber(customer?.phoneNumber)}
                </Span>

                <Span margin="mt-2">Order Placed:</Span>
                <Span weight="semi" transform="capitalize">
                  {formatDateV2(data?.createdAt)}
                </Span>

                <Span margin="mt-2">Payment Method:</Span>
                <Span weight="semi" transform="capitalize">
                  {PAYMENT_METHODS[data?.paymentMethod]}
                </Span>

                <Span margin="mt-2">
                  {!data?.scheduleStartTime ? "Service Type:" : "Delivery at:"}
                </Span>
                <Span weight="semi" transform="capitalize">
                  {!data?.scheduleStartTime
                    ? "Delivery Now"
                    : formatDateV2(data?.scheduleStartTime)}
                </Span>
              </SubContainer>

              {data?.thirdPartyDriver ? (
                <Badge.Ribbon text="3rd Provider">
                  <SubContainer border="grey" padding="p-2">
                    <Span weight="semi" color="brown" transform="uppercase">
                      Driver Details
                    </Span>

                    <Span margin="mt-2">Name:</Span>
                    <Span weight="semi" transform="capitalize">
                      {data?.thirdPartyDriver?.contactName ?? ""}
                    </Span>

                    <Span margin="mt-2">Phone Number:</Span>
                    <Span weight="semi" transform="capitalize">
                      {formatPhoneNumber(
                        data?.thirdPartyDriver?.contactNumber
                      ) ?? ""}
                    </Span>

                    <Span margin="mt-2">Plate Number:</Span>
                    <Span weight="semi" transform="capitalize">
                      {data?.thirdPartyDriver?.plateNumber ?? ""}
                    </Span>
                  </SubContainer>
                </Badge.Ribbon>
              ) : (
                <SubContainer border="grey" padding="p-2">
                  <Span weight="semi" color="brown" transform="uppercase">
                    Driver Details
                  </Span>
                  {!data?.driver ? (
                    <div className="flex items-center justify-center flex-col">
                      <Empty />
                    </div>
                  ) : (
                    <>
                      <Span margin="mt-2">Name:</Span>
                      <Span weight="semi" transform="capitalize">
                        {`${data?.driver?.driverProfile?.lastName ?? ""}, ${
                          data?.driver?.driverProfile?.firstName ?? ""
                        } ${data?.driver?.driverProfile?.middleName ?? ""}`}
                      </Span>

                      <Span margin="mt-2">Phone Number:</Span>
                      <Span weight="semi" transform="capitalize">
                        {formatPhoneNumber(data?.driver?.phoneNumber) ?? ""}
                      </Span>
                    </>
                  )}
                </SubContainer>
              )}

              <SubContainer border="grey" padding="p-2">
                <Span weight="semi" color="brown" transform="uppercase">
                  REMARKS
                </Span>

                <Span margin="mt-2">Items:</Span>
                <Span
                  weight="semi"
                  transform="capitalize"
                  color={
                    data?.itemDescription === "" ? "lightgrey" : "required"
                  }
                >
                  {data?.itemDescription === ""
                    ? "No Data"
                    : data?.itemDescription}
                </Span>

                <Span margin="mt-2">Notes to Driver:</Span>
                <Span
                  weight="semi"
                  transform="capitalize"
                  color={data?.notes === "" ? "lightgrey" : "required"}
                >
                  {data?.notes === "" ? "No Data" : data?.notes}
                </Span>
              </SubContainer>
            </div>

            <div className="col-span-4">
              <GridContainer grid="six" padding="none">
                <div className="col-span-5">
                  <div className="flex justify-between items-center p-2 mt-4">
                    <Span weight="semi" size="lg">
                      Delivery Details
                    </Span>
                    <div className="flex flex-row justify-center items-center">
                      <div
                        className="p-1 border-2 border-primary-main opacity-70 cursor-pointer"
                        onClick={() => refresh()}
                      >
                        <IoRefresh color="black" />
                      </div>
                      <Button
                        padding="px-2"
                        radius="sm"
                        margin="mx-2"
                        onClick={handleResync}
                      >
                        Sync order to FireStore
                      </Button>
                      <Button
                        padding="px-2"
                        radius="sm"
                        onClick={() => history.push(`${prevRoute}?tab=${tab}`)}
                      >
                        Back to parcel
                      </Button>
                    </div>
                  </div>

                  <div
                    className={`${
                      !data?.order?.source || !data?.order?.device
                        ? "justify-around"
                        : "justify-between"
                    } flex py-2 px-2 border-[1px] border-grey-1 rounded-sm`}
                  >
                    <div className="flex flex-row">
                      <Span margin="mx-2" weight="semi">
                        Vehicle Used
                      </Span>
                      <Span transform="uppercase">
                        {VEHICLE[data?.vehicleType]}
                      </Span>
                    </div>

                    {(data?.order?.source || data?.order?.device) ===
                      undefined && (
                      <div className=" h-5 border-gray-300 border-l-[1px]" />
                    )}

                    <div className="flex flex-row">
                      {(data?.order?.source || data?.order?.device) !==
                        undefined && (
                        <div className="h-full border-gray-300 border-l-[1px]" />
                      )}
                      <Span margin="mx-2" weight="semi">
                        {" "}
                        Total Distance{" "}
                      </Span>
                      <Span transform="uppercase">{data?.distance} KM</Span>
                    </div>

                    {(data?.order?.source || data?.order?.device) !==
                      undefined && (
                      <>
                        <div className="flex flex-row">
                          <div className="h-full border-gray-300 border-l-[1px]" />
                          <Span margin="mx-2" weight="semi">
                            {" "}
                            Order Source{" "}
                          </Span>
                          <Span transform="uppercase">
                            {data?.order?.source ?? "Unknown"}
                          </Span>
                        </div>

                        <div className="flex flex-row">
                          <div className="h-full border-gray-300 border-l-[1px]" />
                          <Span margin="mx-2" weight="semi">
                            {" "}
                            Device Used{" "}
                          </Span>
                          <Span transform="uppercase">
                            {data?.order?.device ?? "unknown"}
                          </Span>
                        </div>
                      </>
                    )}
                  </div>

                  <SubContainer border="grey" padding="none">
                    <div
                      className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                      onClick={togglePickUp}
                    >
                      <div className="flex my-auto">
                        <CheckSquareFilled
                          style={{
                            fontSize: "1.8rem",
                            color: "#84b15c",
                            marginRight: "0.8rem",
                          }}
                        />
                        <Span margin="my-auto">Pick-up Location</Span>
                      </div>

                      {pickUp ? (
                        <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                      ) : (
                        <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                      )}
                    </div>

                    <Collapse in={pickUp}>
                      <Pickup
                        pickUp={data?.pickup ?? data?.pickUp}
                        driver={data?.driver}
                        thirdPartyDriver={data?.thirdPartyDriver}
                      />
                    </Collapse>

                    <div
                      className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                      onClick={toggleDropOff}
                    >
                      <div className="flex my-auto">
                        <InteractionFilled
                          style={{
                            fontSize: "1.8rem",
                            color: "rgb(113, 64, 184)",
                            marginRight: "0.8rem",
                          }}
                        />
                        <Span margin="my-auto">Drop-off Location</Span>
                      </div>
                      {dropOff ? (
                        <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                      ) : (
                        <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                      )}
                    </div>

                    <Collapse in={dropOff}>
                      <DropOff
                        stops={data?.stops}
                        expressId={id}
                        status={data?.status}
                        driver={data?.driver}
                        thirdPartyDriver={data?.thirdPartyDriver}
                        arrivedAtDropOffAt={data?.arrivedAtDropOffAt}
                      />
                    </Collapse>

                    <div
                      className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                      onClick={toggleOrderItems}
                    >
                      <div className="flex my-auto">
                        <CheckSquareFilled
                          style={{
                            fontSize: "1.8rem",
                            color: "#84b15c",
                            marginRight: "0.8rem",
                          }}
                        />
                        <Span margin="my-auto">Order Items</Span>
                      </div>

                      {orderItems ? (
                        <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                      ) : (
                        <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                      )}
                    </div>

                    <Collapse in={orderItems}>
                      <OrderItems orderItems={data?.order?.orderItems} />
                    </Collapse>

                    <div
                      className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                      onClick={toggleMap}
                    >
                      <div className="flex my-auto">
                        <CheckSquareFilled
                          style={{
                            fontSize: "1.8rem",
                            color: "rgb(255, 77, 79)",
                            marginRight: "0.8rem",
                          }}
                        />
                        <Span margin="my-auto">Map Navigation</Span>
                      </div>
                      {map ? (
                        <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                      ) : (
                        <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                      )}
                    </div>

                    <Collapse in={map}>
                      {data && (
                        <Map
                          data={data}
                          driverData={driverData[0]}
                          isShow={map}
                        />
                      )}
                    </Collapse>
                  </SubContainer>

                  <SubContainer border="grey" padding="none">
                    <GridContainer padding="p-2" grid="two" gap="none">
                      <div className=" border-r-[1px] border-gray-300 border-solid">
                        {renderOrderFare}

                        <div className="px-4 flex items-center justify-between">
                          <Span color="brown" weight="semi">
                            Total Delivery Charge
                          </Span>
                          <Span color="brown" weight="semi">
                            ₱ {formatDecimalPrice(data?.order?.deliveryFee)}
                          </Span>
                        </div>
                      </div>

                      <div>
                        <div className="px-4 flex items-center justify-between">
                          <Span weight="semi">Delivery Fare</Span>
                        </div>
                        {data?.fee?.detail
                          ?.filter(
                            (detail) =>
                              ![
                                "amount_to_be_collected",
                                "amount_to_be_remitted",
                              ].includes(detail?.type)
                          )
                          .filter((detail) => detail?.amount !== 0)
                          ?.map((detail, index) => (
                            <div
                              key={index}
                              className="px-4 flex items-center justify-between"
                            >
                              <Span transform="capitalize">
                                {detail?.particular}
                              </Span>
                              <Span>
                                ₱ {formatDecimalPrice(detail?.amount)}
                              </Span>
                            </div>
                          ))}
                      </div>
                    </GridContainer>

                    <hr className="mx-4" />

                    <GridContainer padding="p-2" grid="two" gap="none">
                      <div className="px-2 flex items-center justify-between border-r-[1px] border-gray-300 border-solid">
                        <Span color="violet" weight="semi" size="lg">
                          Grand Total
                        </Span>
                        <Span color="violet" weight="semi" size="lg">
                          ₱ {formatDecimalPrice(data?.order?.totalCharge)}
                        </Span>
                      </div>

                      <div className="px-2 flex items-center justify-between">
                        <Span color="violet" weight="semi" size="lg">
                          Total Fee
                        </Span>
                        <Span color="violet" weight="semi" size="lg">
                          ₱ {formatDecimalPrice(data?.fee?.total) ?? 0}
                        </Span>
                      </div>
                    </GridContainer>
                  </SubContainer>

                  {!COMPLETED_ORDERS.includes(data?.status) && (
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row">
                        <Button
                          bg="green"
                          padding="px-2"
                          radius="sm"
                          onClick={handleAssignedDriver}
                        >
                          {isAssignOrReAssign}
                        </Button>
                      </div>
                      <div>
                        <Button
                          bg="red"
                          padding="px-2"
                          radius="sm"
                          onClick={() => setCancelModal(true)}
                        >
                          Cancel Delivery
                        </Button>
                      </div>
                    </div>
                  )}

                  <UpdateStatusModal
                    status={CHANGE_TO[data?.status]}
                    orderId={id}
                    updateStatusModal={updateStatusModal}
                    setUpdateStatusModal={setUpdateStatusModal}
                  />

                  <AssignedDriverModal
                    setAssignedDriverModal={setAssignedDriverModal}
                    assignedDriverModal={assignedDriverModal}
                    deliveryIds={id}
                    isAssignOrReAssign={isAssignOrReAssign}
                    vehicleType={data?.vehicleType}
                    driverGroup={"parcel_and_grocery_delivery"}
                  />

                  <CancelModal
                    cancelModal={cancelModal}
                    setCancelModal={setCancelModal}
                    orderId={id}
                  />
                </div>

                <HistoryStatusBuy buy={data} />
              </GridContainer>
            </div>
          </GridContainer>
        </div>
      ) : null}
    </>
  );
};
