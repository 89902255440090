import { useGetCurrentRoleAccesses } from '@hooks';
import { Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { AttachmentPage } from '@containers'

export const Attachment = () => { 
  const module_name = 'manage_attachment';
  useGetCurrentRoleAccesses(module_name);

  return (
    <>
      <MainLayout>
        <SideBar />  
        <Header /> 
        <ContainerLayout>
          <AttachmentPage />
        </ContainerLayout>
      </MainLayout>
    </>
  )
}