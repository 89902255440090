/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, NoData, Span } from "@common";
import { Button, Tabs, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";
import { COLUMNS, HEADER_CUSTOMER_ORDER } from "./column";
import { useMediaQuery, useTheme } from "@mui/material";

import { useIsWindowActive } from "@hooks";
import { formatDateV2, useQuery } from "@utils";
import { Table, SearchMerchant, CSVLinkContent } from "@components";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { IoMdDownload } from "react-icons/io";
import moment from "moment";
import { COMPLETED_ORDERS, COMPLETED_ORDERS_STATUS } from "@constants";

export const MerchantOrdersPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const columns = useMemo(() => COLUMNS, []);

  const now = moment();
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const maxSearchInputCount = 3;

  const history = useHistory();
  const queries = useQuery();
  const tab: any = queries.get("tab");
  const isWindowActive = useIsWindowActive();
  const [activeTab, setActiveTab] = useState(tab);
  const { TabPane } = Tabs;
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  var RECONNECT_TIMER: any;
  const [searchData, setSearchData] = useState([
    {
      filter: "status",
      value: "all",
    },
  ]);
  const [csvData, setCsvData] = useState([] as any);

  const { original_data: mainData, data }: any = useSelector(
    (state: RootState) => state.Table.tables.merchantOrder
  );

  const completedData: any[] = useSelector(
    (state: RootState) => state.Table.tables.merchantOrdersCompleted?.data
  ).reverse();
  const filters: any = useSelector(
    (state: RootState) => state.Table.tables.merchantOrderCompleted?.filters
  );

  const searchCompleted = () => {
    dispatch.Table.getMerchantOrders({
      name: "store_supplier_order",
      table: "merchantOrder",
      tab,
    });
  };

  const setFilters = () => {
    if (activeTab === "openOrders") {
      let result: any[] = mainData;
      searchData
        ?.filter((s) => s?.value !== "")
        ?.map((search) => {
          switch (search.filter) {
            case "status":
              result = result?.filter((order) => {
                if (
                  order?.status.search(
                    new RegExp(search.value === "all" ? "" : search.value, "i")
                  ) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "id":
              result = result?.filter((order) => {
                if (order?.id?.search(new RegExp(search.value, "i")) > -1) {
                  return true;
                }
                return false;
              });
              break;
            case "storeName":
              result = result?.filter((order) => {
                if (
                  order?.store?.name?.search(new RegExp(search.value, "i")) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "contactNumber":
              result = result?.filter((order) => {
                if (
                  order?.customer?.contact?.contactNumber?.search(
                    new RegExp(search.value, "i")
                  ) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "contactName":
              result = result?.filter((order) => {
                const contactName = `${order?.customer?.firstName ?? ""} ${
                  order?.customer?.middleName ?? ""
                } ${order?.customer?.lastName ?? ""}`;
                if (contactName.search(new RegExp(search.value, "i")) > -1) {
                  return true;
                }
                return false;
              });
              break;
            default:
              result = [];
              break;
          }
        });
      dispatch.Table.setTableData({ table: "merchantOrder", data: result });
    } else {
      let stringKeyword = "";
      searchData
        ?.filter((s) => s.value !== "")
        ?.forEach((search) => {
          const string =
            search?.value === "all"
              ? "&status[]=completed&status[]=canceled"
              : `&${search?.filter}=${search?.value}`;
          stringKeyword = stringKeyword + string;
        });
      setIsFirstSearch(false);
      dispatch.Table.updateKeyword({
        table: "merchantOrdersCompleted",
        value: stringKeyword,
      });
      searchCompleted();
    }
  };

  useEffect(() => {
    if (activeTab === "completedOrders") {
      if (isFirstSearch) {
        dispatch.Table.updateState({ isLoading: false });
      } else {
        searchCompleted();
      }
    }
  }, [filters]);

  useEffect(() => {
    if (activeTab === "openOrders") {
      setIsFirstSearch(true);
      setSearchData([{ filter: "status", value: "all" }]);
      history.push("/parcel-management/merchant-orders?tab=openOrders");
    }

    if (activeTab === "completedOrders") {
      setSearchData([{ filter: "status", value: "all" }]);
      history.push("/parcel-management/merchant-orders?tab=completedOrders");
    }
  }, [activeTab]);

  useEffect(() => {
    // closeLoader();
  }, [completedData, data]);

  useEffect(() => {
    if (searchData?.length !== 1 || searchData[0].value !== "all") {
      setFilters();
    }
  }, [mainData]);

  useEffect(() => {
    if (data.length > 0 || tab === "completedOrders")
      dispatch.Table.updateState({ isLoading: false });
    if (isWindowActive) {
      if (tab === "openOrders") {
        RECONNECT_TIMER = setTimeout(() => {
          dispatch.Table.getMerchantOrders({
            name: "store_supplier_order",
            table: "merchantOrder",
            tab,
          });
        }, 1500);
      }
    } else {
      if (tab === "openOrders") {
        dispatch.Table.unsubscribed();
        clearTimeout(RECONNECT_TIMER);
      }
    }

    return () => {
      dispatch.Table.updateState({ isLoading: true });
      dispatch.Table.unsubscribed();
    };
  }, [isWindowActive, tab]);

  useEffect(() => {
    // const CSVD = tab === "openOrders" ? data : completedData;
    const newData = data
      ?.filter((s) => COMPLETED_ORDERS.includes(s.status))
      ?.map((data) => {
        return {
          ...data,
          customerName: `${data?.customer?.firstName}  ${data?.customer?.middleName} ${data?.customer?.lastName}`,
          storeName: `${data?.store?.name}`,
          storeContact: `${data?.store?.contactName} (${data?.store?.contactNumber})`,
          created: `${formatDateV2(data?.createdAt)}`,
          statusCompleted: `${COMPLETED_ORDERS_STATUS[data?.status]}`,
        };
      });
    setCsvData(newData);
  }, [data, completedData]);

  return (
    <>
      <div className="relative">
        <Container border="grey" padding="none">
          <div className="p-4">
            <div className="flex justify-between">
              <Span margin="mt-4" weight="bold" size="2xl">
                All Merchant Orders
              </Span>

              <div className="flex justify-end px-4 mt-4">
                <Button
                  // onClick={showDrawer}
                  type="primary"
                  size="large"
                >
                  <div className="flex pr-2 items-center">
                    <IoMdDownload className="my-auto mr-2 text-2xl items-center inline-flex" />
                    <CSVLinkContent
                      data={csvData}
                      headers={HEADER_CUSTOMER_ORDER}
                      filename={`Customer-Order-${now.format(
                        "MM/DD/YYYY hh:ss"
                      )}`}
                    />
                  </div>
                </Button>
              </div>
            </div>
            {/* <Tabs
              defaultActiveKey={activeTab}
              onChange={(key) => setActiveTab(key)}
            >
              <TabPane tab="Open Orders" key="openOrders" />
              <TabPane tab="Completed Orders" key="completedOrders" />
            </Tabs> */}
          </div>

          <div className="p-4 w-full flex flex-row items-center">
            {searchData?.map((search, index) => (
              <SearchMerchant
                search={search}
                searchData={searchData}
                setSearchData={setSearchData}
                setFilters={setFilters}
                tab={tab}
                index={index}
              />
            ))}

            {!matchDownSm && (
              <Button
                type="primary"
                size="large"
                icon={
                  <SearchOutlined
                    style={{ display: "inline-flex", alignItems: "center" }}
                  />
                }
                onClick={setFilters}
              >
                Search
              </Button>
            )}

            <div className="px-2">
              <Button
                type="primary"
                size="large"
                className="px-2"
                icon={
                  <PlusOutlined
                    style={{ display: "inline-flex", alignItems: "center" }}
                  />
                }
                onClick={() => {
                  if (maxSearchInputCount === searchData?.length)
                    return message.error("Already reach search limit!");
                  setSearchData([
                    ...searchData,
                    { filter: "pleaseSelect", value: "" },
                  ]);
                }}
              >
                Add Search
              </Button>
            </div>
          </div>

          <div className="overflow-auto">
            {/* {!data?.length && !completedData.length ? ( */}
            {!data?.length ? (
              <NoData />
            ) : (
              <Table
                columns={columns}
                // data={tab === "openOrders" ? data : completedData}
                data={data}
                dataType="merchant-orders"
                sortName="createdAt"
                descSort={true}
              />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};
