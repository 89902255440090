import { ColumnFilter } from '@components';  
import { formatDate } from '@utils';
import { BsFillEyeFill } from 'react-icons/bs';

export const COLUMNS = [ 
    {
        accessor: 'url',
        Header: ' ',
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
                <div className="flex transition-all hover:scale-105 p-2">
                    <a href={d?.url} target='_blank' className='m-auto flex'>
                        <BsFillEyeFill className="text-lg text-orange-1 m-auto"/>  
                        <span className="mx-1 text-[#000]">View</span>
                    </a>
                </div>
            )
        }
    },
    {
        accessor: 'reference',
        Header: 'ID',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
                <div className='flex justify-center'>
                    <span className="text-center">{d.reference}</span>
                </div>
        )}
    },  
    {
        accessor: 'description',
        Header: 'Description',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
                <div className='flex justify-center'>
                    <span className="text-center">{d.description}</span>
                </div>
        )}
    },    
    {
        accessor: 'lat',
        Header: 'Lat',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
            <a href={`https://www.google.com/maps/place/${d.lat + "," + d.lng}`} target="_blank">
                <span className="text-center">{d.lat + "," + d.lng}</span>
            </a>
        )}
    },   
    {
        accessor: 'paymentRemarks',
        Header: 'Payment Remarks',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
                <div className='flex justify-center'>
                    <span className="text-center">{d.paymentRemarks}</span>
                </div>
        )}
    },   
    {
        accessor: 'customerReference',
        Header: 'Customer Reference',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
                <div className='flex justify-center'>
                    <span className="text-center">{d.customerReference}</span>
                </div>
        )}
    }, 
    {
        accessor: 'referenceOrderNumber',
        Header: 'Reference Order Number',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
                <div className='flex justify-center'>
                    <span className="text-center">{d.referenceOrderNumber}</span>
                </div>
        )}
    },  
    // {
    //     accessor: 'tags',
    //     Header: 'Tags',
    //     Filter: ColumnFilter,
    //     isVisible: true,
    //     Cell: data => {
    //         const d = data?.row?.original; 
    //         return (
    //             <div className='flex justify-center w-52'>
    //                 <span className="text-center capitalize">{d?.tags?.filter(Boolean).join(', ')}</span>
    //             </div>
    //     )}
    // },  
    {
        accessor: 'time',
        Header: 'Date Upload',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
                <div className='flex justify-center w-52'>
                    <span className="text-center">{formatDate(d?.time, true)}</span>
                </div>
        )}
    },  
]