/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from "react-redux";
import { useState } from "react";

import { addNewDriver, uploadProfile } from "@api";
import { onlyNumberKey } from "@utils";
import { Container, Span } from "@common";

import "../../containers/DriverManagement/AllDrivers/Driver.css";
import { Dispatch } from "@store";
import { DRIVERGROUP, RESTRICTION_CODE } from "@constants";

import {
  provinceData,
  cityData,
  clusterData,
  area,
  clustercities,
} from "@components";

import {
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Button,
  Popconfirm,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Vehicle, BackDrop, UploadPhoto } from "@components";
const { Option } = Select;

type Props = {
  addDriver: boolean;
  setAddDriver: Function;
  getData: Function;
};
type RequiredMark = boolean | "optional";

export const AddDriver = ({
  addDriver,
  setAddDriver,
  getData,
  ...props
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(false);
  const [activeVehicleIndex, setActiveVehicleIndex] = useState(null);
  const [isLoading, setisloading] = useState(false);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);  
  const [resCode, setResCode] = useState<{ code: string }[]>([]);

  const initialState = {
    address: "",
    city: "",
    codCapable: false,
    deliveryArea: " ",
    deliveryCity: "",
    deliveryCluster: "",
    driverGroup: "",
    driverStatus: "inactive",
    email: "",
    firstName: "",
    lastName: "",
    licenseRestriction: "",
    middleName: "",
    phoneNumber: "",
    province: "",
    status: "inactive",
    vehicles: [
      {
        registeredPlateNumber: "",
        status: "",
        vehicleModel: "",
        vehicleType: "",
      },
    ], 
    emergencyContact: {
      name: "",
      phoneNumber: "",
      relationship: "",
      barangayContactNumber: "",
    },
    resCode: [
      {
        code: ""
      }
    ], 
  };

  const [driverDetails, setDriverDetails] = useState(initialState);
  const error = (errMsg) => {
    message.error(errMsg);
  };

  const handleAddDriver = async () => {
    try {
      setisloading(true);
      const res: any = await addNewDriver({
        ...driverDetails,
        phoneNumber: `+63${driverDetails?.phoneNumber}`,
        emergencyContact: {
          ...driverDetails?.emergencyContact,
          phoneNumber: `+63${driverDetails?.emergencyContact?.phoneNumber}`,
        },
        driverRestrictionCode: resCode,
      });
      if ([201, 200].includes(res.status)) {
        const driverId = res?.data?.driverProfile?.driverId;
        await uploadProfile(driverId, { file, url: imageUrl });
        setisloading(false);
        setAddDriver(false);
        dispatch.UI.setAlert({
          message: "Driver user added!",
          type: "Success",
        });
        setDriverDetails(initialState);
        getData();
      }
    } catch (err: any) {
      const errMsg =
        err?.response?.data?.message ?? "Unable to add new driver!";
      setisloading(false);
      error(errMsg);
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setDriverDetails({
      ...driverDetails,
      [name]: value,
    });
  };

  // ADDRESS
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const [cities, setCities] = useState(cityData[provinceData[0]]);
  const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);

  const handleProvinceChange = (value) => {
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
    setDriverDetails({ ...driverDetails, province: value });
  };

  const onSecondCityChange = (value) => {
    setSecondCity(value);
    setDriverDetails({ ...driverDetails, deliveryCity: value });
  };

  const [clusters, setClusters] = useState(area[clusterData[0]]);
  const [secondArea, setArea] = useState(area[clusterData[0]][0]);
  const [clustercity, setClusterCity] = useState(
    clustercities[area[clusterData[0]][0]]
  );

  const handleClusterChange = (value) => {
    setClusters(area[value]);
    setArea(area[value][0]);
    setDriverDetails({ ...driverDetails, deliveryCluster: value });
  };

  const onSecondAreaChange = (value) => {
    setArea(value);
    setClusterCity(clustercities[value]);
    setDriverDetails({ ...driverDetails, deliveryArea: value });
  };


  const handleSelectChange = (selectedValues: string[]) => {
    const updatedResCode = selectedValues.map(code => ({ code }));
    setResCode(updatedResCode);
  };

  return (
    <Drawer
      title="Create a new driver"
      placement="right"
      width={500}
      onClose={() => {
        setDriverDetails(initialState);
        setAddDriver(false);
      }}
      visible={addDriver}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in the profile information?"
              onConfirm={() => {
                setDriverDetails(initialState);
                setAddDriver(false);
              }}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Add Driver
            </Button>
          </Space>
        </div>
      }
    >
      <BackDrop />
      <UploadPhoto file={file} setFile={setFile} addDriver={addDriver} />

      <div className="pb-8 pt-4">
        <Span color="lightgrey">Or you can upload your image source below</Span>
        <Input
          placeholder="Image Source"
          onChange={(e: any) => setImageUrl(e?.target?.value)}
        />
      </div>

      <Container border="grey" margin="mb-2">
        <div className="flex justify-between">
          <Span weight="semi" margin="my-auto">
            System Status
          </Span>

          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            onChange={(v) =>
              setDriverDetails({
                ...driverDetails,
                status: v ? "active" : "inactive",
              })
            }
          />
        </div>
      </Container> 

      <Form
        onFinish={handleAddDriver}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
        requiredMark={requiredMark}
      >
        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Profile
          </Span>

          <Form.Item
            label="Driver Group"
            name="driverGroup"
            rules={[{ required: true }]}
          >
            <Select
              className="w-full"
              placeholder="Driver Group"
              onChange={(v) =>
                setDriverDetails({ ...driverDetails, driverGroup: v })
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {DRIVERGROUP?.map((type, index) => (
                <Option key={index} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="First Name"
              onChange={onChange}
              name="firstName"
            />
          </Form.Item>

          <Form.Item label="Middle Name" name="middleName">
            <Input
              placeholder="Middle Name (Optional)"
              onChange={onChange}
              name="middleName"
            />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Last Name"
              onChange={onChange}
              name="lastName"
            />
          </Form.Item>
        </div>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Contact Details
          </Span>

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true }]}
          >
            <Input
              addonBefore="+63"
              placeholder="Phone Number"
              maxLength={10}
              onChange={onChange}
              onKeyPress={onlyNumberKey}
              name="phoneNumber"
            />
          </Form.Item>

          <Form.Item label="Email" name="email">
            <Input
              placeholder="Email"
              onChange={onChange}
              type="email"
              name="email"
            />
          </Form.Item>
        </div>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Emergency Contact Details
          </Span>

          <Form.Item
            label="Name"
            name="emergencyname"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Name"
              name="name"
              onChange={(e) => {
                setDriverDetails({
                  ...driverDetails,
                  emergencyContact: {
                    ...driverDetails?.emergencyContact,
                    name: e?.target.value,
                  },
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="emergencycontactNumber"
            rules={[{ required: true }]}
          >
            <Input
              addonBefore="+63"
              placeholder="Phone Number"
              maxLength={10}
              onKeyPress={onlyNumberKey}
              name="phoneNumber"
              onChange={(e) => {
                setDriverDetails({
                  ...driverDetails,
                  emergencyContact: {
                    ...driverDetails?.emergencyContact,
                    phoneNumber: e?.target.value,
                  },
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Relationship"
            name="emergencyrelationship"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Relationship"
              name="relationship"
              onChange={(e) => {
                setDriverDetails({
                  ...driverDetails,
                  emergencyContact: {
                    ...driverDetails?.emergencyContact,
                    relationship: e?.target.value,
                  },
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Barangay Contact Number"
            name="emergencybarangayContactNumber"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Barangay Contact Number"
              name="barangayContactNumber"
              onChange={(e) => {
                setDriverDetails({
                  ...driverDetails,
                  emergencyContact: {
                    ...driverDetails?.emergencyContact,
                    barangayContactNumber: e?.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </div>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Address
          </Span>

          <Form.Item
            label="Province"
            name="province"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="Province"
              onChange={handleProvinceChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option value="" selected disabled hidden>
                City
              </Option>
              {provinceData
                .sort((a, b) => a.localeCompare(b))
                .map((province) => (
                  <Option key={province} value={province}>
                    {province}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="City"
            name="deliveryCity"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="City"
              onChange={onSecondCityChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {cities
                .sort((a, b) => a.localeCompare(b))
                .map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="House No., Street, Barangay" name="address">
            <Input
              placeholder="House No., Street, Barangay"
              onChange={onChange}
              name="address"
            />
          </Form.Item>
        </div>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Delivery Details
          </Span>

          <Form.Item
            label="Cluster"
            name="deliveryCluster"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="Cluster"
              onChange={handleClusterChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {clusterData.map((clusterr) => (
                <Option key={clusterr} value={clusterr}>
                  {clusterr}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Area"
            name="deliveryArea"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="Area"
              onChange={onSecondAreaChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {clusters
                .sort((a, b) => a.localeCompare(b))
                .map((area) => (
                  <Option key={area} value={area}>
                    {area}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="City" name="city" rules={[{ required: true }]}>
            <Select
              showSearch
              onSearch={onSearch}
              className="w-full"
              placeholder="City"
              onChange={(v) => setDriverDetails({ ...driverDetails, city: v })}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {clustercity
                .sort((a, b) => a.localeCompare(b))
                .map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>

        <Container padding="none" margin="mb-2">
          <div className="flex justify-between">
            <Span margin="my-auto">COD capable</Span>

            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(v) =>
                setDriverDetails({ ...driverDetails, codCapable: v })
              }
            />
          </div>
        </Container>

        <div className="text-left py-4">
          <Span weight="semi" size="lg" transform="uppercase">
            Registered Vehicle
          </Span>

          <Form.Item
            label="Driver's License Restriction"
            name="driverRestrictionCode"
            rules={[{ required: true }]}
          >
            <Select
                  placeholder="Driver's License Restriction"
                  mode="multiple"
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear
                  onChange={handleSelectChange}
                >
                  {RESTRICTION_CODE.map(item => (
                    <Select.Option
                      key={item.value}
                      value={item.value}
                      label={item.label}
                    >
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
          </Form.Item>
        </div>

        {driverDetails?.vehicles?.map((vehicle, index) => (
          <Vehicle
            driverDetails={driverDetails}
            setDriverDetails={setDriverDetails}
            index={index}
            activeVehicleIndex={activeVehicleIndex}
            setActiveVehicleIndex={setActiveVehicleIndex}
            vehicle={vehicle}
            form={form}
          />
        ))}

        <div className="mt-4">
          <Button
            ghost
            type="primary"
            icon={
              <PlusOutlined
                style={{ display: "inline-flex", alignItems: "center" }}
              />
            }
            onClick={() =>
              setDriverDetails({
                ...driverDetails,
                vehicles: [
                  ...driverDetails.vehicles,
                  {
                    registeredPlateNumber: "",
                    status: "",
                    vehicleModel: "",
                    vehicleType: "",
                  },
                ],
              })
            }
          >
            Add Vehicle
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
