import { useLocation } from 'react-router-dom'
import { Button, Select, Input } from 'antd'; 
import { DeleteOutlined } from '@ant-design/icons';
import { VEHICLETYPE } from '@constants'


import { cityData, clusterData, area } from "@components";

import { onlyNumberKey } from '@utils';

type Props = {
  searchData: any;
  setSearchData: any;  
  index: any;
  search: any;
}

export const SearchInputDM = ({ setSearchData, searchData, index, search }: Props) => {
  
  const city: any = Object.values(cityData);
  const arrayCity = [].concat.apply([], city).sort((a: any, b: any) => a.localeCompare(b));
  const Area: any = Object.values(area);
  const arrayArea = [].concat.apply([], Area).sort((a: any, b: any) => a.localeCompare(b)); 
  
  const searchFilters = searchData?.map((s) => s?.filter);
  const pathName = useLocation().pathname;

  const FILTERVALUES = {
    approvalStatus: 'Approval Status', 
    driverStatus: 'Driver Status',
    status: 'System Status',
    driverId: 'Driver ID',
    name: 'Driver Name',
    phoneNumber: 'Phone Number',
    vehicleType: 'Vehicle',
    deliveryCluster: 'Cluster',
    deliveryCity: 'City',
    deliveryArea: 'Area',
    pleaseSelect: 'Please select',
  }

  const searchOptions = [
    {
      value: 'approvalStatus',
      label: 'Approval Status'
    },
    {
      value: 'driverStatus',
      label: 'Driver Status'
    },
    {
      value: 'status',
      label: 'System Status'
    }, 
    {
      value: 'driverId',
      label: 'Driver ID'
    },
    {
      value: 'name',
      label: 'Driver Name'
    },
    {
      value: 'phoneNumber',
      label: 'Phone Number'
    },
    {
      value: 'vehicleType',
      label: 'Vehicle'
    }, 
    {
      value: 'deliveryCluster',
      label: 'Cluster'
    }, 
    {
      value: 'deliveryCity',
      label: 'City'
    }, 
    {
      value: 'deliveryArea',
      label: 'Area'
    },  
  ]

  const { Option } = Select;

  const renderInput = () => {
    switch (search?.filter) {
      case 'driverStatus':
        return (
          <Select
            style={{ width: '100%' }}
            bordered={false}
            value={search?.value}
            onChange={(v: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: v } : item)])}
           > 
            <Option value="all">All</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        )
      case 'approvalStatus':
        return (
          <Select
            style={{ width: '100%' }}
            bordered={false}
            optionFilterProp="children"
            placeholder='Choose Status'
            value={search?.value}
            onChange={(v: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: v } : item)])}
            filterOption={(input, option) =>
              (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="all">All</Option>
            <Option value="approved">Approved</Option>
            <Option value="pending">Pending</Option>
            <Option value="rejected">Rejected</Option>
          </Select>
        )
      case 'status':
        return (
          <Select
            style={{ width: '100%' }}
            bordered={false}
            optionFilterProp="children"
            placeholder='Choose Status'
            value={search?.value}
            onChange={(v: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: v } : item)])}
            filterOption={(input, option) =>
              (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        ) 
      case 'vehicleType':
      return (
        <Select
          style={{ width: '100%' }}
          bordered={false}
          optionFilterProp="children"
          placeholder='Choose Vehicle'
          value={search?.value}
          onChange={(v: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: v } : item)])}
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          <Option value="all">All</Option>
          {VEHICLETYPE?.map(item => (
            <Option key={item?.value} value={item?.value}>{item?.name}</Option>
          ))}
        </Select>
      )
      case 'deliveryCluster':
      return (
        <Select
          style={{ width: '100%' }}
          bordered={false}
          optionFilterProp="children"
          placeholder='Choose Cluster'
          value={search?.value}
            onChange={(v: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: v } : item)])}
           filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          <Option value="all">All</Option>
          {clusterData?.map(cluster => (
            <Option key={cluster} value={cluster}>{cluster}</Option>
          ))}
        </Select>
      )
    case 'deliveryCity':
      return (
        <Select
          showSearch
          style={{ width: '100%' }}
          bordered={false}
          optionFilterProp="children"
          placeholder='Choose City'
          value={search?.value}
            onChange={(v: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: v } : item)])}
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          <Option value="all">All</Option>
          {arrayCity?.map((city, index) => (
            <Option key={index} value={city}>{city}</Option>
          ))}
        </Select>
      )
    case 'deliveryArea':
      return (
        <Select
          showSearch
          style={{ width: '100%' }}
          bordered={false}
          optionFilterProp="children"
          placeholder='Choose Area'
          value={search?.value}
            onChange={(v: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: v } : item)])}
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          <Option value="all">All</Option>
          {arrayArea?.map((area, index) => (
            <Option key={index} value={area}>{area}</Option>
          ))}
        </Select>
      )
      case 'driverId':
        return (
          <Input
            style={{ width: '100%' }}
            bordered={false}
            placeholder='Driver ID' 
            value={search?.value}
            onChange={(e: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: e.target.value } : item)])}
          />
        )
      case 'name':
        return (
          <Input
            style={{ width: '100%' }}
            bordered={false}
            placeholder='Driver Name' 
            value={search?.value}
            onChange={(e: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: e.target.value } : item)])}
          />
        )
      case 'phoneNumber':
        return (
          <Input
            style={{ width: '100%' }}
            bordered={false}
            placeholder='Phone Number' 
            onKeyPress={onlyNumberKey}
            value={search?.value}
            onChange={(e: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: e.target.value } : item)])}
          />
        )
    default:
      return <></>
    }
  }

  return (<>
    <div className='w-3/4 md:w-2/4 lg:w-2/5 border-[1px] border-gray-400 flex flex-row-reverse justify-start items-center p-1 rounded-sm search_border'>
      {renderInput()}

      <div className='h-[20px] border-[1px] border-gray-300' />

      <Select
        style={{ width: '100%' }}
        bordered={false}
        value={FILTERVALUES[search.filter]}
        onChange={(v: any) => {
          const newItem = { filter: v, value: v === 'driverStatus' ? 'all' : '' };
          setSearchData([...searchData?.map((item, i) => i === index ? newItem : item)])
        }}

      >
        {searchOptions 
        ?.filter(option => pathName?.search('pending-drivers') === -1 ? !searchFilters?.includes(option.value) : !searchFilters?.includes(option.value) && option.value !== 'approvalStatus')
        ?.map((option) => (<>  
          <Option key={option.value} value={option.value}>{option.label}</Option> 
         </>))} 
      </Select> 
    </div>

    <div className='px-2'>
      {index !== 0 && (
        <Button
          shape='circle'
          icon={<DeleteOutlined />}
          type='ghost'
          danger
          onClick={() => {
            setSearchData([...searchData?.filter((item, i) => i !== index)])
          }}
        />
      )}
    </div> 
  </>
  )
}