import { Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { useGetCurrentRoleAccesses } from '@hooks';

import { RentalPage } from '@containers'

export const RentalManagement = () => {
    const module_name = 'manage_rental';
    useGetCurrentRoleAccesses(module_name);
    
    return (
        <>
            <MainLayout>
                <SideBar />  
                <Header /> 
                <ContainerLayout>
                    <RentalPage /> 
                </ContainerLayout>
            </MainLayout>
        </>
    )
}