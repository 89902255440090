import { useLocation } from "react-router-dom";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

type Props = {
  searchData: any;
  setSearchData: any;
  deletedSearch?: any;
  setDeletedSearch?: any;
  index: any;
  search: any;
};

export const SearchDisbursement = ({
  setSearchData,
  searchData,
  index,
  search,
}: Props) => {
  const pathName = useLocation().pathname;

  const searchFilters = searchData?.map((s) => s?.filter);
  const FILTERVALUES = {
    keyword: "Keyword",
    orderCode: "Order Code",
    // name: "Name",
    pleaseSelect: "Please select",
  };
  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "orderCode",
      label: "Order Code",
    },
    // {
    //   value: "name",
    //   label: "Name",
    // },
  ];

  const { Option } = Select;

  const renderInput = () => {
    switch (search?.filter) {
      case "keyword":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Keyword"
            name="driver"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "orderCode":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Order Code"
            name="driver"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      // case "name":
      //   return (
      //     <input
      //       className="w-full text-sm focus:outline-none"
      //       type="text"
      //       placeholder="Search Name"
      //       name="driver"
      //       value={search?.value}
      //       onChange={(e: any) =>
      //         setSearchData([
      //           ...searchData?.map((item, i) =>
      //             i === index ? { ...item, value: e.target.value } : item
      //           ),
      //         ])
      //       }
      //     />
      //   );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="w-3/4 md:w-2/4 lg:w-2/5 border-[1px] border-gray-400 flex flex-row-reverse justify-start items-center p-1 rounded-sm search_border mr-2">
        {renderInput()}

        <div className="h-[20px] border-[1px] border-gray-300 mx-2" />

        <Select
          style={{ width: "100%" }}
          bordered={false}
          value={FILTERVALUES[search.filter]}
          onChange={(v: any) => {
            const newItem = {
              filter: v,
              value: "",
            };
            setSearchData([
              ...searchData?.map((item, i) => (i === index ? newItem : item)),
            ]);
          }}
        >
          {searchOptions
            ?.filter((option) =>
              pathName?.search("ez-buy-service-orders") === -1
                ? !searchFilters?.includes(option.value)
                : !searchFilters?.includes(option.value) &&
                  option.value !== "premium"
            )
            ?.map((option, index) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </div>

      <div className="px-2">
        {index !== 0 && (
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            type="ghost"
            danger
            onClick={() => {
              setSearchData([...searchData?.filter((item, i) => i !== index)]);
            }}
          />
        )}
      </div>
    </>
  );
};
