/* eslint-disable import/no-anonymous-default-export */
import { GridContainer, Span } from "@common";

export default ({ paymentDetails }: any) => {
  return (
    <>
      <GridContainer grid="two" padding="none">
        <div>
          <Span weight="semi"> Email: </Span>
          <Span>{paymentDetails?.email}</Span> 
        </div>

        <div>
          <Span weight="semi"> Name: </Span>
          <Span transform="capitalize">{paymentDetails?.name}</Span> 
        </div>  
      </GridContainer>

      <GridContainer grid="four">
        {!paymentDetails?.branch ??
          <div>
            <Span weight="semi"> Branch: </Span>
            <Span>{paymentDetails?.branch}</Span> 
          </div>
        }

        {!paymentDetails?.brand ??
          <div>
            <Span weight="semi"> Brand: </Span>
            <Span>{paymentDetails?.brand}</Span> 
          </div>
        }

        {!paymentDetails?.chargeTo ??
          <div>
            <Span weight="semi"> Charge To: </Span>
            <Span>{paymentDetails?.chargeTo}</Span> 
          </div>
        }

        {!paymentDetails?.corporateBillingName ??
          <div>
            <Span weight="semi"> Corporate Billing Name: </Span>
            <Span>{paymentDetails?.corporateBillingName}</Span> 
          </div>
        }
      </GridContainer>  
    </>
  );
};