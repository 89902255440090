import { useParams } from 'react-router-dom'
import {Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { ExpressOrdersViewPage } from '@components' 

export const ExpressOrdersView = () => {

  let params: any = useParams();

  return (
    <>
      <MainLayout>
        <SideBar />  
        <Header />
        
        <ContainerLayout>
          <ExpressOrdersViewPage id={params.id} />
        </ContainerLayout>
      </MainLayout> 
    </>
  )
}