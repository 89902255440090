import { Button, Modal, Table } from "antd"; 

type Props = {
  setOpenUploadModal: Function;
  openUploadModal: boolean;
  uploadData: any;  
};

export const MassUploadModal = ({ 
  openUploadModal,
  setOpenUploadModal,
  uploadData,  
}: Props) => {   
 
  const handleClose = () => { 
    setOpenUploadModal(false); 
  }; 

  const dataSource = Object.keys(uploadData).map((key) => ({
    key,
    row: key,
    message: uploadData[key][0],
  }));

  const columns = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      width: 100,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
  ];

  return (
    <>
      <Modal
        title="Uploaded Addons Result"
        visible={openUploadModal}   
        onCancel={handleClose}
        width={800}
        footer={[
          <Button type="primary"onClick={handleClose}
        >
            Close
          </Button>
        ]} 
      >
        <Table
          rowKey="contactNumber"
          columns={columns} 
          dataSource={dataSource}
          pagination={false}
          scroll={{
            y: 167,
          }}
        /> 
      </Modal>
    </>
  );
};
