/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";

import { Dispatch, RootState } from "@store"; 

type Props = {
  searchData: any;
  setSearchData: any;
  deletedSearch?: any;
  setDeletedSearch?: any;
  index: any;
  search: any;
};

export const SearchAttachment = ({
  setSearchData,
  searchData,
  index,
  search,
}: Props) => { 

  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const { referenceSearch }: any = useSelector(
    (state: RootState) => state.Attachment
  ); 

  const searchFilters = searchData?.map((s) => s?.filter);
  const FILTERVALUES = {
    reference: "Reference ID",
    customerReference: "Customer Reference",
    referenceOrderNumber: "Reference Order Number",
    paymentRemarks: "Payment Remarks",
    description: "Description",
    refOrderNumber: "Reference Order Number",
    tags: "Tags",
    pleaseSelect: "Please select",
  };

  const searchOptions = [
    {
      value: "reference",
      label: "Reference ID",
    },
    {
      value: "customerReference",
      label: "Customer Reference",
    },
    {
      value: "paymentRemarks",
      label: "Payment Remarks",
    },
    {
      value: "refOrderNumber",
      label: "Reference Order Number",
    },
    {
      value: "tags",
      label: "Tags",
    },
  ];

  const { Option } = Select;

  useEffect(() => {
    const reference = params.get("reference");

    dispatch.Attachment.updateState({ referenceSearch: reference }); 

    const delayDebounceFn = setTimeout(() => {
      setSearchData([
        ...searchData?.map((item, i) =>
          i === index ? { ...item, value: reference } : item
        ),
      ]);
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  const handleReference = (e: any) => {
    if (e.target.value) {
      dispatch.Attachment.updateState({ referenceSearch: e.target.value });
      setSearchData([
        ...searchData?.map((item, i) =>
          i === index ? { ...item, value: e.target.value } : item
        ),
      ]);
      history.replace(`?reference=${e.target.value}`);
    } else {
      dispatch.Attachment.updateState({ referenceSearch: null });
      history.replace(`?`);
    }
  };

  const renderInput = () => {
    switch (search?.filter) {
      case "reference":
        return (
          <input
            className="w-full text-sm focus:outline-none orderCode"
            type="text"
            placeholder="Search id"
            name="reference"
            value={referenceSearch === null ? referenceSearch : search?.value}
            onChange={handleReference}
          />
        );
      case "customerReference":
        return (
          <input
            className="w-full text-sm focus:outline-none orderCode"
            type="text"
            placeholder="Search Customer Reference"
            name="customerReference"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "refOrderNumber":
        return (
          <input
            className="w-full text-sm focus:outline-none orderCode"
            type="text"
            placeholder="Search Reference Order Number"
            name="refOrderNumber"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "paymentRemarks":
        return (
          <input
            className="w-full text-sm focus:outline-none orderCode"
            type="text"
            placeholder="Search Payment Remarks"
            name="paymentRemarks"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "tags":
        return (
          <input
            className="w-full text-sm focus:outline-none orderCode"
            type="text"
            placeholder="Search Tags"
            name="tags"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="w-3/4 md:w-2/4 lg:w-2/5 border-[1px] border-gray-400 flex flex-row-reverse justify-start items-center p-1 rounded-sm search_border">
        {renderInput()}

        {search?.filter !== "premium" && (
          <div className="h-[20px] border-[1px] border-gray-300 mx-2" />
        )}

        <Select
          style={{ width: "100%" }}
          bordered={false}
          value={FILTERVALUES[search.filter]}
          onChange={(v: any) => {
            const newItem = { filter: v, value: v === "status" ? "all" : "" };
            setSearchData([
              ...searchData?.map((item, i) => (i === index ? newItem : item)),
            ]);
          }}
        >
          {searchOptions
            ?.filter((option) => !searchFilters?.includes(option.value))
            ?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </div>

      <div className="px-1">
        {index !== 0 && (
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            type="ghost"
            danger
            onClick={() => {
              setSearchData([...searchData?.filter((item, i) => i !== index)]);
            }}
          />
        )}
      </div>
    </>
  );
};
