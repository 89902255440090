/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useRef } from 'react' 
import { onlyNumberKey } from '@utils';
 
import { GridContainer, Container, Button, Span } from '@common'
import { DRIVERGROUP, VEHICLETYPE } from '@constants'; 

import '../../containers/DriverManagement/AllDrivers/Driver.css' 

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store"; 

import { Input, Select, Switch } from 'antd';
const { Option } = Select; 

type Props = {
    setViewDriver: Function,
}

export const ViewDriver = ({setViewDriver, ...props}: Props) => {
    const dispatch = useDispatch<Dispatch>();

    const viewDriverId: any = useSelector(
        (state: RootState) => state.UI.forms.editDriver?.id ?? null
    );

    const drivers: any = {}//useSelector((state: RootState) => state.Table.tables.drivers.data);
    const CDD = drivers.length > 0 && drivers?.filter(driver => driver.id === viewDriverId)[0] || '';//current driver details

    const currentdDriverVehicle = CDD?.driverVehicle?.length > 0 && CDD?.driverVehicle[0];

    const [openAlert, setOpenAlert] = useState(false);
    const [errMessage, setErrMessage] = useState(''); 
    const refSwitch: any = useRef(null);
    const [active, setActive] = useState(CDD?.systemStatus === 'active' ? true : false);

    const [driverDetails, setDriverDetails] = useState({
        firstName: CDD?.firstName,
        middleName: CDD?.middleName,
        lastName: CDD?.lastName,
        phoneNumber: CDD?.phoneNumber?.slice(3),
        email: CDD?.email,
        province: CDD?.province,
        city: CDD?.city,
        address: CDD?.address,
        driverStatus: CDD?.driverStatus,
        driverVehicle: [
            {
                vehicleType: currentdDriverVehicle?.vehicleType,
                vehicleModel: currentdDriverVehicle?.vehicleModel,
                plateNumber: currentdDriverVehicle?.plateNumber,
                status: currentdDriverVehicle?.status,
            }
        ],
        deliveryArea: {
            driverGroup: CDD?.deliveryArea?.driverGroup,
            cluster: CDD?.deliveryArea?.cluster,
            clusterCity: CDD?.deliveryArea?.clusterCity,
            clusterArea: CDD?.deliveryArea?.clusterArea,
        },
        driverLicenseRestriction: CDD.driverLicenseRestriction,
    }) as any;

    const handleChange = (name: string, e: any, dest: any) => {
        const value = e.target.value;
        if (dest === 'none') {
            setDriverDetails({
                ...driverDetails,
                [name]: value
            });
        } else if (dest === 'driverVehicle') {
            driverDetails[dest][0][name] = value
        } else {
            setDriverDetails({
                ...driverDetails,
                [dest]: {
                    ...driverDetails[dest],
                    [name]: value
                }
            });
        }
    }

    const isChangeInData = () => {
        if (CDD) {
            const oodriverDetails = Object.entries(CDD).filter(entry => {
                if (['deliveryArea', 'driverVehicle'].includes(entry[0])) {
                    return null
                }
                return entry
            }).map(entry => `${entry[0]} ${entry[1]}`);
            const originalDeliveryArea = Object.entries(CDD.deliveryArea).map(entry => `${entry[0]} ${entry[1]}`);
            const irginalDriverVehicle = Object.entries(CDD?.driverVehicle[0]).map(entry => `${entry[0]} ${entry[1]}`);
            const originalDetails = originalDeliveryArea.concat(irginalDriverVehicle, oodriverDetails);

            const uudriverDetails = Object.entries({ ...driverDetails, systemStatus: active ? 'active' : 'inactive', phoneNumber: `+63${driverDetails.phoneNumber}` })
                .filter(entry => {
                    if (['deliveryArea', 'driverVehicle'].includes(entry[0])) {
                        return null
                    }
                    return entry
                }).map(entry => `${entry[0]} ${entry[1]}`);
            const updatedDeliveryArea = Object.entries(driverDetails.deliveryArea).map(entry => `${entry[0]} ${entry[1]}`);
            const updatedDriverVehicle = Object.entries(driverDetails?.driverVehicle[0]).map(entry => `${entry[0]} ${entry[1]}`);
            const updatedDetails = updatedDeliveryArea.concat(updatedDriverVehicle, uudriverDetails);

            const result = updatedDetails.map((item) => originalDetails.includes(item)).some(res => res === false);
            return result;
        }
    };

    const showError = () => {
        setErrMessage('No Changes made!');
        setOpenAlert(true);
    }

    const handleUpdateDriver = async (e) => {
        e.preventDefault();
        const isChange = await isChangeInData();
        const updatedDetails = {
            ...driverDetails,
            systemStatus: active ? 'active' : 'inactive'
        }

        if (!isChange) {
            return showError();
        }

        try {
            dispatch.UI.setIsLoading(true)
            const res = { isSuccess: false }//await updateDriver({updatedDetails, viewDriverId})
            if (res.isSuccess) {
                dispatch.UI.updateFormId({ target: "editDriver", value: null });
                dispatch.UI.setAlert({ message: 'Driver details updated', type: 'Success' })
                dispatch.UI.setIsLoading(false);
                window.location.reload()
            }
        } catch (err: any) {
            const errMsg = err.data.error;
            setErrMessage(errMsg)
            setOpenAlert(true);
            dispatch.UI.setIsLoading(false);
        }
    } 

    useEffect(() => {
        if (active) {
            refSwitch.current.click();
        }
    }, []);

    return (
        <>   
        <div className='side-parcel-body'>
            <Container border='grey'>
                <div className='flex justify-between'>
                    <Span weight='semi' margin='my-auto'>
                        Driver Status
                    </Span> 
                    
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive"/>
                </div>

                <div className='flex justify-between mt-2'>
                    <Span weight='semi' margin='my-auto'>
                        System Status
                    </Span>

                    <Switch checkedChildren="Active" unCheckedChildren="Inactive"/>
                </div>

            </Container>

            <form onSubmit={handleUpdateDriver}>
                <div className='text-left py-4'>
                    <Span weight='semi' size='lg' transform='uppercase'>
                        Profile
                    </Span>

                    <Span margin='mt-4'>First Name</Span>
                    <Input placeholder='First Name' value={driverDetails.firstName} onChange={(e) => handleChange('firstName', e, 'none')} required />

                    <Span margin='mt-4'>Middle Name (Optional)</Span>
                    <Input placeholder='Middle Name' value={driverDetails.middleName} onChange={(e) => handleChange('middleName', e, 'none')} />

                    <Span margin='mt-4'>Last Name</Span>
                    <Input placeholder='Last Name' value={driverDetails.lastName} onChange={(e) => handleChange('lastName', e, 'none')} required />
                </div>

                <div className='text-left py-4'>
                    <Span weight='semi' size='lg' transform='uppercase'>
                        Contact Details
                    </Span>

                    <Span margin='mt-4'>Phone Number</Span> 
                    <Input 
                        addonBefore="+63" 
                        placeholder="Phone Number"
                        maxLength={10} 
                        value={driverDetails.phoneNumber}
                        onChange={(e) => handleChange('phoneNumber', e, 'none')}
                        onKeyPress={onlyNumberKey}
                        required
                    />

                    <Span margin='mt-4'>Email</Span>
                    <Input placeholder='Email' value={driverDetails.email} onChange={(e) => handleChange('email', e, 'none')} required />
                </div>

                <div className='text-left py-4'>
                    <Span weight='semi' size='lg' transform='uppercase'>
                        Address
                    </Span>

                    <Span margin='mt-4'>Province</Span>
                    <Input placeholder='Province' value={driverDetails.province} onChange={(e) => handleChange('province', e, 'none')} required />

                    <Span margin='mt-4'>City</Span>
                    <Input placeholder='City' value={driverDetails.city} onChange={(e) => handleChange('city', e, 'none')} required />

                    <Span margin='mt-4'>Street, Brgy</Span>
                    <Input placeholder='Street, Brgy' value={driverDetails.address} onChange={(e) => handleChange('address', e, 'none')} required />
                </div>

                <div className='text-left py-4'>
                    <Span weight='semi' size='lg' transform='uppercase'>
                        Delivery Details
                    </Span>

                    <Span margin='mt-4'>Driver Group</Span>
                    <Select 
                        className='w-full'
                        placeholder="Driver Group"  
                        defaultValue={driverDetails.deliveryArea.driverGroup} 
                        onChange={(e) => handleChange('driverGroup', e, 'deliveryArea')}>
                        {DRIVERGROUP?.map((type, index) => (
                            <option key={index} value={type.value}>{type.name}</option>
                        ))}
                    </Select>

                    <Span margin='mt-4'>Cluster</Span>
                    <Input placeholder='Cluster' value={driverDetails.deliveryArea.cluster} onChange={(e) => handleChange('cluster', e, 'deliveryArea')} required />

                    <Span margin='mt-4'>City</Span>
                    <Input placeholder='City' value={driverDetails.deliveryArea.clusterCity} onChange={(e) => handleChange('clusterCity', e, 'deliveryArea')} required />

                    <Span margin='mt-4'>Area</Span>
                    <Input placeholder='Area' value={driverDetails.deliveryArea.clusterArea} onChange={(e) => handleChange('clusterArea', e, 'deliveryArea')} required />
                </div>

                <div className='text-left py-4'>
                    <Span weight='semi' size='lg' transform='uppercase'>
                        Registered Vehicle
                    </Span>

                    <Span margin='mt-4'>Driver's License Restriction</Span>
                    <Input  
                        placeholder="Driver's License Restriction"
                        value={driverDetails.driverLicenseRestriction} 
                        onChange={(e) => handleChange('driverLicenseRestriction', e, 'none')}
                        required
                        onKeyPress={onlyNumberKey}
                        maxLength={1}
                    />
                </div>

                <Container border='grey'>
                    <div className='flex justify-between'>
                        <Span weight='semi' color='brown'>
                            Vehicle
                        </Span>
                        {/* <MdDelete className='text-red-2 border border-red-2 rounded-full text-2xl px-0.5'/> */}
                    </div>
                    <GridContainer grid='two' padding='none'>
                        <div>
                            <Span margin='mt-4'>Vehicle Type</Span>
                            <Select  
                                className='w-full'
                                placeholder="Vehicle Type"  
                                defaultValue={driverDetails.driverVehicle[0].vehicleType} 
                                onChange={(e) => handleChange('vehicleType', e, 'driverVehicle')}
                            >
                                {VEHICLETYPE?.map((item, index) => (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </div>

                        <div>
                            <Span margin='mt-4'>Status</Span>
                            <Select  
                                className='w-full'
                                placeholder="Status" 
                                defaultValue={driverDetails.driverVehicle[0].status} 
                                onChange={(e) => handleChange('status', e, 'driverVehicle')}
                            >
                                <option value='inactive'>Inactive</option>
                                <option value='active'>active</option>
                            </Select>
                        </div>

                        <div>
                            <Span>Vehicle Model</Span>
                            <Input placeholder='Vehicle Model' defaultValue={driverDetails.driverVehicle[0].vehicleModel} onChange={(e) => handleChange('vehicleModel', e, 'driverVehicle')} required />
                        </div>

                        <div>
                            <Span>Plate Number</Span>
                            <Input placeholder='Registered Plate Number' defaultValue={driverDetails.driverVehicle[0].plateNumber} onChange={(e) => handleChange('plateNumber', e, 'driverVehicle')} required />
                        </div>

                    </GridContainer>

                    {/* <div className='mt-4 border-t border-grey-1'>
                        <Button width='none' bg='white' color='grey' border='lightgrey' margin='mt-4'>
                            <div className='flex pr-2'>
                                <BsPlusLg className='my-auto mx-2' />
                                Add Vehicle
                            </div>
                        </Button>
                    </div> */}
                </Container>

                <div className="flex inset-x-0 bottom-0 py-4 text-center">
                    <Button
                        margin="mx-2"
                        bg="white"
                        border="lightgrey"
                        color="black"
                        onClick={() => dispatch.UI.updateFormId({ target: "editDriver", value: null })}
                    >
                        Cancel
                    </Button>
                    <Button margin="mx-2" type="submit">
                        Update Driver
                    </Button>
                </div>
            </form>
        </div> 
        </>
    );
}   
