/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";

import { removeSCAndCapitalize } from "@utils";

import { NoData, Span, Button, Container } from "@common";
import { BiPlusCircle } from "react-icons/bi";

import { COLUMNS } from "./column";

import {
  AddCalendar,
  EditCalendar,
  Loader,
  Table,
  DeleteModal,
  OptionModal,
} from "@components";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Switch } from "antd";
import { Stack } from "@mui/material";
import "./calendar.css";

export const Calender = () => {
  const dispatch = useDispatch<Dispatch>();

  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState(false);
  const [eventId, setEventId] = useState(null) as any;
  const [title, setTitle] = useState(null) as any;
  const [optionModal, setOptionModal] = useState(false);

  const editCalendarId: any = useSelector(
    (state: RootState) => state.UI.forms.editCalendar?.id
  );

  const deleteCalendarId: any = useSelector(
    (state: RootState) => state.UI.forms.deleteCalendar?.id
  );

  const filters: any = useSelector(
    (state: RootState) => state.Table.tables.calendar?.filters
  );
  const data: any[] = useSelector(
    (state: RootState) => state.Table.tables.calendar?.data
  );
  const columns = useMemo(() => COLUMNS, []);

  const events = data?.map((event) => {
    const result = {
      title: `${event?.name} (${removeSCAndCapitalize(
        event?.holidayType,
        "_"
      )})`,
      date: new Date(event.date),
      id: event?.id,
      allDay: false,
    };

    return result;
  });

  const getData = () => {
    dispatch.Table.getFirebaseData({
      name: "calendar",
      table: "calendar",
    });
  };

  const [addCalendar, setAddCalendar] = useState(false);
  const showDrawer = () => {
    setAddCalendar(true);
  };

  const handleEventClick = (eventInfo) => {
    const id = eventInfo?.event?.id;
    const title = eventInfo?.event?.title;
    setTitle(title);
    setEventId(id);
    setOptionModal(true);
  };

  useEffect(() => {
    dispatch.Table.resetState();
  }, []);

  useEffect(() => {
    dispatch.Table.getFirebaseData({
      name: "calendar",
      table: "calendar",
    });
    setIsLoading(false);

    return () => {
      dispatch.Table.updateState({ isLoading: true });
    };
  }, [filters]);

  return (
    <>
      <div className="relative">
        <Loader isLoading={isLoading} />
        <Container border="grey" padding="none">
          <div className="p-4">
            <div className="flex justify-between item-start">
              <Stack
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="flex-start"
                className="calendar-switch"
              >
                <Span weight="bold" size="2xl">
                  Calendar
                </Span>
                <Switch
                  checkedChildren="List View"
                  unCheckedChildren="Calendar View"
                  onChange={(v) => setView(v)}
                />
              </Stack>
              <div className="flex justify-end px-4 my-4">
                <Button width="none" padding="py-1" onClick={showDrawer}>
                  <div className="flex pr-2 items-center">
                    <BiPlusCircle className="my-auto mx-2 text-2xl" />
                    Add Calendar
                  </div>
                </Button>
              </div>
            </div>
          </div>

          <div className="overflow-auto ">
            {view ? (
              <div className="p-4">
                <FullCalendar
                  initialView="dayGridMonth"
                  plugins={[dayGridPlugin]}
                  events={events}
                  height="65vh"
                  editable={true}
                  selectable={true}
                  eventClick={handleEventClick}
                />
              </div>
            ) : (
              <>
                {data?.length < 0 ? (
                  <NoData />
                ) : (
                  <Table
                    columns={columns}
                    data={data}
                    dataType="calendar"
                    sortName="date"
                    descSort={true}
                  />
                )}
              </>
            )}
          </div>
        </Container>
      </div>

      <DeleteModal id={eventId} whatToDelete={title} getData={getData} />

      <OptionModal
        optionModal={optionModal}
        setOptionModal={setOptionModal}
        id={eventId}
      />

      <EditCalendar id={editCalendarId} getData={getData} table="calendar" />

      <AddCalendar
        setAddCalendar={setAddCalendar}
        getData={getData}
        addCalendar={addCalendar}
      />
    </>
  );
};
