import { useState } from 'react'  
import { Form, Input, Modal } from 'antd';

import { Dispatch } from '@store';
import { useDispatch } from 'react-redux';
import { pendingDriver } from '@api' 

type Props = {
    setPendingModal: Function,
    pendingModal: boolean,
    id: string,
    dataType: string,
}

export const PendingModal = ({ setPendingModal, pendingModal, id, dataType, ...props }: Props) => {

    const dispatch = useDispatch<Dispatch>();
    const [form] = Form.useForm();
    const [note, setNote] = useState('');

    const handlePending = async () => {
        setPendingModal(false);
        try {
            dispatch.UI.setIsLoading(true);
            const res = await pendingDriver(id, note)
            if ([200, 201].includes(res.status)) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: 'Status updated', type: 'Success' });
                dispatch.Table.getDriversData({ table: dataType, type: dataType === 'pending' ? 'pending' : '' });
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.message ?? 'Unable to update driver status';
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        }
    }

    return (
        <>
            <Modal title='Update approval status' visible={pendingModal} onOk={form.submit} okText='Reject' okButtonProps={{ danger: true }} onCancel={() => setPendingModal(false)}>
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handlePending}
                    wrapperCol={{ span: 24 }}
                >
                    <Form.Item
                        name='Pending Note'
                        label='Pending Note'
                        rules={[{ required: true }]}
                    >
                        <Input onChange={(v) => setNote(v.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}   