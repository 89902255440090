import { get, post, put, remove } from '@api';

export const addNewHoliday = async(body: any) => {
    try{
        const res = await post(`/calendar`, body); 
        return res;
    }catch(err){
        throw err;
    }
}

export const updateCalendar = async(id: any, body: any) => {
    try{
        const res = await put(`/calendar/${id}`, body);
        return res;
    }catch(err){
        throw err;
    }
}

export const deleteCalendarEvents = async(id: any) => {
    try{
        const res = await remove(`/calendar/${id}`);
        return res;
    }catch(err){
        throw err;
    }
}

export const getCalendar = async (pageIndex: any, pageSize: any) => {
    try {
        const res = await get(`/calendar?pageIndex=${pageIndex}&pageSize=${pageSize}`);  
        return res.data; 
    } catch (err) {
        throw err;
    }
}