import { ColumnFilter } from "@components";
import { DISBURSEMENT_TYPE } from "@constants";
import { formatDate, formatPrice } from "@utils";

export const COLUMNS = [
  // {
  //   accessor: "orderCode",
  //   Header: "Order Code",
  //   Filter: ColumnFilter,
  //   isVisible: true,
  //   Cell: (data) => {
  //     const d = data?.row?.original?.delivery;
  //     return (
  //       <div className="flex justify-center">
  //         <span className="text-center capitalize">{d?.orderCode}</span>
  //       </div>
  //     );
  //   },
  // },
  {
    accessor: "disbursementReference",
    Header: "Disbursement Reference",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">
            {d?.disbursementReference}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "name",
    Header: "Name",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original?.data;
      const name = d.disbursement_firstname + " " + d.disbursement_lastname;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">{name} </span>
        </div>
      );
    },
  },
  {
    accessor: "disbursementType",
    Header: "Disbursement Type",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">
            {DISBURSEMENT_TYPE[d.disbursementType]}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "disbursement_mobile",
    Header: "Mobile",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original?.data;
      return <div className="flex justify-center">{d.disbursement_mobile}</div>;
    },
  },
  {
    accessor: "disbursement_amount",
    Header: "Amount",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original?.data;
      return (
        <div className="w-full flex items-center justify-center">
          <span className="text-[#7140b8] font-bold">
            {formatPrice(d?.disbursement_amount)}
          </span>
        </div>
      );
    },
  },
  // {
  //   accessor: "deliveredAt",
  //   Header: "Delivered At",
  //   Filter: ColumnFilter,
  //   isVisible: true,
  //   Cell: (data) => {
  //     const d = data?.row?.original?.delivery;
  //     return (
  //       <div className="flex justify-center">{formatDate(d.deliveredAt)}</div>
  //     );
  //   },
  // },
  {
    accessor: "performAt",
    Header: "Perform At",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span
            className={`${d?.performAt ? "text-black-1" : "text-gray-400"}`}
          >
            {d?.performAt ? formatDate(d?.performAt) : "No Data"}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "action",
    Header: "Action",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">{d?.action}</span>
        </div>
      );
    },
  },
];
