import { useLocation } from 'react-router-dom'
import { SELECT_RENTAL_COMPLETED_ORDERS, SELECT_RENTAL_OPEN_ORDERS } from '@constants'


import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';

import { useTheme } from '@mui/material/styles';  
import { useMediaQuery } from '@mui/material';

type Props = {
  searchData: any;
  setSearchData: any;
  deletedSearch?: any;
  setDeletedSearch?: any;
  setFilters: any;
  tab: any;
  index: any;
  search: any;
}

export const SearchRentalCompleted = ({ setSearchData, searchData, setFilters, tab, index, search }: Props) => {

  const pathName = useLocation().pathname;
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const searchFilters = searchData?.map(s => s?.filter);
  const FILTERVALUES = {
    status: 'Status', 
    orderCode: 'Order Code',    
    client: 'Client Name or Contact Number', 
    pleaseSelect: 'Please select'
  }
  const searchOptions = [
    {
      value: 'status',
      label: 'Status'
    },
    {
      value: 'orderCode',
      label: 'Order Code'
    },
    {
      value: 'client',
      label: 'Client Name or Contact Number'
    }, 
  ]

  const { Option } = Select; 

  const renderInput = () => {
    switch (search?.filter) {
      case 'status':
        return (
          <Select
            style={{ width: '100%' }}
            bordered={false}
            value={search?.value}
            onChange={(v: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: v } : item)])}
          >
            {tab === 'openOrders' ? 
                SELECT_RENTAL_OPEN_ORDERS?.map(item => (
                <Option key={item?.value} value={item?.value}>{item?.name}</Option>
              ))
              :
              SELECT_RENTAL_COMPLETED_ORDERS?.map(item => (
                <Option key={item?.value} value={item?.value}>{item?.name}</Option>
              ))
            }
          </Select>
        )
      case 'orderCode':
        return (
          <input
            className='w-full text-sm focus:outline-none orderCode'
            type='text'
            placeholder="Search Order Code"
            name='driver'
            value={search?.value}
            onChange={(e: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: e.target.value } : item)])}
          />
        ) 
      case 'client':
        return (
          <input
            className='w-full text-sm focus:outline-none'
            type='text'
            placeholder="Search Client Name or Contact Number"
            name='client'
            value={search?.value}
            onChange={(e: any) => setSearchData([...searchData?.map((item, i) => i === index ? { ...item, value: e.target.value } : item)])}
          />
        )  
      default:
        return <></>
    }
  }

  return (<>
    <div className='w-3/4 md:w-2/4 lg:w-2/5 border-[1px] border-gray-400 flex flex-row-reverse justify-start items-center p-1 rounded-sm search_border mr-2'>
      {renderInput()} 
 
      <div className="h-[20px] border-[1px] border-gray-300 mx-2" /> 

      <Select
        style={{ width: '100%' }}
        bordered={false}
        value={FILTERVALUES[search.filter]}
        onChange={(v: any) => {
          const newItem = { filter: v, value: v === 'status' ? tab === 'openOrders' ? 'all' : 'all' : '' };
          setSearchData([...searchData?.map((item, i) => i === index ? newItem : item)])
        }}

      >
        {searchOptions
          ?.filter(option => pathName?.search('rental-management') === -1 ? !searchFilters?.includes(option.value) : !searchFilters?.includes(option.value))
          ?.map((option, index) => (
            <Option key={option.value} value={option.value}>{option.label}</Option>
          ))}
      </Select>

      {matchDownSm && (
        <Tooltip title="search">
          <Button type="primary" shape="circle" icon={<SearchOutlined style={{ display: 'inline-flex', alignItems: 'center' }} />} onClick={setFilters} />
        </Tooltip>
      )}
    </div>

    <div className='pr-2'>
      {index !== 0 && (
        <Button
          shape='circle'
          icon={<DeleteOutlined />}
          type='ghost'
          danger
          onClick={() => {
            setSearchData([...searchData?.filter((item, i) => i !== index)])
          }}
        />
      )}
    </div>

  </>
  )
}