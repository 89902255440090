import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { Header, SideBar, MainLayout, ContainerLayout, FullContainer} from '@common'
 import { BRAND_IMAGES } from '@constants'

import { Dispatch, RootState } from '@store' 

export const Home = () => {
  const dispatch = useDispatch<Dispatch>();
  const userInfo = useSelector((state: RootState) => state.User.userInfo);
  const isNewLogin = useSelector((state: RootState) => state.UI.isNewLogin);

  useEffect(() => {
    if (isNewLogin) {
      dispatch.UI.setAlert({ message: userInfo?.email, type: 'Success' });
      dispatch.UI.updateState({ isNewLogin: false });
    }
  }, [isNewLogin]);
  return (
    <>
      <MainLayout>
        <SideBar />  
        <Header />
        
        <ContainerLayout>
          <FullContainer height="h-screen">
            <img alt='logo' src={BRAND_IMAGES['BRAND_LOGO']} className='h-64'/>
          </FullContainer>
        </ContainerLayout>
      </MainLayout>
    </>
  )
}