import { useState } from "react";

import { NoData, Container, Button, GridContainer } from "@common";

import { formatDate } from "@utils";
import { Card, Space, Tag, Typography } from "antd";
import Meta from "antd/lib/card/Meta";

import { slice } from "lodash";
import "./AttachmentView.scss";

export const AttachmentView = ({ data }: any) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(8);
  const initialPosts = slice(data, 0, index);

  const loadMore = () => {
    setIndex(index + 4);
    if (index >= data.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };

  return (
    <>
      <div className="relative">
        <Container border="grey" padding="none">
          {data?.length === 0 ? (
            <NoData />
          ) : (
            <>
              <GridContainer grid="four">
                {initialPosts
                  ?.sort((a, b) => a?.time - b?.time)
                  .map((attr) => (
                    <>
                      <div>
                        <Card
                          className="card-image"
                          cover={
                            <a
                              href={attr?.url}
                              target="_blank"
                              className="card-image-link"
                            >
                              <img
                                alt={attr?.url}
                                src={attr?.url}
                                height={270}
                                onClick={attr?.url}
                              />
                            </a>
                          }
                        >
                          <Meta
                            description={
                              <Space direction="vertical" size={5}>
                                <span>
                                  <Typography.Text strong>ID:</Typography.Text>{" "}
                                  {attr.reference}
                                </span>
                                <span>
                                  <Typography.Text strong>
                                    Lat and Lng:
                                  </Typography.Text>{" "}
                                  <a
                                    href={`https://www.google.com/maps/place/${
                                      attr.lat + "," + attr.lng
                                    }`}
                                    target="_blank"
                                  >
                                    <span className="text-center">
                                      {attr.lat + "- " + attr.lng}
                                    </span>
                                  </a>
                                </span>

                                <span>
                                  <Typography.Text strong>
                                    Payment Remarks:
                                  </Typography.Text>{" "}
                                  <p className="mb-0">
                                    {attr.paymentRemarks
                                      ? attr.paymentRemarks
                                      : "No Data"}
                                  </p>
                                </span>

                                <span>
                                  <Typography.Text strong>
                                    Description:
                                  </Typography.Text>{" "}
                                  <p className="mb-0">
                                    {attr.description
                                      ? attr.description
                                      : "No Data"}
                                  </p>
                                </span>

                                <span>
                                  <Typography.Text strong>
                                    Customer Reference:
                                  </Typography.Text>{" "}
                                  <p className="mb-0">
                                    {attr.customerReference
                                      ? attr.customerReference
                                      : "No Data"}
                                  </p>
                                </span>

                                <span>
                                  <Typography.Text strong>
                                    Reference Order Number:
                                  </Typography.Text>{" "}
                                  <p className="mb-0">
                                    {attr.referenceOrderNumber
                                      ? attr.referenceOrderNumber
                                      : "No Data"}
                                  </p>
                                </span>

                                <span>
                                  <Typography.Text strong>
                                    Tags:
                                  </Typography.Text>{" "}
                                  <p className="mb-1 capitalize">
                                    {attr.tags
                                      ? attr?.tags?.map((t) => (
                                          <Tag
                                            color="geekblue"
                                            className="capitalize tags"
                                          >
                                            {t}
                                          </Tag>
                                        ))
                                      : null}
                                    {/* <Tag color="geekblue" className="capitalize">
                                  {attr.tags
                                    ? attr?.tags?.map((t) => t)
                                    : "No Data"}
                                </Tag> */}
                                  </p>
                                </span>

                                <span>
                                  <Typography.Text strong>
                                    Date:{" "}
                                  </Typography.Text>{" "}
                                  {formatDate(attr?.time, true)}
                                </span>
                              </Space>
                            }
                          />
                        </Card>
                      </div>
                    </>
                  ))}
              </GridContainer>

              <div className="text-center my-3">
                {isCompleted ? (
                  <></>
                ) : (
                  <Button width="none" onClick={loadMore}>
                    <div className="flex items-center px-4">
                      Load More
                      {/* <BiPlusCircle className='my-auto mx-2 text-2xl' /> */}
                    </div>
                  </Button>
                )}
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};
