import { get, post, put } from '@api';

export const updateToNextStatusRental = async (id: any, status) => {
    try {
        const res = await put(`/rental/${id}/update-status`, {status});
        return res;
    } catch (err) {
        throw err;
    }
}

export const cancelScheduleByRentalItemsId = async (id: string, cancelReason: string) => {
    try {
        const res = await post(`/rental/${id}/cancel`, { cancelReason });
        return res;
    } catch (err) {
        throw err;
    }
}

export const assignRentalDriver = async (rentalId: string, body: any) => {
    try {
        const res = await put(`/rental/${rentalId}/assign`, body);
        return res;
    } catch (err) {
        throw err;
    }
}

export const getDriverByDriverId = async(driverId: any) => {
    try{
        const res = await get(`/drivers?pageIndex=1&pageSize=10&approvalStatus=approved&status=active&driverId=${driverId}`);
        return res.data;
    }catch(err){
        throw err;
    }
}

export const reAssignRentalDriver = async(id: any, body: any) => {
    try{
        const res = await put(`/rental/${id}/re-assign-rental-item`, body);
        return res;
    }catch(err){
        throw err;
    }
}

export const getCompletedRentalOrders = async (pageIndex: any, pageSize: any, keyword: string = '', type: string) => {
    try {
        const res = await get(`/deliveries/${type}?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`); 
        return res.data; 
    } catch (err) {
        throw err;
    }
}