import { TableNoData } from "@common";
import { ColumnFilter, GenerateStatusDesign } from "@components";
import { OPEN_ORDERS_STATUS } from "@constants";
import { formatPrice } from "@utils";

export const COLUMNS = [
  {
    accessor: "id",
    Header: "Order Id",
    isVisible: true,
    Cell: (data) => {
      const id = data?.row?.original?.id;
      return (
        <div className="flex justify-center">
          <span className="text-center">{id}</span>
        </div>
      );
    },
  },
  {
    accessor: "referenceNumber",
    Header: "Reference Code",
    isVisible: true,
    Cell: (data) => {
      const referenceNumber = data?.row?.original?.referenceNumber;
      return (
        <div className="flex justify-center">
          <span className="text-center">
            {!referenceNumber ? <TableNoData /> : referenceNumber}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "storeName",
    Header: "Store Name",
    isVisible: true,
    Cell: (data) => {
      const storeName = data?.row?.original?.store?.name;
      return (
        <div className="flex justify-center">
          <span className="text-center">{storeName}</span>
        </div>
      );
    },
  },
  {
    id: "customer",
    accessor: (data) => data?.customer,
    Header: "Client Name",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const customer = d?.customer;
      return (
        <div className="flex justify-center">
          <span className="text-center">
            {customer?.firstName} {customer?.middleName} {customer?.lastName}
          </span>
        </div>
      );
    },
  },
  {
    id: "contactNumber",
    accessor: (data) => data?.customer?.contact,
    Header: "Contact Number",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const contactNumber = d?.customer?.contact?.contactNumber;
      return (
        <div className="flex justify-center">
          <span className="text-center">{contactNumber}</span>
        </div>
      );
    },
  },
  {
    id: "email",
    accessor: (data) => data?.customer?.contact,
    Header: "Email",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const email = d?.customer?.contact?.email;
      return (
        <div className="flex justify-center">
          <span className="text-center">{email}</span>
        </div>
      );
    },
  },
  {
    accessor: "totalUnit",
    Header: "Total Unit",
    isVisible: true,
    Cell: (data) => {
      const totalUnit = data?.row?.original?.totalUnit;
      return (
        <div className="flex justify-center">
          <span className="text-center">{totalUnit}</span>
        </div>
      );
    },
  },
  {
    accessor: "totalAmount",
    Header: "Grand Amount",
    isVisible: true,
    Cell: (data) => {
      const amount = data?.row?.original?.totalAmount;
      return (
        <div className="flex justify-center">
          <span className="text-[#7140b8] font-bold">
            {formatPrice(amount)}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "status",
    Header: "Status",
    Filter: ColumnFilter,
    isVisible: false,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="w-full flex justify-center items-center">
          <GenerateStatusDesign
            status={d?.status}
            label={OPEN_ORDERS_STATUS[d?.status]}
          />
        </div>
      );
    },
  },
];

export const HEADER_CUSTOMER_ORDER = [
  { label: "Id", key: "id" },
  { label: "Name", key: "customerName" },
  { label: "Store", key: "storeName" },
  { label: "Store contact", key: "storeContact" },
  { label: "Total Unit", key: "totalUnit" },
  { label: "Total Amount", key: "totalAmount" },
  { label: "Created At", key: "created" },
  { label: "Status", key: "statusCompleted" },
];
