import { get, patch, post, postMultipart, put } from '@api';

export const getCompletedExpressOrders = async (pageIndex: any, pageSize: any, keyword: string = '', type: string) => {
    try {
        const res = await get(`/deliveries?type=${type}&pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`);
        return res.data;
    } catch (err) {
        throw err;
    }
} 

export const getDeliveryInfo = async (deliveryId: any) => {
    try {
        const res = await get(`/deliveries/detail/${deliveryId}`); 
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getActiveDrivers = async (pageIndex: any, pageSize: any, vehicle: any, driverGroup: any, driverName?: string) => {
    try {
        const res = await get(`/drivers?pageIndex=${pageIndex}&pageSize=${pageSize}&approvalStatus=approved&status=active&vehicleType=${vehicle}&driverGroup=${driverGroup}&driverGroup=all_of_the_above${driverName ? `&name=${driverName}` : ''}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getCustomerInformation = async (id: any) => {
    try { 
        const res = await get(`/deliveries/get-customer-info-by-order-code/${id}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getAddsOnFeeById = async (orderId: string) => { 
    try {  
      const res = await get(`/express-orders/${orderId}/get-add-ons-fee`);
         
      return res.data;
    } catch (err) {
        throw err;
    }
  } 


export const updateToNextStatus = async (id: any) => {
    try {
        const res = await put(`/deliveries/${id}/next-status`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const cancelBookingByOrderId = async (id: string, cancelReason: string) => {
    try {
        const res = await post(`/deliveries/${id}/cancel`, { cancelReason });
        return res;
    } catch (err) {
        throw err;
    }
}

export const changePickupDate = async (id: any, pickedUpAt: any) => {
    try {
        const res = await put(`/deliveries/${id}/change-pick-up-time`, { pickedUpAt })
        return res;
    } catch (err) {
        throw err;
    }
}

export const syncOrderToFireStore = async (orderCode: any) => {
    try {
        const res = await patch('/resync', { orderCodes: [orderCode] })
        return res;
    } catch (err) {
        throw err;
    }
}

export const assignDriver = async (id: any, body: any) => {
    try {
        const res = await put(`/deliveries/${id}/assign`, body);
        return res;
    } catch (err) {
        throw err;
    }
}

export const reAssignDriver = async (id: any, body: any) => {
    try {
        const res = await post(`/deliveries/${id}/re-assign`, body);
        return res;
    } catch (err) {
        throw err;
    }
}

export const getFoodOrderItems = async (orderId: any) => {
    try {
        const res = await get(`/food-orders/get-by-order-id/${orderId}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getMartOrderItems = async (orderId: any) => {
    try {
        const res = await get(`/mart-orders/get-by-order-id/${orderId}`); 
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getShopOrderItems = async (orderId: any) => {
    try {
        const res = await get(`/deliveries/detail/${orderId}`); 
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getStoreCategories = async (storeId: any) => {
    try {
        const res = await get(`/stores/${storeId}`); 
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const addCustomAddOn = async (id: any, formData: FormData) => {
    try {
        const res = await postMultipart(`/deliveries/${id}/create-custom-add-on`, formData)
        return res;
    } catch (err) {
        throw err;
    }
}

export const uploadBulkCustomAddOns = async (file: FormData) => {
    try { 

        const res = await postMultipart(`/deliveries/bulk-create-custom-add-ons`, file)
        return res;
    } catch (err) {
        throw err;
    }
} 

export const linkDeliveries = async (body: any) => {
    try {
        const res = await post(`/deliveries/link-supplier-order`, body)
        return res;
    } catch (err) {
        throw err;
    }
}