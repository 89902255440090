import { CheckSquareFilled } from "@ant-design/icons";
import {
  Button,
  GridContainer,
  Span,
  SpanContainer,
  SubContainer,
} from "@common";
import { MERCHANT } from "@constants";
import { Collapse } from "@mui/material";
import { RootState } from "@store";
import { formatDateV2, formatDecimalPrice, formatPhoneNumber } from "@utils";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { MerchantDetails } from "./MerchantDetails";
import { LinkOrderModal } from "@components";

export const MerchantOrdersViewPage = ({ id }: any) => {
  const params: any = useParams();
  const tab = params?.tab;
  const history = useHistory();

  const prevRoute = useSelector((state: RootState) => state.UI.currentRoute);

  const [store, setStore] = useState(true);
  const toggleStore = (e: any) => {
    e.preventDefault();
    setStore(!store);
  };

  const [details, setDetails] = useState(true);
  const toggleSchedule = (e: any) => {
    e.preventDefault();
    setDetails(!details);
  };

  const merchant = useSelector(
    (state: RootState) => state.Table.tables?.merchantOrder.data
  );
  const M = merchant?.filter((m) => m?.id === id)[0];
  const [linkOrderModal, setLinkOrderModal] = useState(false);
  return (
    <>
      <div className="bg-white rounded border-gray p-6">
        <GridContainer grid="five" padding="none">
          <div>
            <div className="bg-brown-1 p-8 text-center rounded-md my-4">
              <Span weight="semi" size="2xl" color="white">
                ₱{formatDecimalPrice(M?.totalAmount ?? 0)}
              </Span>
              <Span color="white">Total Amount</Span>
            </div>

            <div className="mb-2">
              <SpanContainer padding="pr-2">Status:</SpanContainer>
              <SpanContainer color="brown" weight="bold">
                {MERCHANT[M?.status]}
              </SpanContainer>
            </div>

            <SubContainer border="grey" padding="p-2">
              <Span weight="semi" color="brown" transform="uppercase">
                Customer Details
              </Span>

              <Span margin="my-2">Order Number:</Span>
              <span className="text-sm font-semibold p-1 border-[1px] border-[#b7eb8f] bg-[#f6ffed] mb-2">
                {id}
              </span>

              {M?.referenceNumber && (
                <>
                  <Span margin="my-2">Reference Code:</Span>
                  <span className="text-xs font-semibold p-1 border-[1px] border-[#b7eb8f] bg-[#f6ffed] mb-2">
                    {M?.referenceNumber}
                  </span>
                </>
              )}

              <Span margin="mt-2">Contact Name:</Span>
              <Span weight="semi" transform="capitalize">
                {M?.customer?.firstName ?? ""} {M?.customer?.middleName ?? ""}{" "}
                {M?.customer?.lastName ?? ""}
              </Span>

              <Span margin="mt-2">Contact No:</Span>
              <Span weight="semi" transform="capitalize">
                {formatPhoneNumber(M?.customer?.contact?.contactNumber)}
              </Span>
              <Span margin="mt-2">Email:</Span>
              <Span weight="semi">{M?.customer?.contact?.email}</Span>

              <Span margin="mt-2">Order Placed:</Span>
              <Span weight="semi" transform="capitalize">
                {formatDateV2(M?.createdAt)}
              </Span>
            </SubContainer>

            <SubContainer border="grey" padding="p-2">
              <Span weight="semi" color="brown" transform="uppercase">
                REMARKS
              </Span>

              <Span margin="mt-2">Total Unit:</Span>
              <Span weight="semi" transform="capitalize">
                {M?.totalUnit} pcs
              </Span>
            </SubContainer>
          </div>

          <div className="col-span-4">
            <div className="flex justify-between items-center p-2 mt-4">
              <Span weight="semi" size="lg">
                Delivery Details
              </Span>
              <div className="flex flex-row justify-center items-center">
                <Button
                  padding="px-2"
                  margin="mx-2"
                  radius="sm"
                  onClick={() => history.push(`${prevRoute}?tab=${tab}`)}
                >
                  Back to parcel
                </Button>
              </div>
            </div>

            <SubContainer border="grey" padding="none">
              <div
                className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                onClick={toggleStore}
              >
                <div className="flex my-auto">
                  <CheckSquareFilled
                    style={{
                      fontSize: "1.8rem",
                      color: "#84b15c",
                      marginRight: "0.8rem",
                    }}
                  />
                  <Span margin="my-auto">Store</Span>
                </div>

                {store ? (
                  <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                ) : (
                  <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                )}
              </div>

              <Collapse in={store}>
                <GridContainer grid="four">
                  <div>
                    <Span weight="semi"> Store Name </Span>
                    <Span transform="capitalize">{M?.store?.name}</Span>
                  </div>

                  <div>
                    <Span weight="semi"> Branch </Span>
                    <Span transform="capitalize">
                      {M?.store?.branchName ?? "No Data"}
                    </Span>
                  </div>

                  <div>
                    <Span weight="semi"> Address </Span>
                    <Span transform="capitalize">{M?.store?.address}</Span>
                  </div>

                  <div>
                    <Span weight="semi"> Contact Details </Span>
                    <Span transform="capitalize">{M?.store?.contactName}</Span>
                    <p className="flex">
                      <Span transform="capitalize">
                        {M?.store?.contactNumber}
                      </Span>
                    </p>
                  </div>
                </GridContainer>
              </Collapse>
            </SubContainer>

            <SubContainer border="grey" padding="none">
              <div
                className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                onClick={toggleSchedule}
              >
                <div className="flex my-auto">
                  <CheckSquareFilled
                    style={{
                      fontSize: "1.8rem",
                      color: "rgb(255, 77, 79)",
                      marginRight: "0.8rem",
                    }}
                  />
                  <Span margin="my-auto">Order Summary</Span>
                </div>

                {details ? (
                  <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                ) : (
                  <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                )}
              </div>

              <Collapse in={details}>
                <MerchantDetails data={M?.orderItem} />
              </Collapse>
            </SubContainer>

            {!M?.referenceNumber && (
              <div className="flex justify-start">
                <div>
                  <Button
                    bg="green"
                    padding="px-2"
                    radius="sm"
                    onClick={() => setLinkOrderModal(true)}
                  >
                    Link Order
                  </Button>
                </div>
              </div>
            )}
          </div>
        </GridContainer>
      </div>

      {linkOrderModal && (
        <LinkOrderModal
          setLinkOrderModal={setLinkOrderModal}
          linkOrderModal={linkOrderModal}
          id={id}
        />
      )}
    </>
  );
};
