import { createModel } from "@rematch/core";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { RootModel } from ".";
import { authenticateViaPassword, getUserCredentials } from "@api";
import { getRoleModulesAndObjectOfRoleAccesses } from "@utils";
import { store } from "@store";

export interface State {
  customToken: string;
  isUserLoggedIn: boolean;
  userInfo: any | null;
  userToken: string;
  shouldRedirectToLogin: boolean;
  roleModules: any;
  objectOfRoleModules: any;
}

const initialState = {
  customToken: "",
  isUserLoggedIn: false,
  shouldRedirectToLogin: false,
  userInfo: null,
  userToken: "",
  roleModules: ["home"],
  objectOfRoleModules: null,
} as State

export const User = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    setUserLoggedIn(state, payload: boolean) {
      state.isUserLoggedIn = payload;
      return { ...state };
    },
    setUserInfo(state, payload: any | null) {
      state.userInfo = payload;
      return { ...state };
    },
    setUserToken(state, payload: string) {
      state.userToken = payload;
      return { ...state };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState }
    },
  },
  effects: (dispatch) => ({
    /**
     * @name login
     * @desc login user using email and password
     */
    async login({ email, password }: any) {
      dispatch.UI.setIsUserLoading({ target: "login", value: true });
      try {
        const auth = getAuth();
        const defaultRoleModules = store.getState().User.roleModules;
        // const res = await signInWithEmailAndPassword(auth, email, password);
        //const idToken = await auth.currentUser?.getIdToken(true);

        let res = await authenticateViaPassword({
          email,
          password,
        });

        const customToken = res.customToken

        const userCred = await signInWithCustomToken(auth, customToken)

        if (userCred) {
          const userCredentials = await getUserCredentials();
          const { modules, objectOfRole } = getRoleModulesAndObjectOfRoleAccesses(userCredentials);
          
          dispatch.User.updateState({
            userInfo: userCredentials,
            roleModules: [...defaultRoleModules, ...modules],
            objectOfRoleModules: objectOfRole
          }); 

          dispatch.User.updateState({
            isUserLoggedIn: true
          });

          dispatch.UI.setErrorMsg({
            target: "login",
            value: "",
          });

          dispatch.UI.resetForm("login");
          return { isSuccess: true }
        }
      } catch (err: any) { 
        const errMsg = err?.data?.error ?? 'Invalid credentials';
        dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        dispatch.UI.setErrorMsg({
          target: "login",
          value: errMsg,
        });
        return Promise.reject(errMsg);
      }
    },
    async logout(payload, rootState) {
      dispatch.User.resetState();
      dispatch.UI.resetState();
      dispatch.Table.resetState();
      dispatch.Locations.resetState();
      dispatch.User.updateState({ shouldRedirectToLogin: true });
      localStorage.removeItem('accessToken')
    },
  }),
});
