/* eslint-disable import/no-anonymous-default-export */
import { Spin } from "antd";

export const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="absolute z-50 items-center flex justify-center w-full h-full overscroll-none bg-[rgba(255,255,255,0.5)]">
          <Spin
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      )}
    </>
  );
};
