/* eslint-disable array-callback-return */
import { Container, Span } from "@common";
import "./Merchant.scss";
import { formatPrice } from "@utils";

export const MerchantDetails = ({ data }: any) => {
  return (
    <>
      <div className="p-2 max-h-[400px] overflow-y-auto">
        <Container>
          <div className="orders-content">
            <table>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th> UOM</th>
                  <th> Price</th>
                  <th> Quantity</th>
                  <th> Subtotal</th>
                </tr>
              </thead>

              <tbody>
                {data?.map((item, index) => {
                  return (
                    <tr className="card-orders" key={index}>
                      <td>
                        <Span transform="capitalize">
                          {item?.product?.name}
                        </Span>
                      </td>

                      <td>
                        <Span transform="capitalize">
                          {item?.category?.name}
                        </Span>
                      </td>

                      <td>
                        <Span transform="capitalize">
                          {item?.unitMeasurement?.name}
                        </Span>
                      </td>
                      <td>
                        <Span transform="capitalize">
                          {formatPrice(item?.product?.price)}
                        </Span>
                      </td>

                      <td>
                        <Span transform="capitalize">
                          {item?.product?.quantity}
                        </Span>
                      </td>
                      <td>
                        <Span transform="capitalize">
                          {formatPrice(item?.product?.subTotal)}
                        </Span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Container>
      </div>
    </>
  );
};
