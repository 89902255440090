import { get } from '@api';

export const getAttachment = async () => {
    try{     
        const res = await get(`/drivers-attachment`);   
        return res.data;
    }catch(err){
        throw err;
    }
}

export const getAttachmentByReference = async (id: any) => {
    try{     
        const res = await get(`/drivers-attachment?reference=${id}`);  
        return res.data;
    }catch(err){
        throw err;
    }
}

export const getAttachmentFilter = async (pageIndex: any, pageSize: any, keyword: string = '', service: string) => {
    try {
        const res = await get(`/drivers-attachment?service=${service}&pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}
