/* eslint-disable import/no-anonymous-default-export */
import { Steps } from 'antd' 
import { formatDateV2 } from '@utils'; 
import './HistoryStatus.scss'

const { Step } = Steps;
  
export default ({ express }: any) => { 

    const pendingSchedule = express?.createdAt ? formatDateV2(express?.createdAt): ''; 
    const forpickupSchedule = express?.acceptedAt ? formatDateV2(express?.acceptedAt) : '';
    const pickupSchedule = express?.pickedUpAt ? formatDateV2(express?.pickedUpAt): '';
    const ongoingSchedule = express?.onGoingAt? formatDateV2(express?.onGoingAt): '';
    const deliveredSchedule = express?.deliveredAt ? formatDateV2(express?.deliveredAt): '';
    const canceledSchedule = express?.cancelledAt ? formatDateV2(express?.cancelledAt) : ''; 
    const StatusExpress = [
        {
            label: 'Pending',
            value: 'pending',
            timestamp: pendingSchedule,
        },
        {
            label: 'For PickUp',
            value: 'for_pickup',
            timestamp: forpickupSchedule,
        },
        // {
        //     label: 'Arrived at Pickup',
        //     value: 'arrivedAtPickup', 
        //     timestamp: arrivedAtPickupSchedule,
        // },
        {
            label: 'Picked Up',
            value: 'picked_up',
            timestamp: pickupSchedule,
        },
        {
            label: 'On Going',
            value: 'on_going',
            timestamp: ongoingSchedule,
        },
        {
            label: 'Delivered',
            value: 'delivered',
            timestamp: deliveredSchedule,
        },
        {
            label: 'Canceled',
            value: 'canceled',
            timestamp: canceledSchedule,
        },
    ]  

    // const expressStatus = express?.status == 'for_pickup' && express?.deliveryStartTime != null && express?.arrivedAtPickupAt != null
    // ? 'arrivedAtPickup' : express?.status
    const index = StatusExpress?.findIndex(item => item.value === express?.status); 

    return (
      <> 
        <Steps direction="vertical" size="small" current={index}>   

            {StatusExpress?.map((item, index) => (
                <Step 
                    title={item.label} 
                    key={index} 
                    description={item.timestamp} 
                />
            ))}
        </Steps> 
      </>
  );
}; 
