import { useSelector } from 'react-redux'; 
import { RootState } from '@store';
import { Spin } from 'antd';

export const BackDrop = () => {

    const isLoading = useSelector((state: RootState) => state.UI.isLoading);

    return (
        <>
            {isLoading ? (
                <div className='absolute w-screen flex h-screen items-center justify-center bg-[rgba(255,255,255,0.5)] top-0 left-0 z-50'>
                    <div>
                        <Spin />
                    </div>
                </div>
            ) : (
                <>
                </>
            )}
        </>
    );
};
