/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';

import { Container, Span} from '@common'  
import { getActiveDrivers, assignRentalDriver } from '@api'; 

import { Dispatch } from '@store';

import { Checkbox, Form, Modal, Select, Tag, Input, Button as ButtonAntd, message, Cascader } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { debounce } from 'lodash'
import moment from 'moment';

type Props = {
    setAssignedDriverModal: Function,
    assignedDriverModal: boolean
    orderId: string,
    isAssignOrReAssign: string,
    driverGroup: string,
    orderData: any;
    rentalItems: any;
    setRentalItems: Function;
}

export const AssignedDriverRental = ({ setAssignedDriverModal, assignedDriverModal, orderId, isAssignOrReAssign, driverGroup, orderData, rentalItems, setRentalItems, ...props }: Props) => {
    
    const dispatch = useDispatch<Dispatch>();
    const [form]: any = Form.useForm(); 

    const { vehicleType, noOfDriver } = orderData;
    const initialTemp = {
        contactNumber: '',
        contactName: '',
        driverId: '',
        scheduleId: '',
        date: ''
    }

    const initialpayload = {
        driver3rdParty: [],
        regDriver: [],
        reason: ''
    }

    const [isLoading, setIsloading] = useState(false);
    const [drivers, setDrivers] = useState([]) as any;
    const [use3rdDriver, setUse3rdDriver] = useState(false);
    const [tempData, setTempData] = useState(initialTemp) as any;
    const [payload, setPayload] = useState(initialpayload) as any;
    
    const [data, setData] = useState({
        pageIndex: 1,
        pageSize: 20,
    })

    const { Option } = Select;

    const handleRemoveDriver = (driverType, date) => {
        if (driverType === 'regular') {
            setPayload({ ...payload, regDriver: [...payload?.regDriver?.filter(d => d?.date !== date)] })
        } else {
            setPayload({ ...payload, driver3rdParty: [...payload?.driver3rdParty?.filter(d => d?.date !== date)] })
        }
    }

    const onChange = (value: any) => {
        const { driverId, driverProfile } = JSON.parse(value); 
        setTempData({
            ...tempData,
            driverId,
            contactName: `${driverProfile?.lastName}, ${driverProfile?.firstName}`
        });
    }; 

    const onSearch = debounce(async (value: string) => {
        const driverName = value;
        try {
            setIsloading(true)
            if (value !== '') {
                const res = await getActiveDrivers(1, data?.pageSize, vehicleType, driverGroup, driverName);
                
                setDrivers([...res.results]); 
                // const driverData = drivers?.vehicles.map?.filter(d => d?.status === "active")
            } else {
                const res = await getActiveDrivers(1, data?.pageSize, vehicleType, driverGroup);
                setDrivers([...res.results]);
                setData({ ...data, pageIndex: 1 });
            }
            setIsloading(false)
        } catch (err) {
            console.log(err)
        }
    }, 500) 

    const getParcelDrivers = async () => {
        try {
            setIsloading(true)
            const res = await getActiveDrivers(data?.pageIndex, data?.pageSize, vehicleType, driverGroup);
            setDrivers([...drivers, ...res.results]);
            setIsloading(false)
        } catch (err) {
            console.log(err)
        }
    }

    const onScroll = (event) => {
        const target = event.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            setData({ ...data, pageIndex: data.pageIndex + 1 });
        }
    }

    const handleAssigned = async () => {
        setAssignedDriverModal(false);
        let dataToLoop;
        if (use3rdDriver) {
            dataToLoop = payload?.driver3rdParty;
        } else {
            dataToLoop = payload?.regDriver
        }
        dispatch.UI.setIsLoading(true);
        if (isAssignOrReAssign === 'Assign Driver') {
            const results = await dataToLoop?.map(async (el) => {
                let body;
                if (use3rdDriver) {
                    body = {
                        contactNumber: el?.contactNumber,
                        contactName: el?.contactName,
                    }
                } else {
                    body = {
                        // scheduleId: el?.scheduleId,
                        driverId: el?.driverId
                    }
                }
                try {
                    const res = await assignRentalDriver(el?.scheduleId, body);
                    return res.status;
                } catch (err: any) {
                    dispatch.UI.setIsLoading(false);
                    let errMsg = err?.response?.data?.message ?? `Unable to assign driver!`;
                    dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
                    return;
                }
            });

            const arrayofResult: any = await Promise.allSettled(results);
            const isFailed = arrayofResult?.some(res => res.value !== 200);

            if (!isFailed) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: `Order Number Assigned`, type: 'Success' });
                setPayload(initialpayload);
            }
        }
        // else {
        //     res = await reAssignDriver(orderId, body);
        //     if ([200, 201].includes(res.status)) {
        //         const { deliveryId, orderCode } = res?.data;
        //         await syncOrderToFireStore(orderCode);
        //         history.push(pathName.replace(orderId, deliveryId))
        //         dispatch.UI.setIsLoading(false);
        //         dispatch.UI.setAlert({ message: 'Driver Re-assign', type: 'Success' })
        //     }
        // }
    }

    const addedContactNumber = payload?.driver3rdParty?.map(d => d?.contactNumber);
    const addedDrivers = [...payload?.regDriver?.map(d => d.driverId), ...payload?.driver3rdParty?.map(d => d.driverId)]
    const addedSchedule = [...payload?.regDriver?.map(d => d?.scheduleId), ...payload?.driver3rdParty?.map(d => d?.scheduleId)];
    const isAssignDisabled = use3rdDriver && payload?.driver3rdParty?.length === 0 ?
        true :
        !use3rdDriver && payload?.regDriver?.length === 0 ?
            true :
            false;
    const isAddRegDriverDisabled = payload?.regDriver?.length === noOfDriver;
    const isAdd3rdDriverDisabled = payload?.driver3rdParty?.length === noOfDriver;

    const options = (rentalItems || [])
        ?.filter(sched => !addedSchedule.includes(sched?.id))
        ?.sort((a: any, b: any) => {
            a = new Date(a?.scheduleStartTime?.seconds * 1000);
            b = new Date(b?.scheduleStartTime?.seconds * 1000);
            return a - b;
        })
        ?.map(sched => {
            const originalSD = new Date(sched?.scheduleStartTime?.seconds * 1000);
            const formattedSD = `${moment(new Date(sched?.scheduleStartTime?.seconds * 1000)).format('MMM. DD, YYYY')}`;
            const formattedST = moment(new Date(sched?.scheduleStartTime?.seconds * 1000)).format('hh:mm a');
            const formattedET = moment(originalSD.setHours(sched?.scheduleType === 'halfday' ? originalSD.getHours() + 5 : originalSD.getHours() + 9)).format('hh:mm a');
              
            const time = `(${formattedST} - ${formattedET})`
            const label = `${formattedSD}-${time}`

            return {
                label,
                value: JSON.stringify({
                    date: formattedSD,
                    scheduleId: sched?.id,
                })
            }
        })

    useEffect(() => {
        if (data?.pageIndex > 1) {
            getParcelDrivers()
        }
    }, [data?.pageIndex])

    return (<>
        <Modal title={isAssignOrReAssign} visible={assignedDriverModal} okButtonProps={{ disabled: isAssignDisabled }} onOk={handleAssigned} okText={isAssignOrReAssign === 'Assign Driver' ? 'Assign' : 'Re-assign'} onCancel={() => setAssignedDriverModal(false)}>
            <div className='flex flex-col' >
                <div className='flex flex-row justify-start items-center mb-2'>
                    <Span>Use 3rd Provider Driver</Span>
                    <Checkbox onChange={(e: any) => {
                        setPayload(initialpayload);
                        setTempData(initialTemp);
                        setUse3rdDriver(e.target.checked)
                    }}
                        style={{ marginLeft: '0.5rem' }}
                    />
                </div>

                {/* {(use3rdDriver && payload?.driver3rdParty?.length !== noOfDriver || !use3rdDriver && payload?.regDriver?.length !== noOfDriver) && (
                    <Alert message={`Required No. of driver ${noOfDriver}`} type="warning" style={{ padding: '4px 4px' }} showIcon />
                )} */}

                {use3rdDriver ? (
                    <>
                        <Form
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                            style={{ width: '100%', marginTop: '0.5rem' }}
                            onFinish={() => {
                                const { scheduleId, date, contactName, contactNumber } = tempData;
                                if ([scheduleId, contactNumber, contactName].includes('')) return message.error('Please complete required fields!');
                                if (addedContactNumber.includes(contactNumber)) return message.error('Phone number already added!');
                                if (isNaN(contactNumber)) return message.error('Invalid phone number!');
                                setPayload({ ...payload, driver3rdParty: [...payload.driver3rdParty, { scheduleId, contactName, date, contactNumber }] });
                                form.resetFields();
                                setTempData(initialTemp)
                            }}
                        >
                            <Form.Item name="contactName" label="Select a schedule and driver to assign" >
                                <Input.Group compact>
                                    <Cascader
                                        disabled={isAdd3rdDriverDisabled}
                                        style={{ width: '30%' }}
                                        options={options}
                                        placeholder="Select Schedule"
                                        onChange={(v: any) => {
                                            const { scheduleId, date } = JSON.parse(v[0]);
                                            setTempData({
                                                ...tempData,
                                                scheduleId,
                                                date
                                            })
                                        }}
                                    />
                                    <Input
                                        disabled={isAdd3rdDriverDisabled}
                                        placeholder='Driver Contact Name'
                                        name='Name'
                                        style={{ width: '70%' }}
                                        onChange={(e) => setTempData({ ...tempData, contactName: e.target.value })} />
                                </Input.Group>
                            </Form.Item>

                            <Form.Item name="contactNumber" label="Driver Contact Number"
                                rules={[
                                    { required: true, message: "Please Input Driver Contact!" },
                                    { min: 10 },
                                    { max: 10 },
                                ]}>
                                <Input
                                    disabled={isAdd3rdDriverDisabled}
                                    addonBefore='+63'
                                    maxLength={10}
                                    placeholder='Driver Contact Number'
                                    name='contactNumber'
                                    onChange={(e) => setTempData({ ...tempData, contactNumber: e.target.value })}
                                />
                            </Form.Item>

                            {isAssignOrReAssign !== 'Assign Driver' && (
                                <Form.Item name='reason' label='Re-assign reason' rules={[{ required: true }]}>
                                    <Input name='reason' onChange={(e) => setPayload({ ...payload, reason: e.target.value })} />
                                </Form.Item>
                            )}
                        </Form>

                        {payload?.driver3rdParty?.length > 0 && (
                            <>
                                <Tag color="green" className='w-16 rounded-sm' style={{ marginBottom: '2px' }} >{`${payload?.driver3rdParty?.length} Driver${payload?.driver3rdParty?.length > 1 ? 's' : ''}`}</Tag>
                                <Container radius='sm' border='grey' padding='p-2'>
                                    {payload?.driver3rdParty?.map(driver => (
                                        <Tag key={driver?.date} style={{ display: 'inline-flex', alignItems: 'center' }} closable onClose={() => handleRemoveDriver('thirdparty', driver?.date)} >
                                            {`${driver?.date}(${driver?.contactName})`}
                                        </Tag>
                                    ))}
                                </Container>
                            </>
                        )}

                        <div className='py-2 flex justify-end'>
                            <ButtonAntd disabled={isAdd3rdDriverDisabled} type="primary" shape="circle" icon={<UserAddOutlined />} onClick={form.submit} />
                        </div>
                    </>
                ) : (
                    <div className='flex flex-col justify-center items-start mt-2'>
                        <Form
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                            style={{ width: '100%' }}
                            onFinish={() => {
                                const { driverId, scheduleId, date, contactName } = tempData;
                                if ([driverId, scheduleId].includes('')) return message.error('Please a select schedule and driver!');
                                setPayload({ ...payload, regDriver: [...payload.regDriver, { driverId, scheduleId, date, contactName }] });
                                form.resetFields();
                                setTempData(initialTemp)
                            }}
                        >
                            <Form.Item name="driver" label={isAssignOrReAssign === 'Assign Driver' ? 'Select a schedule and driver to assign' : 'Select a schedule and driver to re-assign'} >
                                <Input.Group compact>
                                    <Cascader
                                        disabled={isAddRegDriverDisabled}
                                        style={{ width: '30%' }}
                                        options={options}
                                        placeholder="Select Schedule"
                                        onChange={(v: any) => {
                                            const { scheduleId, date } = JSON.parse(v[0]);
                                            setTempData({
                                                ...tempData,
                                                scheduleId,
                                                date
                                            })
                                        }}
                                    />
                                    <Select
                                        disabled={isAddRegDriverDisabled}
                                        style={{ width: '70%' }}
                                        showSearch
                                        placeholder="Select a driver"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        loading={isLoading}
                                        onDropdownVisibleChange={(v) => {
                                            if (!v) {
                                                setData({ ...data, pageIndex: 1 });
                                                setDrivers([]);
                                            } else {
                                                getParcelDrivers()
                                            }
                                        }}
                                        onPopupScroll={onScroll}
                                        filterOption={(input, option: any) => {
                                            return (option!.children.props?.defaultValue as unknown as string).toLowerCase().includes(input.toLowerCase())
                                        }}
                                    >
                                        {drivers?.filter(d => !addedDrivers.includes(d?.driverId))?.map((driver: any) => (
                                            <Option value={JSON.stringify(driver)} key={driver.userId} >
                                                <div className='flex flex-col justify-center items-start' defaultValue={`${JSON.stringify(driver)}`}>
                                                    <Span weight='semi'>{driver?.driverProfile?.lastName ?? ''} {driver?.driverProfile?.firstName ?? ''} {driver?.driverProfile?.middleName ?? ''}</Span>
                                                    <div className='flex flex-row justify-center items-center'>
                                                        <Span>{driver?.phoneNumber ?? ''}</Span>
                                                        <Tag color={driver?.driverProfile?.isBusy ? 'warning' : driver?.driverStatus === 'inactive' ? 'error' : 'success'} style={{ marginLeft: '1rem' }}>
                                                            {driver?.driverProfile?.isBusy ? 'Is Busy' : driver?.driverStatus === 'inactive' ? 'Inactive' : 'Active'}
                                                        </Tag>
                                                    </div>
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </Input.Group>
                            </Form.Item>

                            {isAssignOrReAssign !== 'Assign Driver' && (
                                <Form.Item name='reason' label='Re-assign reason' rules={[{ required: true }]}>
                                    <Input name='reason' onChange={(e) => setPayload({ ...payload, reason: e.target.value })} />
                                </Form.Item>
                            )}
                        </Form>

                        {payload?.regDriver?.length > 0 && (
                            <div className='w-full'>
                                <Tag color="green" className='w-16 rounded-sm' style={{ marginBottom: '2px' }} >{`${payload?.regDriver?.length} Driver${payload?.regDriver?.length > 1 ? 's' : ''}`}</Tag>
                                <Container radius='sm' border='grey' padding='p-2'>
                                    {payload?.regDriver?.map(driver => (
                                        <Tag key={driver?.date} style={{ display: 'inline-flex', alignItems: 'center' }} closable onClose={() => handleRemoveDriver('regular', driver?.date)} >
                                            {`${driver?.date}(${driver?.contactName})`}
                                        </Tag>
                                    ))}
                                </Container>
                            </div>
                        )}

                        <div className='py-2 flex self-end'>
                            <ButtonAntd disabled={isAddRegDriverDisabled} type="primary" shape="circle" icon={<UserAddOutlined />} onClick={form.submit} />
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    </>);
}
