import { SELECT_COMPLETED_ORDERS, SELECT_MERCHANT_ORDERS } from "@constants";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { onlyNumberKey } from "@utils";

type Props = {
  searchData: any;
  setSearchData: any;
  deletedSearch?: any;
  setDeletedSearch?: any;
  setFilters: any;
  tab: any;
  index: any;
  search: any;
};

export const SearchMerchant = ({
  setSearchData,
  searchData,
  tab,
  index,
  search,
}: Props) => {
  const searchFilters = searchData?.map((s) => s?.filter);
  const FILTERVALUES = {
    status: "Status",
    id: "Order Id",
    storeName: "Store Name",
    contactName: "Contact Name",
    contactNumber: "Contact Number",
    pleaseSelect: "Please select",
  };
  const searchOptions = [
    {
      value: "status",
      label: "Status",
    },
    {
      value: "id",
      label: "Order Id",
    },
    {
      value: "storeName",
      label: "Store Name",
    },
    {
      value: "contactName",
      label: "Client Name",
    },
    {
      value: "contactNumber",
      label: "Contact Number",
    },
  ];

  const { Option } = Select;

  const renderInput = () => {
    switch (search?.filter) {
      case "status":
        return (
          <Select
            style={{ width: "100%" }}
            bordered={false}
            value={search?.value}
            onChange={(v: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: v } : item
                ),
              ])
            }
          >
            {tab === "openOrders"
              ? SELECT_MERCHANT_ORDERS?.map((item) => (
                  <Option key={item?.value} value={item?.value}>
                    {item?.name}
                  </Option>
                ))
              : SELECT_COMPLETED_ORDERS?.map((item) => (
                  <Option key={item?.value} value={item?.value}>
                    {item?.name}
                  </Option>
                ))}
          </Select>
        );
      case "id":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Order Id"
            name="id"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "storeName":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Store Name"
            name="storeName"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "contactName":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Client Name"
            name="contactName"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "contactNumber":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Contact Number"
            name="contactNumber"
            maxLength={10}
            onKeyPress={onlyNumberKey}
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="w-3/4 md:w-2/4 lg:w-2/5 border-[1px] border-gray-400 flex flex-row-reverse justify-start items-center p-1 rounded-sm search_border mr-2">
        {renderInput()}

        {search?.filter !== "premium" && (
          <div className="h-[20px] border-[1px] border-gray-300 mx-2" />
        )}

        <Select
          style={{ width: "100%" }}
          bordered={false}
          value={FILTERVALUES[search.filter]}
          onChange={(v: any) => {
            const newItem = {
              filter: v,
              value:
                v === "status" ? (tab === "openOrders" ? "all" : "all") : "",
            };
            setSearchData([
              ...searchData?.map((item, i) => (i === index ? newItem : item)),
            ]);
          }}
        >
          {searchOptions
            ?.filter((option) => !searchFilters?.includes(option.value))
            ?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </div>

      <div className="px-2">
        {index !== 0 && (
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            type="ghost"
            danger
            onClick={() => {
              setSearchData([...searchData?.filter((item, i) => i !== index)]);
            }}
          />
        )}
      </div>
    </>
  );
};
