import { createModel } from "@rematch/core";
import { RootModel } from ".";

interface ILocation {
    address: string;
    lat: number;
    lng: number;
}

interface ISearchLocation {
    address: string | null;
    location: ILocation;
}

interface ILocationState {
    searchLocation: ISearchLocation;
}

const initialState = {
    searchLocation: {
        address: '',
        location: {
            address: '',
            lat: 14.3840,
            lng: 120.98129634733525
        }
    }
} as ILocationState

export const Locations = createModel<RootModel>()({
    state: initialState,
    reducers: {
        resetState(state) {
            return {
                ...initialState
            }
        },

        updateState(state, newState) {
            return {
                ...state,
                ...newState
            }
        }
    },
    effects: (dispatch) => ({
        //some functions
    })

});