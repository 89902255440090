import { formatDate, isPendingAndForPickupExceedsTimeLimit } from "@utils";
import { GenerateStatusDesign } from "@components";
import { ColumnFilter } from "@components";
import { TableNoData } from "@common";
import { OPEN_ORDERS_STATUS } from "@constants";

export const COLUMNS = [
  {
    id: "orderCode",
    field: "orderCode",
    accessor: (data) => data?.orderCode ?? data?.order?.orderCode,
    Header: "Order code",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-primary-main";
      const ordercode = d?.orderCode ?? d?.order?.orderCode;
      return (
        <div className="truncate max-w-[200px]">
          <span
            className={`${
              ordercode ? "text-black-1" : "text-gray-400"
            }, ${textColor} `}
          >
            {ordercode ?? <TableNoData />}
          </span>
        </div>
      );
    },
  },
  {
    id: "clientReferenceNumber",
    field: "clientReferenceNumber",
    accessor: "clientReferenceNumber",
    Header: "Reference Code",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-primary-main";
      const clientReferenceNumber = d?.clientReferenceNumber
        ? d?.clientReferenceNumber
        : d?.order?.referenceNumber;
      return (
        <div className="truncate max-w-[200px]">
          <span
            className={`${
              clientReferenceNumber ? "text-black-1" : "text-gray-400"
            }, ${textColor} `}
          >
            {!clientReferenceNumber ? <TableNoData /> : clientReferenceNumber}
          </span>
        </div>
      );
    },
  },
  {
    id: "referenceNumber",
    field: "referenceNumber",
    accessor: "referenceNumber",
    Header: "Order Id",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-primary-main";
      const orderId = d?.referenceNumber;
      return (
        <div className="truncate max-w-[300px]">
          <span
            className={`${
              orderId ? "text-black-1" : "text-gray-400"
            }, ${textColor} `}
          >
            {orderId ?? <TableNoData />}
          </span>
        </div>
      );
    },
  },
  {
    id: "vehicleType",
    field: "vehicleType",
    accessor: "vehicleType",
    Header: "Vehicle Type",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      return (
        <div className="truncate max-w-[200px] uppercase text-center">
          <span className={`${d?.vehicleType ? textColor : "text-gray-400"}`}>
            {d?.vehicleType ?? <TableNoData />}
          </span>
        </div>
      );
    },
  },
  {
    id: "location",
    field: "location",
    accessor: (data) =>
      data?.pickup?.locationAddress ?? data?.pickUp?.locationAddress,
    Header: "Pickup Location",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      const location = d?.pickup?.locationAddress ?? d?.pickUp?.locationAddress;
      return (
        <div className="truncate max-w-[160px]">
          <span className={`${location ? textColor : "text-gray-400"} `}>
            {location ?? <TableNoData />}
          </span>
        </div>
      );
    },
  },
  {
    id: "pickUp",
    field: "pickUp",
    accessor: (data) => data?.pickup?.contactName ?? data?.pickUp?.contactName,
    Header: "Contact Name",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      const contactname = d?.pickup?.contactName ?? d?.pickUp?.contactName;
      return (
        <div className="truncate max-w-[200px] text-center">
          <span className={contactname ? textColor : "text-gray-400"}>
            {contactname ?? <TableNoData />}
          </span>
        </div>
      );
    },
  },
  {
    id: "driver",
    field: "driver",
    accessor: (data) =>
      `${data?.driver?.driverProfile?.firstName} ${data?.driver?.driverProfile?.lastName} ${data?.driver?.driverProfile?.middleName}`,
    Header: "Driver",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const driver = d?.driver?.driverProfile;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      return (
        <div className="truncate max-w-[200px] text-center capitalize">
          <span
            className={
              !driver?.firstName && !driver?.lastName && !driver?.middleName
                ? "text-gray-400"
                : textColor
            }
          >
            {!driver?.firstName ? "" : `${driver?.firstName} `}
            {!driver?.lastName ? "" : `${driver?.lastName} `}
            {!driver?.middleName ? "" : `${driver?.middleName} `}
          </span>
        </div>
      );
    },
  },
  {
    id: "createdAt",
    field: "createdAt",
    accessor: "createdAt",
    Header: "Date & Time Placed",
    // Filter: ColumnFilter,
    isVisible: true,
    sortType: "basic",
    sortInverted: false,
    Cell: (data) => {
      const d = data?.row?.original;
      const createdAt = d?.createdAt;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";

      return (
        <>
          <div className="text-center">
            <span className={createdAt ? textColor : "text-gray-400"}>
              {createdAt ? formatDate(createdAt) : <TableNoData />}
            </span>
          </div>
        </>
      );
    },
  },
  {
    id: "pickUpTime",
    field: "pickUpTime",
    accessor: (data) =>
      data?.isPremium
        ? Math.round(new Date().getTime() / 1000) +
          data?.createdAt?.seconds * 1000
        : data?.createdAt?.seconds,
    Header: "Pick up Time & Date",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const date = d?.scheduleStartTime
        ? d?.scheduleStartTime
        : d?.deliveryStartTime
        ? d?.deliveryStartTime
        : d?.pickedUpAt;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";

      return (
        <>
          <div className="text-center">
            <span className={date ? textColor : "text-gray-400"}>
              {date ? formatDate(date) : <TableNoData />}
            </span>
          </div>
        </>
      );
    },
  },
  {
    id: "type",
    field: "type",
    accessor: (data) => (data?.scheduleStartTime ? "Scheduled" : "Immediate"),
    Header: "Type",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      return (
        <>
          <div className="text-center">
            <span className={textColor}>
              {d?.scheduleStartTime ? "Scheduled" : "Immediate"}
            </span>
          </div>
        </>
      );
    },
  },
  {
    id: "status",
    field: "status",
    accessor: "status",
    Header: "Status",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <GenerateStatusDesign
          status={d?.status}
          label={OPEN_ORDERS_STATUS[d?.status]}
        />
      );
    },
  },
  {
    id: "riderShare",
    field: "riderShare",
    accessor: (data) => data?.fee?.deliveryFee,
    Header: "Rider Share",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="w-full flex items-center justify-center">
          <span className="text-[#7140b8] font-bold">
            {d?.fee?.total ?? 0} ₱
          </span>
        </div>
      );
    },
  },
];
