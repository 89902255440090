/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react/jsx-no-target-blank */
import { formatDateStartEndCompleted,formatDateStartEndHalfdayCompleted } from "@utils";
import { useState } from "react";
import moment from "moment";
import { Tag } from "antd";

import { Container, Span, SpanContainer } from "@common"; 
import { RENTALSTATUS} from "@constants";

import { TransactionHistoryRental } from "@components";

export default ({ rentalItems }: any) => {
 
  const [transactionHistoryModal, setTransactionHistoryModal] = useState<boolean>(false);
  const [rentalItemsHistory, setRentalItemsHistory] = useState([]) as any;

  return (
    <div className="p-2 max-h-[400px] overflow-y-auto">
      <Container>
        <Span weight="semi" color="brown" transform="uppercase">
          Driver Details & Schedule
        </Span>

        <div className="mt-4">
          <ul className="timeline">
            {rentalItems
              ?.sort(
                (a, b) =>
                  a?.scheduleStartTime?.seconds - b?.scheduleStartTime?.seconds
              )
              ?.map((item, index) => {
                const isThirdparty = item?.thirdPartyDriver ? true : false;
                const assignedDriver = item?.driver;
                const name =
                  assignedDriver?.contactName ??
                  `${assignedDriver?.driverProfile?.lastName ?? ""}, ${
                    assignedDriver?.driverProfile?.firstName
                  }`;
                const phoneNumber =
                  assignedDriver?.contactNumber ??
                  assignedDriver?.phoneNumber?.replace("+63", "");
                const d = new Date(item?.scheduleStartTime);
                const dateTime = d.valueOf();

                return (
                  <li key={index}>
                    <div className="sequence">{item?.sequence}</div>
                    {moment(item?.scheduleStartTime).format("MMM. DD, YYYY")}
                    {item?.scheduleType === "halfday"
                      ? formatDateStartEndHalfdayCompleted(dateTime)
                      : formatDateStartEndCompleted(dateTime)}
                    <ul>
                      {isThirdparty ? (
                        <li>
                          <Tag color="#2db7f5" style={{ margin: "0 0.5rem" }}>
                            3rd Party Driver
                          </Tag>
                        </li>
                      ) : (
                        <></>
                      )}
                      <li>
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                          <div>
                            <Span margin="mx-2" transform="capitalize">
                              <strong>ID:</strong> {item?.fireStoreId}
                            </Span>
                            {!["pending", "canceled"].includes(
                              item?.status
                            ) && (
                              <Span margin="mx-2" transform="capitalize">
                                <strong>Driver Name:</strong> {name ?? ""}
                              </Span>
                            )}
                            <div className="mx-2 flex">
                              <SpanContainer>
                                <strong>Status:</strong>
                              </SpanContainer>

                              <div
                                onClick={() => {
                                  setTransactionHistoryModal(true);
                                  setRentalItemsHistory(item?.rentalItemDeliveryStatusHistories);
                                }}
                                className="attachment-link uppercase"
                              >
                                {RENTALSTATUS[item?.status]}
                              </div>
                            </div>

                            {!["pending", "assigned", "canceled"].includes(
                              item?.status
                            ) && (
                              <a
                                href={`/attachment?reference=${item.fireStoreId}`}
                                className="attachment-link"
                                target="_blank"
                              >
                                Link to Driver Attachment
                              </a>
                            )}
                          </div>

                          <div>
                            <div className="mx-2">
                              <SpanContainer padding="pr-2">
                                <strong>Driver Share:</strong>
                              </SpanContainer>

                              <SpanContainer
                                transform="uppercase"
                                color={"violet"}
                                weight="bold"
                              >
                                ₱{item?.riderShare}
                              </SpanContainer>
                            </div>

                            {!["pending", "canceled"].includes(
                              item?.status
                            ) && (
                              <Span margin="mx-2">
                                <strong>Driver Ctc#: </strong>
                                {"+63" + phoneNumber ?? ""}
                              </Span>
                            )}

                            <div>
                              {item?.status === "canceled" ? (
                                <li>
                                  <Span margin="mx-2" transform="capitalize">
                                    <strong>Cancel Reason: </strong>
                                    {item?.cancelReason ?? "No Data"}
                                  </Span>
                                </li>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <hr className="mt-6" />
                      </li>
                    </ul>
                  </li>
                );
              })}
          </ul>
        </div>
      </Container>

      <TransactionHistoryRental
        setTransactionHistoryModal={setTransactionHistoryModal}
        transactionHistoryModal={transactionHistoryModal}
        rental={rentalItemsHistory}
      />
    </div>
  );
};
