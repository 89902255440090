import classnames from 'classnames';
import * as React from 'react';

type Props = React.HTMLProps<HTMLInputElement> & {
  children?: React.ReactNode, 
  border?: 'none' | 'grey', 
  padding?: 'pl-10' | 'py-1' | 'py-2' | 'pl-12' | 'pl-16',
  width?: 'auto'
}  

let getBorderStyle = (border: Props['border']) => {
    switch (border) {
      case 'none':
        return  'border-none'
      case 'grey':
        return 'border border-grey-1';
      default:
        return 'border border-grey-1 focus:border-blue-3';
    }
  }

let getPadding = (padding: Props['padding']) => {
  switch (padding) {
    case 'pl-16':
      return  'py-2.5 pl-16 pr-2.5' 
    case 'pl-12':
      return  'py-2.5 pl-12 pr-2.5' 
    case 'pl-10':
      return  'py-2.5 pl-10 pr-2.5' 
    case 'py-1':
      return  'py-1' 
    case 'py-2':
      return  'py-2' 
    default:
      return 'p-2';
  }
}

let getWidth = (width: Props['width']) => {
  switch (width) {
    case 'auto':
      return  'w-auto'
    default:
      return 'w-full';
  }
}


export const Input = ({border,width, padding, ...props} : Props) => (
    <input className={classnames("rounded focus:outline-none text-black-1 text-sm",
    getBorderStyle(border), getPadding(padding), getWidth(width)
    )} {...props}/>
  );