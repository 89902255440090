/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popconfirm, Select } from "antd";

import { Container, GridContainer, Span } from "@common";
import { VEHICLETYPE } from "@constants";

type Props = {
  driverDetails: any;
  setDriverDetails: any;
  index: any;
  setActiveVehicleIndex: any;
  activeVehicleIndex: any;
  vehicle: any;
  form: any;
  deletedVehicle?: any;
  setDeletedVehicle?: any;
};

export const Vehicle = ({
  driverDetails,
  setDriverDetails,
  index,
  setActiveVehicleIndex,
  activeVehicleIndex,
  vehicle,
  form,
  deletedVehicle,
  setDeletedVehicle,
}: Props) => {
  const [status, setStatus] = useState(
    vehicle.status !== "" ? vehicle?.status : null
  ) as any;

  const setVehicle: any = (value, name) => {
    const result = driverDetails?.vehicles?.map((item, i) =>
      index === i ? { ...item, [name]: value } : item
    );
    return result;
  };

  const { Option } = Select;

  useEffect(() => {
    if (activeVehicleIndex !== null) {
      if (activeVehicleIndex === index) {
        setDriverDetails({
          ...driverDetails,
          vehicles: [
            ...driverDetails?.vehicles?.map((a, i) =>
              i === index
                ? { ...a, status: "active" }
                : { ...a, status: "inactive" }
            ),
          ],
        });
        setStatus("active");
      } else {
        setStatus("inactive");
        setDriverDetails({
          ...driverDetails,
          vehicles: [
            ...driverDetails?.vehicles?.map((a, i) =>
              i === index ? { ...a, status: "inactive" } : a
            ),
          ],
        });
      }
    }
  }, [activeVehicleIndex]);

  useEffect(() => {
    const name1 = `vehicleType${index}`;
    const name2 = `vehicleModel${index}`;
    const name3 = `registeredPlateNumber${index}`;
    if (
      vehicle?.status !== "" ||
      vehicle?.vehicleType !== "" ||
      vehicle?.vehicleModel !== "" ||
      vehicle?.registeredPlateNumber !== ""
    ) {
      form.setFieldsValue({
        [name1]: vehicle?.vehicleType,
        [name2]: vehicle?.vehicleModel,
        [name3]: vehicle?.registeredPlateNumber,
      });
    } else {
      form.setFieldsValue({
        [name1]: null,
        [name2]: null,
        [name3]: null,
      });
    }
  }, [driverDetails?.vehicles?.length]);

  return (
    <div className="my-4">
      <Container border="grey" key={index}>
        <div className="flex justify-between">
          <Span weight="semi" color="blue">
            Vehicle {index + 1}
          </Span>
        </div>

        <GridContainer grid="two" padding="none">
          <Form.Item
            label="Vehicle Type"
            name={`vehicleType${index}`}
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
          >
            <Select
              placeholder="Vehicle Type"
              className="w-full"
              onChange={(v) => {
                setDriverDetails({
                  ...driverDetails,
                  vehicles: setVehicle(v, "vehicleType"),
                });
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              style={{ width: "12rem" }}
            >
              {VEHICLETYPE?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Status"
            //name={`Status ${index}`}
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
          >
            <Select
              placeholder="Status"
              className="w-full"
              value={status}
              onChange={(v) => {
                setDriverDetails({
                  ...driverDetails,
                  vehicles: setVehicle(v, "status"),
                });
                if (v === "active") {
                  setActiveVehicleIndex(index);
                } else {
                  setStatus("inactive");
                }
              }}
              style={{ width: "12rem" }}
              disabled={vehicle?.status === "active" ? true : false}
            >
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Vehicle Model"
            name={`vehicleModel${index}`}
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
          >
            <Input
              placeholder="Vehicle Model"
              onChange={(e) =>
                setDriverDetails({
                  ...driverDetails,
                  vehicles: setVehicle(e.target.value, "vehicleModel"),
                })
              }
              style={{ width: "12rem" }}
            />
          </Form.Item>

          <Form.Item
            label="Plate Number"
            name={`registeredPlateNumber${index}`}
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
          >
            <Input
              placeholder="Registered Plate Number"
              onChange={(e) =>
                setDriverDetails({
                  ...driverDetails,
                  vehicles: setVehicle(e.target.value, "registeredPlateNumber"),
                })
              }
              style={{ width: "12rem" }}
            />
          </Form.Item>
        </GridContainer>

        {driverDetails?.vehicles?.length > 1 && (
          <div className="flex justify-end my-4">
            <Popconfirm
              placement="leftTop"
              title="Are you sure?"
              onConfirm={() => {
                setDriverDetails({
                  ...driverDetails,
                  vehicles: [
                    ...driverDetails?.vehicles?.filter(
                      (item, i) => i !== index
                    ),
                  ],
                });
                if (vehicle?.vehicleId) {
                  setDeletedVehicle([...deletedVehicle, vehicle?.vehicleId]);
                }
              }}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                type="ghost"
                danger
              />
            </Popconfirm>
          </div>
        )}
      </Container>
    </div>
  );
};
