/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RootState, Dispatch } from "@store";
import { useSelector, useDispatch } from "react-redux";

import {
  GridContainer,
  SubContainer,
  Span,
  SpanContainer,
  Button,
} from "@common";
import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  where,
  Unsubscribe,
} from "firebase/firestore";

import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { IoRefresh } from "react-icons/io5";
import Pickup from "./Dropdown/Pickup";
import DropOff from "./Dropdown/DropOff";
import Map from "../Map/index";

import { AssignedDriverModal } from "../Modal/AssignedDriver";

import {
  CHANGE_TO,
  COMPLETED_ORDERS,
  DONT_SHOW_CHANGE_TO,
  EXPRESS,
  FAILED_ORDERS_STATUS,
  PAYMENTSTATUS,
  PAYMENT_METHODS,
  VEHICLE,
} from "@constants";

import {
  UpdateStatusModal,
  ChangePickupTimeModal,
  CancelModal,
  AddOnModal,
  Loader,
} from "@components";
import { Collapse } from "@mui/material";
import { formatDateV2, formatDecimalPrice, formatPhoneNumber } from "@utils";
import { Badge, Empty } from "antd";
import { CheckSquareFilled, InteractionFilled } from "@ant-design/icons";
import {
  getAddsOnFeeById,
  getCustomerInformation,
  getDeliveryInfo,
  syncOrderToFireStore,
} from "@api";
import HistoryStatus from "./HistoryStatus";
import { useIsWindowActive } from "@hooks";
import { AiFillPlusCircle } from "react-icons/ai";
import { useLoadScript } from "@react-google-maps/api";

const REACT_APP_API_MAP_KEY = process.env.REACT_APP_API_MAP_KEY!;

let UNSUBSCRIBE: Unsubscribe;

export const ExpressOrdersViewPage = ({ id }: any) => {
  useLoadScript({
    googleMapsApiKey: REACT_APP_API_MAP_KEY,
  });

  const isWindowActive = useIsWindowActive();
  const params: any = useParams();
  const tab = params?.tab;
  const history = useHistory();
  const pathName = useLocation().pathname;

  const [data, setData] = useState<any>({});
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [customer, setCustomerInfo] = useState<any>({});

  const dispatch = useDispatch<Dispatch>();
  const prevRoute = useSelector((state: RootState) => state.UI.currentRoute);

  const isAssignOrReAssign =
    data?.driver?.driverProfile?.driverId || data?.thirdPartyDriver
      ? "Re-assign Driver"
      : "Assign Driver";

  const user: any = useSelector(
    (state: RootState) => state.User?.userInfo
  ) as any;

  const roleAccesses = user?.role?.roleAccesses;
  const role = roleAccesses?.filter(
    (v) => v?.module?.key === "manage_parcel_deliveries"
  );
  const canCreateCustomAddOn = role[0].canCreateCustomAddOn;

  const [pickUp, setPickUp] = useState(true);
  const [dropOff, setDropOff] = useState(true);
  const [map, setMap] = useState(false);
  const [assignedDriverModal, setAssignedDriverModal] = useState(false);
  const [addOnModal, setAddOnModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [driverData, setDriverData] = useState([]) as any;
  const [addsOnFee, setAddsOnFee] = useState([] as any);

  const togglePickUp = (e: any) => {
    e.preventDefault();
    setPickUp(!pickUp);
  };

  const toggleDropOff = (e: any) => {
    e.preventDefault();
    setDropOff(!dropOff);
  };

  const toggleMap = (e: any) => {
    e.preventDefault();
    setMap(!map);
  };

  const handleAssignedDriver = (e) => {
    e.preventDefault();
    setAssignedDriverModal(true);
  };

  const refresh = () => {
    getDeliveryData();
  };

  const db = getFirestore();

  const handleResync = async () => {
    try {
      setLoading(true);
      const orderCode = data?.order?.orderCode;
      const res = await syncOrderToFireStore(orderCode);
      if ([200, 201].includes(res?.status)) {
        getDeliveryData();
        setLoading(false);
        dispatch.UI.setAlert({
          message: "Order successfully resync",
          type: "Success",
        });
      }
    } catch (err: any) {
      setLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to resync order!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  useEffect(() => {
    if (data?.order) {
      (async () => {
        const res = await getCustomerInformation(data?.order?.orderCode);
        setCustomerInfo(res);
      })();
    }
  }, [data?.order]);

  useEffect(() => {
    if (isWindowActive) {
      if (tab === "openOrders") {
        const q = query(
          collection(db, "deliveries"),
          where("deliveryId", "==", id)
        );

        UNSUBSCRIBE = onSnapshot(q, (doc) => {
          const listData = (doc?.docs || [])?.map((ref) => {
            const data = ref.data();
            return data;
          });
          setDriverData(listData);
          getDeliveryData();
        });

      } else {
        getDeliveryData();
      }
    } else {
      setMap(false);
      if (UNSUBSCRIBE) UNSUBSCRIBE();
    }
    return () => {
      if (UNSUBSCRIBE) UNSUBSCRIBE();
    };
  }, [pathName, isWindowActive, id]);

  const getDeliveryData = async () => {
    try {
      dispatch.UI.setIsLoading(true);
      const res = await getDeliveryInfo(id);
      setData(res.results[0]);
    } catch (error) {
      throw error;
    } finally {
      dispatch.UI.setIsLoading(false);
    }
  };
  
  const addsOnFeeById = async () => { 
    try {  
      const res = await getAddsOnFeeById(data?.order?.orderId);  
      setAddsOnFee(res);
    } catch (err) {
      console.log(err);
    } 
  }; 

  useEffect(() => {
    addsOnFeeById();
  }, [data?.order]); 


  return (
    <>
      <Loader isLoading={loading} />
      {data && data?.order ? (
        <div className="bg-white rounded border-gray p-6">
          <GridContainer grid="five" padding="none">
            <div>
              <div className="bg-brown-1 p-8 text-center rounded-md my-4">
                <Span weight="semi" size="2xl" color="white">
                  ₱ &nbsp;
                  {formatDecimalPrice(data?.order?.totalCharge ?? 0)}
                </Span>
                <Span color="white">Total Amount</Span>
              </div>

              <div className="mb-2">
                <SpanContainer padding="pr-2">Status:</SpanContainer>
                <SpanContainer color="brown" weight="bold">
                  {EXPRESS[data?.status]}
                </SpanContainer>
              </div>

              {data?.paymentDetails?.paymentStatus && (
                <div className="my-4">
                  <span
                    className={
                      data?.paymentDetails?.paymentStatus ===
                        "payment_success" ||
                      data?.paymentDetails?.paymentStatus === "refund_success"
                        ? "tag-green"
                        : "tag-red"
                    }
                  >
                    {PAYMENTSTATUS[data?.paymentDetails?.paymentStatus]}
                  </span>
                </div>
              )}

              {!DONT_SHOW_CHANGE_TO.includes(data?.status) ? (
                <span
                  className="p-1 border-[1px] border-primary-main truncate text-sm text-primary-main cursor-pointer"
                  onClick={() => setUpdateStatusModal(true)}
                >
                  Change to <strong>{EXPRESS[CHANGE_TO[data?.status]]}</strong>
                </span>
              ) : (
                FAILED_ORDERS_STATUS.includes(data?.status) && (
                  <>
                    <div className="my-2">
                      <Span padding="pr-2">Cancel reason:</Span>
                      <Span weight="semi">{data?.cancelReason}</Span>
                    </div>
                    <div className="mb-2">
                      <Span padding="pr-2">Cancel at:</Span>
                      <Span weight="semi">
                        {formatDateV2(data?.cancelledAt)}
                      </Span>
                    </div>
                  </>
                )
              )}

              <SubContainer border="grey" padding="p-2">
                <Span weight="semi" color="brown" transform="uppercase">
                  Customer Details
                </Span>

                <Span margin="my-2">Order Number:</Span>
                <span className="text-sm font-semibold p-1 border-[1px] border-[#b7eb8f] bg-[#f6ffed] mb-2">
                  {data?.order?.orderCode}
                </span>

                {data?.order?.referenceNumber && (
                  <div className="my-4">
                    <Span margin="my-2">Reference Number:</Span>
                    <span className="text-sm font-semibold p-1 border-[1px] border-[#b7eb8f] bg-[#f6ffed]">
                      {data?.order?.referenceNumber}
                    </span>
                  </div>
                )}

                <Span margin="mt-2">Contact Name:</Span>
                <Span weight="semi" transform="capitalize">
                  {customer?.firstName ?? ""} {customer?.middleName ?? ""}{" "}
                  {customer?.lastName ?? ""}
                </Span>

                <Span margin="mt-2">Contact No:</Span>
                <Span weight="semi" transform="capitalize">
                  {formatPhoneNumber(customer?.phoneNumber)}
                </Span>

                <Span margin="mt-2">Order Placed:</Span>
                <Span weight="semi" transform="capitalize">
                  {formatDateV2(data?.createdAt)}
                </Span>

                <Span margin="mt-2">Payment Method:</Span>
                <Span weight="semi" transform="capitalize">
                  {PAYMENT_METHODS[data?.paymentMethod]}
                </Span>

                <Span margin="mt-2">
                  {!data?.scheduleStartTime ? "Service Type:" : "Delivery at:"}
                </Span>
                <Span weight="semi" transform="capitalize">
                  {!data?.scheduleStartTime
                    ? "Delivery Now"
                    : formatDateV2(data?.scheduleStartTime)}
                </Span>
              </SubContainer>

              {data?.thirdPartyDriver ? (
                <Badge.Ribbon text="3rd Provider">
                  <SubContainer border="grey" padding="p-2">
                    <Span weight="semi" color="brown" transform="uppercase">
                      Driver Details
                    </Span>

                    <Span margin="mt-2">Name:</Span>
                    <Span weight="semi" transform="capitalize">
                      {data?.thirdPartyDriver?.contactName ?? ""}
                    </Span>

                    <Span margin="mt-2">Phone Number:</Span>
                    <Span weight="semi" transform="capitalize">
                      {formatPhoneNumber(
                        data?.thirdPartyDriver?.contactNumber
                      ) ?? ""}
                    </Span>

                    <Span margin="mt-2">Plate Number:</Span>
                    <Span weight="semi" transform="capitalize">
                      {data?.thirdPartyDriver?.plateNumber ?? ""}
                    </Span>
                  </SubContainer>
                </Badge.Ribbon>
              ) : (
                <SubContainer border="grey" padding="p-2">
                  <Span weight="semi" color="brown" transform="uppercase">
                    Driver Details
                  </Span>
                  {!data?.driver ? (
                    <div className="flex items-center justify-center flex-col">
                      <Empty />
                    </div>
                  ) : (
                    <>
                      <Span margin="mt-2">Name:</Span>
                      <Span weight="semi" transform="capitalize">
                        {`${data?.driver?.driverProfile?.lastName ?? ""}, ${
                          data?.driver?.driverProfile?.firstName ?? ""
                        } ${data?.driver?.driverProfile?.middleName ?? ""}`}
                      </Span>

                      <Span margin="mt-2">Phone Number:</Span>
                      <Span weight="semi" transform="capitalize">
                        {formatPhoneNumber(data?.driver?.phoneNumber) ?? ""}
                      </Span>
                    </>
                  )}
                </SubContainer>
              )}

              <SubContainer border="grey" padding="p-2">
                <Span weight="semi" color="brown" transform="uppercase">
                  REMARKS
                </Span>

                <Span margin="mt-2">Items:</Span>
                <Span
                  weight="semi"
                  transform="capitalize"
                  color={
                    data?.itemDescription === "" ? "lightgrey" : "required"
                  }
                >
                  {data?.itemDescription === ""
                    ? "No Data"
                    : data?.itemDescription}
                </Span>

                <Span margin="mt-2">Notes to Driver:</Span>
                <Span
                  weight="semi"
                  transform="capitalize"
                  color={data?.notes === "" ? "lightgrey" : "required"}
                >
                  {data?.notes === "" ? "No Data" : data?.notes}
                </Span>
              </SubContainer>
            </div>

            <div className="col-span-4">
              <GridContainer grid="six" padding="none">
                <div className="col-span-5">
                  <div className="flex justify-between items-center p-2 mt-4">
                    <Span weight="semi" size="lg">
                      Delivery Details
                    </Span>
                    <div className="flex flex-row justify-center items-center">
                      <div
                        className="p-1 border-2 border-primary-main opacity-70 cursor-pointer"
                        onClick={() => refresh()}
                      >
                        <IoRefresh color="black" />
                      </div>
                      <Button
                        padding="px-2"
                        radius="sm"
                        margin="mx-2"
                        onClick={handleResync}
                      >
                        Sync order to FireStore
                      </Button>
                      <Button
                        padding="px-2"
                        radius="sm"
                        onClick={() => history.push(`${prevRoute}?tab=${tab}`)}
                      >
                        Back to parcel
                      </Button>
                    </div>
                  </div>

                  <div
                    className={`${
                      !data?.order?.source || !data?.order?.device
                        ? "justify-around"
                        : "justify-between"
                    } flex py-2 px-2 border-[1px] border-grey-1 rounded-sm`}
                  >
                    <div className="flex flex-row">
                      <Span margin="mx-2" weight="semi">
                        Vehicle Used
                      </Span>
                      <Span transform="uppercase">
                        {VEHICLE[data?.vehicleType]}
                      </Span>
                    </div>

                    {(data?.order?.source || data?.order?.device) ===
                      undefined && (
                      <div className=" h-5 border-gray-300 border-l-[1px]" />
                    )}

                    <div className="flex flex-row">
                      {(data?.order?.source || data?.order?.device) !==
                        undefined && (
                        <div className="h-full border-gray-300 border-l-[1px]" />
                      )}
                      <Span margin="mx-2" weight="semi">
                        {" "}
                        Total Distance{" "}
                      </Span>
                      <Span transform="uppercase">{data?.distance} KM</Span>
                    </div>

                    {(data?.order?.source || data?.order?.device) !==
                      undefined && (
                      <>
                        <div className="flex flex-row">
                          <div className="h-full border-gray-300 border-l-[1px]" />
                          <Span margin="mx-2" weight="semi">
                            {" "}
                            Order Source{" "}
                          </Span>
                          <Span transform="uppercase">
                            {data?.order?.source ?? "Unknown"}
                          </Span>
                        </div>

                        <div className="flex flex-row">
                          <div className="h-full border-gray-300 border-l-[1px]" />
                          <Span margin="mx-2" weight="semi">
                            {" "}
                            Device Used{" "}
                          </Span>
                          <Span transform="uppercase">
                            {data?.order?.device ?? "unknown"}
                          </Span>
                        </div>
                      </>
                    )}
                  </div>

                  <SubContainer border="grey" padding="none">
                    <div
                      className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                      onClick={togglePickUp}
                    >
                      <div className="flex my-auto">
                        <CheckSquareFilled
                          style={{
                            fontSize: "1.8rem",
                            color: "#84b15c",
                            marginRight: "0.8rem",
                          }}
                        />
                        <Span margin="my-auto">Pick-up Location</Span>
                      </div>

                      {pickUp ? (
                        <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                      ) : (
                        <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                      )}
                    </div>

                    <Collapse in={pickUp}>
                      <Pickup
                        pickUp={data?.pickup ?? data?.pickUp}
                        driver={data?.driver}
                        thirdPartyDriver={data?.thirdPartyDriver}
                      />
                    </Collapse>

                    <div
                      className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                      onClick={toggleDropOff}
                    >
                      <div className="flex my-auto">
                        <InteractionFilled
                          style={{
                            fontSize: "1.8rem",
                            color: "rgb(113, 64, 184)",
                            marginRight: "0.8rem",
                          }}
                        />
                        <Span margin="my-auto">Drop-off Location</Span>
                      </div>
                      {dropOff ? (
                        <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                      ) : (
                        <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                      )}
                    </div>

                    <Collapse in={dropOff}>
                      <DropOff
                        stops={data?.stops}
                        expressId={id}
                        status={data?.status}
                        driver={data?.driver}
                        thirdPartyDriver={data?.thirdPartyDriver}
                        arrivedAtDropOffAt={data?.arrivedAtDropOffAt}
                      />
                    </Collapse>

                    <div
                      className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                      onClick={toggleMap}
                    >
                      <div className="flex my-auto">
                        <CheckSquareFilled
                          style={{
                            fontSize: "1.8rem",
                            color: "rgb(255, 77, 79)",
                            marginRight: "0.8rem",
                          }}
                        />
                        <Span margin="my-auto">Map Navigation</Span>
                      </div>
                      {map ? (
                        <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                      ) : (
                        <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                      )}
                    </div>

                    <Collapse in={map}>
                      {data && (
                        <Map
                          data={data}
                          driverData={driverData[0]}
                          isShow={map}
                        />
                      )}
                    </Collapse>
                  </SubContainer>

                  <SubContainer border="grey" padding="none">
                    <GridContainer padding="p-2" grid="two" gap="none">
                      <div className=" border-r-[1px] border-gray-300 border-solid">
                        {Object.entries(data?.order ?? (data?.order || {}))
                          ?.filter(
                            (v: any) => v[0]?.endsWith("Fee") && v[1] !== 0
                          )
                          ?.map((detail, index) =>
                            detail[0] !== "deliveryFee" ? (
                              <div
                                key={index}
                              >
                                <div className="px-4 flex items-center justify-between">
                                  <Span transform="capitalize">
                                    {detail[0] === "holidaySurchargeFee" && 
                                      addsOnFee.length && addsOnFee.filter(item => item.type === 'holiday_surcharge_fee')
                                      ? addsOnFee.filter(item => item.type === 'holiday_surcharge_fee')[0].particular
                                      : detail[0] === "afterHoursSurchargeFee" ? "Peak Hours Surcharge Fee" 
                                      : detail[0] === "customAddOnsFee" ? null : detail[0].replace(/([a-z])([A-Z])/g, "$1 $2")
                                    }
                                  </Span>
                                  <Span> {detail[0] != "customAddOnsFee" && `₱ ${detail[1]}`}</Span>
                                </div>

                                {detail[0] === "customAddOnsFee" && addsOnFee.filter(item=> item.isCustomAddOns).map(item => (
                                  
                                  <div className="px-4 flex items-center justify-between"> 
                                    <a href={item.image} target="_blank">{item.particular}</a>
                                    <Span>{`₱ ${item.amount}`}</Span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )
                          )}

                        {data?.order?.customAddOnsDetail?.length && (
                          <>
                            {data?.order?.customAddOnsDetail?.map((c) => (
                              <div className="px-4 flex items-center justify-between">
                                <a href={c?.filePath} target="_blank">
                                  {c?.filePath ? (
                                    <Span color="blue">{c?.particular}</Span>
                                  ) : (
                                    <Span>{c?.particular}</Span>
                                  )}
                                </a>
                                <Span>₱ {c?.amount}</Span>
                              </div>
                            ))}
                          </>
                        )}

                        {data?.order?.voucherCode && (
                          <div className="px-4 flex items-center justify-between">
                            <Span>{`Discount (Voucher Code: ${data?.order?.voucherCode})`}</Span>
                            <Span>₱ -{data?.order?.discountAmount}</Span>
                          </div>
                        )}

                        <div className="px-4 flex items-center justify-between">
                          <Span color="brown" weight="semi">
                            Total Delivery Charge
                          </Span>
                          <Span color="brown" weight="semi">
                            ₱ {formatDecimalPrice(data?.order?.deliveryFee)}
                          </Span>
                        </div>
                      </div>

                      <div> 
                        <div className="px-4 flex items-center justify-between">
                          <Span weight="semi">Delivery Fare</Span>
                        </div>
                        {data?.fee?.detail
                          ?.filter(
                            (detail) =>
                              ![
                                "amount_to_be_collected",
                                "amount_to_be_remitted",
                              ].includes(detail?.type)
                          )
                          ?.filter((detail) => detail?.amount !== 0)
                          ?.map((detail, index) => (
                            <div
                              key={index}
                              className="px-4 flex items-center justify-between"
                            >
                              <Span transform="capitalize">
                                {detail?.particular}
                              </Span>
                              <Span>
                                ₱ {formatDecimalPrice(detail?.amount)}
                              </Span>
                            </div>
                          ))}

                        <div className="px-4 flex items-center justify-between">
                          <Span color="brown" weight="semi">
                            Amount to be collected
                          </Span>
                          <Span color="brown" weight="semi">
                            ₱{" "}
                            {formatDecimalPrice(data?.fee?.amountToBeCollected)}
                          </Span>
                        </div>
                        <div className="px-4 flex items-center justify-between">
                          <Span color="brown" weight="semi">
                            Amount to be remitted
                          </Span>
                          <Span color="brown" weight="semi">
                            ₱{" "}
                            {formatDecimalPrice(data?.fee?.amountToBeRemitted)}
                          </Span>
                        </div>
                      </div>
                    </GridContainer>

                    <hr className="mx-4" />

                    <GridContainer padding="p-2" grid="two" gap="none">
                      <div className="px-2 flex items-center justify-between border-r-[1px] border-gray-300 border-solid">
                        <Span color="violet" weight="semi" size="lg">
                          Grand Total
                        </Span>
                        <Span color="violet" weight="semi" size="lg">
                          ₱ {formatDecimalPrice(data?.order?.totalCharge)}
                        </Span>
                      </div>

                      <div className="px-2 flex items-center justify-between">
                        <Span color="violet" weight="semi" size="lg">
                          Total Fee
                        </Span>
                        <Span color="violet" weight="semi" size="lg">
                          ₱ {formatDecimalPrice(data?.fee?.total) ?? 0}
                        </Span>
                      </div>
                    </GridContainer>
                  </SubContainer>

                  {!COMPLETED_ORDERS.includes(data?.status) && (
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row">
                        <Button
                          bg="green"
                          padding="px-2"
                          radius="sm"
                          onClick={handleAssignedDriver}
                        >
                          {isAssignOrReAssign}
                        </Button>

                        {["new", "pending", "assigned", "for_pickup"].includes(
                          data?.status
                        ) && (
                          <Button
                            padding="px-2"
                            margin="mx-2"
                            radius="sm"
                            onClick={() => setChangeDate(true)}
                          >
                            Change pick-up time
                          </Button>
                        )}
                      </div>
                      <div>
                        <Button
                          bg="red"
                          padding="px-2"
                          radius="sm"
                          onClick={() => setCancelModal(true)}
                        >
                          Cancel Delivery
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* Modals declarations */}

                  <UpdateStatusModal
                    status={CHANGE_TO[data?.status]}
                    orderId={id}
                    updateStatusModal={updateStatusModal}
                    setUpdateStatusModal={setUpdateStatusModal}
                  />

                  <AssignedDriverModal
                    setAssignedDriverModal={setAssignedDriverModal}
                    assignedDriverModal={assignedDriverModal}
                    deliveryIds={id} //deliveryId
                    isAssignOrReAssign={isAssignOrReAssign}
                    vehicleType={data?.vehicleType}
                    driverGroup={"parcel_and_grocery_delivery"}
                  />

                  <CancelModal
                    cancelModal={cancelModal}
                    setCancelModal={setCancelModal}
                    orderId={id}
                  />

                  <ChangePickupTimeModal
                    changeDate={changeDate}
                    setChangeDate={setChangeDate}
                    id={id}
                  />

                  <AddOnModal
                    setAddOnModal={setAddOnModal}
                    addOnModal={addOnModal}
                    id={id}
                    refresh={refresh}
                    getDeliveryData={getDeliveryData}
                  />
                </div>

                <HistoryStatus express={data} />
              </GridContainer>
            </div>
          </GridContainer>
        </div>
      ) : null}
    </>
  );
};
