/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from "react-redux";
import { useState } from "react";

import "../../containers/DriverManagement/AllDrivers/Driver.css";

import { Dispatch } from "@store";
import {
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Button,
  Popconfirm,
  message,
  DatePicker,
} from "antd";

import { TYPECALENDAR } from "@constants";
import { addNewHoliday } from "@api";

const { Option } = Select;

type Props = {
  addCalendar: boolean;
  setAddCalendar: Function;
  getData: Function;
};
type RequiredMark = boolean | "optional";

export const AddCalendar = ({
  addCalendar,
  setAddCalendar,
  getData,
  ...props
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const initialState = {
    name: "",
    holidayType: "",
    date: "",
  };

  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");
  const [calendarDetails, setCalendarDetails] = useState(initialState);
  const [isLoading, setisloading] = useState(false);

  const error = (errMsg) => {
    message.error(errMsg);
  };

  const handleAddCalendar = async () => {
    try {
      setisloading(true);
      const res = await addNewHoliday({ ...calendarDetails });
      if ([201, 200].includes(res.status)) {
        setisloading(false);
        setAddCalendar(false);
        dispatch.UI.setAlert({
          message: "New holiday added!",
          type: "Success",
        });
        setCalendarDetails(initialState);
        getData();
      }
    } catch (err: any) {
      const errMsg =
        err?.response?.data.message ?? "Unable to add new holiday!";
      setisloading(false);
      error(errMsg);
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setCalendarDetails({
      ...calendarDetails,
      [name]: value,
    });
  };

  return (
    <>
      <Drawer
        title="Create a new holiday"
        placement="right"
        width={500}
        onClose={() => {
          setCalendarDetails(initialState);
          setAddCalendar(false);
        }}
        visible={addCalendar}
        footer={
          <div className="flex justify-center">
            <Space>
              <Popconfirm
                placement="top"
                title="Discard changes made in the profile information?"
                onConfirm={() => {
                  setCalendarDetails(initialState);
                  setAddCalendar(false);
                }}
                okText="Discard"
                cancelText="Cancel"
              >
                <Button loading={isLoading}>Cancel</Button>
              </Popconfirm>
              <Button onClick={form.submit} type="primary" loading={isLoading}>
                Add Holiday
              </Button>
            </Space>
          </div>
        }
      >
        <Form
          onFinish={() => {
            form.resetFields();
            handleAddCalendar();
          }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
          requiredMark={requiredMark}
        >
          <div className="text-left">
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Name" name="name" onChange={onChange} />
            </Form.Item>

            <Form.Item label="Date" name="date" rules={[{ required: true }]}>
              <DatePicker
                name="date"
                inputReadOnly={true}
                className="w-full"
                onChange={(e: any) =>
                  setCalendarDetails({ ...calendarDetails, date: e })
                }
              />
            </Form.Item>

            <Form.Item
              label="Holiday Type"
              name="holidayType"
              rules={[{ required: true }]}
            >
              <Select
                className="w-full"
                placeholder="Holiday Type"
                onChange={(v: any) =>
                  setCalendarDetails({ ...calendarDetails, holidayType: v })
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {TYPECALENDAR?.map((type, index) => (
                  <Option key={index} value={type.value}>
                    {type.name}
                  </Option>
                ))}
                {/* <Option value='regular_holiday'>Regular Holiday</Option>
                                <Option value='special_non_working_day'>Specail non working days</Option>  */}
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </>
  );
};
