import { Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { ShopOrdersPage } from '@containers';

import { useGetCurrentRoleAccesses } from '@hooks';

export const ShopOrders = () => {
    const module_name = 'manage_parcel_deliveries';
    useGetCurrentRoleAccesses(module_name);
    return (
        <>
            <MainLayout>
                <SideBar />  
                <Header />
                
                <ContainerLayout>
                    <ShopOrdersPage />
                </ContainerLayout>
            </MainLayout>
        </>
    )
}