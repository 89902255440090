import { useState, useEffect } from 'react' 
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash'

import { Dispatch } from '@store'; 
import { Checkbox, Form, Modal, Select, Tag, Input } from 'antd';
import { getActiveDrivers, reAssignRentalDriver } from '@api';

import { Span } from '@common' 

type Props = {
    setReassignModal: Function,
    reassignModal: boolean
    orderId:  string | null, 
    reassigndriverId:  string | null, 
    driverGroup: string,
    orderData: any; 
}

export const ReassignDriverRental = ({ setReassignModal, reassignModal, orderId, reassigndriverId, driverGroup, orderData, ...props }: Props) => {
   
    const dispatch = useDispatch<Dispatch>();
    const [form]: any = Form.useForm(); 
    
    const { vehicleType } = orderData; 
    const initialTemp = {
        contactNumber: '',
        contactName: '',
        driverId: '',
        scheduleId: '',
        date: '',
        reason: ''
    }

    const initialpayload = {
        driver3rdParty: [],
        regDriver: [],
        reason: ''
    }

    const [isLoading, setIsloading] = useState(false);
    const [drivers, setDrivers] = useState([]) as any;
    const [use3rdDriver, setUse3rdDriver] = useState(false); 
    const [payload, setPayload] = useState(initialpayload) as any;

    const [data, setData] = useState({
        pageIndex: 1,
        pageSize: 20,
    })

    const { Option } = Select;

    const onChange = (value: any) => {
        const { driverId, phoneNumber: contactNumber, lastName, firstName, middleName } = JSON.parse(value);
        setPayload({ ...payload, driverId, contactNumber });
        form.setFieldsValue({ driver: `${lastName} ${firstName} ${middleName}` })
    }; 

    const onSearch = debounce(async (value: string) => {
        const driverName = value;
        try {
            setIsloading(true)
            if (value !== '') {
                const res = await getActiveDrivers(1, data?.pageSize, vehicleType, driverGroup, driverName);
                setDrivers([...res.results]);
            } else {
                const res = await getActiveDrivers(1, data?.pageSize, vehicleType, driverGroup);
                setDrivers([...res.results]);
                setData({ ...data, pageIndex: 1 });
            }
            setIsloading(false)
        } catch (err) {
            console.log(err)
        }
    }, 500) 

    const getParcelDrivers = async () => {
        try {
            setIsloading(true)
            const res = await getActiveDrivers(data?.pageIndex, data?.pageSize, vehicleType, driverGroup);
            setDrivers([...drivers, ...res.results]);
            setIsloading(false)
        } catch (err) {
            console.log(err)
        }
    }

    const onScroll = (event) => {
        const target = event.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            setData({ ...data, pageIndex: data.pageIndex + 1 });
        }
    }
   

    const handleAssigned = async () => {
        setReassignModal(false);
        let res;
        let body;
        if (use3rdDriver) {
            body = {
                contactName: payload.contactName,
                contactNumber: payload.contactNumber,
                reason: payload.reason
            }
        } else {
            body = { 
                driverId: payload.driverId,
                reason: payload.reason
            }
        }
        try {
            dispatch.UI.setIsLoading(true); 
            res = await reAssignRentalDriver(orderId, body);
            if ([200, 201].includes(res.status)) { 
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: 'Driver Re-assign', type: 'Success' })
            } 

        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            let errMsg = err?.response?.data?.message ?? `Unable to assign driver!`;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        }
    }
 

    useEffect(() => {
        if (data?.pageIndex > 1) {
            getParcelDrivers()
        }
    }, [data?.pageIndex]) 

    return (<>
        <Modal 
            title={'Re-assign Driver'} visible={reassignModal} 
            okText={'Re-assign'} 
            onCancel={() => setReassignModal(false)} 
            onOk={handleAssigned} 
        > 
            <div className='flex flex-col' >
                <div className='flex flex-row justify-start items-center'>
                    <Span>Use 3rd Provider Driver</Span>
                    <Checkbox onChange={(e: any) => setUse3rdDriver(e.target.checked)} style={{ marginLeft: '0.5rem' }} />
                </div>

                {use3rdDriver ? (
                    <>
                        <Form
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                            style={{ width: '100%', marginTop: '0.5rem' }}
                            onFinish={() => {
                                form.resetFields();
                                setUse3rdDriver(false);
                                handleAssigned();
                            }}
                        >
                            <Form.Item name="Name" label="Driver Contact Name" rules={[{ required: true, message: "Please Input Driver Name!" }]}>
                                <Input placeholder='Driver Contact Name' name='Name' onChange={(e) => setPayload({ ...payload, contactName: e.target.value })} />
                            </Form.Item>

                            <Form.Item name="Phone Number" label="Driver Contact Number"
                                rules={[
                                    { required: true, message: "Please Input Driver Contact!" },
                                    { min: 10 },
                                    { max: 10 },
                                ]}>
                                <Input addonBefore='+63' placeholder='Driver Contact Number' name='Phone Number' onChange={(e) => setPayload({ ...payload, contactNumber: e.target.value })} />
                            </Form.Item>
 
                                <Form.Item name='reason' label='Re-assign reason' rules={[{ required: true }]}>
                                    <Input name='reason' onChange={(e) => setPayload({ ...payload, reason: e.target.value })} />
                                </Form.Item> 
                        </Form>
                    </>
                ) : (
                    <div className='flex flex-col justify-center items-start mt-2'>
                        <Form
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                            style={{ width: '100%' }}
                            onFinish={() => {
                                form.resetFields();
                                handleAssigned();
                            }}
                        >
                            <Form.Item name="driver" label={'Select a driver to re-assign'} rules={[{ required: true, message: "Please Select driver!" }]}>
                                <Input.Group compact>
                                    <Select
                                        style={{ marginTop: '0.5rem', width: '100%' }}
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        loading={isLoading}
                                        onDropdownVisibleChange={(v) => {
                                            if (!v) {
                                                setData({ ...data, pageIndex: 1 });
                                                setDrivers([]);
                                            } else {
                                                getParcelDrivers()
                                            }
                                        }}
                                        onPopupScroll={onScroll}
                                        filterOption={(input, option: any) => {
                                            return (option!.children.props?.defaultValue as unknown as string).toLowerCase().includes(input.toLowerCase())
                                        }}
                                    >
                                        {drivers?.filter(d => d?.driverId != reassigndriverId)?.map((driver: any) => (
                                            <Option value={JSON.stringify(driver)} key={driver.userId} >
                                                <div className='flex flex-col justify-center items-start' defaultValue={`${JSON.stringify(driver)}`}>
                                                    <Span weight='semi'>{driver?.driverProfile?.lastName ?? ''} {driver?.driverProfile?.firstName ?? ''} {driver?.driverProfile?.middleName ?? ''}</Span>
                                                    <div className='flex flex-row justify-center items-center'>
                                                        <Span>{driver?.phoneNumber ?? ''}</Span>
                                                        <Tag color={driver?.driverProfile?.isBusy ? 'warning' : driver?.driverStatus === 'inactive' ? 'error' : 'success'} style={{ marginLeft: '1rem' }}>
                                                            {driver?.driverProfile?.isBusy ? 'Is Busy' : driver?.driverStatus === 'inactive' ? 'Inactive' : 'Active'}
                                                        </Tag>
                                                    </div>
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </Input.Group>
                            </Form.Item>

                            
                            <Form.Item name='reason' label='Re-assign reason' rules={[{ required: true }]}>
                                <Input name='reason' onChange={(e) => setPayload({ ...payload, reason: e.target.value })} />
                            </Form.Item>
                            
                        </Form>
                    </div>
                )}
            </div> 
        </Modal>
    </>);
} 