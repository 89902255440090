import { useGetCurrentRoleAccesses } from '@hooks';
import { Header, SideBar, MainLayout, ContainerLayout } from '@common'

import { PendingDriversPage } from '@containers'

export const PendingDrivers = () => {
    const module_name = 'manage_drivers';
    useGetCurrentRoleAccesses(module_name);
    return (
        <>
            <MainLayout>
                <SideBar />  
                <Header />
                
                <ContainerLayout>
                    <PendingDriversPage />
                </ContainerLayout>
            </MainLayout>
        </>
    )
}