import BRAND_LOGO from "../assets/images/alleasygo.svg";
import NO_DATA from "../assets/images/Nodata_icon.png";
import DROF_OFF_ICON from "../assets/images/dropoff_icon.png";
import PICK_UP_ICON from "../assets/images/pickup_map.png";
import PICKUP from "../assets/images/pickup.svg";
import VEHICLEDRIVERLOCATION from "../assets/images/drivericon.png";
import DRIVERLOCATION from "../assets/images/driverlocationicon.png";

import DRIVERPROFILE from "../assets/images/driver.png";

import motorcycle from "../assets/images/vehicle-motorcycle-icon-c.png"
import mpv200 from "../assets/images/vehicle-mpv200-c.png"
import mpv300 from "../assets/images/vehicle-mpv300-c.png"
import mpv600 from "../assets/images/vehicle-mpv600-c.png"
import lt1000 from "../assets/images/vehicle-lt1000-c.png"
import truck from "../assets/images/vehicle-truck-c.png"
import truck_6w from "../assets/images/vehicle-6w-c.png"
import truck_10w from "../assets/images/vehicle-10w-c.png"

import motorcycle_b from "../assets/images/vehicle-motorcycle-icon-bw.png"
import mpv200_b from "../assets/images/vehicle-mpv200-bw.png"
import mpv300_b from "../assets/images/vehicle-mpv300-bw.png"
import mpv600_b from "../assets/images/vehicle-mpv600-bw.png"
import lt1000_b from "../assets/images/vehicle-lt1000-bw.png"
import truck_b from "../assets/images/vehicle-truck-bw.png"
import truck_6w_b from "../assets/images/vehicle-6w-bw.png"
import truck_10w_b from "../assets/images/vehicle-10w-bw.png"


export * from "./stopIcons";

export const BRAND_NAME = "Getall";

export const BASE_URL = "https://development.tms.getall.com.ph";

export const BRAND_IMAGES = {
  BRAND_LOGO,
  NO_DATA,
  DROF_OFF_ICON,
  PICK_UP_ICON,
  PICKUP,
  VEHICLEDRIVERLOCATION,
  DRIVERPROFILE,
  DRIVERLOCATION
};

export const VEHICLE = {
  motorcycle: "Motorcycle",
  mpv200:  "MPV 200",
  mpv300: "MPV 300",
  mpv600: "MPV 600",
  lt1000: "LT1000",
  truck: "Truck",
  truck6w:"6-Wheeler Truck",
  truck10w:"10-Wheeler Truck"
};

export const VEHICLE_TYPE_IMAGES_ACTIVE = {
  motorcycle: motorcycle,
  mpv200: mpv200,
  mpv300: mpv300,
  mpv600: mpv600,
  lt1000: lt1000,
  truck: truck,
  truck6w: truck_6w,
  truck10w: truck_10w
};

export const VEHICLE_TYPE_IMAGES_INACTIVE = {
  motorcycle: motorcycle_b,
  mpv200: mpv200_b,
  mpv300: mpv300_b,
  mpv600: mpv600_b,
  lt1000: lt1000_b,
  truck: truck_b,
  truck6w: truck_6w_b,
  truck10w: truck_10w_b
};

export const OPEN_ORDERS = [
  "pending",
  "assigned",
  "for_pickup",
  "picked_up",
  "on_going", 
  "accepted",
  "time_in"
];

export const ORDERS = [ 
  "assigned",
  "for_pickup",
  "picked_up",
  "on_going", 
  "accepted",
  "time_in"
];

export const MERCHANT_OPEN_ORDERS = [
  "draft",
  "pending",
  "accepted",
  "preparing",
  "ready",
  "for_delivery",
  "in_transit", 
  "picked_up", 
];


export const HISTORY_STATUS = {
  pending: "Pending",
  time_in: "Time In",
  assigned: "Assigned",
  for_pickup: "For Pickup",
  picked_up: "Accepted",
  on_going: "On Going", 
  accepted: "Accepted", 
  canceled: "Canceled",
  delivered: "Delivered",
  failed_delivery: "Failed Delivery",
  declined: "Declined",
  completed: "Completed",
};


export const OPEN_ORDERS_STATUS = {
  pending: "Pending",
  assigned: "Assigned",
  for_pickup: "For Pickup",
  picked_up: "Picked Up",
  on_going: "On Going", 
  accepted: "Accepted",
  for_delivery: "For Delivery",
  ready: "Ready"
};

export const COMPLETED_ORDERS = [
  "canceled",
  "delivered",
  "failed_delivery",
  "declined", 
  "completed",
];

export const DONT_SHOW_CHANGE_TO = [
  "canceled",
  "delivered",
  "failed_delivery",
  "declined",
  "pending",
  "new", 
  "completed",
];

export const FAILED_ORDERS_STATUS = ["canceled", "failed_delivery", "declined"];

export const COMPLETED_ORDERS_STATUS = {
  canceled: "Canceled",
  delivered: "Delivered",
  failed_delivery: "Failed Delivery",
  declined: "Declined",
  completed: "Completed",
};

export const APPROVAL_STATUS = {
  approved: "Approved",
  pending: "Pending",
  rejected: "Rejected", 
};

export const STATUS = {
  active: "Active",
  inactive: "Inactive",
};

export const SELECT_COMPLETED_ORDERS = [
  {
    name: "Delivered",
    value: "delivered",
  },
  {
    name: "Canceled",
    value: "canceled",
  },
  // {
  //     name: 'Failed Delivery',
  //     value: 'failed_delivery'
  // },
  // {
  //     name: 'Declined',
  //     value: 'declined'
  // },
  {
    name: "All",
    value: "all",
  },
];

export const SELECT_RENTAL_COMPLETED_ORDERS = [
  {
    name: "Completed",
    value: "completed",
  },
  {
    name: "Canceled",
    value: "canceled",
  }, 
  {
    name: "All",
    value: "all",
  },
];

export const SELECT_RENTAL_OPEN_ORDERS = [ 
  {
    name: "Pending",
    value: "pending",
  }, 
  {
    name: "On Going",
    value: "on_going",
  }, 
  {
    name: "All",
    value: "all",
  },
];

export const SELECT_OPEN_ORDERS = [
  {
    name: "On Going",
    value: "on_going",
  },
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Assigned",
    value: "assigned",
  },
  {
    name: "For Pickup",
    value: "for_pickup",
  }, 
  {
    name: "Accepted",
    value: "picked_up",
  },
  {
    name: "All",
    value: "all",
  },
];


export const SELECT_MERCHANT_ORDERS = [
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Accepted",
    value: "accepted",
  },
  {
    name: "Preparing",
    value: "preparing",
  }, 
  {
    name: "Ready",
    value: "ready",
  },
  {
    name: "For Delivery",
    value: "for_delivery",
  },
  {
    name: "In Transit",
    value: "in_transit",
  },
  {
    name: "Picked Up",
    value: "picked_up",
  },
  {
    name: "Delivered",
    value: "delivered",
  },
  {
    name: "Canceled",
    value: "canceled",
  },
  {
    name: "All",
    value: "all",
  },
];

export const TAB = {
  openOrders: OPEN_ORDERS,
  completedOrders: COMPLETED_ORDERS,
  ordersTab: ORDERS,
};

export const TAB_MERCHANT = {
  openOrders: MERCHANT_OPEN_ORDERS,
  completedOrders: COMPLETED_ORDERS,
};

// Driver
export const DRIVER = {
  pending: "Inactive",
  active: "Active",
  inactive: "Inactive",
};

export const EXPRESS = {
  new: "Pending",
  pending: "Pending",
  assigned: "Assigned",
  for_pickup: "For Pickup",
  picked_up: "Accepted",
  on_going: "On going",
  failed_delivery: "Failed Delivery",
  delivered: "Delivered",
  canceled: "Canceled",
  accepted: "Accepted", 
};

export const MERCHANT = { 
  pending: "Pending",
  accepted: "Accepted",
  preparing: "Preparing",
  ready: "Ready",
  for_delivery: "For Delivery",
  in_transit: "In Transit",
  picked_up: "Picked Up",
  canceled: "Canceled", 
  delivered: "Delivered", 
}; 

export const PAYMENTSTATUS = {
  refund_pending: "Refund Pending",
  payment_failed: "Payment Failed",
  payment_pending: "Payment Pending",
  refund_success: "Refund Success",
  payment_success: "Payment Success", 
};

export const RENTALSTATUS = {
  time_in: "Time in",
  pending: "Pending",
  assigned: "assigned",
  for_pickup: "For Pickup",
  picked_up: "Picked Up",
  on_going: "On Going",
  completed: "Completed",
  delivered: "Delivered",
  canceled: "Canceled", 
};

export const PACKAGE_STATUS = {
  pending: Object.keys(EXPRESS)[1],
  assigned: Object.keys(EXPRESS)[2],
  for_pickup: Object.keys(EXPRESS)[3],
  picked_up: Object.keys(EXPRESS)[4],
  on_going: Object.keys(EXPRESS)[5],
  delivered: Object.keys(EXPRESS)[7],
  failed_delivery: Object.keys(EXPRESS)[6],
  canceled: Object.keys(EXPRESS)[8],
};

export const ROLE_MODULES = {
  "/home": "home",
  "/parcel-management/express-orders": "manage_parcel_deliveries",
  "/parcel-management/food-orders": "manage_parcel_deliveries",
  "/parcel-management/shop-orders": "manage_parcel_deliveries",
  "/parcel-management/ez-buy-service-orders": "manage_parcel_deliveries",
  "/parcel-management/merchant-orders": "manage_parcel_deliveries",
  "/driver-management/all-drivers": "manage_drivers",
  "/driver-management/pending-drivers": "manage_drivers",
  "/driver-management/driver-dashboard": "manage_drivers",
  "/calendar-management": "manage_calendar",
  "/attachment": "manage_attachment",
  "/rental-management": "manage_rental",
  "/disbursements/pending": "manage_disbursements", 
  "/disbursements/failed": "manage_disbursements", 
  "/disbursements/success": "manage_disbursements", 
};

export const DRIVERGROUPSHOW = {
  parcel_and_grocery_delivery: "PARCEL AND GROCERY DELIVERY",
  food_delivery_only: "FOOD DELIVERY ONLY",
  bulky_orders_only: "BULKY ORDERS ONLY",
  rental_only: "RENTAL ONLY",
  all_of_the_above: "AL OF THE ABOVE",
};

export const APPROVAL = {
  pending: "Pending",
  reject: "Reject",
  approved: "Approved",
};

export const RENTAL_ASSIGN_STATUS = {  
  time_in: "Time In",
  on_going: "Ongoing",
  completed: "Completed", 
};

export const DRIVERGROUP = [
  {
    name: "Parcel And Grocery Delivery",
    value: "parcel_and_grocery_delivery",
  },
  {
    name: "Food Delivery Only",
    value: "food_delivery_only",
  },
  {
    name: "Shop Delivery Only",
    value: "mart_delivery_only",
  },
  {
    name: "Bulky Orders Only",
    value: "bulky_orders_only",
  },
  {
    name: "Rental Only",
    value: "rental_only",
  },
  {
    name: "All of the Above",
    value: "all_of_the_above",
  },
];

export const TYPECALENDAR = [
  {
    name: "Regular Holiday",
    value: "regular_holiday",
  },
  {
    name: "Special Non Working Holiday",
    value: "special_non_working_day",
  },
];

export const AMOUNT_TYPE = [
  {
    name: "Percentage",
    value: "percentage",
  },
  {
    name: "Fixed",
    value: "fixed",
  },
];


export const Holiday = {
  regular_holiday: "Regular Holiday",
  special_non_working_day: "Special Non Working Holiday",
};

export const Holiday_Services = {
  express_only: "Express Only",
  buy_only: "Buy Only",
  rental_only: "Rental Only",
  all: "All"
}; 

export const SURCHARGE_TYPE = [
  {
    name: "Generic Surcharge Fee",
    value: "generic_surcharge_fee",
  },
  {
    name: "Holiday Surcharge Fee",
    value: "holiday_surcharge_fee",
  },
];

export const CALENDAR_SERVICE_TYPE = [
  {
    name: "Express Only",
    value: "express_only",
  },
  {
    name: "Buy Only",
    value: "buy_only",
  },
  {
    name: "Rental Only",
    value: "rental_only",
  },
  {
    name: "All",
    value: "all",
  },
];

export const VEHICLETYPE = [
  {
    name: "Motorcycle",
    value: "motorcycle",
  }, 
  {
    name: "MPV200",
    value: "mpv200",
  }, 
  {
    name: "MPV300",
    value: "mpv300",
  },   
  {
    name: "MPV600",
    value: "mpv600",
  }, 
  {
    name: "LT1000",
    value: "lt1000",
  }, 
  {
    name: "Truck",
    value: "truck",
  },
  {
    name: '6-Wheeler Truck',
    value: 'truck6w',
  },
  {
    name: '10-Wheeler Truck',
    value: 'truck10w',
  },
];

export const CHANGE_TO = {
  assigned: "for_pickup",
  for_pickup: "accepted",
  accepted: "on_going",
  on_going: "delivered",
  picked_up: "on_going",
  canceled: "delivered", 
  delivered: "canceled",
};

export const CHANGE_TO_RENTAL = { 
  assigned: "time_in",
  time_in: 'on_going',
  on_going: "completed" 
}; 

export const REASONOFREJECT = [
  {
    name: "Incomplete Requirement",
    value: "incomplete_requirement",
  },
];

export const CANCEL_REASON = [
  {
    value: 'FD-Customer is not available',
    label: 'FD-Customer is not available',
  },
  {
    value: 'FD-Cancelled order by customer/client',
    label: 'FD-Cancelled order by customer/client',
  },
  {
    value: 'customer not reachable',
    label: 'FD-Customer not reachable',
  },
  {
    value: 'incorrect delivery address',
    label: 'FD-Incorrect delivery address',
  },
  {
    value: 'Refused to Accept',
    label: 'FD-Refused to accept',
  },
  {
    value: 'Payment Not Ready',
    label: 'FD-Payment not ready',
  },
  {
    value: 'force majeure',
    label: 'FD-Force majeure',
  },
  {
    value: 'Critical Area',
    label: 'FD-Critical area',
  },
  {
    value: 'FD-Item is not available during pickup',
    label: 'FD-Item is not available during pickup',
  },
  {
    value: 'Cancelled-Customer waited too long',
    label: 'Cancelled-Customer waited too long',
  },
  {
    value: 'Cancelled-Rider/driver ask to cancel',
    label: 'Cancelled-Rider/driver ask to cancel',
  },
  {
    value: 'Cancelled-Rider is too far from the pick-up location',
    label: 'Cancelled-Rider is too far from the pick-up location',
  },
  {
    value: 'Cancelled-Rider issue',
    label: 'Cancelled-Rider issue',
  },
  {
    value: 'Cancelled-Double order',
    label: 'Cancelled-Double order',
  },
  {
    value: 'Cancelled-No rider found',
    label: 'Cancelled-No rider found',
  },
  {
    value: 'Cancelled-Delivery fee is too high',
    label: 'Cancelled-Delivery fee is too high',
  },
  {
    value: 'For Rebooking-Change vehicle type',
    label: 'For Rebooking-Change vehicle type',
  },
  {
    value: 'For Rebooking-Change payment type',
    label: 'For Rebooking-Change payment type',
  },
  {
    value: 'For Rebooking-Wrong pin (PUP)',
    label: 'For Rebooking-Wrong pin (PUP)',
  },
  {
    value: 'For Rebooking-Wrong pin (DOP)',
    label: 'For Rebooking-Wrong pin (DOP)',
  },
  {
    value: 'For Rebooking-Change rider details',
    label: 'For Rebooking-Change rider details',
  },
  {
    value: 'For Rebooking-Change pick-up location',
    label: 'For Rebooking-Change pick-up location',
  },
  {
    value: 'For Rebooking-Change drop-off location',
    label: 'For Rebooking-Change drop-off location',
  },
  {
    value: 'For Rebooking-Change delivery time',
    label: 'For Rebooking-Change delivery time',
  },
  {
    value: 'For Rebooking-Change store/branch',
    label: 'For Rebooking-Change store/branch',
  },
  {
    value: 'Restaurant is busy',
    label: 'Restaurant is busy',
  },
  {
    value: 'Most or all items are not available',
    label: 'Most or all items are not available',
  },
  {
    value: 'We`re closing soon',
    label: 'We`re closing soon',
  },
  {
    value: 'System Issue',
    label: 'System Issue',
  },
  {
    value: 'Consignee Out',
    label: 'Consignee Out',
  },
  {
    value: 'Consignee Unknown',
    label: 'Consignee Unknown',
  },
  {
    value: 'House / Office Closed',
    label: 'House / Office Closed',
  },
  {
    value: 'Moved Out',
    label: 'Moved Out',
  },
  {
    value: 'Test Order',
    label: 'Test Order',
  }
];

export const CANCEL_REASON_RENTAL = ["Invalid"];

export const REJECT_REASON = [
  "Invalid/Missing Copy of OR/CR",
  "Invalid/Missing Copy of Driver's License",
  "Invalid/Missing Barangay/Police/NBI Clearance",
  "Invalid/Missing A Selfie holding the license",
  "Invalid/Missing Photo of the vehicle showing the plate number",
  "Invalid/Missing Vehicle insurance (for 4-wheeled vehicle)",
  "Invalid/Missing Authorization letter if vehicle is not owned",
  "Invalid/Missing Deed of sale if bought from a previous owner",
];

export const METHOD = {
  get: "canList",
  post: "canCreate",
  put: "canEdit",
  patch: "canEdit",
  delete: "canDelete",
};

export const FIREBASE_PATH_NAMES = [
  "/calendar-management",
  "/attachment",
  "/parcel-management/express-orders",
  "/parcel-management/food-orders", 
  "/parcel-management/shop-orders", 
  "/parcel-management/ez-buy-service-orders",
  "/parcel-management/merchant-orders",
  "/rental-management",
];

export const PAYMENT_METHODS = {
  all_easy: 'All Easy',
  cash_on_delivery: 'Cash on Delivery',
  cash_on_pickup: 'Cash of Pickup',
  billing: 'Billing',
  paynamics_card: 'Credit/Debit Card',
  alleasy_on_delivery: 'All Easy On Delivery',
  reward_points: 'Reward Points',
};


// Disbursements
export const DISBURSEMENT_TYPE = {
  rider_share: "Rider Share", 
  custom_add_ons: "Custom Add Ons",
  tip: "Tip"
};

export const DISBURSEMENT_STATUS = {
  pending: "Pending", 
  failed: "Failed",
  success: "Success"
};

export const DISBURSEMENT_ACTION = {
  manual: "Manual", 
  auto: "Auto",
};


export const validFileExtension = ['xls', 'xlsx', 'xlsm'];

export const RESTRICTION_CODE = [
  {
    value: 'A',
    label: 'A',
  },
  {
    value: 'A1',
    label: 'A1',
  },
  {
    value: 'B',
    label: 'B',
  },
  {
    value: 'B1',
    label: 'B1',
  },
  {
    value: 'B2',
    label: 'B2',
  },
  {
    value: 'C',
    label: 'C',
  },
  {
    value: 'D',
    label: 'D',
  },
  {
    value: 'BE',
    label: 'BE',
  },
  {
    value: 'CE',
    label: 'CE',
  },
];
