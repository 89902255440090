import { GridContainer, Span } from "@common"; 

export default ({ restaurant }: any) => {
  return (
    <>
      <GridContainer grid="four">
        <div>
          <Span weight="semi"> Name </Span>
          <Span>{restaurant?.name ?? 'No Data'}</Span>
        </div>

        <div>
          <Span weight="semi"> Branch </Span>
          <Span>{restaurant?.branchName}</Span>
        </div>

        <div>
          <Span weight="semi"> Category </Span>
          <Span>{restaurant?.name}</Span>
        </div>

        <div>
          <Span weight="semi"> Address </Span>
          <Span transform="capitalize">{restaurant?.address}</Span>
        </div>
      </GridContainer>
    </>
  );
};
