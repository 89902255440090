/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import {
  ExpressOrders,
  ExpressOrdersView,
  FoodOrders,
  FoodOrdersView,
  ShopOrders,
  ShopOrdersView,
  EzBuyServiceOrders,
  EzBuyServiceOrdersView,
  Login,
  Home,
  Logout,
  PageNotFound,
  AllDrivers,
  PendingDrivers,
  CalendarManagement,
  RentalManagement,
  RentalManagementView,
  MerchantOrders,
  MerchantOrdersView,
  DriverDashboard,
  Attachment,
  DisbursementsPending,
  DisbursementsFailed,
  DisbursementsSuccess,
} from "@pages";

import "./Firebase";
import LoginRoute from "./SecuredRoute/LoginRoute";
import AuthRoute from "./SecuredRoute/AuthRoute";

import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import { Dispatch, store } from "@store";
import { app } from "./Firebase";
import { getUserCredentials } from "@api";
import { getRoleModulesAndObjectOfRoleAccesses } from "@utils";

function App() {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const auth = getAuth(app);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        history.push("/");
        return;
      }
      await updateToken();
    });

    onIdTokenChanged(auth, async (user) => {
      if (user) {
        localStorage.setItem("accessToken", (user as any).accessToken);
        await Promise.all([updateToken(), getUserCredential()]);
        location.pathname === "/" && history.push("/home");
      }
    });
  }, []);

  const updateToken = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) return;
    const token = await user.getIdToken();
    localStorage.setItem("accessToken", token);
    dispatch.User.updateState({ userToken: token });
  }, []);

  const getUserCredential = useCallback(async () => {
    try {
      const userCredentials = await getUserCredentials();
      const defaultRoleModules = store.getState().User.roleModules;
      const { modules, objectOfRole } =
        getRoleModulesAndObjectOfRoleAccesses(userCredentials);

      dispatch.User.updateState({
        userInfo: userCredentials,
        roleModules: [...defaultRoleModules, ...modules],
        objectOfRoleModules: objectOfRole,
      });
    } catch (error) {
      throw error;
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/logout" exact component={Logout} />
        <LoginRoute path="/" exact component={Login} />
        <AuthRoute path="/home" exact component={Home} />
        <AuthRoute
          path="/parcel-management/express-orders"
          exact
          component={ExpressOrders}
        />
        <AuthRoute
          path="/parcel-management/express-orders/view/:id/:tab"
          exact
          component={ExpressOrdersView}
        />
        <AuthRoute
          path="/parcel-management/food-orders"
          exact
          component={FoodOrders}
        />
        <AuthRoute
          path="/parcel-management/food-orders/view/:id/:tab"
          exact
          component={FoodOrdersView}
        />
        <AuthRoute
          path="/parcel-management/shop-orders"
          exact
          component={ShopOrders}
        />
        <AuthRoute
          path="/parcel-management/shop-orders/view/:id/:tab"
          exact
          component={ShopOrdersView}
        />

        <AuthRoute
          path="/parcel-management/ez-buy-service-orders"
          exact
          component={EzBuyServiceOrders}
        />
        <AuthRoute
          path="/parcel-management/ez-buy-service-orders/view/:id/:tab"
          exact
          component={EzBuyServiceOrdersView}
        />
        <AuthRoute
          path="/rental-management"
          exact
          component={RentalManagement}
        />
        <AuthRoute
          path="/rental-management/view/:id/:tab"
          exact
          component={RentalManagementView}
        />
        <AuthRoute
          path="/parcel-management/merchant-orders"
          exact
          component={MerchantOrders}
        />
        <AuthRoute
          path="/parcel-management/merchant-orders/view/:id/:tab"
          exact
          component={MerchantOrdersView}
        />
        <AuthRoute
          path="/driver-management/all-drivers"
          exact
          component={AllDrivers}
        />
        <AuthRoute
          path="/driver-management/pending-drivers"
          exact
          component={PendingDrivers}
        />
        <AuthRoute
          path="/driver-management/driver-dashboard"
          exact
          component={DriverDashboard}
        />
        <AuthRoute path="/attachment" exact component={Attachment} />
        <AuthRoute
          path="/calendar-management"
          exact
          component={CalendarManagement}
        />
        <AuthRoute
          path="/disbursements/pending"
          exact
          component={DisbursementsPending}
        />
        <AuthRoute
          path="/disbursements/failed"
          exact
          component={DisbursementsFailed}
        />
        <AuthRoute
          path="/disbursements/success"
          exact
          component={DisbursementsSuccess}
        />
        <Route exact component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
