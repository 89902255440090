import { GenerateStatusDesign, ColumnFilter } from "@components";
import { OPEN_ORDERS_STATUS } from "@constants";
import { formatDate } from "@utils";

export const COLUMNS_RENTALITEM = [
  {
    id: "orderCode",
    field: "orderCode",
    accessor: (data) => data?.orderCode,
    Header: "Order code",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const ordercode = d?.orderCode;
      return (
        <div className="truncate max-w-[200px]">
          <span className={`text-primary-main`}>{ordercode}</span>
        </div>
      );
    },
  },
  {
    accessor: "name",
    Header: "Client Name",
    Filter: ColumnFilter,
    isVisible: false,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center capitalize">{d?.client?.name}</div>
      );
    },
  },
  {
    accessor: "contactNumber",
    Header: "Contact Number",
    Filter: ColumnFilter,
    isVisible: false,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">{d?.client?.contactNumber}</div>
      );
    },
  },
  {
    accessor: "locationDetails",
    Header: "Location Details",
    Filter: ColumnFilter,
    isVisible: false,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center capitalize">
          {d?.client?.locationDetails}
        </div>
      );
    },
  },
  {
    id: "craetedAt",
    accessor: (data) => data?.createdAt?.seconds,
    Header: "Pick up Time & Date",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const date = d?.scheduleStartTime
        ? formatDate(d?.scheduleStartTime)
        : formatDate(d?.createdAt);
      return <div className="flex justify-center">{date}</div>;
    },
  },
  {
    accessor: "status",
    Header: "Status",
    Filter: ColumnFilter,
    isVisible: false,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="w-full flex justify-center items-center">
          <GenerateStatusDesign
            status={d?.status}
            label={OPEN_ORDERS_STATUS[d?.status]}
          />
        </div>
      );
    },
  },
  {
    accessor: "vehicleType",
    Header: "Vehicle",
    Filter: ColumnFilter,
    isVisible: false,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center capitalize">{d?.vehicleType}</div>
      );
    },
  },
  {
    accessor: "orderType",
    Header: "Order Type",
    Filter: ColumnFilter,
    isVisible: false,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center capitalize">{d?.orderType}</div>
      );
    },
  },
];
