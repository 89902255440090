import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { RootState } from "@store";

const getBase64 = async (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }

  return isJpgOrPng && isLt2M;
};

const dummyRequest = ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

type Props = {
  file: any;
  setFile: Function;
  defaultImageurl?: any;
  addDriver?: boolean;
};

export const UploadPhoto = ({
  file,
  setFile,
  defaultImageurl,
  addDriver,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const editDriverId: any = useSelector(
    (state: RootState) => state.UI.forms.editDriver?.id
  );

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        setFile(info.file.originFileObj);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    if (!editDriverId && !addDriver) {
      setImageUrl(null);
    }
  }, [editDriverId, addDriver]);

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={dummyRequest}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl || defaultImageurl?.length > 15 ? (
        <img
          src={imageUrl ?? defaultImageurl}
          alt="avatar"
          style={{
            width: "100%",
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};
