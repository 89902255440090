/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Dispatch, RootState } from "@store";

import "./Login.css";
import { CenterContainer, InputContainer, Input, Button } from "@common";
import { AlertSnackBar } from "@components";
import { BRAND_IMAGES } from "@constants";

import designbg from "../../assets/images/login_design3.png";
import designbg1 from "../../assets/images/login_design.png";
import username_icon from "../../assets/images/username.png";
import password_icon from "../../assets/images/password.png";

import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
import { Spin } from "antd";

export const LoginPage = () => {
  const history = useHistory();

  const dispatch = useDispatch<Dispatch>();

  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const email: any = useSelector(
    (state: RootState) => state.UI.forms.login.fields.email
  );
  const password: any = useSelector(
    (state: RootState) => state.UI.forms.login.fields.password
  );

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      dispatch.UI.resetAlert();
      setOpen(false);
      const res: any = await dispatch.User.login({ email, password });
      if (res?.isSuccess) {
        setIsLoading(false);
      }
    } catch (err: any) {
      console.log(err);
      setError(true);
      setIsLoading(false);
    }
  };

  const handleEnter = (e: any) => {
    const ASCIICode = e.which ? e.which : e.keyCode;
    if (ASCIICode === 13) {
      handleLogin();
    }
  };

  const setFormValue = (field: any, value: any) => {
    dispatch.UI.setInputFormFields({
      target: "login",
      field: field,
      value: value,
    });
  };

  const login = () => {
    history.push("/parcels");
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>
      <AlertSnackBar open={open} setOpen={setOpen} />
      <div className='min-h-screen overflow-hidden"'>
        <img src={designbg} alt="" className="absolute top-0 right-0" />
        <img src={designbg1} alt="" className="absolute bottom-0 left-0" />

        <CenterContainer>
          <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="" className="login_logo" />
          {error && (
            <>
              <div className="text-center py-1 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30">
                <span className="text-xs text-red-500">
                  Email or password is incorrect!
                </span>
              </div>
            </>
          )}

          {!isLoading ? (
            <>
              <form onSubmit={handleLogin}>
                <InputContainer margin="mt-4">
                  <Input
                    padding="pl-10"
                    value={email}
                    onKeyPress={handleEnter}
                    required
                    onChange={(e: any) => {
                      setFormValue("email", e.target.value);
                    }}
                    type="email"
                    placeholder="Email"
                  />
                  <img src={username_icon} alt="" className="login_icon" />
                </InputContainer>

                <InputContainer margin="mt-4">
                  <Input
                    padding="pl-10"
                    value={password}
                    onKeyPress={handleEnter}
                    required
                    onChange={(e: any) =>
                      setFormValue("password", e.target.value)
                    }
                    type={isVisible ? "text" : "password"}
                    placeholder="Password"
                  />
                  <img src={password_icon} alt="" className="login_icon" />
                  {!isVisible ? (
                    <MdOutlineVisibilityOff
                      className="w-4 h-4 absolute right-2 top-2 m-auto cursor-pointer"
                      onClick={() => setIsVisible(true)}
                    />
                  ) : (
                    <MdOutlineVisibility
                      className="w-4 h-4 absolute right-2 top-2 m-auto cursor-pointer"
                      onClick={() => setIsVisible(false)}
                    />
                  )}
                </InputContainer>

                <Button
                  type="submit"
                  shadow="grey"
                  margin="mt-6"
                  padding="py-1"
                >
                  Login
                </Button>
              </form>
            </>
          ) : (
            <>
              <div className="my-auto items-center flex justify-center overscroll-none z-10 bg-white">
                <Spin />
              </div>
            </>
          )}
        </CenterContainer>
      </div>
    </>
  );
};
