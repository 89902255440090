/* eslint-disable import/no-anonymous-default-export */
import { Steps } from 'antd' 
import { formatDateV2 } from '@utils';  

const { Step } = Steps;
  
export default ({ food }: any) => { 

    const pendingSchedule = food?.createdAt ? formatDateV2(food?.createdAt): ''; 
    const forAcceptedSchedule = food?.acceptedAt ? formatDateV2(food?.acceptedAt): ''; 
    const preparingPickupSchedule = food?.preparedAt ? formatDateV2(food?.preparedAt): ''; 
    const readySchedule = food?.readyAt ? formatDateV2(food?.readyAt): ''; 
    const forDeliverySchedule = food?.deliveryAcceptedAt ? formatDateV2(food?.deliveryAcceptedAt): '';  
    const inTransitSchedule = food?.inTransitAt ? formatDateV2(food?.inTransitAt): ''; 
    const deliveredSchedule = food?.deliveredAt ? formatDateV2(food?.deliveredAt): ''; 
    const canceledSchedule = food?.canceledAt ? formatDateV2(food?.canceledAt): ''; 
    
    const StatusFood = [
        {
            label: 'Pending',
            value: 'pending',
            timestamp: pendingSchedule,
        },
        {
            label: 'Accepted Orders',
            value: 'picked_up',
            timestamp: forAcceptedSchedule,
        },
        {
            label: 'Preparing',
            value: 'preparing', 
            timestamp: preparingPickupSchedule,
        },
        {
            label: 'Order Ready',
            value: 'ready',
            timestamp: readySchedule,
        },
        {
            label: 'For Delivery',
            value: 'for_delivery',
            timestamp: forDeliverySchedule,
        },  
        {
            label: 'In Transit',
            value: 'in_transit',
            timestamp: inTransitSchedule,
        },
        {
            label: 'Delivered',
            value: 'delivered',
            timestamp: deliveredSchedule,
        },
        {
            label: 'Canceled',
            value: 'canceled',
            timestamp: canceledSchedule,
        },
    ]  
 
    const index = StatusFood?.findIndex(item => item.value === food?.status); 

    return (
        <> 
          <Steps direction="vertical" size="small" current={index}>   
  
              {StatusFood?.map((item, index) => (
                  <Step 
                      title={item.label} 
                      key={index} 
                      description={item.timestamp} 
                  />
              ))}
          </Steps> 
        </>
    );
  }; 