import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { RootState, Dispatch } from "@store";

import { NoData, Container, Span } from "@common";

import { COLUMNS } from "./column";

import "./attachment.scss";

import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Switch, message } from "antd";
import { Stack } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { AttachmentView, SearchAttachment, Loader, Table } from "@components";

export const AttachmentPage = () => {
  const params = new URLSearchParams(window.location.search);
  const columns = useMemo(() => COLUMNS, []);
  const dispatch = useDispatch<Dispatch>();

  const maxSearchInputCount = 4;

  const [view, setView] = useState(false);

  const { referenceSearch }: any = useSelector(
    (state: RootState) => state.Attachment
  );

  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { original_data: mainData }: any = useSelector(
    (state: RootState) => state.Table.tables.attachment
  );
  const completedData: any[] = useSelector(
    (state: RootState) => state.Table.tables.attachment.data
  )?.sort((a, b) => a?.time - b?.time);
  const { isLoading } = useSelector((state: RootState) => state.Table);

  const [searchData, setSearchData] = useState([
    {
      filter: "reference",
      value: "",
    },
  ]);

  useEffect(() => {
    const reference = params.get("reference");

    setSearchData([
      {
        filter: "reference",
        value: reference ? reference : "",
      },
    ]);
  }, []);

  const searchCompleted = () => {
    dispatch.Table.getAttachmentFilterData({
      table: "attachment",
      service: "rental",
    });
  };

  const setFilters = () => {
    let stringKeyword = "";
    if (referenceSearch === null) {
      stringKeyword = "";
    } else {
      searchData
        ?.filter((s) => s.value !== "")
        ?.forEach((search) => {
          const string =
            search?.value === ""
              ? ""
              : `&${
                  search?.filter === "reference" ? "reference" : search?.filter
                }=${search?.value}`;
          const searchInput = string;
          stringKeyword = stringKeyword + searchInput;
        });
    }
    dispatch.Table.updateKeyword({ table: "attachment", value: stringKeyword });
    searchCompleted();
  };

  useEffect(() => {
    setFilters();
  }, [searchData, mainData, referenceSearch]);

  return (
    <>
      <div className="relative">
        <Loader isLoading={isLoading} />
        <Container border="grey" padding="none">
          <div className="p-4">
            <Stack
              direction="column"
              spacing={1}
              justifyContent="center"
              alignItems="flex-start"
              className="calendar-switch"
            >
              <Span margin="mt-4" weight="bold" size="2xl">
                Attachment
              </Span>
              <Switch
                checkedChildren="Attachment View"
                unCheckedChildren="List View"
                onChange={(v) => setView(v)}
              />
            </Stack>
          </div>

          <div className="p-4 w-full flex flex-row items-center">
            {searchData?.map((search, index) => (
              <SearchAttachment
                search={search}
                searchData={searchData}
                setSearchData={setSearchData}
                index={index}
              />
            ))}

            {!matchDownSm && (
              <Button
                type="primary"
                size="large"
                icon={
                  <SearchOutlined
                    style={{ display: "inline-flex", alignItems: "center" }}
                  />
                }
                onClick={setFilters}
              >
                Search
              </Button>
            )}

            {maxSearchInputCount != searchData?.length ? (
              <div className="px-2">
                <Button
                  type="primary"
                  size="large"
                  className="px-2"
                  icon={
                    <PlusOutlined
                      style={{ display: "inline-flex", alignItems: "center" }}
                    />
                  }
                  onClick={() => {
                    if (maxSearchInputCount === searchData?.length)
                      return message.error("Already reach search limit!");
                    setSearchData([
                      ...searchData,
                      { filter: "pleaseSelect", value: "" },
                    ]);
                  }}
                >
                  Add Search
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="overflow-auto">
            {view ? (
              <div className="p-4">
                <AttachmentView data={completedData} />
              </div>
            ) : (
              <>
                {completedData?.length < 0 ? (
                  <NoData />
                ) : (
                  <Table
                    columns={columns}
                    data={completedData}
                    dataType="attachment"
                    sortName="time"
                    descSort={true}
                  />
                )}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};
