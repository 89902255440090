import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store';

export const useGetCurrentRoleAccesses = (module_name) => {

    const dispatch = useDispatch<RootState>();

    const objectOfRoleModules = useSelector((state: RootState) => state.User.objectOfRoleModules);
    const roleAccesses = objectOfRoleModules[module_name];

    dispatch.UI.updateState({currentRoleAccesses: roleAccesses});
}