
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { Dispatch } from '@store'
 
export const Logout = () => {

    const history = useHistory();
    const dispatch = useDispatch<Dispatch>()

    useEffect(() => {
        dispatch.Table.resetState();
        dispatch.UI.resetState();
        dispatch.User.resetState();
        dispatch.Locations.resetState();
        dispatch.Attachment.resetState();
        dispatch.Driver.resetState();
        dispatch.User.updateState({ shouldRedirectToLogin: true }); 
        localStorage.removeItem('accessToken')
        history.push(`/`);
    }, [])
    return ( 
        <> 
        </>
    )
}
