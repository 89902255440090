import { SELECT_COMPLETED_ORDERS, SELECT_OPEN_ORDERS } from "@constants";
import { useLocation } from "react-router-dom";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

type Props = {
  searchData: any;
  setSearchData: any;
  deletedSearch?: any;
  setDeletedSearch?: any;
  setFilters: any;
  tab: any;
  index: any;
  search: any;
};

export const SearchInput = ({
  setSearchData,
  searchData,
  tab,
  index,
  search,
}: Props) => {
  const pathName = useLocation().pathname;

  const searchFilters = searchData?.map((s) => s?.filter);
  const FILTERVALUES = {
    status: "Status",
    orderCode: "Order Code",
    clientReferenceNumber: "Reference Code",
    referenceNumber: "Order Id",
    driverName: "Driver Name",
    premium: "Premium",
    restaurant: "Restaurant",
    location: "Pickup Location",
    pleaseSelect: "Please select",
  };
  const searchOptions = [
    {
      value: "status",
      label: "Status",
    },
    {
      value: "orderCode",
      label: "Order Code",
    },
    {
      value: "clientReferenceNumber",
      label: "Reference Code",
    },
    {
      value: "referenceNumber",
      label: "Order Id",
    },
    {
      value: "driverName",
      label: "Driver Name",
    },
    {
      value: "location",
      label: "Pickup Location",
    },
    {
      value: "restaurant",
      label: "Restaurant",
    },
    {
      value: "premium",
      label: "Premium",
    },
  ];

  const { Option } = Select;

  const renderInput = () => {
    switch (search?.filter) {
      case "status":
        return (
          <Select
            style={{ width: "100%" }}
            bordered={false}
            value={search?.value}
            onChange={(v: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: v } : item
                ),
              ])
            }
          >
            {tab === "openOrders"
              ? SELECT_OPEN_ORDERS?.map((item) => (
                  <Option key={item?.value} value={item?.value}>
                    {item?.name}
                  </Option>
                ))
              : SELECT_COMPLETED_ORDERS?.map((item) => (
                  <Option key={item?.value} value={item?.value}>
                    {item?.name}
                  </Option>
                ))}
          </Select>
        );
      case "driverName":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Driver's Name"
            name="driverName"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "location":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Pickup Location"
            name="location"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "restaurant":
        return (
          <input
            className="w-full text-sm focus:outline-none"
            type="text"
            placeholder="Search Restaurant"
            name="restaurant"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "orderCode":
        return (
          <input
            className="w-full text-sm focus:outline-none orderCode"
            type="text"
            placeholder="Search Order Code"
            name="orderCode"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      case "clientReferenceNumber":
        return (
          <input
            className="w-full text-sm focus:outline-none orderCode"
            type="text"
            placeholder="Search Reference Code"
            name="clientReferenceNumber"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );

      case "referenceNumber":
        return (
          <input
            className="w-full text-sm focus:outline-none referenceNumber"
            type="text"
            placeholder="Search Order Id"
            name="referenceNumber"
            value={search?.value}
            onChange={(e: any) =>
              setSearchData([
                ...searchData?.map((item, i) =>
                  i === index ? { ...item, value: e.target.value } : item
                ),
              ])
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="w-3/4 md:w-2/4 lg:w-2/5 border-[1px] border-gray-400 flex flex-row-reverse justify-start items-center p-1 rounded-sm search_border mr-2">
        {renderInput()}

        {search?.filter !== "premium" && (
          <div className="h-[20px] border-[1px] border-gray-300 mx-2" />
        )}

        <Select
          style={{ width: "100%" }}
          bordered={false}
          value={FILTERVALUES[search.filter]}
          onChange={(v: any) => {
            const newItem = {
              filter: v,
              value:
                v === "status" ? (tab === "openOrders" ? "all" : "all") : "",
            };
            setSearchData([
              ...searchData?.map((item, i) => (i === index ? newItem : item)),
            ]);
          }}
        >
          {searchOptions
            ?.filter((option) =>
              pathName?.search("food-orders") === -1
                ? !searchFilters?.includes(option.value) &&
                  option.value !== "restaurant"
                : !searchFilters?.includes(option.value) &&
                  option.value !== "premium" &&
                  option.value !== "location" &&
                  option.value !== "referenceNumber"
            )
            ?.map((option, index) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </div>

      <div className="px-2">
        {index !== 0 && (
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            type="ghost"
            danger
            onClick={() => {
              setSearchData([...searchData?.filter((item, i) => i !== index)]);
            }}
          />
        )}
      </div>
    </>
  );
};
