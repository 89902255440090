/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "../../containers/DriverManagement/AllDrivers/Driver.css";
import { Dispatch, RootState } from "@store";
import { updateCalendar } from "@api";

import { TYPECALENDAR } from "@constants";
import { DatePicker } from "antd";

import {
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Button,
  Popconfirm,
  message,
} from "antd";
import moment from "moment";

const { Option } = Select;
type Props = {
  id: string;
  table: string;
  getData: Function;
};
type RequiredMark = boolean | "optional";

export const EditCalendar = ({ id, getData, table, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  const [calendarDetails, setCalendarDetails] = useState({}) as any;

  const calendars = useSelector(
    (state: RootState) => state.Table.tables[table].data
  );
  const D = calendars?.filter((calendar) => calendar?.id === id)[0];

  const today = new Date();

  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(false);
  const [isLoading, setisloading] = useState(false);

  const error = (errMsg) => {
    message.error(errMsg);
  };

  const handleUpdateCalendar = async () => {
    try {
      setisloading(true);
      const res = await updateCalendar(id, calendarDetails);
      if ([201, 200].includes(res.status)) {
        setisloading(false);
        dispatch.UI.updateFormId({ target: "editCalendar", value: null });
        dispatch.UI.setAlert({ message: "Calendar updated!", type: "Success" });
        getData();
      }
    } catch (err: any) {
      const errMsg = err?.response?.data.message ?? "Unable updated calendar!";
      setisloading(false);
      error(errMsg);
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setCalendarDetails({
      ...calendarDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    const details = {
      id: D?.id,
      name: D?.name,
      date: D?.date,
      holidayType: D?.holidayType,
    };
    setCalendarDetails(details);
    form.setFieldsValue(details);
  }, [id]);

  return (
    <Drawer
      title="Edit Calendar"
      placement="right"
      width={500}
      onClose={() => {
        form.resetFields();
        dispatch.UI.updateFormId({ target: "editCalendar", value: null });
      }}
      visible={id ? true : false}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in the profile information?"
              onConfirm={() => {
                dispatch.UI.updateFormId({
                  target: "editCalendar",
                  value: null,
                });
              }}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Update Calendar
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        onFinish={handleUpdateCalendar}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
        requiredMark={requiredMark}
      >
        <div className="text-left">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Name" name="name" onChange={onChange} />
          </Form.Item>

          <div className="date-form">
            <div className="span">
              <span>Date</span>
            </div>
            <DatePicker
              name="date"
              inputReadOnly={true}
              className="w-full mt-2"
              value={moment(new Date(calendarDetails?.date))}
              defaultValue={moment(new Date(D?.date))}
              format="yyyy-MM-DD"
              onChange={(v: any) => {
                setCalendarDetails({ ...calendarDetails, date: new Date(v) });
              }}
            />
          </div>

          <Form.Item
            label="Holiday Type"
            name="holidayType"
            rules={[{ required: true }]}
          >
            <Select
              className="w-full"
              placeholder="Holiday Type"
              onChange={(v: any) =>
                setCalendarDetails({ ...calendarDetails, holidayType: v })
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {TYPECALENDAR?.map((type, index) => (
                <Option key={index} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};
