import * as React from 'react';
import classnames from 'classnames';

type Props = React.HTMLProps<HTMLButtonElement> & { 
    children: React.ReactNode, 
    type?: 'submit' | 'reset',
    width?: 'none' | 'one',
    height?: '2/4', 
    color?: 'black' | 'grey' | 'darkgrey' | 'red' | 'brown' | 'green' | 'lightgrey' | 'mainColor' | 'hoverColor', 
    shadow?: 'grey' | 'inner_shadow'
    bg?: 'grey' | 'none' | 'white' | 'blue' | 'red' | 'green' | 'white-brown',
    border?: 'bottom' | 'bottom-grey' | 'grey' | 'lightgrey' | 'brown' | 'red' | 'bottom-main' | 'none' | 'primary',
    weight?: 'medium' | 'semi' | 'bold' | 'semi-hover',
    radius?: 'none' | 'sm',
    padding?: 'px-4' | 'px-8' | 'px-2' | 'py-1',
    transform?: 'uppercase',
    margin?: 'mx-4' | 'mt-6' | 'mx-2' | 'mt-4',
    align?: 'left'
}



let getColor = (color: Props['color']) => {
    switch (color) {    
        case 'hoverColor':
            return 'text-hover-main'
        case 'mainColor':
            return 'text-primary-main' 
        case 'green':
          return 'text-green-1'
        case 'brown':
          return 'text-brown-1 hover:text-white'
        case 'red':
          return 'text-red-2'
        case 'darkgrey':
            return 'text-grey-2'
        case 'lightgrey':
            return 'text-grey-8'
        case 'grey':
            return 'text-grey-10'
        case 'black':
            return 'text-black-1 hover:text-black-2'
        default:
            return 'text-white'
    }
}

let getWidth = (width: Props['width']) => {
    switch (width) {
        case 'none':
            return '';
        case 'one':
            return 'w-1'
        default:
            return 'w-full';
    }
} 

let getHeight = (height: Props['height']) => {
    switch (height) {
        case '2/4':
            return 'h-2/4';
        default:
            return 'none';
    }
} 

let getBg = (bg: Props['bg']) => {
    switch (bg) {
        case 'none':
            return ''; 
        case 'green':
            return 'bg-green-4';
        case 'red':
            return 'bg-red-2';
        case 'blue':
            return 'bg-blue-2'; 
        case 'white-brown':
            return 'bg-white-2 hover:bg-brown-1';
        case 'white':
            return 'bg-white';
        case 'grey':
            return 'bg-grey-4';
        default:
            return 'bg-blue-3';
    }
} 

let getBoxShadow = (shadow: Props['shadow']) => {
    switch (shadow) {
        case 'inner_shadow':
            return 'inner_shadow'
        case 'grey':
            return 'shadow-lg'; 
        default:
            return 'shadow-none';
    }
} 

let getBorder = (border: Props['border']) => {
    switch (border) {   
        case 'primary':
            return 'border border-primary-main'
        case 'bottom-main':
            return 'border-b-2 border-primary-main'
        case 'bottom':
            return 'border-b-2 border-grey-6 hover:border-grey-2';  
        case 'bottom-grey':
            return 'border-b-2 border-grey-2';  
        case 'brown':
            return 'border border-brown-1'; 
        case 'lightgrey':
            return 'border border-grey-8';
        case 'grey':
            return 'border border-grey-2';
        case 'red':
            return 'border border-red-2'; 
        case 'none':
            return 'border-none';
        default:
            return 'border'
    }
} 

let getFontWeight = (weight: Props['weight']) => {
    switch (weight) {   
        case 'bold':
            return 'font-bold';   
        case 'semi':
            return 'font-semibold'; 
        case 'semi-hover':
            return 'font-medium hover:font-semibold'; 
        case 'medium':
            return 'font-medium';
        default:
        return 'font-normal';
    } 
}

let getBorderRadius = (radius: Props['radius']) => {
    switch (radius) {   
        case 'sm':
            return 'rounded-sm'
        case 'none': 
            return 'rounded-none';
        default:
            return 'rounded';
    } 
} 

let getPadding = (padding: Props['padding']) => {
    switch (padding) {
        case 'px-8': 
            return 'py-2 px-8';   
        case 'px-4': 
            return 'py-2 px-4';
        case 'px-2': 
            return 'px-2 py-1';
        case 'py-1': 
            return 'py-1';  
        default:
            return 'py-2';
    } 
} 

let getTransform = (transform: Props['transform']) => {
    switch (transform) {   
        case 'uppercase': 
            return 'uppercase';
        default:
            return 'normal-case';
    } 
}  

let getMargin = (margin: Props['margin']) => {
    switch (margin) {   
        case 'mt-4':
            return 'mt-4'
        case 'mt-6':
            return 'mt-6'
        case 'mx-4': 
            return 'mx-4';
        case 'mx-2': 
            return 'mx-2';
        default:
            return 'm-0';
    } 
}  

let getAlign = (align: Props['align']) => {
    switch (align) {    
        case 'left': 
            return 'text-left';
        default:
            return 'text-center';
    } 
}  

  
export const Button = ({ type, width, height, color, bg, shadow, border, weight, radius, padding, transform, margin, 
                        align, children, ...props}: Props) => {
    return <button className={classnames("tracking-wider text-sm whitespace-nowrap transition-all transform duration-500 hover:opacity-80",
            getWidth(width), getColor(color), getBg(bg), getBoxShadow(shadow), getBorder(border),
            getFontWeight(weight), getBorderRadius(radius), getPadding(padding), getTransform(transform),
            getMargin(margin), getAlign(align), getHeight(height)
            )}  type={type} { ...props }
            >{children}
            </button>
}