/* eslint-disable @typescript-eslint/no-unused-vars */
import { createModel } from "@rematch/core"; 
import { RootModel } from ".";
import { collection, getDocs, getFirestore, onSnapshot, doc as Doc, query, where } from "firebase/firestore";
import { TAB, TAB_MERCHANT } from "@constants"; 
import { 
  getAllSearchPendingDrivers, 
  getCalendar, 
  getCompletedExpressOrders, 
  getCompletedRentalOrders, 
  getCustomerInformation, 
  // getDisbursements, 
  getDisbursementsFilter, 
  getDriverByDriverId, 
  getMerchantList
} from "@api";
import { getAttachment, getAttachmentFilter } from "@api";

var TABLE_UNSUBSCRIBE: any;

interface IFirebaseTablePayload {
  table: string;
  name: string;
  tab?: any;
  type?: any;
}

interface ITableUnsubscribePayload {
  table: string;
  data: any;
}

interface ITableDataPayload {
  table: string;
  data: any;
  next?: any;
  previous?: any;
  totalRecords?: any;
  value?: number;
}

export interface IFilterValue {
  field: string;
  operation: string;
  value: any;
  type: string;
}

interface IFilterPayload {
  table: string;
  key: string;
  filter: any;
}

let defaultTableValue = {
  filters: {},
  limit: 10,
  page: 1,
  sort: '',
  unsubscribe: null,
  data: [],
  totalRecords: null,
  keyword: '',
  original_data: null
}

const initialState = {
  tables: {
    expressOrders: defaultTableValue,
    expressOrdersCompleted: defaultTableValue,
    foodOrders: defaultTableValue,
    foodOrdersCompleted: defaultTableValue,
    shopOrders: defaultTableValue,
    shopOrdersCompleted: defaultTableValue,
    ezBuyServiceOrders: defaultTableValue,
    ezBuyServiceOrdersCompleted: defaultTableValue,
    allDrivers: defaultTableValue,
    pendingDrivers: defaultTableValue,
    calendar: defaultTableValue,
    rental: defaultTableValue,
    rentalOrdersCompleted: defaultTableValue,
    attachment: defaultTableValue,
    merchant: defaultTableValue,
    merchantOrder: defaultTableValue,
    merchantOrdersCompleted: defaultTableValue,
    orders: defaultTableValue,
    disbursements: defaultTableValue,
    disbursementsPending: defaultTableValue,
    disbursementsSuccess: defaultTableValue,
  },
  isLoading: true,
  currentRoute: null,
  customerInfo: {},
  driverInfo: [],
  driverDetails : [],
  orderDetails : [],
  merchantDetails : []
} as any;

export const Table = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    /**
     * @name resetForm
     * @description resets from by passing table property underform
     * @param table
     */
    resetTable(state, table: string) {
      return {
        ...state,
        tables: { ...state.tables, [table]: { ...initialState.tables[table] } },
      };
    },
    setFilterValue(state, payload: IFilterPayload) {
      const { table, key, filter } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            filters: { [key]: filter },
          },
        },
      };
    },
    setTableData(state, payload: ITableDataPayload) {
      const { table, data, totalRecords = null } = payload;

      return {
        ...state,
        isLoading: false,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            data,
            totalRecords
          },
        },
      };
    },
    setFirebaseData(state, payload: ITableDataPayload) {
      const { table, data } = payload;

      return {
        ...state,
        isLoading: false,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            original_data: data,
            data,
          },
        },
      };
    },
    updatePageIndex(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            page: value,
          },
        },
      };
    },
    updatePageLimit(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            limit: value,
          },
        },
      };
    },
    updateKeyword(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            keyword: value,
          },
        },
      };
    },
    updateState(state, newState: any) {
      return {
        ...state,
        ...newState
      };
    },
    setTableUnsubscribe(state, payload: ITableUnsubscribePayload) {
      const { table, data } = payload; 

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            unsubscribe: data
          },
        },
      };
    },
  },
  effects: (dispatch) => ({
    async getFirebaseData(payload: IFirebaseTablePayload, rootState) {

      const db = getFirestore();

      let { name, table } = payload

      let { filters } = rootState.Table.tables[table]

      let q = query(collection(db, name));

      if (filters) {
        for (const key in filters) {
          let { field, operation, value } = filters[key]

          if (value) {
            q = query(q, where(field, operation, value))
          }
        }
      }

      let querySnapshot = await getDocs(q);


      const listData = (querySnapshot?.docs || []).map((ref) => {
        const data = ref.data() as any;
        return data
      }) as any[];

      dispatch.Table.setTableData({
        table: table,
        data: listData
      })

    },

    async subscribeToFirebase(payload: IFirebaseTablePayload, rootState) {
      const db = getFirestore();
      let { name, table, tab, type } = payload;
      const { data } = rootState.Table.tables[table];
      
      if(data?.length > 0) dispatch.Table.updateState({isLoading: false});
      if (TABLE_UNSUBSCRIBE) TABLE_UNSUBSCRIBE();

      let q = query(collection(db, name)); 

      if (tab || type) {
        q = query(q, where('status', 'in', TAB[tab]), where('deliveryType', '==', type));
      }

      TABLE_UNSUBSCRIBE = onSnapshot(q, (doc) => {
        const listData = (doc?.docs || []).map((ref) => {
          const data = { ...ref.data(), id: ref?.id };
          return data
        }) as any[];

        dispatch.Table.setFirebaseData({
          table: table,
          data: listData,
        })

      })

    },

    async getOpenOrders(payload: IFirebaseTablePayload, rootState) {
      const db = getFirestore();
      let { name, table, tab } = payload;
      const { data } = rootState.Table.tables[table]; 
      
      if(data?.length > 0) dispatch.Table.updateState({isLoading: false});
      if (TABLE_UNSUBSCRIBE) TABLE_UNSUBSCRIBE();

      let q = query(collection(db, name)); 

      if (tab) {
        q = query(q, where('status', 'in', TAB[tab]));
      }

      TABLE_UNSUBSCRIBE = onSnapshot(q, (doc) => {
        const listData = (doc?.docs || []).map((ref) => {
          const data = { ...ref.data(), id: ref?.id };
          return data
        }) as any[]; 
        dispatch.Table.setFirebaseData({
          table: table,
          data: listData,
        })

      })

    }, 

    async getMerchantOrders(payload: IFirebaseTablePayload, rootState) {
      const db = getFirestore();
      let { name, table, tab, type } = payload;
      const { data } = rootState.Table.tables[table];
      
      if(data?.length > 0) dispatch.Table.updateState({isLoading: false});
      if (TABLE_UNSUBSCRIBE) TABLE_UNSUBSCRIBE();

      let q = query(collection(db, name)); 

      if (tab || type) {
        // q = query(q, where('status', 'in', TAB_MERCHANT[tab]));
        q = query(q);
      }

      TABLE_UNSUBSCRIBE = onSnapshot(q, (doc) => {
        const listData = (doc?.docs || []).map((ref) => {
          const data = { ...ref.data(), id: ref?.id };
          return data
        }) as any[];

        dispatch.Table.setFirebaseData({
          table: table,
          data: listData,
        })

      })

    },

    async getCompletedRental(payload: any, rootState) {
      const { type, table } = payload;

      dispatch.Table.updateState({isLoading: true});

      try {
        const { page: pageIndex, limit: pageSize, keyword } = rootState.Table.tables[table]; 
        const res = await getCompletedRentalOrders(pageIndex, pageSize, keyword, type);   
        if (res.length) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res[0],
            totalRecords: res[1]
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    


    async unsubscribed() {
      TABLE_UNSUBSCRIBE();
    },

    async getCompletedOrders(payload: any, rootState) {
      const { type, table } = payload;

      dispatch.Table.updateState({isLoading: true});

      try {
        const { page: pageIndex, limit: pageSize, keyword } = rootState.Table.tables[table];
        const res = await getCompletedExpressOrders(pageIndex, pageSize, keyword, type); 
        if (res.results) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res?.results,
            totalRecords: res?.totalRecords
          })
        }
      } catch (err) {
        console.log(err)
      }
    }, 

    async getCalendarData(payload: any, rootState) { 
      const { table } = payload;

      dispatch.Table.updateState({isLoading: true});

      try {
        const { page: pageIndex, limit: pageSize } = rootState.Table.tables[table];
        const res = await getCalendar(pageIndex, pageSize);
        
        if (res.length) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res[0],
            totalRecords: res?.length
          })
        }
      } catch (err) {
        console.log(err)
      }
    }, 
    
    async getAttachmentData(payload: any, rootState) {
        // const { driverName, table } = payload;

      // dispatch.Table.updateState({isLoading: true}); 

      try {
        // const { page: pageIndex, limit: pageSize } = rootState.Table.tables[table];
        const res = await getAttachment(); 
        if (res.length) {
          dispatch.UI.setIsLoading(false);
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res[0],
            totalRecords: res[1]
          })
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getAttachmentFilterData(payload: any, rootState) {
      const { table, service } = payload;

      dispatch.Table.updateState({isLoading: true});

      try {
        const { page: pageIndex, limit: pageSize, keyword } = rootState.Table.tables[table];
        const res = await getAttachmentFilter(pageIndex, pageSize, keyword, service); 
        if (res.length) {
          dispatch.UI.setIsLoading(false);
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res[0],
            totalRecords: res[1]
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getDisbursementsData(payload: any, rootState) {
     
      const { table, status } = payload;   
      dispatch.Table.updateState({isLoading: true});  

      try {
        const { page: pageIndex, limit: pageSize, keyword } = rootState.Table.tables[table];  
        const res = await getDisbursementsFilter(pageIndex, pageSize, status, keyword);  
        if (res.results) {
          dispatch.UI.setIsLoading(false);
          dispatch.Table.setTableData({
            table: table,
            data: res.results,
            totalRecords: res.totalRecords
          });
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getDriversData(payload: any, rootState) {
      const { table, isWithORCR, firstView } = payload;
      const { data } = rootState.Table.tables[table];
      
      if(data?.length > 0 && firstView){
        dispatch.Table.updateState({isLoading: false});
      }else{
        dispatch.Table.updateState({isLoading: true});
      }

      const withORCR = table === 'allDrivers' ? '' : isWithORCR ? '&withOrCr=1' : '&withOrCr=0'

      try {
        const { page: pageIndex, limit: pageSize, keyword } = rootState.Table.tables[table]; 
        const res = await getAllSearchPendingDrivers(pageIndex, pageSize, keyword, withORCR);
        if (res.results) {
          dispatch.UI.setIsLoading(false);
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res?.results,
            totalRecords: res?.totalRecords
          })
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getCustomerInfo(payload: any, rootState) {
      const { customerID } = payload;
      try {
        const res = await getCustomerInformation(customerID);
        dispatch.Table.updateState({ customerInfo: res });
        return res?.userId;
      } catch (err: any) {
        const errMsg = err?.response?.data?.message ?? 'Unable to get customer Info';
        dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
      }
    },

    async getDriverById(payload: any, rootState) {
      try {
        const { driverIds } = payload;
        const driverInfo = driverIds?.map(async (id) => {
          const res = await getDriverByDriverId(id);
          return res?.results[0];
        })
        const result = (await Promise.allSettled(driverInfo)).map((item: any) => item.value);
        dispatch.Table.updateState({ driverInfo: result })
      } catch (err) {
        console.log(err);
      }
    },

    async getMerchantData(payload: any) { 

    try { 
      const res = await getMerchantList(); 
      if (res.length) {
        dispatch.UI.setIsLoading(false);
        dispatch.Table.setTableData({
          table: payload?.table,
          data: res?.results
        })
      }
    } catch (err) {
      console.log(err)
    }
  },
  }),
});
