import STOP_ICON_1 from '../assets/images/stop_icons/icon_stop_1.png';
import STOP_ICON_2 from '../assets/images/stop_icons/icon_stop_2.png';
import STOP_ICON_3 from '../assets/images/stop_icons/icon_stop_3.png';
import STOP_ICON_4 from '../assets/images/stop_icons/icon_stop_4.png';
import STOP_ICON_5 from '../assets/images/stop_icons/icon_stop_5.png';
import STOP_ICON_6 from '../assets/images/stop_icons/icon_stop_6.png';
import STOP_ICON_7 from '../assets/images/stop_icons/icon_stop_7.png';
import STOP_ICON_8 from '../assets/images/stop_icons/icon_stop_8.png';
import STOP_ICON_9 from '../assets/images/stop_icons/icon_stop_9.png';
import STOP_ICON_10 from '../assets/images/stop_icons/icon_stop_10.png';
import STOP_ICON_11 from '../assets/images/stop_icons/icon_stop_11.png';
import STOP_ICON_12 from '../assets/images/stop_icons/icon_stop_12.png';
import STOP_ICON_13 from '../assets/images/stop_icons/icon_stop_13.png';
import STOP_ICON_14 from '../assets/images/stop_icons/icon_stop_14.png';
import STOP_ICON_15 from '../assets/images/stop_icons/icon_stop_15.png';
import STOP_ICON_16 from '../assets/images/stop_icons/icon_stop_16.png';
import STOP_ICON_17 from '../assets/images/stop_icons/icon_stop_17.png';
import STOP_ICON_18 from '../assets/images/stop_icons/icon_stop_18.png';
import STOP_ICON_19 from '../assets/images/stop_icons/icon_stop_19.png';
import STOP_ICON_20 from '../assets/images/stop_icons/icon_stop_20.png';
import STOP_ICON_21 from '../assets/images/stop_icons/icon_stop_21.png';
import STOP_ICON_22 from '../assets/images/stop_icons/icon_stop_22.png';
import STOP_ICON_23 from '../assets/images/stop_icons/icon_stop_23.png';
import STOP_ICON_24 from '../assets/images/stop_icons/icon_stop_24.png';
import STOP_ICON_25 from '../assets/images/stop_icons/icon_stop_25.png';
import STOP_ICON_26 from '../assets/images/stop_icons/icon_stop_26.png';
import STOP_ICON_27 from '../assets/images/stop_icons/icon_stop_27.png';
import STOP_ICON_28 from '../assets/images/stop_icons/icon_stop_28.png';
import STOP_ICON_29 from '../assets/images/stop_icons/icon_stop_29.png';
import STOP_ICON_30 from '../assets/images/stop_icons/icon_stop_30.png';

export const STOP_ICONS = {
    STOP_ICON_1,
    STOP_ICON_2,
    STOP_ICON_3,
    STOP_ICON_4,
    STOP_ICON_5,
    STOP_ICON_6,
    STOP_ICON_7,
    STOP_ICON_8,
    STOP_ICON_9,
    STOP_ICON_10,
    STOP_ICON_11,
    STOP_ICON_12,
    STOP_ICON_13,
    STOP_ICON_14,
    STOP_ICON_15,
    STOP_ICON_16,
    STOP_ICON_17,
    STOP_ICON_18,
    STOP_ICON_19,
    STOP_ICON_20,
    STOP_ICON_21,
    STOP_ICON_22,
    STOP_ICON_23,
    STOP_ICON_24,
    STOP_ICON_25,
    STOP_ICON_26,
    STOP_ICON_27,
    STOP_ICON_28,
    STOP_ICON_29,
    STOP_ICON_30,
}