/* eslint-disable import/no-anonymous-default-export */
import { GridContainer, Span } from "@common"; 
import { BsDot } from "react-icons/bs";
import { formatDate } from "@utils";

export default ({ pickUp, driver, thirdPartyDriver }: any) => { 
  return (
    <>
      <GridContainer grid="four">
        <div className="col-span-2">
          <Span weight="semi"> Address: </Span>
          <Span transform="capitalize">{pickUp?.locationAddress}</Span>
          <p className="flex">
            <BsDot className="text-grey-11 my-auto" />
            <Span color="lightgrey" transform="capitalize">{!pickUp?.location?.address ? 'No Data' : pickUp?.location?.address}</Span>
          </p>
        </div>

        <div>
          <Span weight="semi"> Contact Person </Span>
          <Span>{pickUp?.contactName}</Span>
        </div>

        <div>
          <Span weight="semi"> Contact Number </Span>
          <Span>{pickUp?.contactPhone}</Span>
        </div> 
      </GridContainer>

      {pickUp?.pickedAt && (
        <>
          <hr className="mx-4" />

          <div className="flex justify-end items-center px-8 pb-4">
            <Span><strong>{!driver?.driverProfile?.lastName ? `${thirdPartyDriver?.contactName} ` : `${driver?.driverProfile?.lastName}, ${driver?.driverProfile?.firstName}, `}</strong>at <strong>{formatDate(pickUp?.pickedAt)}</strong></Span>
          </div>
        </>
      )}

    </>
  );
};
