/* eslint-disable import/no-anonymous-default-export */
import { GridContainer, Span } from "@common";
import { formatDecimalPrice } from "@utils";
import { isEmpty } from "lodash";

export default ({ orderItems }: any) => {
  return (
    <>
      {orderItems?.map((order) => (
        <div key={order?.productId}>
          <GridContainer grid="two">
            <div>
              <Span>
                <strong>Product:</strong> {order?.productName}
              </Span>
              <Span>
                <strong>Regular Price: </strong>₱{" "}
                {formatDecimalPrice(order?.basePrice) ?? 0}
              </Span>
              {Boolean(order?.totalAddonsPrice) && (
                <Span>
                  <strong>Addons Total Price: </strong>₱{" "}
                  {formatDecimalPrice(order?.totalAddonsPrice)}
                </Span>
              )}
              {Boolean(order?.discount) && (
                <Span>
                  <strong>Discount: </strong>- ₱{" "}
                  {formatDecimalPrice(order?.discount)}
                </Span>
              )}
              <Span>
                <strong>Price: </strong>₱{" "}
                {formatDecimalPrice(order?.pricePerUnit) ?? 0}
              </Span>
              {order?.isPisoApplied && (
                <Span>
                  <strong>Piso Discount: </strong>- ₱{" "}
                  {formatDecimalPrice(order?.pisoPromotion?.discount)}
                </Span>
              )}
              <Span>
                <strong>Total: </strong>₱{" "}
                {formatDecimalPrice(order?.total) ?? 0}
              </Span>
            </div>

            <div>
              <Span>
                <strong>Unit:</strong> {order?.unit ?? "No Data"}
              </Span>
              <Span>
                <strong>Actual Unit:</strong> {order?.actualUnit ?? "No Data"}
              </Span>
              <Span>
                <strong>Notes:</strong> {order?.notes ?? "No Data"}
              </Span>
              <Span>
                <strong>Addons:</strong>
              </Span>
              {isEmpty(order?.addons)
                ? "No Data"
                : order?.addons?.map((item, index) => (
                    <div key={index}>
                      <Span>- {item?.name}</Span>
                      {item?.options
                        ? item?.options?.map((option, indexOption) => (
                            <Span key={indexOption}>
                              + {option?.name}, ₱{" "}
                              {formatDecimalPrice(option?.price)}
                            </Span>
                          ))
                        : null}
                    </div>
                  ))}
            </div>
          </GridContainer>
          <hr className="mx-4" />
        </div>
      ))}
    </>
  );
};
