import StatusDriver from "../../../components/status/StatusDriver";
import { DriverStatusDesign, ColumnFilter } from "@components";
import { Span } from "@common";
import { Avatar } from "antd";
import { VEHICLE } from "@constants";

export const COLUMNS = [
  {
    accessor: (data) =>
      `${data?.driverProfile?.lastName ?? ""}, ${
        data?.driverProfile?.firstName ?? ""
      } `,
    Header: "Driver Name",
    Filter: ColumnFilter,
    isVisible: true,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="truncate">
          <Span>
            <Avatar
              style={{ backgroundColor: "#84b15c", marginRight: "5px" }}
              src={d?.driverProfile?.picture}
              alt="profile-image"
            >
              {d?.driverProfile?.lastName[0]}
            </Avatar>
            {`${d?.driverProfile?.lastName ?? ""}, ${
              d?.driverProfile?.firstName ?? ""
            } `}
          </Span>
        </div>
      );
    },
  },
  {
    accessor: "phoneNumber",
    Header: "Contact No.",
    Filter: ColumnFilter,
    isVisible: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
  },
  {
    accessor: (data) =>
      JSON.stringify((data?.vehicles || [])?.map((v) => `${v?.vehicleType}`)),
    Header: "Vehicle",
    Filter: ColumnFilter,
    isVisible: true,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div>
          {d?.vehicles?.length > 0 ? (
            d?.vehicles?.map((v) => (
              <div className="truncate" key={v?.vehicleId}>
                <Span
                  color={v?.status === "active" ? "blue" : "default"}
                  transform="uppercase"
                >{`${VEHICLE[v?.vehicleType]}`}</Span>
              </div>
            ))
          ) : (
            <Span color="lightgrey">No Data</Span>
          )}
        </div>
      );
    },
  },
  {
    accessor: (data) =>
      data?.driverProfile?.driverGroup
        ?.split("_")
        ?.map((str) => str[0]?.toUpperCase() + str.substring(1))
        ?.join(" "),
    Header: "Driver Group",
    Filter: ColumnFilter,
    isVisible: true,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    accessor: "deliveryCluster",
    Header: "Cluster",
    Filter: ColumnFilter,
    isVisible: true,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <Span color={!d?.deliveryCluster ? "lightgrey" : "default"}>
          {d?.deliveryCluster ?? "No data"}
        </Span>
      );
    },
  },
  {
    accessor: "deliveryCity",
    Header: "City",
    Filter: ColumnFilter,
    isVisible: true,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <Span color={!d?.deliveryCity ? "lightgrey" : "default"}>
          {d?.deliveryCity ?? "No data"}
        </Span>
      );
    },
  },
  {
    accessor: (data) => data?.deliveryArea,
    Header: "Area",
    Filter: ColumnFilter,
    isVisible: true,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <Span color={!d?.deliveryArea ? "lightgrey" : "default"}>
          {d?.deliveryArea ?? "No data"}
        </Span>
      );
    },
  },
  {
    accessor: "approvalStatus",
    Header: "Approval Status",
    Filter: ColumnFilter,
    isVisible: true,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original;
      return <DriverStatusDesign status={d?.approvalStatus} />;
    },
  },
  {
    accessor: (data) => data?.driverStatus,
    Header: "Driver Status",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return <DriverStatusDesign status={d?.driverStatus} />;
    },
  },
  {
    accessor: "status",
    Header: "System Status",
    Filter: ColumnFilter,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return <DriverStatusDesign status={d?.status} />;
    },
  },
  {
    accessor: " ",
    Header: " ",
    Filter: ColumnFilter,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: (data) => (
      <StatusDriver
        id={
          data.row.original.driverId === undefined
            ? data.row.original.id
            : data.row.original.driverId
        }
        status={data.row.original.approvalStatus}
        dataType={"allDrivers"}
      />
    ),
  },
];
