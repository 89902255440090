import { useParams } from 'react-router-dom'

import { Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { FoodOrdersViewPage } from '@components'

export const FoodOrdersView = () => {

  let params: any = useParams();

  return (
    <>
      <MainLayout>
        <SideBar />  
        <Header />
        
        <ContainerLayout>
          <FoodOrdersViewPage id={params.id} />
        </ContainerLayout>
      </MainLayout>
    </>
  )
}