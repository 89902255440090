import { useDispatch } from 'react-redux';

import { Span } from '@common' 

import { Dispatch } from '@store';
import { Modal } from 'antd'
import { approveDriver } from '@api' 

type Props = {
    setApproveModal: Function,
    approvedModal: boolean,
    id: string,
    dataType: string,
}

export const ApproveModal = ({ setApproveModal, approvedModal, id, dataType, ...props }: Props) => {

    const dispatch = useDispatch<Dispatch>();

    const { confirm } = Modal;

    const handleApproved = async () => {
        setApproveModal(false);
        try {
            dispatch.UI.setIsLoading(true);
            const res = await approveDriver(id);
            if ([200, 201].includes(res.status)) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: 'Status updated', type: 'Success' });
                dispatch.Table.getDriversData({ table: dataType, type: dataType === 'pending' ? 'pending' : '' });
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.message ?? 'Unable to update status';
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Confirm',
            content: (
                <Span>Are you sure</Span>
            ),
            onOk() {
                handleApproved();
            },
            onCancel() {
                setApproveModal(false)
            }
        })
    }

    return (
        <>
            {approvedModal && showConfirm()}
        </>
    );
}   