/* eslint-disable eqeqeq */
/* eslint-disable import/no-anonymous-default-export */
import { Steps } from 'antd' 
import { formatDateV2 } from '@utils';

const { Step } = Steps;
  
export default ({ buy }: any) => { 

    const pendingSchedule = buy?.createdAt ? formatDateV2(buy?.createdAt): ''; 
    const forpickupSchedule = buy?.acceptedAt ? formatDateV2(buy?.acceptedAt) : '';
    const arrivedAtPickupSchedule = buy?.arrivedAtPickupAt ? formatDateV2(buy?.arrivedAtPickupAt) : '';
    const pickupSchedule = buy?.pickedUpAt ? formatDateV2(buy?.pickedUpAt): '';
    const ongoingSchedule = buy?.onGoingAt? formatDateV2(buy?.onGoingAt): '';
    const deliveredSchedule = buy?.deliveredAt ? formatDateV2(buy?.deliveredAt): '';
    const canceledSchedule = buy?.cancelledAt ? formatDateV2(buy?.cancelledAt) : '';
  
    const StatusBuy = [
        {
            label: 'Pending',
            value: 'pending',
            timestamp: pendingSchedule,
        },
        {
            label: 'For PickUp',
            value: 'for_pickup',
            timestamp: forpickupSchedule,
        },
        {
            label: 'Arrived at Pickup',
            value: 'arrivedAtPickup', 
            timestamp: arrivedAtPickupSchedule,
        },
        {
            label: 'Picked Up',
            value: 'picked_up',
            timestamp: pickupSchedule,
        },
        {
            label: 'On Going',
            value: 'on_going',
            timestamp: ongoingSchedule,
        },
        {
            label: 'Delivered',
            value: 'delivered',
            timestamp: deliveredSchedule,
        },
        {
            label: 'Canceled',
            value: 'canceled',
            timestamp: canceledSchedule,
        },
    ]  

    const buyStatus = buy?.status == 'for_pickup' && buy?.deliveryStartTime != null && buy?.arrivedAtPickupAt != null
    ? 'arrivedAtPickup' : buy?.status
    const index = StatusBuy?.filter(status => !buy?.deliveryStartTime 
        ? status?.value !== 'arrivedAtPickup' 
        : status?.value !== 'none')?.findIndex(item => item.value === buyStatus); 

    return (
      <> 
        <Steps direction="vertical" size="small" current={index}>   

            {StatusBuy?.filter(status => !buy?.deliveryStartTime 
            ? status?.value !== 'arrivedAtPickup' : status?.value !== 'none')?.map((item, index) => (
                <Step 
                    title={item.label} 
                    key={index} 
                    description={item.timestamp} 
                />
            ))}
        </Steps> 
      </>
  );
}; 
