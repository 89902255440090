/* eslint-disable import/no-anonymous-default-export */
import Map from "./Map";

export default ({ data, driverData, isShow }: any) => {
  return (
    <div className="flex w-full h-[50vh] p-2">
      <Map data={data} driverData={driverData} isShow={isShow} />
    </div>
  );
};
