export const clusterData = [
  'NCR',
  'South Luzon',
  'North Luzon',
  'Visayas',
  'Mindanao',
];

export const area = {
  NCR: ['Metro Manila'],
  'South Luzon': [
    'Cavite',
    'Laguna',
    'Rizal',
    'Batangas',
    'Camarines Sur',
    'Quezon',
  ],
  'North Luzon': [
    'Bataan',
    'Bulacan',
    'Isabela',
    'Pampanga',
    'Nueva Ecija',
    'Pangasinan',
  ],
  Visayas: ['Negros Occidental', 'Iloilo', 'Cebu'],
  Mindanao: [
    'Davao Del Sur',
    'South Cotabato',
    'Agusan Del Norte',
    'Surigao del Norte',
    'Misamis Oriental',
  ],
};

export const clustercities = {
  'Metro Manila': [
    'Las Pinas City',
    'Mandaluyong City',
    'Makati City',
    'Paranaque City',
    'Pasig City',
    'Taguig City',
    'Quezon City',
    'Pasay City',
    'Manila City',
    'Muntinlupa City',
    'Marikina City',
    'San Juan City',
    'Caloocan City',
    'Malabon City',
    'Valenzuela City',
    'Navotas City',
  ],
  Cavite: [
    'Bacoor City',
    'Santa Rosa City',
    'Dasmarinas City',
    'Silang',
    'General Trias',
    'Tanza',
    'Imus City',
    'Carmona City',
    'Kawit',
    'Tagaytay City',
    'Amadeo',
    'Trece Martires',
    'Rosario',
    'Mendez',
    'Alfonso',
    'Naic',
    'Noveleta',
    'Cavite City',
    'General Mariano Alvarez',
  ],
  Rizal: [
    'Cainta',
    'Baras',
    'Antipolo City',
    'Taytay',
    'Binangonan',
    'Morong',
    'San Mateo',
    'Rodriguez',
    'Angono',
  ],
  Laguna: [
    'Binan City',
    'San Pedro City',
    'Cabuyao City',
    'Calauan',
    'Calamba CIty',
    'San Pablo City',
  ],
  Batangas: [
    'Tanauan City',
    'Santo Tomas City',
    'Lipa City',
    'Lemery',
    'Santa Maria',
  ],
  'Camarines Sur': ['Naga City', 'Buhi', 'Pili'],
  Quezon: ['Lucena'],
  Bataan: ['Mariveles', 'Balanga City', 'Orani', 'Hermosa', 'Bagac', 'Limay'],
  Bulacan: [
    'Guiguinto',
    'San Jose Del Monte City',
    'Malolos City',
    'Santa Maria',
    'Marilao',
    'San Ildefonso',
    'Paombong',
    'Bulacan City',
    'Calumpit',
    'San Miguel',
    'Pulilan',
    'Baliuag City',
    'Meycauayan City',
    'Pandi City',
    'Norzagaray',
    'Obando City',
  ],
  Isabela: ['Cauayan City', 'Isabela City', 'Santiago'],
  Pampanga: [
    'Santo Tomas',
    'Porac',
    'Lubao',
    'Angeles City',
    'San Fernando City',
  ],
  'Nueva Ecija': ['Cabanatuan City', 'Cabiao', 'Gapan City', 'Laur City'],
  Pangasinan: ['Mangaldan'],
  'Negros Occidental': ['Bacolod City', 'Silay', 'San Carlos City'],
  Iloilo: [
    'Oton',
    'Pavia',
    'Iloilo City',
    'La Paz',
    'Pototan',
    'Jaro',
    'Leganes',
  ],
  Cebu: ['Mandaue City', 'Carcar City', 'Talisay City', 'Cebu City'],
  'Davao Del Sur': ['Davao City'],
  'South Cotabato': ['Koronadal'],
  'Agusan Del Norte': ['Butuan City'],
  'Surigao del Norte': ['Surigao City'],
  'Misamis Oriental': ['Cagayan de Oro', 'Cagayan De Oro City'],
};
