/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined } from "@ant-design/icons";
import { Container, GridContainer, Span } from "@common";
import { UploadPhoto } from "@components";
import { AutoComplete, Button, Checkbox, Form, Input, Popconfirm } from "antd";
import { useEffect } from "react";

type Props = {
  addOn: any;
  index: any;
  addsOnDetails: any;
  file?: any;
  form: any;
  setActiveCustomAddsOnIndex: any;
  activeCustomAddsOnIndex: any;
  setAddsOnDetails: any;
  setDeletedAddsOn?: any;
  setFile: Function;
  deletedAddsOn?: any;
};

const options = [{ value: "Parking Fee" }, { value: "Toll Fee" }];

export const CustomAddOn = ({
  addsOnDetails,
  addOn,
  index,
  form,
  file,
  setAddsOnDetails,
  setFile,
}: Props) => {
  const setAddOn: any = (value, name) => {
    const result = addsOnDetails?.addOn?.map((item, i) =>
      index === i ? { ...item, [name]: value } : item
    );
    return result;
  };

  useEffect(() => {
    const name1 = `particular${index}`;
    const name2 = `amount${index}`;
    const name3 = `driverShareOnly${index}`;
    if (
      addOn?.particular !== "" ||
      addOn?.amount !== 0 ||
      addOn?.driverShareOnly !== ""
    ) {
      form.setFieldsValue({
        [name1]: addOn?.particular,
        [name2]: addOn?.amount,
        [name3]: addOn?.driverShareOnly,
      });
    } else {
      form.setFieldsValue({
        [name1]: null,
        [name2]: null,
        [name3]: null,
      });
    }
  }, [addsOnDetails?.addOn?.length]);

  return (
    <>
      <Container border="grey" margin="mb-2" key={index}>
        <div className="flex justify-between">
          <Span weight="semi" color="blue">
            Custom Adds On {index + 1}
          </Span>

          {addsOnDetails?.addOn?.length > 1 && (
            <div className="flex justify-end">
              <Popconfirm
                placement="leftTop"
                title="Are you sure?"
                onConfirm={() => {
                  setAddsOnDetails({
                    ...addsOnDetails,
                    addOn: [
                      ...addsOnDetails?.addOn?.filter((item, i) => i !== index),
                    ],
                  });
                }}
                okText="Discard"
                cancelText="Cancel"
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  type="ghost"
                  danger
                />
              </Popconfirm>
            </div>
          )}
        </div>

        <GridContainer grid="two" padding="none">
          <Form.Item name={`particular${index}`} rules={[{ required: true }]}>
            <p className="my-2">
              <span className="required"> Add-on Name </span>
            </p>
            <AutoComplete
              className="w-full"
              options={options}
              placeholder="Add-on Name"
              filterOption={(inputValue, option) =>
                option!.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value: any) => {
                setAddsOnDetails({
                  ...addsOnDetails,
                  addOn: setAddOn(value, "particular"),
                });
              }}
            />
          </Form.Item>

          <Form.Item name={`amount${index}`} rules={[{ required: true }]}>
            <p className="my-2">
              <span className="required"> Amount (Php) </span>
            </p>
            <Input
              placeholder="Amount"
              onChange={(e) =>
                setAddsOnDetails({
                  ...addsOnDetails,
                  addOn: setAddOn(Number(e.target.value), "amount"),
                })
              }
            />
          </Form.Item>
        </GridContainer>
        <Form.Item
          name={`driverShareOnly${index}`}
          rules={[{ required: true }]}
        >
          <div className="flex flex-row justify-start items-center mt-4 mb-4">
            <Checkbox
              onChange={(e: any) =>
                setAddsOnDetails({
                  ...addsOnDetails,
                  addOn: setAddOn(e.target.checked, "driverShareOnly"),
                })
              }
              style={{ marginRight: "0.5rem" }}
            />
            <Span>Driver Share only</Span>
          </div>
        </Form.Item>
        <Form.Item required>
          <UploadPhoto file={file} setFile={setFile} />
        </Form.Item>
      </Container>
    </>
  );
};
