import { createModel } from "@rematch/core"; 
import { RootModel } from "."; 

export interface State { 
    isDriverEnabled: boolean; 
    isMerchantEnabled: boolean; 
}

const initialState = { 
  isDriverEnabled: true, 
  isMerchantEnabled: false
} as State

export const Driver = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    }, 
    updateState(state, newState) {
        return {
          ...state,
          ...newState,
        };
      }, 
  },
});
