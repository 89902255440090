import { APPROVAL_STATUS, STATUS } from '@constants'

export const DriverStatusDesign = ({ status }: any) => {

    const renderDesign = () => {
        switch (status) {
            case 'approved': 
                return (
                    <div className='bg-[#f6ffed] border-[1px] border-[#b7eb8f] rounded-sm w-[100px] flex items-center justify-center'>
                        <span className='text-xs text-[#389e0d] p-1'>{APPROVAL_STATUS[status]}</span>
                    </div>
                )
            case 'pending':
                return (
                    <div className='bg-[#fffbe6] border-[1px] border-[#ffe58f] rounded-sm w-[100px] flex items-center justify-center'>
                        <span className='text-xs text-[#d48806] p-1'>{APPROVAL_STATUS[status]}</span>
                    </div>
                ) 
            case 'rejected':
                return (
                    <div className='bg-[#fff1f0] border-[1px] border-[#ffa39e] rounded-sm w-[100px] flex items-center justify-center'>
                        <span className='text-xs  text-[#cf1322] p-1'>{APPROVAL_STATUS[status]}</span>
                    </div>
                ) 
            case 'active':
                return (
                    <div className='bg-[#f6ffed] border-[1px] border-[#b7eb8f] rounded-sm w-[100px] flex items-center justify-center'>
                        <span className='text-xs text-[#389e0d] p-1'>{STATUS[status]}</span>
                    </div>
                ) 
            case 'inactive':
                return (
                    <div className='bg-[#fff1f0] border-[1px] border-[#ffa39e] rounded-sm w-[100px] flex items-center justify-center'>
                        <span className='text-xs  text-[#cf1322] p-1'>{STATUS[status]}</span>
                    </div>
                ) 
            default:
                return (
                    <div className='border-[1px] border-[#b7eb8f] rounded-sm w-[100px] bg-[#f6ffed] flex items-center justify-center'>
                        <span className='text-xs text-[#389e0d] p-1'>{STATUS[status] ?? APPROVAL_STATUS[status]}</span>
                    </div>
                )
        }
    }
    return (
        renderDesign()
    )
}