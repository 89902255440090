/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable array-callback-return */
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

type Props = {
    driverLocation: any
}

export default ({driverLocation}: Props) => {  
    
  const dispatch = useDispatch<Dispatch>();
  const [sQuery, setSQuery] = useState("");

  const handleOnclick = (id: string, location: any) => {
    dispatch.Locations.updateState({
      searchLocation: { location },
    });
    dispatch.UI.updateFormId({ target: "viewDriverDetails", value: id });
    dispatch.UI.updateFormId({ target: "viewMerchantDetails", value: null }); 
  };

  
  return (<>
  <div className="driver-dashboard">
    <div className="flex justify-center p-4">
      <input
        onChange={(e) => setSQuery(e.target.value)}
        style={{
          textAlign: "center",
          height: 40,
          width: "100%",
          borderRadius: 4,
          borderWidth: 2,
          borderColor: "#FFC300",
          marginBottom: 10,
        }}
        placeholder="Find Driver Here"
      />
    </div>

    <table id="customers">
      {driverLocation 
        .filter((dl) => {
          if (sQuery === "") {
            return dl;
          } else if (
            dl?.profile?.firstName
              ?.toLowerCase()
              .includes(sQuery.toLowerCase()) ||
            dl?.profile?.lastName
              ?.toLowerCase()
              .includes(sQuery.toLowerCase())
          ) {
            return dl;
          }
        })
        ?.sort((a, b) => a?.isBusy - b?.isBusy)
        .map((item, index) => {
          return (
            <tr
              key={item?.id}
              onClick={() =>
                handleOnclick(item?.id, {
                  lat: item?.lat,
                  lng: item?.lng,
                })
              }
              style={{ cursor: "pointer" }}
            >
              {item?.driverStatus &&
              item?.vehicles?.length &&
              item.driverStatus === "active" &&
              item?.profile?.firstName &&
              item?.profile?.lastName ? (
                <td
                  className="capitalize"
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  {[
                    item?.profile?.firstName,
                    item?.profile?.middleName?.charAt(0),
                    item?.profile?.lastName,
                  ]
                    .filter(Boolean)
                    .join(" ")}
                </td>
              ) : (
                <></>
              )}

              {item?.driverStatus &&
              item?.vehicles?.length &&
              item?.driverStatus === "active" &&
              item?.isBusy !== undefined &&
              item?.isBusy !== "" ? (
                <td
                  style={{ cursor: "pointer" }}
                > 
                  <Fragment>
                    <div
                      className="colored-circle"
                      style={{
                        backgroundColor: item?.isBusy
                          ? "#f20c0c"
                          : "#02b302",
                        margin: 0,
                        boxShadow: "1px 2px 3px #9E9E9E",
                      }}
                    ></div>
                  </Fragment>
                </td>
              ) : (
                <></>
              )}
            </tr>
          );
        })}
    </table>
  </div>
  </>)
};