/* eslint-disable import/no-anonymous-default-export */
import { GridContainer, Span } from "@common";

export default ({ orderItems }: any) => {
  return (
    <>
      {orderItems?.map((order) => (
        <div key={order?.productId}>
          <GridContainer grid="two">
            <div>
              <Span>
                <strong>Product:</strong> {order?.itemName}
              </Span>
            </div>

            <div>
              <Span>
                <strong>Unit:</strong> {order?.unit ?? "No Data"}
              </Span>
            </div>
          </GridContainer>
          <hr className="mx-4" />
        </div>
      ))}
    </>
  );
};
