import { COMPLETED_ORDERS_STATUS, OPEN_ORDERS_STATUS } from "@constants";

export const GenerateStatusDesign = ({ status, label }: any) => {
  const renderDesign = () => {
    switch (status) {
      case "pending":
        return (
          <div className="bg-[#f2bb0014] border-[#f2bb00] status-style">
            <span className="status-label text-[#f2bb00]">{label}</span>
          </div>
        );
      case "for_pickup":
        return (
          <div className="bg-[#2a2ce114] border-[#2a2ce1] status-style">
            <span className="status-label text-[#2a2ce1]">{label}</span>
          </div>
        );
      case "assigned":
        return (
          <div className="bg-[#e0983225] border-[#e09832] status-style">
            <span className="status-label text-[#e09832]">{label}</span>
          </div>
        );
      case "picked_up":
        return (
          <div className="bg-[#73a92014] border-[#73a920] status-style">
            <span className="status-label text-[#73a920]">{label}</span>
          </div>
        );
      case "on_going":
        return (
          <div className="bg-[#07291014] border-[#072910] status-style">
            <span className="status-label text-[#072910]">{label}</span>
          </div>
        );
      case "time_in":
        return (
          <div className="bg-[#cfc22614] border-[#cfc226] status-style">
            <span className="status-label text-[#cfc226]">{label}</span>
          </div>
        );
      case "time_in":
        return (
          <div className="bg-[#f0f5ff] border-[1px] border-[#adc6ff] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs text-[#1d39c4] p-1">{label}</span>
          </div>
        );
      case "delivered":
        return (
          <div className="bg-[#44a7d114] border-[#44a7d1] status-style">
            <span className="status-label text-[#44a7d1]">
              {COMPLETED_ORDERS_STATUS[status]}
            </span>
          </div>
        );
      case "completed":
        return (
          <div className="bg-[#44a7d114] border-[#44a7d1] status-style">
            <span className="status-label text-[#44a7d1]">
              {COMPLETED_ORDERS_STATUS[status]}
            </span>
          </div>
        );
      case "canceled":
        return (
          <div className="bg-[#99999914] border-[#999999] status-style">
            <span className="status-label text-[#999999]">
              {COMPLETED_ORDERS_STATUS[status]}
            </span>
          </div>
        );
      case "declined":
        return (
          <div className="bg-[#99999914] border-[#999999] status-style">
            <span className="status-label text-[#999999]">
              {COMPLETED_ORDERS_STATUS[status]}
            </span>
          </div>
        );
      case "failed_delivery":
        return (
          <div className="bg-[#99999914] border-[#999999] status-style">
            <span className="status-label text-[#999999]">
              {COMPLETED_ORDERS_STATUS[status]}
            </span>
          </div>
        );
      case "for_delivery":
        return (
          <div className="bg-[#fff1f0] border-[#ffa39e] status-style">
            <span className="status-label text-[#cf1322]">{label}</span>
          </div>
        );

      case "ready":
        return (
          <div className="bg-[#e6f7ff] border-[#91d5ff] status-style">
            <span className="status-label text-[#096dd9]">{label}</span>
          </div>
        );
      default:
        return (
          <div className="border-[#b7eb8f] rounded-sm w-[100px] bg-[#f6ffed] flex items-center justify-center">
            <span className="status-label text-[#389e0d]">
              {OPEN_ORDERS_STATUS[status] ?? COMPLETED_ORDERS_STATUS[status]}
            </span>
          </div>
        );
    }
  };
  return renderDesign();
};
