/* eslint-disable import/no-anonymous-default-export */
import { GridContainer, Span } from "@common";

export default ({ data }: any) => {

  return (
    <>
      <GridContainer grid="four">
        <div className="col-span-2">
          <Span weight="semi"> Location: </Span>
          <Span transform="capitalize">{data?.pickup?.locationAddress ? data?.pickup?.locationAddress : data?.pickup}</Span>
        </div>
        <div>
          <Span weight="semi"> Client Name: </Span>
          <Span transform="capitalize">{data?.client?.name}</Span>
        </div>
        <div>
          <Span weight="semi"> Contact Number: </Span>
          <Span>{data?.client?.contactNumber}</Span>
        </div>
      </GridContainer>
    </>
  );
};
