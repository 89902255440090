import { useGetCurrentRoleAccesses } from "@hooks";
import { Header, SideBar, MainLayout, ContainerLayout } from "@common";
import { DisbursementsPendingPage } from "@containers";

export const DisbursementsPending = () => {
  const module_name = "manage_disbursements";
  useGetCurrentRoleAccesses(module_name);
  return (
    <>
      <MainLayout>
        <SideBar />
        <Header />

        <ContainerLayout>
          <DisbursementsPendingPage />
        </ContainerLayout>
      </MainLayout>
    </>
  );
};
