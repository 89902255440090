import axios from "axios";
import { store } from "@store";
import { METHOD } from "@constants";
import {  getAuth } from "firebase/auth";
import {app} from "../Firebase"

const auth = getAuth(app);

axios.interceptors.request.use(
  async (config) => {

    // const accesses = store?.getState()?.UI?.currentRoleAccesses;
    // const module = METHOD[config.method];
    // if(accesses){
    //   const isUserHaveAccess = accesses[module];
    //   if(!isUserHaveAccess){
    //     store.dispatch.UI.setAlert({message: 'Access denied!', type:'Error'});
    //     // eslint-disable-next-line no-throw-literal
    //     throw {response:{data:{message: 'Access denied!'}}}
    //   }
    // }
    let accessToken = localStorage.getItem("accessToken") ?? "";
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      store.dispatch.User.updateState({ userToken: accessToken });
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => res,
  async(err) => {
    if (err.response?.status === 401) {
      const accessToken = await auth.currentUser.getIdToken();
      localStorage.setItem('accessToken', accessToken);
    }
    return Promise.reject(err);
  }
);

export const getUrl = (endpoint) => {
  const url =
    endpoint.indexOf("http://") === 0 || endpoint.indexOf("https://") === 0
      ? endpoint
      : process.env.REACT_APP_API_URL + endpoint;

  return url;
};

export const constructHeader = (token = store.getState().User.userToken) => {
  return token ? { Authorization: "Bearer " + token } : {};
};

const coreFunc = (verb, endpoint, config = {}, data = null) => {
  config.headers = {
    ...config.headers,
    ...constructHeader(),
  };

  if (data !== null) {
    return axios[verb](getUrl(endpoint), data, config);
  }

  return axios[verb](getUrl(endpoint), config);
};

export const patch = async (endpoint, data, headers = {}) =>
   coreFunc(
    "patch",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const post = async (endpoint, data, headers = {}) =>
   coreFunc(
    "post",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const put = async (endpoint, data, headers = {}) =>
   coreFunc(
    "put",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const postFormData = async (endpoint, data) =>
  coreFunc(
    "post",
    endpoint,
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } },
    data
  );

export const get = async (endpoint, headers = {}) =>
  coreFunc("get", endpoint, {
    headers: { "Content-Type": "application/json", ...headers },
  });

export const getBlob = async (endpoint, data, headers = {}) =>
  coreFunc(
    "post", 
    endpoint, 
    { responseType: "blob", headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
    );

export const postMultipart = async (endpoint, data) =>
  coreFunc(
    "post",
    endpoint,
    { headers: { "Content-Type": "multipart/form-data" } },
    data
  );

export const remove = async (endpoint, data, headers = {}) =>
  coreFunc(
    "delete",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data),
  );
