import { get, patch, put } from '@api';

export const getDisbursementsFilter = async (pageIndex?: any, pageSize?: any, status?: string, keyword: string = '') => {
    try {
        const res = await get(`/deliveries/disbursements?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}${keyword}`); 
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const getDisbursements = async (status?: string) => {
    try {
        const res = await get(`/deliveries/disbursements?status=${status}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const successDisburse = async (id: any) => {
    try {
        const res = await patch(`/deliveries/${id}/mark-disbursement-success`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const manualDisbursement = async (deliveryId: any) => {
    try {
        const res = await put(`/deliveries/${deliveryId}/manual-disbursement`);  
        return res;
    } catch (err) {
        throw err;
    }
}