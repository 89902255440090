/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { RootState, Dispatch } from "@store";

import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Drawer, Tabs, Button, message } from "antd";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { BiPlusCircle } from "react-icons/bi";
 
import { COLUMNS } from "./column";

import { NoData, Container, Span } from "@common";

import { useQuery } from "@utils";

import {
  SearchInputDM,
  Loader,
  ViewDriver,
  AddDriver,
  EditDriver,
  Table,
} from "@components";

let LOADER_TIMER: any;

export const PendingDriversPage = () => {
  const query = useQuery();
  const tab: any = query.get("tab");
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const editDriverId: any = useSelector(
    (state: RootState) => state.UI.forms.editDriver?.id
  );
  const viewDriverId: any = useSelector(
    (state: RootState) => state.UI.forms.viewDriver?.id
  );
  const { filters } = useSelector(
    (state: RootState) => state.Table.tables.pendingDrivers
  );
  const data: any[] = useSelector(
    (state: RootState) => state.Table.tables.pendingDrivers.data
  ).reverse();
  const { isLoading } = useSelector((state: RootState) => state.Table);
  
  const maxSearchInputCount = 4;
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  
  const [searchData, setSearchData] = useState([
    {
      filter: "driverStatus",
      value: "all",
    },
  ]);

  const [activeTab, setActiveTab] = useState(tab);
  const [addDriver, setAddDriver] = useState(false);
  const [isWithORCR, setIsWithORCR] = useState(
    tab === "withORCR" ? true : false
  );
  const [firstView, setFirstView] = useState(true);

  const showDrawer = () => {
    setAddDriver(true);
  };
  const { TabPane } = Tabs;

  const searchCompleted = () => {
    const isWithORCR = tab === "withORCR" ? 1 : 0;
    dispatch.Table.getDriversData({ table: "pendingDrivers", type: "pending", isWithORCR });
  };

  const setFilters = () => {
    let stringKeyword = "";
    searchData
      ?.filter((s) => s.value !== "")
      ?.forEach((search) => {
        stringKeyword = `&${search.filter}=${search.value}&approvalStatus=pending`;
 
        if (search.filter === "driverStatus") {
          if (search.value === "all") {
            stringKeyword = "&driverStatus=active&driverStatus=inactive&approvalStatus=pending";
          } else {
            stringKeyword = `&driverStatus=${search.value}&approvalStatus=pending`;
          }
        }
        
      });
    dispatch.Table.updateKeyword({ table: "pendingDrivers", value: stringKeyword });
    searchCompleted();
  };

  const getData = () => {
    dispatch.Table.updateKeyword({
      table: "pendingDrivers",
      value: "&approvalStatus=pending",
    });

    dispatch.Table.getDriversData({
      table: "pendingDrivers",
      type: "pending",
      isWithORCR,
      firstView,
    });
  };

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    if (activeTab === "withORCR") {
      history.push("/driver-management/pending-drivers?tab=withORCR");
      setIsWithORCR(true);
    }

    if (activeTab === "withoutORCR") {
      history.push("/driver-management/pending-drivers?tab=withoutORCR");
      setIsWithORCR(false);
      setFirstView(false);
    }
  }, [activeTab]);

  useEffect(() => {
    getData();

    return () => {
      dispatch.Table.updateState({ isLoading: true });
      clearTimeout(LOADER_TIMER);
    };
  }, [filters, isWithORCR]);

  return (
    <div className="relative">
      <Loader isLoading={isLoading} />
      <Container border="grey" padding="none">
        <div className="p-4">
          <div className="flex justify-between">
            <Span margin="mt-4" weight="bold" size="2xl">
              Pending Drivers
            </Span>
            <div className="flex justify-end px-4 my-4">
              <Button onClick={showDrawer} size="large">
                <div className="flex pr-2 items-center">
                  <BiPlusCircle className="my-auto mr-2 text-2xl items-center inline-flex" />
                  Add Driver
                </div>
              </Button> 
            </div>
          </div>
        </div>

        <div className="px-4">
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          >
            <TabPane tab="With OR-CR" key="withORCR" />
            <TabPane tab="Without OR-CR" key="withoutORCR" />
          </Tabs>
        </div>

        <div className="p-4 w-full flex flex-row items-center">
          {searchData?.map((search, index) => (
            <SearchInputDM
              searchData={searchData}
              setSearchData={setSearchData}
              index={index}
              search={search}
            />
          ))}

          {!matchDownSm && (
            <Button
              type="primary"
              size="large"
              icon={
                <SearchOutlined
                  style={{ display: "inline-flex", alignItems: "center" }}
                />
              }
              onClick={setFilters}
            >
              Search
            </Button>
          )}

          {maxSearchInputCount != searchData?.length ? (
            <div className="px-2">
              <Button
                type="primary"
                size="large"
                className="px-2"
                icon={
                  <PlusOutlined
                    style={{ display: "inline-flex", alignItems: "center" }}
                  />
                }
                onClick={() => {
                  if (maxSearchInputCount === searchData?.length)
                    return message.error("Already reach search limit!");
                    setSearchData([
                    ...searchData,
                    { filter: "pleaseSelect", value: "" },
                  ]);
                }}
              >
                Add Search
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="overflow-auto">
          {data?.length < 0 ? (
            <NoData />
          ) : (
            <Table columns={columns} data={data} dataType="pending-drivers" />
          )}
        </div>
      </Container>

      <EditDriver
        driverId={editDriverId}
        getData={getData}
        table="pendingDrivers"
      />
      <AddDriver
        setAddDriver={setAddDriver}
        addDriver={addDriver}
        getData={getData}
      />

      {/* DRIVER VIEW */}
      <Drawer
        title="Driver info"
        placement="right"
        width={500}
        onClose={() => {
          dispatch.UI.updateFormId({
            target: "viewDriver",
            value: null,
          });
        }}
        visible={viewDriverId}
      >
        <ViewDriver setViewDriver={viewDriverId} />
      </Drawer>
    </div>
  );
};
