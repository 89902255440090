/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { Dispatch } from "../../store";
import { useDispatch } from "react-redux";

import { Form, Input, Modal } from "antd";
import { linkDeliveries, syncOrderToFireStore } from "@api";

type Props = {
  setLinkOrderModal: Function;
  linkOrderModal: boolean;
  id: string;
};

export const LinkOrderModal = ({
  setLinkOrderModal,
  linkOrderModal,
  id,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form] = Form.useForm();
  const [orderCode, setOrderCode] = useState("");

  const handleLinkDeliveries = async () => {
    try {
      setLinkOrderModal(false);
      dispatch.UI.setIsLoading(true);
      const res = await linkDeliveries({ orderCode: orderCode, orderId: id });
      if ([201, 200].includes(res.status)) {
        dispatch.UI.setIsLoading(false);
        dispatch.UI.setAlert({
          message: `Order successfully linked!`,
          type: "Success",
        });
        await syncOrderToFireStore(orderCode);
      }
    } catch (err: any) {
      dispatch.UI.setIsLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to link order!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  return (
    <>
      <Modal
        title="Link Delivery Order"
        visible={linkOrderModal}
        onOk={form.submit}
        okText="Submit"
        onCancel={() => setLinkOrderModal(false)}
      >
        <Form
          onFinish={handleLinkDeliveries}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          form={form}
        >
          <Form.Item
            name="orderId"
            label="Order Code"
            rules={[{ required: true }]}
          >
            <Input
              name="reason"
              onChange={(e) => setOrderCode(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
