/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { RootState, Dispatch } from "@store";

import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { CheckSquareFilled } from "@ant-design/icons";
import { Collapse } from "@mui/material";
import { syncOrderToFireStore } from "@api";

import {
  CHANGE_TO_RENTAL,
  DONT_SHOW_CHANGE_TO,
  FAILED_ORDERS_STATUS,
  RENTALSTATUS,
  VEHICLE,
} from "@constants";
import {
  GridContainer,
  SubContainer,
  Span,
  SpanContainer,
  Button,
} from "@common";

import ScheduleDetailsCompleted from "./Dropdown/ScheduleDetailsCompleted";
import ScheduleDetails from "./Dropdown/ScheduleDetails";
import PaymentDetails from "./Dropdown/PaymentDetails";
import Pickup from "./Dropdown/Pickup";
import { formatDate } from "@utils";

import "./Rental.css";

import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdOutlinePayments } from "react-icons/md";
import { IoRefresh } from "react-icons/io5";

import {
  UpdateStatusRental,
  CancelRental,
  AssignedDriverRental,
  TransactionHistoryRental,
} from "@components";

import { useIsWindowActive } from "@hooks";

let UNSUBSCRIBE_RENTAL: any;
let UNSUBSCRIBE_RENTAL_ITEMS: any;

export const RentalView = ({ id }: any) => {
  const isWindowActive = useIsWindowActive();
  const params: any = useParams();
  const tab = params?.tab;
  const history = useHistory();
  const pathName = useLocation().pathname;

  const dispatch = useDispatch<Dispatch>();
  const prevRoute = useSelector((state: RootState) => state.UI.currentRoute);
  const completedData = useSelector(
    (state: RootState) => state.Table.tables.rentalOrdersCompleted.data
  );

  const [payment, setPayment] = useState(true);
  const [assignedDriverModal, setAssignedDriverModal] = useState(false);
  const [transactionHistoryModal, setTransactionHistoryModal] = useState(false);

  const handleAssignedDriver = (e) => {
    e.preventDefault();
    setAssignedDriverModal(true);
  };

  const handleTransactionHistory = (e) => {
    e.preventDefault();
    setTransactionHistoryModal(true);
  };

  const togglePayment = (e: any) => {
    e.preventDefault();
    setPayment(!payment);
  };

  const [pickUp, setPickUp] = useState(true);
  const togglePickUp = (e: any) => {
    e.preventDefault();
    setPickUp(!pickUp);
  };

  const [schedule, setSchedule] = useState(true);
  const toggleSchedule = (e: any) => {
    e.preventDefault();
    setSchedule(!schedule);
  };

  let [data, setData] = useState({} as any);
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [rentalId, setRentalId] = useState<any>(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [rentalItems, setRentalItems] = useState([]) as any;

  const { status, orderCode, driverId } = data !== undefined && data;

  const db = getFirestore();
  const getData = async () => {
    UNSUBSCRIBE_RENTAL = onSnapshot(doc(db, "rental", id), (doc) => {
      if (doc.exists()) {
        setData(doc.data());
      } else {
        console.log("No such document!");
      }
    });
  };

  const refresh = () => {
    dispatch.UI.setIsLoading(true);
    if (tab === "openOrders" || tab === "openOrderItem") {
      getData();
    } else {
      getData2();
    }
    setTimeout(() => {
      dispatch.UI.setIsLoading(false);
    }, 500);
  };

  const getData2 = () => {
    dispatch.UI.setIsLoading(true);
    const selectedOrder = (completedData || []).filter(
      (item) => item?.fireStoreId === id
    )[0];
    setData(selectedOrder);
    setTimeout(() => {
      dispatch.UI.setIsLoading(false);
    }, 500);
  };

  const getRentalItems = () => {
    const q = query(
      collection(db, "rental_items"),
      where("referenceNumber", "==", id),
      where("status", "==", "pending")
    );

    UNSUBSCRIBE_RENTAL_ITEMS = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || [])?.map((ref) => {
        const data = ref.data();
        return data;
      });
      setRentalItems(
        listData.sort((a, b): any => a.scheduleStartTime - b.scheduleStartTime)
      );
    });
  };

  const handleResync = async () => {
    try {
      dispatch.UI.setIsLoading(true);
      const ordercode = data?.orderCode ?? orderCode;
      const res = await syncOrderToFireStore(ordercode);
      if ([200, 201].includes(res?.status)) {
        dispatch.UI.setIsLoading(false);
        dispatch.UI.setAlert({
          message: "Order successfully resync",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setIsLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to resync order!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  useEffect(() => {
    if (driverId?.length > 0) {
      dispatch.Table.getDriverById({ driverIds: driverId });
    }

    if (isWindowActive) {
      if (id) {
        getRentalItems();
      }
    } else {
      if (UNSUBSCRIBE_RENTAL_ITEMS) UNSUBSCRIBE_RENTAL_ITEMS();
    }

    return () => {
      if (UNSUBSCRIBE_RENTAL_ITEMS) UNSUBSCRIBE_RENTAL_ITEMS();
    };
  }, [data, isWindowActive]);

  useEffect(() => {
    if (isWindowActive) {
      if (tab === "openOrders" || tab === "openOrderItem") {
        getData();
        dispatch.Table.updateState({ driverInfo: [] });
      } else {
        getData2();
        dispatch.Table.updateState({ driverInfo: [] });
      }
    } else {
      if (UNSUBSCRIBE_RENTAL) UNSUBSCRIBE_RENTAL();
    }

    return () => {
      if (UNSUBSCRIBE_RENTAL) UNSUBSCRIBE_RENTAL();
    };
  }, [pathName, isWindowActive]);

  return (
    <>
      <div className="bg-white rounded border-gray p-6">
        <GridContainer grid="four" padding="none">
          <div>
            <div className="bg-brown-1 p-8 text-center rounded-md my-4">
              <Span weight="semi" size="2xl" color="white">
                ₱ &nbsp;
                {parseFloat(data.originTotal ?? 0)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Span>
              <Span color="white">Total Amount</Span>
            </div>

            <div className="mb-2">
              <SpanContainer padding="pr-2">Status:</SpanContainer>
              <SpanContainer color="brown" weight="bold">
                {RENTALSTATUS[status]}
              </SpanContainer>
            </div>

            {!DONT_SHOW_CHANGE_TO.includes(status) ? (
              <></>
            ) : (
              FAILED_ORDERS_STATUS.includes(status) && (
                <>
                  {data?.cancelReason ? (
                    <div className="mb-2">
                      <Span padding="pr-2">Cancel reason:</Span>
                      <Span weight="semi">{data?.cancelReason}</Span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {data?.cancelledAt ? (
                    <div className="mb-2">
                      <Span padding="pr-2">Cancel at:</Span>
                      <Span weight="semi">{formatDate(data?.cancelledAt)}</Span>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )
            )}

            <SubContainer border="grey" padding="p-2">
              <Span weight="semi" color="brown" transform="uppercase">
                Customer Details
              </Span>

              <Span margin="my-2">Order Number:</Span>
              <span className="text-sm font-semibold p-1 border-[1px] border-[#b7eb8f] bg-[#f6ffed]">
                {orderCode}
              </span>

              <Span margin="mt-2">Contact Name:</Span>
              <Span weight="semi" transform="capitalize">
                {data?.client?.name ?? ""}
              </Span>

              <Span margin="mt-2">Contact No:</Span>
              <Span weight="semi" transform="capitalize">
                {data?.client?.contactNumber}
              </Span>

              <Span margin="mt-2">Order Placed:</Span>
              <Span weight="semi" transform="uppercase">
                {formatDate(data?.createdAt)}
              </Span>

              <Span margin="mt-2">Payment Method:</Span>
              <Span weight="semi" transform="capitalize">
                {data.paymentMethod}
              </Span>
            </SubContainer>

            <SubContainer border="grey" padding="p-2">
              <Span weight="semi" color="brown" transform="uppercase">
                Schedule Details
              </Span>

              <Span margin="mt-2">Schedule Type:</Span>
              <Span weight="semi" transform="capitalize">
                {data.scheduleType}
              </Span>

              <Span margin="mt-2">Order Type:</Span>
              <Span weight="semi" transform="capitalize">
                {data.orderType}
              </Span>
            </SubContainer>

            <SubContainer border="grey" padding="p-2">
              <Span weight="semi" color="brown" transform="uppercase">
                REMARKS
              </Span>

              <Span margin="mt-2">Items:</Span>
              <Span weight="semi" transform="capitalize">
                {!data.itemDescription ? "No Data" : data.itemDescription}
              </Span>

              <Span margin="mt-2">Notes to Driver:</Span>
              <Span weight="semi" transform="capitalize">
                {!data.notes ? "No Data" : data.notes}
              </Span>
            </SubContainer>
          </div>

          <div className="col-span-3">
            <div className="flex justify-between items-center p-2 mt-4">
              <Span weight="semi" size="lg">
                Delivery Details
              </Span>
              <div className="flex flex-row justify-center items-center">
                <div
                  className="p-1 border-2 border-primary-main opacity-70 cursor-pointer"
                  onClick={refresh}
                >
                  <IoRefresh color="black" />
                </div>
                <Button
                  padding="px-2"
                  radius="sm"
                  margin="mx-2"
                  onClick={handleResync}
                >
                  Sync Rental to Database
                </Button>
                <Button
                  padding="px-2"
                  radius="sm"
                  onClick={() => history.push(`${prevRoute}?tab=${tab}`)}
                >
                  Back to parcel
                </Button>
              </div>
            </div>

            <div className="py-2 px-2 border-[1px] border-grey-1 rounded-sm">
              <GridContainer padding="none">
                <div className="flex justify-center">
                  <Span margin="mx-2" weight="semi">
                    Vehicle Used
                  </Span>
                  <Span transform="uppercase">
                    {VEHICLE[data?.vehicleType]}
                  </Span>
                </div>

                <div className="flex justify-center h-full border-gray-300 border-l-[1px]">
                  <Span margin="mx-2" weight="semi">
                    {" "}
                    Order Source{" "}
                  </Span>
                  <Span transform="uppercase">{data?.source}</Span>
                </div>

                <div className="flex justify-center h-full border-gray-300 border-l-[1px]">
                  <Span margin="mx-2" weight="semi">
                    {" "}
                    Device Used{" "}
                  </Span>
                  <Span transform="uppercase">{data?.device}</Span>
                </div>
              </GridContainer>
            </div>

            <SubContainer border="grey" padding="none">
              <div
                className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                onClick={togglePickUp}
              >
                <div className="flex my-auto">
                  <CheckSquareFilled
                    style={{
                      fontSize: "1.8rem",
                      color: "#84b15c",
                      marginRight: "0.8rem",
                    }}
                  />
                  <Span margin="my-auto">Pick-up Location</Span>
                </div>

                {pickUp ? (
                  <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                ) : (
                  <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                )}
              </div>

              <Collapse in={pickUp}>
                <Pickup pickUp={data?.pickup ?? data?.pickUp} data={data} />
              </Collapse>
            </SubContainer>

            <SubContainer border="grey" padding="none">
              <div
                className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                onClick={togglePayment}
              >
                <div className="flex my-auto">
                  <MdOutlinePayments
                    style={{
                      fontSize: "1.8rem",
                      color: "#7140B8",
                      marginRight: "0.8rem",
                    }}
                  />
                  <Span margin="my-auto">Payment Details</Span>
                </div>

                {payment ? (
                  <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                ) : (
                  <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                )}
              </div>

              <Collapse in={payment}>
                <div className="p-4">
                  <PaymentDetails
                    pickUp={data?.pickup ?? data?.pickUp}
                    paymentDetails={data?.paymentDetails}
                  />
                </div>
              </Collapse>
            </SubContainer>

            <SubContainer border="grey" padding="none">
              <div
                className="flex justify-between bg-grey-9 p-4 cursor-pointer border-b-[1px] border-gray-200"
                onClick={toggleSchedule}
              >
                <div className="flex my-auto">
                  <AiOutlineFieldTime
                    style={{
                      fontSize: "1.8rem",
                      color: "#FF4D4F",
                      marginRight: "0.8rem",
                    }}
                  />
                  <Span margin="my-auto">Schedule Details</Span>
                </div>

                {payment ? (
                  <IoIosArrowDown className="mx-4 my-auto text-2xl" />
                ) : (
                  <IoIosArrowForward className="mx-4 my-auto text-2xl" />
                )}
              </div>

              <Collapse in={schedule}>
                {tab === "openOrders" || tab === "openOrderItem" ? (
                  <ScheduleDetails
                    data={data}
                    setRentalId={setRentalId}
                    rentalId={rentalId}
                    setCancelModal={setCancelModal}
                  />
                ) : (
                  <ScheduleDetailsCompleted rentalItems={data?.rentalItems} />
                )}
              </Collapse>
            </SubContainer>

            <div className="flex justify-between items-center">
              {rentalItems?.length > 0 ? (
                <div className="flex flex-row">
                  <Button
                    bg="green"
                    padding="px-2"
                    radius="sm"
                    onClick={handleAssignedDriver}
                  >
                    Assign
                  </Button>
                </div>
              ) : (
                <div></div>
              )}

              <div>
                <Button
                  padding="px-2"
                  radius="sm"
                  onClick={handleTransactionHistory}
                >
                  Transaction History
                </Button>
              </div>
            </div>

            <AssignedDriverRental
              setAssignedDriverModal={setAssignedDriverModal}
              assignedDriverModal={assignedDriverModal}
              isAssignOrReAssign="Assign Driver"
              orderId={id}
              driverGroup={"rental_only"}
              orderData={data}
              rentalItems={rentalItems}
              setRentalItems={setRentalItems}
            />

            <CancelRental
              cancelModal={cancelModal}
              setCancelModal={setCancelModal}
              rentalId={rentalId}
            />

            <UpdateStatusRental
              status={CHANGE_TO_RENTAL[status]}
              orderId={id}
              updateStatusModal={updateStatusModal}
              setUpdateStatusModal={setUpdateStatusModal}
            />

            <TransactionHistoryRental
              setTransactionHistoryModal={setTransactionHistoryModal}
              transactionHistoryModal={transactionHistoryModal}
              rental={
                data?.rentalStatusHistories
                  ? data?.rentalStatusHistories
                  : data?.statusHistory
              }
            />
          </div>
        </GridContainer>
      </div>
    </>
  );
};
