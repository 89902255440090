import { createModel } from "@rematch/core"; 
import { RootModel } from ".";

const initialState = { 
  referenceSearch: null, 
} as any;

export const Attachment = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    updateState(state, newState) {
      return {
        ...state,
        ...newState,
      };
    }, 
  },
  effects: (dispatch) => ({}),
});
