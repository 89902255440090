import { get, patch, post, postMultipart, put, remove } from '@api';

export const getAllSearchPendingDrivers = async (pageIndex: any, pageSize: any, keyword: string = '',  isWithORCR: string) => {
    try {
        const res = await get(`/drivers?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}${isWithORCR}`);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export const rejectDriver = async (id: any, reasons: any) => {
    try {
        const res = await put(`/drivers/${id}/reject`, { reasons })
        return res;
    } catch (err) {
        throw err;
    }
}

export const approveDriver = async (id: any) => {
    try {
        const res = await put(`/drivers/${id}/approve`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const pendingDriver = async (id: any, message: any) => {
    try {
        const res = await put(`/drivers/${id}/pending`, { message });
        return res;
    } catch (err) {
        throw err;
    }
}

export const addNewDriver = async (data: any) => {
    try {
        const body = new FormData();
        body.append("profilePicture", data);

        const res = await post(`/drivers`, data);
        return res;
    } catch (err) {
        throw err;
    }
}

export const updateDriver = async (id: any, body: any) => {
    try {
        const res = await patch(`/drivers/${id}`, body);
        return res;
    } catch (err) {
        throw err;
    }
}

export const deleteVehicle = async (driverId: any, vehicleId: any) => {
    try {
        const res = await remove(`/drivers/${driverId}/vehicles/${vehicleId}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const deleteDoc = async (driverId: string, list: any) => {
    try { 
        const res = await post(`/drivers/${driverId}/delete-docs`, list);
        return res;
    } catch (err) {
        throw err;
    }
} 

export const updateVehicle = async (driverId: any, vehicleId: any, body: any) => {
    try {
        const res = await patch(`/drivers/${driverId}/vehicles/${vehicleId}`, body);
        return res;
    } catch (err) {
        throw err;
    }
}

export const addVehicle = async (driverId: any, body: any) => {
    try {
        const res = await post(`/drivers/${driverId}/vehicles`, body);
        return res;
    } catch (err) {
        throw err;
    }
}

export const addRestrictionCode = async (driverId: any, body: any) => {
    try {
        const res = await post(`/drivers/${driverId}/restriction-code`, body);
        return res;
    } catch (err) {
        throw err;
    }
}

export const deleteRestrictionCode = async (driverId: any, id: any) => {
    try {
        const res = await remove(`/drivers/${driverId}/restriction-code/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export const uploadProfile = async (driverId: string, payload: any) => {
    try{
        const body = new FormData();
        body.append("file", payload?.file);
        body.append("url", payload?.url);

        const res = await postMultipart(`/drivers/${driverId}/profile-image`, body);
        return res;
    }catch(err){
        throw err;
    }
}

export const validateVehicles = async(id: any, body: any) => {
    try{
        const res = await post(`/drivers/${id}/validate-vehicles`, body);
        return res;
    }catch(err){
        throw err;
    }
}

export const getMerchantList = async () => {
    try{     
        const res = await get(`/restaurants/list`);   
        return res.data;
    }catch(err){
        throw err;
    }
}

export const getPlaceDetails = async (placeId: string) => {
    try {
      let res = await get(`/maps/place-details/${placeId}`);
      return res.data;
    }catch(err){
        throw err;
    }
}

export const uploadDriverDocs = async (driverId: string, formData: FormData) => {
    try {
        const res = await postMultipart(`/drivers/${driverId}/upload-docs`, formData);
        return res;
    } catch (err) {
        throw err;
    }
}