/* eslint-disable import/no-anonymous-default-export */
import { GridContainer, Span } from "@common"; 

export default ({ store }: any) => {
  return (
    <>
      <GridContainer grid="four"> 
        <div>
          <Span weight="semi"> Name </Span>
          <Span transform="capitalize">{store?.name}</Span>
        </div>

        <div>
          <Span weight="semi"> Branch </Span>
          <Span transform="capitalize">{store?.branchName ?? 'No Data'}</Span>
        </div>

        <div>
          <Span weight="semi"> Category </Span>
          <Span transform="capitalize">{store?.storeCategories?.map(item => item.name).join(', ') || ''}</Span>
        </div>

        <div>
          <Span weight="semi"> Address </Span>
          <Span transform="capitalize">{store?.address}</Span>
        </div>
      </GridContainer>
    </>
  );
};
