import { useEffect, useState } from 'react'

export const useIsWindowActive = () => {

    const [tabHasFocus, setTabHasFocus] = useState(true);

    useEffect(() => {
        const handleFocus = () => {
            console.log('Tab has focus');
            setTabHasFocus(true);
        };

        const handleBlur = () => {
            console.log('Tab lost focus');
            setTabHasFocus(false);
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    });

    return tabHasFocus;

}