import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@store";
import { deleteCalendarEvents } from "@api";

import { Span, SpanContainer } from "@common";
import { Modal } from "antd";

type Props = {
  id: string;
  whatToDelete: string;
  getData: Function;
};

export const DeleteModal = ({ id, whatToDelete, getData, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const deleteCalendar: any = useSelector(
    (state: RootState) => state.UI.forms.deleteCalendar?.id
  );
  const eventToDelete = id ?? deleteCalendar;
  const { confirm } = Modal;

  const handleDelete = async () => {
    dispatch.UI.updateFormId({
      target: "deleteCalendar",
      value: null,
    });
    try {
      dispatch.UI.setIsLoading(true);
      const res = await deleteCalendarEvents(eventToDelete);
      if (res?.status === 200) {
        dispatch.UI.setIsLoading(false);
        dispatch.UI.setAlert({
          message: `Event successfully deleted!`,
          type: "Success",
        });
        getData();
      }
    } catch (err: any) {
      dispatch.UI.setIsLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to delete event!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  const showConfirm = () => {
    confirm({
      title: whatToDelete,
      content: (
        <Span>
          Are you sure you want to delete this&nbsp;
          <SpanContainer color="red">item</SpanContainer> ?
        </Span>
      ),
      onOk() {
        handleDelete();
      },
      onCancel() {
        dispatch.UI.updateFormId({
          target: "deleteCalendar",
          value: null,
        });
      },
      okType: "danger",
    });
  };

  return <>{deleteCalendar && showConfirm()}</>;
};
