/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { RootState, Dispatch } from '@store';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

export const AlertSnackBar = ({ open, setOpen }: any) => {

  const alert = useSelector((state: RootState) => state?.UI?.alert)
  const dispatch = useDispatch<Dispatch>();

  const alertType: any = () => {
    switch (alert.type) {
      case 'Error':
        return (
          <Alert severity="error" sx={{minWidth: 400}}>
            <AlertTitle>Error</AlertTitle>
            {alert?.message}
          </Alert>
        )
      case 'Info':
        return (
          <Alert severity="info" sx={{minWidth: 400}}>
            <AlertTitle>Info</AlertTitle>
            {alert?.message}
          </Alert>
        )
      case 'Success':
        return (
          <Alert severity="success" sx={{minWidth: 400}}>
            <AlertTitle>Success</AlertTitle>
            {alert?.message}
          </Alert>
        )
      case 'Warning':
        return (
          <Alert severity="warning" sx={{minWidth: 400}}>
            <AlertTitle>Warning</AlertTitle>
            {alert?.message}
          </Alert>
        )
      default:
        return ''
    }
  }

  const handleClose = () => {
    setOpen(false);
    dispatch.UI.resetAlert();
  }

  useEffect(() => {
    if (alert.message !== '') {
      setOpen(true);
      setTimeout(() => {
        handleClose();
      }, 5000);
    }
  }, [alert]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      ContentProps={{
        sx: {
          background: 'transparent',
          border: 'none',
          boxShadow: 'none'
        }
      }}
    >
      {alertType()}
    </Snackbar>
  )

  // return (
  //   <>
  //     {open ? (
  //       <div className={classnames(`flex w-2/4 p-4 mb-4 absolute top-2 right-0 left-0 m-auto z-50`,alertType()[0])} role='alert'>
  //           <BiError className={classnames('w-5 h-5 flex-shrink-0', alertType()[1])} fill='currentColor' />
  //           <div className={classnames('ml-3 text-sm font-semi-bold', alertType()[2])}>
  //             {alert.type}:&nbsp;{alert.message}
  //           </div>
  //           <button type="button" className={classnames("ml-auto -mx-1.5 -my-1.5  rounded-lg focus:ring-2  p-1.5  inline-flex h-8 w-8", alertType()[3])} data-collapse-toggle="alert-2" aria-label="Close">
  //             <span className="sr-only">Close</span>
  //             <RiCloseFill className='w-5 h-5' onClick={handleClose}/>
  //             </button>
  //       </div>
  //     ):(
  //       <>
  //       </>
  //     )}
  //   </>
  // )
};