import { useParams } from 'react-router-dom'

import { Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { RentalView } from '@components' 

export const RentalManagementView = () => {

  let params: any = useParams();

  return (
    <>
      <MainLayout>
        <SideBar />  
        <Header />
        
        <ContainerLayout>
          <RentalView id={params.id} />
        </ContainerLayout>
      </MainLayout> 
    </>
  )
}