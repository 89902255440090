import { Models } from "@rematch/core";
import { Table } from "./TableModel";
import { UI } from "./UIModel";
import { User } from "./UserModel";
import { Locations } from "./LocationModel";
import { Attachment } from "./AttachmentModel";
import { Driver } from "./DriverModel";
import { Shop } from "./ShopModel";
import { Express } from "./ExpressModel";

export interface RootModel extends Models<RootModel> {
  Table: typeof Table;
  UI: typeof UI;
  User: typeof User;
  Locations: typeof Locations;
  Attachment: typeof Attachment;
  Driver: typeof Driver;
  Shop: typeof Shop;
  Express: typeof Express;
}

export const models: RootModel = {
  Table,
  UI,
  User,
  Locations,
  Attachment,
  Driver,
  Shop,
  Express,
};
