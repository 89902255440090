/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { RootState, Dispatch } from "@store";

import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";

import { OPEN_ORDERS, COMPLETED_ORDERS, TAB } from "@constants";

import {
  SearchRental,
  SearchRentalCompleted,
  Loader,
  Table,
} from "@components";

import { Container, Span } from "@common";
import { COLUMNS } from "./column";
import { Tabs, Empty, Button, message } from "antd";
import { useIsWindowActive } from "@hooks";
import { useQuery } from "@utils";

import { useMediaQuery, useTheme } from "@mui/material";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { COLUMNS_RENTALITEM } from "./columnRentalItems";

let UNSUBSCRIBE_RENTAL_ITEMS: any;

export const RentalPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const columns = useMemo(() => COLUMNS, []);
  const columnsRentalItems = useMemo(() => COLUMNS_RENTALITEM, []);

  const { isLoading } = useSelector((state: RootState) => state.Table);
  const { original_data: mainData, data }: any = useSelector(
    (state: RootState) => state.Table.tables.rental
  );
  const completedData: any[] = useSelector(
    (state: RootState) => state.Table.tables.rentalOrdersCompleted?.data
  ).reverse();
  const filters: any = useSelector(
    (state: RootState) => state.Table.tables.rentalOrdersCompleted?.filters
  );

  const history = useHistory();
  const queries = useQuery();
  const isWindowActive = useIsWindowActive();
  const tab: any = queries.get("tab");
  const [status, setStatus] = useState(TAB[tab]) as any[];
  const [activeTab, setActiveTab] = useState(tab);
  const [isData, setIsData] = useState(false);
  const { TabPane } = Tabs;
  const [rentalItems, setRentalItems] = useState([]) as any;
  var RECONNECT_TIMER: any;
  const maxSearchInputCount = tab === "openOrders" ? 4 : 3;

  // Search
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const [searchData, setSearchData] = useState([
    {
      filter: "status",
      value: "all",
    },
  ]);

  const searchCompleted = () => {
    dispatch.Table.getCompletedRental({
      table: "rentalOrdersCompleted",
      type: "rental",
    });
  };
  const setFilters = () => {
    if (activeTab === "openOrders") {
      setIsData(true);
      let result: any[] = mainData;
      searchData
        ?.filter((s) => s?.value !== "")
        ?.map((search) => {
          switch (search.filter) {
            case "status":
              result = result?.filter((rental) => {
                if (
                  rental?.status.search(
                    new RegExp(search.value === "all" ? "" : search.value, "i")
                  ) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "orderCode":
              result = result?.filter((rental) => {
                if (
                  rental?.orderCode?.search(new RegExp(search.value, "i")) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "name":
              result = result?.filter((rental) => {
                if (
                  rental?.client?.name?.search(new RegExp(search.value, "i")) >
                  -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "contactNumber":
              result = result?.filter((rental) => {
                if (
                  rental?.client?.contactNumber?.search(
                    new RegExp(search.value, "i")
                  ) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            case "location":
              result = result?.filter((rental) => {
                if (
                  rental?.client?.locationDetails.search(
                    new RegExp(search.value, "i")
                  ) > -1
                ) {
                  return true;
                }
                return false;
              });
              break;
            default:
              result = [];
              break;
          }
        });
      dispatch.Table.setTableData({ table: "rental", data: result });
    } else {
      let stringKeyword = "";
      searchData
        ?.filter((s) => s.value !== "")
        ?.forEach((search) => {
          const string =
            search?.value === "all"
              ? "&status[]=completed&status[]=canceled"
              : `&${
                  search?.filter === "orderCode"
                    ? "orderNumber"
                    : search?.filter
                }=${search?.value}`;
          stringKeyword = stringKeyword + string;
        });
      setIsFirstSearch(false);
      dispatch.Table.updateKeyword({
        table: "rentalOrdersCompleted",
        value: stringKeyword,
      });
      searchCompleted();
    }
  };

  const db = getFirestore();
  const getRentalItems = () => {
    const q = query(
      collection(db, "rental_items"),
      where("status", "==", "pending")
    );

    UNSUBSCRIBE_RENTAL_ITEMS = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || [])?.map((ref) => {
        const data = ref.data();
        return data;
      });
      setRentalItems(
        listData.sort((a, b): any => a.scheduleStartTime - b.scheduleStartTime)
      );
    });
  };

  useEffect(() => {
    if (activeTab === "completedOrders") {
      if (isFirstSearch) {
        dispatch.Table.updateState({ isLoading: false });
      } else {
        searchCompleted();
      }
    }
  }, [filters]);

  useEffect(() => {
    if (activeTab === "openOrders") {
      setIsFirstSearch(true);
      setStatus(OPEN_ORDERS);
      setSearchData([{ filter: "status", value: "all" }]);
      history.push("/rental-management?tab=openOrders");
    }

    if (activeTab === "openOrderItem") {
      history.push("/rental-management?tab=openOrderItem");
      getRentalItems();
    } else {
      if (UNSUBSCRIBE_RENTAL_ITEMS) UNSUBSCRIBE_RENTAL_ITEMS();
    }

    if (activeTab === "completedOrders") {
      setStatus(COMPLETED_ORDERS);
      setSearchData([{ filter: "status", value: "all" }]);
      history.push("/rental-management?tab=completedOrders");
    }
  }, [activeTab]);

  useEffect(() => {
    // closeLoader();
  }, [completedData, data]);

  useEffect(() => {
    if (searchData?.length !== 1 || searchData[0].value !== "all") {
      setFilters();
    }
  }, [mainData]);

  useEffect(() => {
    if (data.length > 0 || tab === "completedOrders")
      dispatch.Table.updateState({ isLoading: false });
    if (isWindowActive) {
      if (tab === "openOrders") {
        RECONNECT_TIMER = setTimeout(() => {
          dispatch.Table.getOpenOrders({
            name: "rental",
            table: "rental",
            tab,
          });
        }, 1500);
      }
    } else {
      if (tab === "openOrders") {
        dispatch.Table.unsubscribed();
        clearTimeout(RECONNECT_TIMER);
      }
    }

    return () => {
      dispatch.Table.updateState({ isLoading: true });
      dispatch.Table.unsubscribed();
    };
  }, [isWindowActive, tab]);

  return (
    <div className="relative">
      <Loader isLoading={isLoading} />
      <Container border="grey" padding="none">
        <div className="p-4">
          <Span margin="mt-4" weight="bold" size="2xl">
            All Rental Orders
          </Span>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          >
            <TabPane tab="Open Orders" key="openOrders" />
            <TabPane tab="Open Order Items" key="openOrderItem" />
            <TabPane tab="Completed Orders" key="completedOrders" />
          </Tabs>
        </div>

        {tab != "openOrderItem" && (
          <div className="p-4 w-full flex flex-row items-center">
            {tab === "openOrders" ? (
              <>
                {searchData?.map((search, index) => (
                  <SearchRental
                    search={search}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    setFilters={setFilters}
                    tab={tab}
                    index={index}
                  />
                ))}
              </>
            ) : (
              <>
                {searchData?.map((search, index) => (
                  <SearchRentalCompleted
                    search={search}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    setFilters={setFilters}
                    tab={tab}
                    index={index}
                  />
                ))}
              </>
            )}

            {!matchDownSm && (
              <>
                <Button
                  type="primary"
                  size="large"
                  icon={
                    <SearchOutlined
                      style={{ display: "inline-flex", alignItems: "center" }}
                    />
                  }
                  onClick={setFilters}
                >
                  Search
                </Button>

                <div className="px-2">
                  <Button
                    type="primary"
                    size="large"
                    className="px-2"
                    icon={
                      <PlusOutlined
                        style={{ display: "inline-flex", alignItems: "center" }}
                      />
                    }
                    onClick={() => {
                      if (maxSearchInputCount === searchData?.length)
                        return message.error("Already reach search limit!");
                      setSearchData([
                        ...searchData,
                        { filter: "pleaseSelect", value: "" },
                      ]);
                    }}
                  >
                    Add Search
                  </Button>
                </div>
              </>
            )}
          </div>
        )}

        <div className="overflow-auto ">
          {data?.length < 0 ? (
            <Empty />
          ) : tab === "openOrderItem" ? (
            <Table
              columns={columnsRentalItems}
              data={rentalItems}
              dataType="rental-management"
              sortName="craetedAt"
              descSort={true}
            />
          ) : (
            <Table
              columns={columns}
              data={tab === "openOrders" ? data : completedData}
              dataType="rental-management"
              sortName="status"
              descSort={true}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
