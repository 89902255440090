/* eslint-disable jsx-a11y/alt-text */
import "../../containers/DriverManagement/AllDrivers/Driver.css";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

import edit_icon from "../../assets/images/edit_icon.png";
import delete_icon from "../../assets/images/reject_icon.png";
import { Stack } from "@mui/material";

const StatusEdit = ({ id }: any) => {
  const dispatch = useDispatch<Dispatch>();

  const handleEdit = () => {
    dispatch.UI.updateFormId({
      target: "editCalendar",
      value: id,
    });
  };

  const handleDelete = () => {
    dispatch.UI.updateFormId({
      target: "deleteCalendar",
      value: id,
    });
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <div
        className="flex transition-all hover:scale-105 p-2"
        onClick={handleEdit}
      >
        <img src={edit_icon} placeholder={edit_icon} className="status_icon" />
        <span>Edit</span>
      </div>

      <div
        className="flex transition-all hover:scale-105 p-2"
        onClick={handleDelete}
      >
        <img
          src={delete_icon}
          placeholder={delete_icon}
          className="status_icon"
        />
        <span>Delete</span>
      </div>
    </Stack>
  );
};

export default StatusEdit;
