import { createModel } from "@rematch/core"; 
import { RootModel } from ".";

const initialState = { 
  orderCodeExpress: null, 
} as any;

export const Express = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    updateState(state, newState) {
      return {
        ...state,
        ...newState,
      };
    }, 
  }
});
