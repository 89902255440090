import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { Dispatch, RootState } from "@store"; 
 
import { AlertSnackBar, BackDrop, ListenForOutsideClicks, ChangePasswordModal} from '@components';

import { AiOutlineKey, AiOutlineLogout } from 'react-icons/ai'
import { Avatar, Zoom } from '@mui/material';
import { getUrl } from '@utils';
import { Button } from '@common'

export const Header = () => { 
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch<Dispatch>(); 

    const user: any = useSelector((state: RootState) => state.User?.userInfo) as any;

    const [changePassword, setChangePassword] = useState(false)

    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    const [isOpen, setIsOpen] = useState(false); 

    useEffect(ListenForOutsideClicks(listening, setListening, menuRef, setIsOpen));

    const handleChangePassword = async () => {
        const url = await getUrl();
        const body = {
            email: user.email,
            url: `${url}changepassword`,
            userId: user.userId,
            userType: 'useradmin-resetpassword'
        }
        try {
            dispatch.UI.setIsLoading(true);
            const res = { isSendEmail: true }//await sendAdminChangePasswordEmail(body)
            if (res.isSendEmail) {
                setChangePassword(true);
                dispatch.UI.setIsLoading(false);
            }
        } catch (err: any) {
            const errMsg = err.data.error === undefined ? 'Unable to change password' : err.data.error;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
            dispatch.UI.setIsLoading(false);
        }
    } 

    return (
        <header>
            <BackDrop />
            <AlertSnackBar open={open} setOpen={setOpen} /> 

            <div className='flex' >
                <span className='mx-3 text-white capitalize'>{user?.lastName ?? ''}, {user?.firstName ?? ''}</span>

                <div ref={menuRef}>
                    <Avatar sx={{ backgroundColor: '#1890ff', width: 26, height: 26, cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)}>
                        <span className='text-base capitalize'>{(user?.firstName).substring(0, 1)}</span>
                    </Avatar>
                </div>
            </div>

            {changePassword &&
                <ChangePasswordModal setChangePassword={setChangePassword} />
            }

            <Zoom in={isOpen}>
                <div className='header_popup transition-all'>
                    <div className='flex text-left hover:scale-95 transition-all'>
                        <AiOutlineKey className='my-auto mx-2 text-xl' />
                        <Button
                            bg='white' color='black' border='none' weight='semi'
                            onClick={handleChangePassword}
                        >
                            Change Password
                        </Button>
                    </div>

                    <div className='flex text-left hover:scale-95 transition-all'>
                        <AiOutlineLogout className='my-auto mx-2 text-xl' />
                        <Button
                            onClick={() => {
                                dispatch.User.logout(true);
                            }}
                            bg='white' color='black' border='none' weight='semi'
                            align='left'>
                            Logout
                        </Button>
                    </div>
                </div>
            </Zoom>
        </header>
    )
}

