/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Span } from "@common";

import { Dispatch } from "@store";

import { Checkbox, Form, Modal, Select, Tag, Input } from "antd";
import {
  assignDriver,
  getActiveDrivers,
  reAssignDriver,
  syncOrderToFireStore,
} from "@api";
import { onlyNumberKey } from "@utils";
import { debounce } from "lodash";

type Props = {
  setAssignedDriverModal: Function;
  assignedDriverModal: boolean;
  deliveryIds: string;
  isAssignOrReAssign: string;
  vehicleType: string;
  driverGroup: string;
};

export const AssignedDriverModal = ({
  setAssignedDriverModal,
  assignedDriverModal,
  deliveryIds,
  isAssignOrReAssign,
  vehicleType,
  driverGroup,
  ...props
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  const pathName = useLocation().pathname;

  const [isLoading, setIsloading] = useState(false);
  const [drivers, setDrivers] = useState([]) as any;
  const [use3rdDriver, setUse3rdDriver] = useState(false);
  const [data, setData] = useState({
    pageIndex: 1,
    pageSize: 20,
  });

  const { Option } = Select;

  const [payload, setPayload] = useState({
    contactName: "",
    contactNumber: "",
    plateNumber: "",
    driverId: "",
    reason: "",
  });

  const onChange = (value: any) => {
    const {
      driverId,
      phoneNumber: contactNumber,
      lastName,
      firstName,
      middleName,
    } = JSON.parse(value);
    setPayload({ ...payload, driverId, contactNumber });
    form.setFieldsValue({ driver: `${lastName} ${firstName} ${middleName}` });
  };

  const onSearch = debounce(async (value: string) => {
    const driverName = value;
    try {
      setIsloading(true);
      if (value !== "") {
        const res = await getActiveDrivers(
          1,
          data?.pageSize,
          vehicleType,
          driverGroup,
          driverName
        );
        setDrivers([...res.results]);
      } else {
        const res = await getActiveDrivers(
          1,
          data?.pageSize,
          vehicleType,
          driverGroup
        );
        setDrivers([...res.results]);
        setData({ ...data, pageIndex: 1 });
      }
      setIsloading(false);
    } catch (err) {
      console.log(err);
    }
  }, 500);

  const getParcelDrivers = async () => {
    try {
      setIsloading(true);
      const res = await getActiveDrivers(
        data?.pageIndex,
        data?.pageSize,
        vehicleType,
        driverGroup
      );
      setDrivers([...drivers, ...res.results]);
      setIsloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onScroll = (event) => {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setData({ ...data, pageIndex: data.pageIndex + 1 });
    }
  };

  const handleAssigned = async () => {
    setAssignedDriverModal(false);
    let res;
    let body;
    if (use3rdDriver) {
      body = {
        contactName: payload.contactName,
        contactNumber: payload.contactNumber,
        plateNumber: payload.plateNumber,
        reason: payload.reason,
      };
    } else {
      body = {
        contactNumber: payload.contactNumber,
        driverId: payload.driverId,
        reason: payload.reason,
      };
    }
    try {
      dispatch.UI.setIsLoading(true);
      if (isAssignOrReAssign === "Assign Driver") {
        res = await assignDriver(deliveryIds, body);
        if ([200, 201].includes(res.status)) {
          dispatch.UI.setIsLoading(false);
          dispatch.UI.setAlert({
            message: `Order Number Assigned`,
            type: "Success",
          });
        }
      } else {
        res = await reAssignDriver(deliveryIds, body);
        if ([200, 201].includes(res.status)) {
          dispatch.UI.setIsLoading(false);
          const { deliveryId, orderCode } = res?.data;
          await syncOrderToFireStore(orderCode);
          history.push(pathName.replace(deliveryIds, deliveryId));

          dispatch.UI.setAlert({
            message: "Driver Re-assign",
            type: "Success",
          });
          window.location.reload();
        }
      }
    } catch (err: any) {
      dispatch.UI.setIsLoading(false);
      let errMsg = err?.response?.data?.message ?? `Unable to assign driver!`;
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  useEffect(() => {
    if (data?.pageIndex > 1) {
      getParcelDrivers();
    }
  }, [data?.pageIndex]);

  return (
    <>
      <Modal
        title={isAssignOrReAssign}
        visible={assignedDriverModal}
        onOk={form.submit}
        okText={isAssignOrReAssign === "Assign Driver" ? "Assign" : "Re-assign"}
        onCancel={() => setAssignedDriverModal(false)}
      >
        <div className="flex flex-col">
          <div className="flex flex-row justify-start items-center">
            <Span>Use 3rd Provider Driver</Span>
            <Checkbox
              onChange={(e: any) => setUse3rdDriver(e.target.checked)}
              style={{ marginLeft: "0.5rem" }}
            />
          </div>

          {use3rdDriver ? (
            <>
              <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                style={{ width: "100%", marginTop: "0.5rem" }}
                onFinish={() => {
                  form.resetFields();
                  setUse3rdDriver(false);
                  handleAssigned();
                }}
              >
                <Form.Item
                  name="Name"
                  label="Driver Contact Name"
                  rules={[
                    { required: true, message: "Please Input Driver Name!" },
                  ]}
                >
                  <Input
                    placeholder="Driver Contact Name"
                    name="Name"
                    onChange={(e) =>
                      setPayload({ ...payload, contactName: e.target.value })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="Phone Number"
                  label="Driver Contact Number"
                  rules={[
                    { required: true, message: "Please Input Driver Contact!" },
                    { min: 10 },
                    { max: 10 },
                  ]}
                >
                  <Input
                    addonBefore="+63"
                    maxLength={10}
                    onKeyPress={onlyNumberKey}
                    placeholder="Driver Contact Number"
                    name="Phone Number"
                    onChange={(e) =>
                      setPayload({ ...payload, contactNumber: e.target.value })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="Plate Number"
                  label="Driver Plate Number"
                  rules={[
                    { required: false, message: "Please Input Driver Name!" },
                  ]}
                >
                  <Input
                    placeholder="Plate Number"
                    name="Plate Number"
                    onChange={(e) =>
                      setPayload({ ...payload, plateNumber: e.target.value })
                    }
                  />
                </Form.Item>

                {isAssignOrReAssign !== "Assign Driver" && (
                  <Form.Item
                    name="reason"
                    label="Re-assign reason"
                    rules={[{ required: true }]}
                  >
                    <Input
                      name="reason"
                      onChange={(e) =>
                        setPayload({ ...payload, reason: e.target.value })
                      }
                    />
                  </Form.Item>
                )}
              </Form>
            </>
          ) : (
            <div className="flex flex-col justify-center items-start mt-2">
              <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                style={{ width: "100%" }}
                onFinish={() => {
                  form.resetFields();
                  handleAssigned();
                }}
              >
                <Form.Item
                  name="driver"
                  label={
                    isAssignOrReAssign === "Assign Driver"
                      ? "Select a driver to assign"
                      : "Select a driver to re-assign"
                  }
                  rules={[{ required: true, message: "Please Select driver!" }]}
                >
                  <Input.Group compact>
                    <Select
                      style={{ marginTop: "0.5rem", width: "100%" }}
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      loading={isLoading}
                      onDropdownVisibleChange={(v) => {
                        if (!v) {
                          setData({ ...data, pageIndex: 1 });
                          setDrivers([]);
                        } else {
                          getParcelDrivers();
                        }
                      }}
                      onPopupScroll={onScroll}
                      filterOption={(input, option: any) => {
                        return (
                          option!.children.props
                            ?.defaultValue as unknown as string
                        )
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {drivers?.map((driver: any) => (
                        <Option
                          value={JSON.stringify(driver)}
                          key={driver.userId}
                        >
                          <div
                            className="flex flex-col justify-center items-start"
                            defaultValue={`${JSON.stringify(driver)}`}
                          >
                            <Span weight="semi">
                              {driver?.driverProfile?.lastName ?? ""}{" "}
                              {driver?.driverProfile?.firstName ?? ""}{" "}
                              {driver?.driverProfile?.middleName ?? ""}
                            </Span>
                            <div className="flex flex-row justify-center items-center">
                              <Span>{driver?.phoneNumber ?? ""}</Span>
                              <Tag
                                color={
                                  driver?.driverProfile?.isBusy
                                    ? "warning"
                                    : driver?.driverStatus === "inactive"
                                    ? "error"
                                    : "success"
                                }
                                style={{ marginLeft: "1rem" }}
                              >
                                {driver?.driverProfile?.isBusy
                                  ? "Is Busy"
                                  : driver?.driverStatus === "inactive"
                                  ? "Inactive"
                                  : "Active"}
                              </Tag>
                            </div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Input.Group>
                </Form.Item>

                {isAssignOrReAssign !== "Assign Driver" && (
                  <Form.Item
                    name="reason"
                    label="Re-assign reason"
                    rules={[{ required: true }]}
                  >
                    <Input
                      name="reason"
                      onChange={(e) =>
                        setPayload({ ...payload, reason: e.target.value })
                      }
                    />
                  </Form.Item>
                )}
              </Form>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
