import { TableNoData } from "@common";
import { GenerateStatusDesign, ColumnFilter } from "@components";
import { OPEN_ORDERS_STATUS } from "@constants";
import { formatDate, isPendingAndForPickupExceedsTimeLimit } from "@utils";

export const COLUMNS = [
  {
    id: "orderCode",
    field: "orderCode",
    accessor: (data) => data?.orderCode ?? data?.order?.orderCode,
    Header: "Order code",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-primary-main";
      const ordercode = d?.orderCode ?? d?.order?.orderCode;
      return (
        <div className="max-w-[200px] truncate">
          <span
            className={`${
              ordercode ? "text-black-1" : "text-gray-400"
            }, ${textColor}`}
          >
            {ordercode ?? "No Data"}
          </span>
        </div>
      );
    },
  },
  {
    id: "restaurant",
    field: "restaurant",
    accessor: (data) =>
      JSON.stringify(
        `${data?.pickup?.locationAddress ?? data?.pickUp?.locationAddress} ${
          data?.stops[0]?.locationAddress ?? "No Data"
        }`
      ),
    Header: "Restaurant",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      const location = d?.pickup?.locationAddress ?? d?.pickUp?.locationAddress;
      return (
        <div>
          <div className="max-w-[150px] truncate">
            <span className={`${location ? textColor : "text-gray-400"}`}>
              {location ?? "No Data"}
            </span>
          </div>
          <span className="text-xs text-gray-400">
            {d?.stops[0]?.locationAddress ?? "No Data"}
          </span>
        </div>
      );
    },
  },
  {
    id: "restaurantContact",
    field: "restaurantContact",
    accessor: (data) => data?.pickup?.contactName ?? data?.pickUp?.contactName,
    Header: "Restaurant Contact",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      const contactname = d?.pickup?.contactName ?? d?.pickUp?.contactName;
      return (
        <div className="max-w-[200px] truncate">
          <span className={`${contactname ? textColor : "text-gray-400"}`}>
            {contactname ?? "No Data"}
          </span>
        </div>
      );
    },
  },
  {
    id: "driver",
    field: "driver",
    accessor: (data) =>
      `${data?.driver?.driverProfile?.firstName} ${data?.driver?.driverProfile?.lastName} ${data?.driver?.driverProfile?.middleName}`,
    Header: "Driver",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const driver = d?.driver?.driverProfile;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      return (
        <div className="max-w-[200px] truncate capitalize">
          <span
            className={
              !driver?.firstName && !driver?.lastName && !driver?.middleName
                ? "text-gray-400"
                : textColor
            }
          >
            {!driver?.firstName ? "" : `${driver?.firstName} `}
            {!driver?.lastName ? "" : `${driver?.lastName} `}
            {!driver?.middleName ? "" : `${driver?.middleName} `}
          </span>
        </div>
      );
    },
  },
  {
    id: "createdAt",
    field: "createdAt",
    accessor: "createdAt",
    Header: "Date & Time Placed",
    // Filter: ColumnFilter,
    isVisible: true,
    sortType: "basic",
    sortInverted: false,
    Cell: (data) => {
      const d = data?.row?.original;
      const createdAt = d?.createdAt;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";

      return (
        <>
          <div className="text-center">
            <span className={createdAt ? textColor : "text-gray-400"}>
              {createdAt ? formatDate(createdAt) : <TableNoData />}
            </span>
          </div>
        </>
      );
    },
  },
  {
    id: "type",
    field: "type",
    accessor: (data) => (data?.scheduleStartTime ? "Scheduled" : "Immediate"),
    Header: "Type",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const isMeetTimeLimit = isPendingAndForPickupExceedsTimeLimit(
        d?.status,
        d?.createdAt?.seconds,
        d?.deliveryStartTime?.seconds
      );
      const textColor = isMeetTimeLimit ? "text-red-500" : "text-black-1";
      return (
        <span className={textColor}>
          {d?.scheduleStartTime ? "Scheduled" : "Immediate"}
        </span>
      );
    },
  },
  {
    id: "status",
    field: "status",
    accessor: "status",
    Header: "Status",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <GenerateStatusDesign
          status={d?.status}
          label={OPEN_ORDERS_STATUS[d?.status]}
        />
      );
    },
  },
  {
    id: "riderShare",
    field: "riderShare",
    accessor: (data) => data?.fee?.deliveryFee,
    Header: "Rider Share",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="w-full flex items-center justify-center">
          <span className="text-[#7140b8] font-bold">
            {d?.fee?.total ?? 0} ₱
          </span>
        </div>
      );
    },
  },
  // {
  //   id: "grandTotal",
  //   field: "grandTotal",
  //   accessor: data => data?.fee?.amountToBeCollected,
  //   Header: "Grand Total",
  //   Filter: ColumnFilter,
  //   isVisible: true,
  //   Cell: data => {
  //     const d = data?.row?.original;
  //     return (
  //       <div className="w-full flex items-center justify-center">
  //         <span className="text-[#7140b8] font-bold">{d?.order?.totalCharge ?? 0} ₱</span>
  //       </div>
  //     )
  //   }
  // },
  // {
  //   id: "source",
  //   field: "source",
  //   accessor: data => data?.source ?? 'Unknown',
  //   Header: "Source",
  //   Filter: ColumnFilter,
  //   isVisible: true,
  //   Cell: data => {
  //     const d = data?.row?.original;
  //     const textColor = d?.status === 'pending' && isTimeExceed10Minutes(d?.createdAt?.seconds) ? 'text-grey-15' : 'text-black-1';
  //     return (
  //       <div className="w-full flex items-center justify-center">
  //         <span className={textColor}>{d?.source ?? 'Unknown'}</span>
  //       </div>
  //     )
  //   }
  // },
  // {
  //   id: "device",
  //   field: "device",
  //   accessor: data => data?.device ?? 'Unknown',
  //   Header: "Device",
  //   Filter: ColumnFilter,
  //   isVisible: true,
  //   Cell: data => {
  //     const d = data?.row?.original;
  //     const textColor = d?.status === 'pending' && isTimeExceed10Minutes(d?.createdAt?.seconds) ? 'text-grey-15' : 'text-black-1';
  //     return (
  //       <div className="w-full flex items-center justify-center">
  //         <span className={textColor}>{d?.device ?? 'Unknown'}</span>
  //       </div>
  //     )
  //   }
  // },
];
