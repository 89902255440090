/* eslint-disable react-hooks/exhaustive-deps */
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch, RootState } from "@store";

import { BiSortDown, BiSortUp } from "react-icons/bi";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { SpanContainer, Span, Button } from "@common";
import { ColumnSelectFilter } from "./selectFilter";
import "./Table.css";
import { useQuery } from "@utils";
import { Skeleton } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import { FIREBASE_PATH_NAMES } from "@constants";

export const Table = ({
  columns,
  data,
  dataType,
  sortName,
  status,
  descSort,
}: any) => {
  const dispatch = useDispatch<Dispatch>();
  const query = useQuery();
  const tab: any = query.get("tab");
  const location = useLocation();
  const pathName = location?.pathname;
  const history = useHistory();
  const [isloading, setIsLoading] = useState(false);

  const sortBy = !sortName
    ? []
    : sortName.split("|").map((id: string) => ({ id, desc: descSort }));

  const table =
    dataType === "express-orders"
      ? "expressOrdersCompleted"
      : dataType === "food-orders"
      ? "foodOrdersCompleted"
      : dataType === "shop-orders"
      ? "shopOrdersCompleted"
      : dataType === "ez-buy-service-orders"
      ? "ezBuyOrdersCompleted"
      : dataType === "all-drivers"
      ? "allDrivers"
      : dataType === "pending-drivers"
      ? "pendingDrivers"
      : dataType === "rental-management"
      ? "rentalOrdersCompleted"
      : dataType === "attachment"
      ? "attachment"
      : dataType === "calendar"
      ? "calendar"
      : dataType === "failed"
      ? "disbursements"
      : dataType === "disbursementsPending"
      ? "disbursementsPending"
      : dataType === "disbursementsSuccess"
      ? "disbursementsSuccess"
      : "";

  const type =
    dataType === "express-orders"
      ? "express"
      : dataType === "food-orders"
      ? "food"
      : dataType === "shop-orders"
      ? "mart"
      : dataType === "ez-buy-service-orders"
      ? "buy"
      : dataType === "rental-management"
      ? "rental"
      : dataType === "all-drivers"
      ? ""
      : dataType === "pending-drivers"
      ? "pending"
      : "";

  const {
    totalRecords,
    page: pageindex,
    limit: pagesize,
  } = useSelector((state: RootState) => state.Table.tables[table]) || {};


  useEffect(() => {
    setIsLoading(false);
  }, [data]);

  const handleRowClick = (e: any, row: any) => {
    const deliveryId =
      row?.original?.id !== undefined
        ? row.original.id
        : row?.original?.deliveryId !== undefined
        ? row.original.deliveryId
        : row?.original?.order?.deliveryId;

    switch (dataType) {
      case "express-orders":
        dispatch.UI.setCurrentRoute(pathName);
        window.open(`${pathName}/view/${deliveryId}/${query.get("tab")}`);
        break;
      case "food-orders":
        dispatch.UI.setCurrentRoute(pathName);
        window.open(`${pathName}/view/${deliveryId}/${query.get("tab")}`);
        break;
      case "shop-orders":
        dispatch.UI.setCurrentRoute(pathName);
        window.open(`${pathName}/view/${deliveryId}/${query.get("tab")}`);
        break;
      case "ez-buy-service-orders":
        dispatch.UI.setCurrentRoute(pathName);
        window.open(`${pathName}/view/${deliveryId}/${query.get("tab")}`);
        break;
      //DRIVER VIEW
      case "all-drivers":
        dispatch.UI.updateFormId({
          target: "editDriver",
          value: row?.original?.id ?? row?.original?.driverId,
        });
        break;
      case "pending-drivers":
        dispatch.UI.updateFormId({
          target: "editDriver",
          value: row?.original?.id ?? row?.original?.driverId,
        });
        break;
      case "calendar":
        dispatch.UI.updateFormId({
          target: "editCalendar",
          value: row?.original?.calendarId,
        });
        break;
      case "rental-management":
        dispatch.UI.setCurrentRoute(pathName);
        history.push(
          `${pathName}/view/${
            tab === "openOrderItem"
              ? row?.original?.referenceNumber
              : row?.original?.id ?? row?.original?.fireStoreId
          }/${query.get("tab")}`
        );
        break;
      case "merchant-orders":
        dispatch.UI.setCurrentRoute(pathName);
        window.open(
          `${pathName}/view/${row?.original?.id}/${query.get("tab")}`
        );
        break;
      default:
        return "";
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetFilters: false,
      defaultColumn: { Filter: ColumnSelectFilter },
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    pageOptions,
    previousPage,
    setPageSize,
    state,
    prepareRow,
    gotoPage,
  } = tableInstance;
  const { pageIndex, pageSize } = state;
  const pageCount = Math.round(totalRecords / pageSize);
  const isFirebase = FIREBASE_PATH_NAMES.includes(pathName)
    ? tab === "completedOrders"
      ? false
      : true
    : false;
  const page_index = isFirebase ? pageIndex + 1 : pageindex;
  const page_count = isFirebase ? pageOptions.length : pageCount;

  useEffect(() => {
    if (page_index > page_count) {
      if (isFirebase) {
        gotoPage(0);
      } else {
        dispatch.Table.updatePageIndex({ table, value: 1 });
      }
    }
  }, [page_count]);

  const disabled = page_count === 0 || page_index === page_count;

  
  useEffect(() => { 
    if (
      [
        "expressOrdersCompleted",
        "foodOrdersCompleted",
        "shopOrdersCompleted",
      ].includes(table)
    ) { 
      if (totalRecords) { 
        setIsLoading(true);
        dispatch.Table.getCompletedOrders({ table, type });
      }
    }

    if (["rentalOrdersCompleted"].includes(table)) {
      if (totalRecords) {
        setIsLoading(true);
        dispatch.Table.getCompletedRental({ table, type });
      }
    }

    if (["allDrivers", "pendingDrivers"].includes(table)) {
      setIsLoading(true);
      if (totalRecords) {
        dispatch.Table.getDriversData({
          table,
          type,
          isWithORCR:
            tab === "withORCR" ? true : tab === "withoutORCR" ? false : "",
        });
      }
    }
    if (["attachment"].includes(table)) {
      if (totalRecords) {
        setIsLoading(true);
        dispatch.Table.getAttachmentData({ table, type });
      }
    }
    if (["calendar"].includes(table)) {
      if (totalRecords) {
        setIsLoading(true);
        dispatch.Table.getCalendarData({ table });
      }
    }

    if (
      ["disbursements", "disbursementsPending", "disbursementsSuccess"].includes(
        table
      )
    ) {   
        setIsLoading(true);
        dispatch.Table.getDisbursementsData({ table, status: status }); 
    }
  }, [pageindex, pagesize]); 

  return (
    <>
      <div className="overflow-auto h-[65vh]">
        <table
          {...getTableProps()}
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="w-full relative"
        >
          <thead>
            {data?.length === 0 ? (
              <tr>
                <th></th>
              </tr>
            ) : (
              <>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className="flex justify-center font-semibold px-3.5 py-4 fil whitespace-nowrap">
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BiSortDown className="my-auto text-lg mx-2" />
                            ) : (
                              <BiSortUp className="my-auto text-lg mx-2" />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className='pt-2 mx-2'>
                                                {column.canFilter ? column.render('Filter') : null}
                                            </div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </thead>

          <tbody {...getTableBodyProps()}>
            {data?.length === 0 ? (
              <div className="absolute left-0 right-0 p-24 flex justify-center flex-col items-center">
                <FileSearchOutlined
                  style={{ fontSize: "6rem", color: "gray" }}
                />
                <Span color="lightgrey" size="2xl">
                  No Data
                </Span>
              </div>
            ) : isloading ? (
              <>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="cursor-pointer">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="text-start text-sm p-4"
                          >
                            <Skeleton paragraph={false} />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {page.map((row) => {
                  prepareRow(row);
                  const lastRow = row?.cells?.length - 1;
                  return (
                    <tr {...row.getRowProps()} className="cursor-pointer">
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            onClick={(e) =>
                              lastRow !== index ? handleRowClick(e, row) : ""
                            }
                            {...cell.getCellProps()}
                            className="text-start text-sm p-4"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>

    {data?.length != 0 && 
      <div className="flex  justify-end p-4">
        <div className="px-4">
          <SpanContainer color="grey">Rows per page: </SpanContainer>
          <select
            className="px-1.5 text-base focus:outline-none text-grey-7"
            value={pageSize}
            onChange={(e) => {
              dispatch.Table.updatePageLimit({ table, value: e.target.value });
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>

        <SpanContainer padding="px-4" color="grey">
          {!isFirebase ? pageindex : pageIndex + 1}-{pageSize} of{" "}
          {!isFirebase ? pageCount : pageOptions.length}
        </SpanContainer>

        <div className="flex my-auto">
          <SpanContainer padding="pr-2">
            <Button
              padding="py-1"
              bg="none"
              border="none"
              disabled={page_count === 0}
              onClick={() =>
                !isFirebase
                  ? dispatch.Table.updatePageIndex({
                      table,
                      value: pageindex === 1 ? 1 : pageindex - 1,
                    })
                  : previousPage()
              }
            >
              <MdArrowBackIosNew className="text-grey-4 cursor-pointer" />
            </Button>
          </SpanContainer>
          <SpanContainer>
            <Button
              padding="py-1"
              bg="none"
              border="none"
              disabled={disabled}
              onClick={() =>
                !isFirebase
                  ? dispatch.Table.updatePageIndex({
                      table,
                      value: pageindex + 1,
                    })
                  : nextPage()
              }
            >
              <MdArrowForwardIos className="text-grey-4 cursor-pointer" />
            </Button>
          </SpanContainer>
        </div>
      </div>}
    </>
  );
};

export const handleMultiSortBy = (column, setSortBy, meinSortBy) => {
  const desc =
    column.isSortedDesc === true
      ? undefined
      : column.isSortedDesc === false
      ? true
      : false;
  setSortBy([{ id: column.id, desc }, ...meinSortBy]);
};
