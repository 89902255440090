/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Dispatch } from "../../store";
import { useDispatch } from "react-redux";

//import { cancelBookingByOrderId } from '../../../common/api';
import { Form, Modal, Select } from "antd";
import { CANCEL_REASON } from "@constants";
import { cancelBookingByOrderId } from "@api";

type Props = {
  setCancelModal: Function;
  cancelModal: boolean;
  orderId: string;
};

export const CancelModal = ({
  setCancelModal,
  cancelModal,
  orderId,
  ...props
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const [form] = Form.useForm();
  const { Option } = Select;

  const [reason, setReason] = useState("");

  const handleCancel = async () => {
    try {
      setCancelModal(false);
      dispatch.UI.setIsLoading(true);
      const res = await cancelBookingByOrderId(orderId, reason);
      if ([201, 200].includes(res.status)) {
        dispatch.UI.setIsLoading(false);
        dispatch.UI.setAlert({
          message: `Order Number cancelled successfully`,
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setIsLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to cancel order!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  return (
    <>
      <Modal
        title="Are you sure you want to cancel?"
        visible={cancelModal}
        onOk={form.submit}
        okText="Cancel"
        okButtonProps={{ danger: true }}
        onCancel={() => setCancelModal(false)}
      >
        <Form
          onFinish={handleCancel}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          form={form}
        >
          <Form.Item name="Reason" label="" rules={[{ required: true }]}>
            <Select
              placeholder="Please select reason"
              onChange={(e) => setReason(e)}
            >
              {CANCEL_REASON?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
