/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { formatDateStartEnd, formatDateStartEndHalfday } from "@utils";
import { useEffect, useState } from "react";
import { Tag } from "antd";
 
import { Span, SpanContainer, Button, Container } from "@common";

import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { CHANGE_TO_RENTAL, DONT_SHOW_CHANGE_TO, RENTALSTATUS, RENTAL_ASSIGN_STATUS } from "@constants";
import { useIsWindowActive } from "@hooks";

import { ReassignDriverRental, UpdateStatusRental, TransactionHistoryRental, ChangePickupTimeModal } from "@components"; 

let UNSUBSCRIBE: any;

export default ({ data, setRentalId, rentalId, setCancelModal }: any) => {
  const isWindowActive = useIsWindowActive();
  const { id } = data;
  const [rentalItems, setRentalItems] = useState([]) as any;
  const [rentalItemsHistory, setRentalItemsHistory] = useState([]) as any;
  const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false);
  const [transactionHistoryModal, setTransactionHistoryModal] = useState<boolean>(false);
  const [reassignModal, setReassignModal] = useState<boolean>(false);
  const [changeDate, setChangeDate] = useState(false);
  const [reassignId, setReassignId] = useState<string | null>(null);
  const [scheduleId, setScheduleId] = useState<string | null>(null); 
  const [driverId, setDriverId] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("");

  const getRentalItems = () => {
    const db = getFirestore();

    const q = query(
      collection(db, "rental_items"),
      where("referenceNumber", "==", id),
      where("status", "in", [
        "pending",
        "assigned",
        "time_in",
        "completed",
        "delivered",
        "canceled",
        "picked_up",
        "for_pickup",
        "on_going",
      ])
    );

    UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || [])
        ?.map((ref) => {
          const data = ref.data();
          return data;
        })
        .sort((a: any, b: any) => {
          a = new Date(a?.scheduleStartTime);
          b = new Date(b?.scheduleStartTime);
          return a - b;
        });
      setRentalItems(listData);
    });
  };

  useEffect(() => {
    if (isWindowActive) {
      if (id) {
        getRentalItems();
      }
    } else {
      if (UNSUBSCRIBE) UNSUBSCRIBE();
    }

    return () => {
      if (UNSUBSCRIBE) UNSUBSCRIBE();
    };
  }, [id, isWindowActive]);
 
  return (
    <div className="p-2 max-h-[400px] overflow-y-auto">
      <Container>
        <Span weight="semi" color="brown" transform="uppercase">
          Driver Details & Schedule
        </Span>

        <div className="mt-4">
          <ul className="timeline">
            {rentalItems
              ?.sort(
                (a, b) =>
                  a?.scheduleStartTime?.seconds - b?.scheduleStartTime?.seconds
              )
              ?.map((item, index) => {
                const isThirdparty = item?.thirdPartyDriver ? true : false;
                const assignedDriver = isThirdparty
                  ? item?.thirdPartyDriver
                  : item?.driver;
                const name =
                  assignedDriver?.contactName ??
                  `${assignedDriver?.driverProfile?.lastName ?? ""}, ${
                    assignedDriver?.driverProfile?.firstName
                  }`;
                const phoneNumber =
                  assignedDriver?.contactNumber ??
                  assignedDriver?.phoneNumber?.replace("+63", "");
                return (
                  <li key={index}>
                    <div className="sequence">{item?.sequence}</div>
                    {item?.scheduleType === "halfday"
                      ? formatDateStartEndHalfday(
                          item?.scheduleStartTime?.seconds
                        )
                      : formatDateStartEnd(item?.scheduleStartTime?.seconds)}
                    <ul>
                      {isThirdparty ? (
                        <li>
                          <Tag color="#2db7f5" style={{ margin: "0 0.5rem" }}>
                            3rd Party Driver
                          </Tag>
                        </li>
                      ) : (
                        <></>
                      )}
                      <li>
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                          <div>
                            <Span margin="mx-2" transform="capitalize">
                              <strong>ID:</strong> {item?.id}
                            </Span>
                            {!["pending", "canceled"].includes(
                              item?.status
                            ) && (
                              <Span margin="mx-2" transform="capitalize">
                                <strong>Driver Name:</strong> {name ?? ""}
                              </Span>
                            )}
                            <div className="mx-2 flex">
                              <SpanContainer>
                                <strong>Status:</strong>
                              </SpanContainer>

                              <div
                                onClick={() => {
                                  setTransactionHistoryModal(true); 
                                  setRentalItemsHistory(item?.deliveryHistory)
                                }} 
                                className="attachment-link uppercase"
                              > 
                                  {RENTALSTATUS[item?.status]} 
                              </div>
                            </div>

                            {!["pending", "assigned", "canceled"].includes(
                              item?.status
                            ) && (
                              <a
                                href={`/attachment?reference=${item.id}`}
                                className="attachment-link"
                                target="_blank"
                              >
                                Link to Driver Attachment
                              </a>
                            )}
                          </div>

                          <div>
                            <div className="mx-2">
                              <SpanContainer padding="pr-2">
                                <strong>Driver Share:</strong>
                              </SpanContainer>

                              <SpanContainer
                                transform="uppercase"
                                color={"violet"}
                                weight="bold"
                              >
                                ₱{item.riderShare || item.riderShareFee || item?.fee?.riderShareFee}
                              </SpanContainer>
                            </div>

                            {!["pending", "canceled"].includes(
                              item?.status
                            ) && (
                              <Span margin="mx-2">
                                <strong>Driver Ctc#: </strong>
                                {"+63" + phoneNumber ?? ""}
                              </Span>
                            )}

                            <div>
                              {item?.status === "canceled" ? (
                                <li>
                                  <Span margin="mx-2" transform="capitalize">
                                    <strong>Cancel Reason: </strong>
                                    {item?.cancelReason ?? "No Data"}
                                  </Span>
                                </li>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="flex justify-between">
                          <div className="flex">
                            {!DONT_SHOW_CHANGE_TO.includes(item?.status) && (
                              <div className="mt-2">
                                <Button
                                  border="primary"
                                  color="mainColor"
                                  bg="white"
                                  padding="px-2"
                                  radius="sm"
                                  margin="mx-2"
                                  width="none"
                                  onClick={() => {
                                    setScheduleId(item?.id);
                                    setUpdateStatusModal(true);
                                    setStatus(item?.status);
                                  }}
                                >
                                  Change to{" "}
                                  <strong>
                                    {
                                      RENTAL_ASSIGN_STATUS[
                                        CHANGE_TO_RENTAL[item?.status]
                                      ]
                                    }
                                  </strong>
                                </Button>
                              </div>
                            )}
                            {!["completed", "delivered", "canceled"].includes(
                              item?.status
                            ) && (
                              <div className="mt-2">
                                <Button
                                  onClick={() => {
                                    setCancelModal(true);
                                    setRentalId(item.id);
                                  }}
                                  border="red"
                                  bg="red"
                                  padding="px-2"
                                  radius="sm"
                                  width="none"
                                >
                                  Cancel Schedule
                                </Button>
                              </div>
                            )}
                          </div>
                          <div className="flex">
                            {["new", "pending", "assigned"].includes(
                              item?.status
                            ) && (
                              <div className="mt-2">
                                <Button
                                  padding="px-2"
                                  margin="mx-2"
                                  radius="sm"
                                  width="none"
                                  onClick={() => {
                                    setChangeDate(true);
                                    setRentalId(item.id);
                                  }}
                                >
                                  Change pick-up time
                                </Button>
                              </div>
                            )}

                            {![
                              "completed",
                              "delivered",
                              "canceled",
                              "pending",
                            ].includes(item?.status) && (
                              <div className="mt-2">
                                <Button
                                  onClick={() => {
                                    setReassignModal(true);
                                    setReassignId(item?.id);
                                    setDriverId(item?.driver.driverId);
                                  }}
                                  padding="px-2"
                                  bg="green"
                                  radius="sm"
                                >
                                  Re-assign Driver
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                      <li>
                        <hr className="mt-6" />
                      </li>
                    </ul>
                  </li>
                );
              })}

            {/* {isNotAssignedDriver && (
              <li>
                <Span color="lightgrey">No driver assigned</Span>
              </li>
            )} */}
          </ul>
        </div>
      </Container>

      <ReassignDriverRental
        setReassignModal={setReassignModal}
        reassignModal={reassignModal}
        orderId={reassignId}
        reassigndriverId={driverId}
        orderData={data}
        driverGroup={"rental_only"} 
      />

      <UpdateStatusRental
        setUpdateStatusModal={setUpdateStatusModal}
        updateStatusModal={updateStatusModal}
        orderId={scheduleId}
        status={status}
      />

      <ChangePickupTimeModal
        changeDate={changeDate}
        setChangeDate={setChangeDate}
        id={rentalId}
      />

      <TransactionHistoryRental
        setTransactionHistoryModal={setTransactionHistoryModal}
        transactionHistoryModal={transactionHistoryModal}  
        rental={rentalItemsHistory} 
      />
    </div>
  );
};
