/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from "react-redux";
import { useState } from "react";
import moment from "moment";

import { BsDot } from "react-icons/bs";
import { SpinnerCircularFixed } from "spinners-react"; 
import { GridContainer, Span} from "@common"; 
import { PACKAGE_STATUS } from "@constants";
import { Dispatch } from "@store";
import { formatDate } from "@utils";

function Stops({ dropOff, status, driver, thirdPartyDriver }: any) {
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setIsLoading] = useState(false);

  const handlePOD = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];
    const fileType = file.type;
    if (
      ![
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg",
        "image/webp",
      ].includes(fileType)
    ) {
      dispatch.UI.setAlert({ message: "Invalid file type!", type: "Error" });
      setIsLoading(false);
      return;
    }
    try {
      const res: any = { isSuccess: false }//await uploadExpressPod(expressId, dropOff?.id, file);
      if (res.isSuccess) {
        setIsLoading(false);
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully uploaded",
          type: "Success",
        });
      }
    } catch (err: any) {
      setIsLoading(false);
      const errMsg = err?.data?.error ?? "Unable to upload file";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  const datedelivered = new Date(dropOff?.deliveredAt?.seconds * 1000);
  const deliveredAt = moment(datedelivered).format("MMMM Do YYYY h:mm:ss a");

  return (
    <GridContainer grid="five">
      <div>
        <Span weight="semi"> Address: </Span>
        <Span>{dropOff?.locationAddress}</Span>
        <p className="flex">
          <BsDot className="text-grey-11 my-auto" />
          <Span color="lightgrey" transform="capitalize">
            {dropOff?.location?.address ? dropOff?.location?.address : 'No Data'}
          </Span>
        </p>
      </div>

      <div>
        <Span weight="semi"> Contact Person </Span>
        <Span>{dropOff?.contactName}</Span>
      </div>

      <div>
        <Span weight="semi"> Contact Number </Span>
        <Span>{dropOff?.contactPhone}</Span>
      </div>

      <div>
        <Span weight="semi"> Item Category </Span>
        <Span color={dropOff?.itemCategory ? 'required' : 'lightgrey'}>
          {dropOff?.itemCategory ? dropOff?.itemCategory : 'No data'}
        </Span>
      </div>

      <div>
        <Span weight="semi"> Package Status </Span>
        {status === PACKAGE_STATUS.canceled ? (
          <Span weight="semi" color="red">
            CANCELED
          </Span>
        ) : status === PACKAGE_STATUS.failed_delivery ? (
          <Span weight="semi" color="red">
            FAILED DELIVERY
          </Span>
        ) : (dropOff?.action !== undefined &&
          dropOff?.action === "completed") ||
          status === PACKAGE_STATUS.delivered ? (
          <Span weight="semi" color="blue">
            DELIVERED
          </Span>
        ) : status === PACKAGE_STATUS.on_going &&
          dropOff?.action === undefined ? (
          <Span weight="semi" color="orange">
            OUT FOR DELIVERY
          </Span>
        ) : (
          <Span weight="semi" color="green">
            PENDING
          </Span>
        )}
      </div>

      {dropOff?.proofImage && (
        <div className="col-span-5 border-[1px] border-gray-300 p-2 rounded-sm">
          <Span weight="semi"> Proof of Delivery: </Span>
          {isLoading ? (
            <SpinnerCircularFixed className="text-brown-1 h-6 w-6" />
          ) : (
            <>
              <a href={dropOff?.proofImage} target="_blank">
                <Span color="blue">{dropOff?.proofImage}</Span>
              </a>
              {/* {dropOff?.proofImage == undefined && status === "delivered" ? (
                <>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={handlePOD}
                    accept="image/*"
                  />
                  <label htmlFor="file" className="cursor-pointer">
                    <Span color="brown" weight="semi">
                      Upload POD
                    </Span>
                  </label>
                </>
              ) : (
                <></>
              )} */}
            </>
          )}
        </div>
      )}

      <div className="flex justify-end items-center pb-4 col-span-5">
        {dropOff?.deliveredAt && (
          <Span><strong>{!driver?.driverProfile?.lastName ? `${thirdPartyDriver?.contactName} ` : `${driver?.driverProfile?.lastName}, ${driver?.driverProfile?.firstName}, `}</strong>at <strong>{formatDate(dropOff?.deliveredAt)}</strong></Span>
        )}
      </div>

    </GridContainer>
  );
}

export default Stops;
