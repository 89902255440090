/* eslint-disable import/no-anonymous-default-export */
import { Steps } from 'antd' 
import { formatDateV2 } from '@utils';  

const { Step } = Steps;
  
export default ({ mart }: any) => { 

    const pendingSchedule = mart?.createdAt ? formatDateV2(mart?.createdAt): ''; 
    const forAcceptedSchedule = mart?.acceptedAt ? formatDateV2(mart?.acceptedAt): ''; 
    const preparingPickupSchedule = mart?.preparedAt ? formatDateV2(mart?.preparedAt): ''; 
    const readySchedule = mart?.readyAt ? formatDateV2(mart?.readyAt): ''; 
    const forDeliverySchedule = mart?.deliveryAcceptedAt ? formatDateV2(mart?.deliveryAcceptedAt): '';  
    const inTransitSchedule = mart?.inTransitAt ? formatDateV2(mart?.inTransitAt): ''; 
    const deliveredSchedule = mart?.deliveredAt ? formatDateV2(mart?.deliveredAt): ''; 
    const canceledSchedule = mart?.canceledAt ? formatDateV2(mart?.canceledAt): ''; 
    
    const StatusMart = [
        {
            label: 'Pending',
            value: 'pending',
            timestamp: pendingSchedule,
        },
        {
            label: 'Accepted Orders',
            value: 'picked_up',
            timestamp: forAcceptedSchedule,
        },
        {
            label: 'Preparing',
            value: 'preparing', 
            timestamp: preparingPickupSchedule,
        },
        {
            label: 'Order Ready',
            value: 'ready',
            timestamp: readySchedule,
        },
        {
            label: 'For Delivery',
            value: 'for_delivery',
            timestamp: forDeliverySchedule,
        }, 
        // {
        //     label: 'Arrived at Pickup',
        //     value: 'arrivedAtPickup', 
        //     timestamp: arrivedAtPickupSchedule,
        // },
        {
            label: 'In Transit',
            value: 'in_transit',
            timestamp: inTransitSchedule,
        },
        {
            label: 'Delivered',
            value: 'delivered',
            timestamp: deliveredSchedule,
        },
        {
            label: 'Canceled',
            value: 'canceled',
            timestamp: canceledSchedule,
        },
    ]  

    // const martStatus = mart?.status == mart?.arrivedAtPickupAt != null
    // ? mart?.status : 'arrivedAtPickup'
    
    const index = StatusMart?.findIndex(item => item.value === mart?.status); 

    return (
        <> 
          <Steps direction="vertical" size="small" current={index}>   
  
              {StatusMart?.map((item, index) => (
                  <Step 
                      title={item.label} 
                      key={index} 
                      description={item.timestamp} 
                  />
              ))}
          </Steps> 
        </>
    );
  }; 