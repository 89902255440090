import { useDispatch } from 'react-redux';
import { useState } from 'react' 

import { Dispatch } from '@store';
import { REJECT_REASON } from '@constants';

import { Form, Modal, Select } from 'antd'
import { rejectDriver } from '@api'

type Props = {
    setRejectModal: Function,
    rejectModal: boolean,
    id: string,
    dataType: string,
}

export const RejectModal = ({ setRejectModal, rejectModal, id, dataType, ...props }: Props) => {

    const dispatch = useDispatch<Dispatch>();
    const [reasons, setReasons] = useState([]);
    const [form] = Form.useForm();

    const { Option } = Select;

    const handleReject = async () => {
        setRejectModal(false);
        try {
            dispatch.UI.setIsLoading(true);
            const res = await rejectDriver(id, reasons)
            if ([200, 201].includes(res.status)) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: 'Status updated', type: 'Success' });
                dispatch.Table.getDriversData({ table: dataType, type: dataType === 'pending' ? 'pending' : '' });
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.message ?? 'Unable to update driver status';
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        }

    }

    return (
        <>
            <Modal title='Update approval status' visible={rejectModal} onOk={form.submit} okText='Reject' okButtonProps={{ danger: true }} onCancel={() => setRejectModal(false)}>
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleReject}
                    wrapperCol={{ span: 24 }}
                >
                    <Form.Item
                        name='Select a reason that why it is disapproved'
                        label='Select a reason that why it is disapproved'
                        rules={[{ required: true }]}
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            onChange={(v => setReasons(v))}
                        >
                            {REJECT_REASON?.map(reason => (
                                <Option key={reason} value={reason}>{reason}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
}

