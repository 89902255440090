import { lazy, Suspense } from "react";

const GoogleMap = lazy(() => import("./Google"));

export default function Map({locations=[], merchantLocation=[], ...props}:any) {
  return (
    <Suspense fallback={<></>}>
      <GoogleMap locations={locations} merchantLocation={merchantLocation}/> 
    </Suspense>
  );
}
