import { useParams } from 'react-router-dom'

import { EzBuyServiceViewPage } from '@components'
import { Header, SideBar, MainLayout, ContainerLayout } from '@common'

export const EzBuyServiceOrdersView = () => {

  let params: any = useParams();

  return (
    <>
      <MainLayout>
        <SideBar />  
        <Header />
        
        <ContainerLayout>
          <EzBuyServiceViewPage id={params.id} />
        </ContainerLayout>
      </MainLayout>
    </>
  )
}