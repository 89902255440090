import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

import { RootState } from "@store";
import { ROLE_MODULES } from "@constants";

interface MyRouteProps extends RouteProps {
  component: any;
  path: any;
  exact: boolean;
}

const AuthRoute: React.FC<MyRouteProps> = ({ component: Component, ...rest }) => {

  const location = useLocation();
  const pathname = location.pathname?.split('/view')[0];
  const isAuthorized = ROLE_MODULES[pathname] ? true : false;
  const { shouldRedirectToLogin, isUserLoggedIn } = useSelector((state: RootState) => state.User);

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn ? (
          isAuthorized ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
          )
        ) : (<>
          {shouldRedirectToLogin ? <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          /> : null}
        </>
        )
      }
    />
  );
};

export default AuthRoute;
