import { useLocation } from "react-router-dom";
import { BASE_URL } from "@constants";
import moment from "moment";
import type { RcFile } from 'antd/es/upload';

const COUNTRY_CALLING_CODE = "+63";

export const isPendingAndForPickupExceedsTimeLimit = (status, createdAt, startDelivery) => {
	const currentTime = new Date().getTime();
	const created_At = new Date(createdAt * 1000);
	const start_Delivery = new Date(startDelivery * 1000);

	const createdTime = created_At.setMinutes(created_At.getMinutes() + 10);
	const startdeliveryTime = start_Delivery.setMinutes(start_Delivery.getMinutes() + 30);
	
	const isPendingMorethan10Minutes = status === 'pending' && currentTime > createdTime ? true : false;
	const isForpickupMorethan30Minutes = status === 'for_pickup' && currentTime > startdeliveryTime ? true : false;
	const result = isPendingMorethan10Minutes || isForpickupMorethan30Minutes ? true : false;

	return result;
}

export const isTimeExceed30Minutes = (seconds) => {
	const currentTime = new Date().getTime() / 1000;
	const timeDue = seconds + (30 * 60);
	const result = currentTime > timeDue ? true : false;

	return result;
}


export const formatHolidate = (d) => {
	const today = new Date()
	const holiday = d.date + "/" + today.getFullYear()
	return holiday;
}

export const formatDateStartEnd = (seconds: any) => {

	const formattedSD = moment(new Date(seconds * 1000)).format('MMM. DD, YYYY');

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm a');
	const formattedET = moment(date.setHours(date.getHours() + 9)).format('hh:mm a');
	
	const finalDateFormat = `${formattedSD} (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}

export const formatDateStartEndHalfday = (seconds: any) => {

	const formattedSD = moment(new Date(seconds * 1000)).format('MMM. DD, YYYY');

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm a');
	const formattedET = moment(date.setHours(date.getHours() + 5)).format('hh:mm a');
	
	const finalDateFormat = `${formattedSD} (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}


export const formatDateStartEndCompleted = (seconds: any) => { 

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm a');
	const formattedET = moment(date.setHours(date.getHours() + 9)).format('hh:mm a');
	
	const finalDateFormat = ` (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}

export const formatDateStartEndHalfdayCompleted = (seconds: any) => {  

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm a');
	const formattedET = moment(date.setHours(date.getHours() + 5)).format('hh:mm a');
	
	const finalDateFormat = ` (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}

export const formatDateV2 = (date) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format('MMMM Do YYYY | h:mm:ss a') 
	return formattedDate; 
}

export const formatDate = (date, seconds = false) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format(`MM-DD-YYYY, h:mm${seconds? ':ss' : ''} a`)
	return formattedDate;
}   

export const formatDateOnly = (date) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format('MMM DD, YYYY')
	return formattedDate;
} 

export const onlyNumberKey = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		return evt.preventDefault();
	}
	return true;
}

export const getRoleModulesAndObjectOfRoleAccesses = (userCredentials) => {
	const modules = userCredentials?.role?.roleAccesses?.map(role => role?.module?.key);
	const objectOfRole = objectOfRoleAccesses(modules, userCredentials);

	const paylaod = {
		modules,
		objectOfRole
	}

	return paylaod;
}

export const objectOfRoleAccesses = (modules, userCredentials) => {
	let object = {};
	modules?.forEach(mod => {
		object[mod] = userCredentials?.role?.roleAccesses?.filter(role => role?.module?.key === mod)[0];
	});
	return object;
}

export const getUrl = () => {
	if (process.env.NODE_ENV === 'development') {
		const link = `http://localhost:3000/`
		return link;
	} else {
		const link = `${BASE_URL}/`
		return link;
	}
}

export const useQuery = () => {
	return new URLSearchParams(useLocation()?.search);
}

export const removeSCAndCapitalize = (text, seperator) => {
	const result = text?.split(seperator)?.map(str => str[0]?.toUpperCase() + str?.substring(1))?.join(' ');
	return result;
}

export const formatPhoneNumber = (phoneNumber: string) => {
	if(!phoneNumber) return
	
	if (phoneNumber.startsWith(COUNTRY_CALLING_CODE)) {
	  return phoneNumber;
	}
  
	if (phoneNumber.startsWith("0")) {
	  return `${COUNTRY_CALLING_CODE}${phoneNumber.substring(1)}`;
	}
  
	if (phoneNumber.startsWith("63")) {
	  return `${COUNTRY_CALLING_CODE}${phoneNumber.substring(2)}`;
	}
  
	return `${COUNTRY_CALLING_CODE}${phoneNumber}`;
  };

export const formatDecimalPrice = (value = 0) =>
value ? Number(parseFloat(`${value}`).toFixed(2)).toLocaleString('en') : 0;

export const getBase64 = (file: RcFile): Promise<string> =>
new Promise((resolve, reject) => {
const reader = new FileReader();
reader.readAsDataURL(file);
reader.onload = () => resolve(reader.result as string);
reader.onerror = (error) => reject(error);
});

export const formatPrice = (value) => {
  	const result = `₱${parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
	return result;
}
export const cleanObject = (obj: Record<string | number, any>) => {
	for (let propName in obj) {
		if ([undefined, null, ''].includes(obj[propName])) delete obj[propName];
	}
	return { ...obj };
};