import { ColumnFilter } from "@components";
import { Holiday } from "@constants";
import moment from "moment";

import StatusEdit from "./StatusEdit";

export const COLUMNS = [
  {
    accessor: "holidayType",
    Header: "Holiday Type",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">{Holiday[d.holidayType]}</span>
        </div>
      );
    },
  },
  {
    accessor: "name",
    Header: "Name",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">{d.name}</span>
        </div>
      );
    },
  },
  {
    accessor: "date",
    Header: "Date",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          {moment(new Date(d.date)).format("MMMM Do YYYY")}
        </div>
      );
    },
  },
  {
    accessor: " ",
    Header: " ",
    Filter: ColumnFilter,
    disableFilters: true,
    disableSortBy: true,
    isVisible: true,
    Cell: (data) => <StatusEdit id={data.row.original.id} />,
  },
];
