import { useParams } from "react-router-dom";
import { Header, SideBar, MainLayout, ContainerLayout } from "@common";
import { MerchantOrdersViewPage } from "@components";

export const MerchantOrdersView = () => {
  let params: any = useParams();

  return (
    <>
      <MainLayout>
        <SideBar />
        <Header />

        <ContainerLayout>
          <MerchantOrdersViewPage id={params.id} />
        </ContainerLayout>
      </MainLayout>
    </>
  );
};
