import { useGetCurrentRoleAccesses } from '@hooks';
import { Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { Calender } from '@containers'

export const CalendarManagement = () => {
    const module_name = 'manage_calendar';
    useGetCurrentRoleAccesses(module_name);
    return (
        <>
            <MainLayout>
                <SideBar />  
                <Header />
                
                <ContainerLayout>
                    <Calender /> 
                </ContainerLayout>
            </MainLayout>
        </>
    )
}