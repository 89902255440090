import { useGetCurrentRoleAccesses } from '@hooks';
import { Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { ExpressOrdersPage } from '@containers';

export const ExpressOrders = () => {
    const module_name = 'manage_parcel_deliveries';
    useGetCurrentRoleAccesses(module_name);
    return (
        <>
            <MainLayout>
                <SideBar />  
                <Header />
                
                <ContainerLayout>
                    <ExpressOrdersPage /> 
                </ContainerLayout>
            </MainLayout>
        </>
    )
}
