import { HISTORY_STATUS } from '@constants'
import { formatDateV2 } from '@utils';
import { Span } from '@common'

import { Modal } from 'antd';
import './RentalModal.scss'

type Props = {
    setTransactionHistoryModal: Function,
    transactionHistoryModal: boolean,
    rental: any
}

export const TransactionHistoryRental = ({ setTransactionHistoryModal, transactionHistoryModal, rental}: Props) => {

    return (<>
        <Modal title={'Transaction History'} visible={transactionHistoryModal} onCancel={() => setTransactionHistoryModal(false)}  footer={null}> 

            <div>
            <ul className="timeline-transaction">
                {rental?.sort((a, b) => a.createdAt < b.createdAt ? 1:-1).map((item, index: number) => 
                    <li key={index}>
                        <ul>
                            <li><Span transform='capitalize' size='base' weight='semi'>{HISTORY_STATUS[item?.status]}</Span>
                                <Span color='lightgrey'> 
                                    {formatDateV2(item?.createdAt)}
                                </Span>    
                            </li>
                        </ul>
                    </li> 
                )}
            </ul>
            
            </div>
        </Modal>
    </>);
}
