/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import { styles } from "./config";
import { BRAND_IMAGES, STOP_ICONS } from "@constants";
import { last } from "lodash";

interface ILatLng {
  lat: number;
  lng: number;
}
interface ILocation {
  location: ILatLng;
}
interface IMapData {
  pickUp?: ILocation;
  pickup?: ILocation;
  stops: ILocation[];
}
interface IMapProps {
  data: IMapData;
  driverData?: any;
  isShow: boolean;
}

const ParcelManagementMap: React.FC<IMapProps> = ({
  data,
  driverData,
  isShow,
}) => {
  const [directions, setDirections] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const pickUp = (data?.pickUp ?? data?.pickup)?.location!;
  const dropOff = last(data?.stops)?.location;
  const stops: any = data?.stops
    ?.filter((_, index) => index < data?.stops.length - 1)
    .map((item) => {
      return {
        location: item?.location,
        stopover: true,
      };
    });

  const googleInstance = window.google;

  useEffect(() => {
    if (googleInstance && pickUp && isShow) {
      const directionsService = new google.maps.DirectionsService();
      directionsService &&
        directionsService.route(
          {
            origin: new window.google.maps.LatLng(pickUp?.lat, pickUp?.lng),
            destination: new window.google.maps.LatLng(
              dropOff?.lat,
              dropOff?.lng
            ),
            optimizeWaypoints: true,
            travelMode: window.google.maps.TravelMode.DRIVING,
            waypoints: stops,
          },
          (result: any, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
              setLoading(false);
            } else {
              // eslint-disable-next-line no-console
              console.error(`error fetching directions ${result}`);
            }
          }
        );
    }
  }, [pickUp, isShow]);

  return googleInstance && isShow ? (
    <GoogleMap
      zoom={15}
      center={pickUp}
      mapContainerClassName="map-container"
      options={{
        styles,
      }}
    >
      <Marker
        position={pickUp}
        icon={{
          url: BRAND_IMAGES.PICK_UP_ICON,
          scaledSize: new google.maps.Size(40, 40),
        }}
        animation={google.maps.Animation.DROP}
      />
      <Marker
        position={dropOff}
        icon={{
          url: BRAND_IMAGES.DROF_OFF_ICON,
          scaledSize: new google.maps.Size(40, 40),
        }}
        animation={google.maps.Animation.DROP}
      />
      {driverData?.driverLocation?.current && (
        <Marker
          position={driverData?.driverLocation?.current}
          icon={{
            url: BRAND_IMAGES.DRIVERLOCATION,
            scaledSize: new google.maps.Size(40, 40),
          }}
          animation={google.maps.Animation.DROP}
        />
      )}
      {stops.map((marker, index) => (
        <Marker
          position={marker.location}
          key={index}
          icon={{
            url: STOP_ICONS[`STOP_ICON_${index + 1}`],
            scaledSize: new google.maps.Size(40, 40),
          }}
        />
      ))}
      {!loading && (
        <DirectionsRenderer
          directions={directions}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#ff8c00",
              strokeOpacity: 0.8,
              strokeWeight: 4,
            },
          }}
        />
      )}
    </GoogleMap>
  ) : null;
};

export default ParcelManagementMap;
