/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  NavLink,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import { createElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CalendarOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AntDesignOutlined,
  UserSwitchOutlined,
  ShoppingOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";

import { BRAND_IMAGES } from "@constants";
import classNames from "classnames";
import { Dispatch, RootState } from "@store";
import { ImAttachment } from "react-icons/im";
import { AiFillDashboard } from "react-icons/ai";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

export const SideBar = (onToggle) => {
  const dispatch = useDispatch<Dispatch>();

  const [collapsed, setCollapsed] = useState(false);
  const { openKeys } = useSelector((state: RootState) => state.UI);
  const roleModules = useSelector(
    (state: RootState) => state.User.roleModules
  ) as any[];

  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/home" || location.pathname === ""
      ? "/home"
      : location.pathname
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  function handleClick(e: any) {
    setCurrent(e.key);
  }

  return (
    <>
      <Sider
        collapsible
        style={{
          overflow: "auto",
          height: "100vh",
        }}
        width={210}
        collapsedWidth={60}
        breakpoint="lg"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={
          <div
            className={classNames(
              "sider-bar__trigger",
              collapsed && "sider-bar__trigger--collapsed"
            )}
          >
            {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              onClick: onToggle,
            })}
          </div>
        }
        className="scroll"
      >
        <div className="brand-logo">
          <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="logo" className="logo" />
        </div>

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[current]}
          onClick={handleClick}
          openKeys={[openKeys]}
        >
          {roleModules.includes("home") && (
            <Menu.Item
              key="/home"
              icon={<AntDesignOutlined />}
              onClick={() => {
                dispatch.UI.updateState({ openKeys: "" });
              }}
            >
              <NavLink to="/home">Home</NavLink>
            </Menu.Item>
          )}

          {roleModules.includes("manage_parcel_deliveries") && (
            <SubMenu
              key="parcel-management"
              onTitleClick={() => {
                if (openKeys !== "parcel-management") {
                  dispatch.UI.updateState({ openKeys: "parcel-management" });
                } else {
                  dispatch.UI.updateState({ openKeys: "" });
                }
              }}
              title={
                <span>
                  <ShoppingOutlined />
                  <span>Parcel Management</span>
                </span>
              }
            >
              <Menu.Item key="/parcel-management/express-orders">
                <NavLink to="/parcel-management/express-orders?tab=openOrders">
                  {" "}
                  Express Orders
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/parcel-management/food-orders">
                <NavLink to="/parcel-management/food-orders?tab=openOrders">
                  Food Orders
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/parcel-management/shop-orders">
                <NavLink to="/parcel-management/shop-orders?tab=openOrders">
                  Shop Orders
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/parcel-management/ez-buy-service-orders">
                <NavLink to="/parcel-management/ez-buy-service-orders?tab=openOrders">
                  EZ Buy Service Orders
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/rental-management">
                <NavLink to="/rental-management?tab=openOrders">Rental</NavLink>
              </Menu.Item>
              <Menu.Item key="/parcel-management/merchant-orders">
                <NavLink to="/parcel-management/merchant-orders?tab=openOrders">
                  Merchant Orders
                </NavLink>
              </Menu.Item>
            </SubMenu>
          )}

          {roleModules.includes("manage_drivers") && (
            <>
              <SubMenu
                key="driver-management"
                onTitleClick={() => {
                  if (openKeys !== "driver-management") {
                    dispatch.UI.updateState({ openKeys: "driver-management" });
                  } else {
                    dispatch.UI.updateState({ openKeys: "" });
                  }
                }}
                title={
                  <span>
                    <UserSwitchOutlined />
                    <span>Driver Management</span>
                  </span>
                }
              >
                <Menu.Item key="/driver-management/all-drivers">
                  <NavLink to="/driver-management/all-drivers">
                    {" "}
                    All Driver
                  </NavLink>
                </Menu.Item>
                {/* <Menu.Item key="/driver-management/pending-drivers">
                  <NavLink to="/driver-management/pending-drivers?tab=withORCR">
                    Pending Drivers
                  </NavLink>
                </Menu.Item> */}
              </SubMenu>

              <Menu.Item
                key="/driver-management/driver-dashboard"
                icon={<AiFillDashboard />}
                onClick={() => {
                  dispatch.UI.updateState({ openKeys: "" });
                }}
              >
                <NavLink to="/driver-management/driver-dashboard">
                  Driver Dashboard
                </NavLink>
              </Menu.Item>
            </>
          )}

          {roleModules.includes("manage_calendar") && (
            <Menu.Item
              key="/calendar-management"
              icon={<CalendarOutlined />}
              onClick={() => {
                dispatch.UI.updateState({ openKeys: "" });
              }}
            >
              <NavLink to="/calendar-management">Calendar Management</NavLink>
            </Menu.Item>
          )}

          <Menu.Item
            key="/attachment"
            icon={<ImAttachment />}
            onClick={() => {
              dispatch.UI.updateState({ openKeys: "" });
            }}
          >
            <NavLink to="/attachment">Attachment</NavLink>
          </Menu.Item>

          {roleModules.includes("manage_disbursements") && (
            <SubMenu
              key="disbursements"
              onTitleClick={() => {
                if (openKeys !== "disbursements") {
                  dispatch.UI.updateState({ openKeys: "disbursements" });
                } else {
                  dispatch.UI.updateState({ openKeys: "" });
                }
              }}
              title={
                <span>
                  <InteractionOutlined />
                  <span>Disbursements</span>
                </span>
              }
            >
              <Menu.Item key="/disbursements/pending">
                <NavLink to="/disbursements/pending">Pending</NavLink>
              </Menu.Item>
              <Menu.Item key="/disbursements/failed">
                <NavLink to="/disbursements/failed">Failed</NavLink>
              </Menu.Item>
              <Menu.Item key="/disbursements/success">
                <NavLink to="/disbursements/success">Success</NavLink>
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Sider>
    </>
  );
};
