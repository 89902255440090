import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Dispatch } from '@store';

import type { RangePickerProps } from 'antd/es/date-picker';
import { DatePicker, Form, Modal } from 'antd';
import moment from 'moment';
import { changePickupDate } from '@api';

type Props = {
    setChangeDate: Function,
    changeDate: boolean,
    id: string
}

export const ChangePickupTimeModal = ({id, setChangeDate, changeDate, ...props }: Props) => {

    const dispatch = useDispatch<Dispatch>();

    const [date, setDate] = useState('') as any;
    const [form] = Form.useForm();

    const handleChange = (date, dateString) => {
        setDate(new Date(date))
    }

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        // Can not select days before today and today
        return moment().isAfter(current)
    };

    const handlePickedUp = async () => {
        setChangeDate(false);
        try {
            dispatch.UI.setIsLoading(true);
            const res = await changePickupDate(id, date);
            if ([200, 201].includes(res.status)) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: `Picked up date successfully updated!`, type: 'Success' })
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.message ?? 'Unable to update picked up date!'
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        }
    }

    return (
        <>
            <Modal title="Change the pick-up time" visible={changeDate} onOk={form.submit} onCancel={() => setChangeDate(false)}>
                <div className='py-4 px-4 flex flex-col' >
                    <Form
                        layout='vertical'
                        form={form}
                        onFinish={handlePickedUp}
                        wrapperCol={{ span: 24 }}
                    >
                        <Form.Item
                            name='Picked up time'
                            label='Picked up time'
                            rules={[{ required: true }]}
                        >
                            <DatePicker showTime  defaultValue={moment(new Date())} onChange={handleChange} style={{ width: '100%' }} disabledDate={disabledDate} name='Date' />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>);
}   