/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable array-callback-return */
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

type Props = {
    merchantLocation: any
}

export default ({merchantLocation}: Props) => {  
    
    const dispatch = useDispatch<Dispatch>();
    const [searchMerchant, setSearchMerchant] = useState(""); 

    const handleOnclick = (id: string, location: any) => {
        dispatch.Locations.updateState({
          searchLocation: { location },
        });
        dispatch.UI.updateFormId({ target: "viewMerchantDetails", value: id });
        dispatch.UI.updateFormId({ target: "viewDriverDetails", value: null }); 
    };

    return (
    <div className="driver-dashboard">
        <div className="flex justify-center p-4">
            <input
                onChange={(e) => setSearchMerchant(e.target.value)}
                style={{
                textAlign: "center",
                height: 40,
                width: "100%",
                borderRadius: 4,
                borderWidth: 2,
                borderColor: "#FFC300",
                marginBottom: 10,
                }}
                placeholder="Find Merchant Here"
            />
        </div>

        <table id="customers">
            {merchantLocation 
            .filter((dl) => {
            if (searchMerchant === "") {
                return dl;
            } else if (
                dl?.name
                ?.toLowerCase()
                .includes(searchMerchant.toLowerCase()) ||
                dl?.branchName
                ?.toLowerCase()
                .includes(searchMerchant.toLowerCase())
            ) {
                return dl;
            }
            })
            ?.sort((a, b) => a?.name - b?.name) 
            .map((item, index) => {
            return (
                <tr
                    key={item?.id} 
                    className="cursor-pointer" 
                    onClick={() =>
                    handleOnclick(item?.id, {
                        lat: item?.location?.lat,
                        lng: item?.location?.lng,
                    })
                    }
                > 
                    <td className="capitalize" key={index}>
                    {`${item.name} (${item.branchName})`}
                    </td> 
 
                    <td style={{cursor: "pointer"}}
                    > 
                    <Fragment>
                        <div
                            className="colored-circle"
                            style={{
                                backgroundColor: item?.isBusy
                                ? "#f20c0c"
                                : "#02b302",
                                margin: 0,
                                boxShadow: "1px 2px 3px #9E9E9E",
                            }}
                        ></div>
                    </Fragment>
                    </td>
                </tr>

                
            );
            })}
        </table>

    </div>
    )
};