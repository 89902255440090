/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Modal } from "antd";
import { useDispatch } from "react-redux";
import { useState } from "react";

import { Dispatch } from "@store";
import { addCustomAddOn } from "@api";

import { Button, Span } from "@common";
import { CustomAddOn } from "./CustomAddOn";
import { AiFillPlusCircle } from "react-icons/ai";

interface IAddOn {
  particular: string;
  amount: number;
  driverShareOnly: boolean;
}

type Props = {
  setAddOnModal: Function;
  addOnModal: boolean;
  id: string;
  refresh: Function;
  getDeliveryData: Function;
};

export const AddOnModal = ({
  setAddOnModal,
  addOnModal,
  id,
  refresh,
  getDeliveryData,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  const initialState = {
    addOn: [
      {
        particular: "",
        amount: 0,
        driverShareOnly: false,
      },
    ],
  };

  const [addsOnDetails, setAddsOnDetails] = useState(initialState);
  const [activeCustomAddsOnIndex, setActiveCustomAddsOnIndex] = useState(null);
  const [deletedAddsOn, setDeletedAddsOn] = useState([]);
  const [file, setFile] = useState([]) as any;

  let handleAddOn = async () => {
    let res;
    try {
      dispatch.UI.setIsLoading(true);
      const formData = await handleFormData({ ...addsOnDetails, file });
      res = await addCustomAddOn(id, formData);
      if ([201, 200].includes(res.status)) {
        dispatch.UI.setIsLoading(false);
        refresh();
        dispatch.UI.setAlert({
          message: "Added Successfully custom add-on",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setIsLoading(false);
      refresh();
      let errMsg =
        err?.response?.data?.message ?? `Unable to Add custom add-on!`;
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }

    setAddsOnDetails(initialState);
    setAddOnModal(false);
    getDeliveryData();
  };

  const handleFile = async (data: any) => {
    setFile([...file, data]);
  };

  const handleFormData = async (data: { addOn: IAddOn[]; file: Blob[] }) => {
    const { addOn, file } = data;
    const formData = new FormData();

    for (let idx = 0; idx < addOn.length; idx++) {
      formData.append("images", file[idx]);
      const addOnItem = addOn[idx];
      const keys = Object.keys(addOnItem);

      keys.forEach((key) => {
        formData.append(`addOn[${idx}][${key}]`, addOnItem[`${key}`]);
      });
    }
    return formData;
  };

  return (
    <>
      <Modal
        title={"Add Custom Add-on"}
        visible={addOnModal}
        onCancel={() => setAddOnModal(false)}
        footer={false}
      >
        {addsOnDetails?.addOn?.map((addOn, index) => (
          <CustomAddOn
            addsOnDetails={addsOnDetails}
            addOn={addOn}
            index={index}
            form={form}
            activeCustomAddsOnIndex={activeCustomAddsOnIndex}
            setActiveCustomAddsOnIndex={setActiveCustomAddsOnIndex}
            setAddsOnDetails={setAddsOnDetails}
            setDeletedAddsOn={setDeletedAddsOn}
            setFile={handleFile}
            deletedAddsOn={deletedAddsOn}
            file={file}
          />
        ))}

        <div className="mt-4">
          <Button
            onClick={() =>
              setAddsOnDetails({
                ...addsOnDetails,
                addOn: [
                  ...addsOnDetails.addOn,
                  {
                    particular: "",
                    amount: 0,
                    driverShareOnly: false,
                  },
                ],
              })
            }
          >
            <div className="flex px-4 justify-center">
              <AiFillPlusCircle className="my-auto text-base mx-1" />
              <Span color="white">Add</Span>
            </div>
          </Button>
        </div>

        <div className="flex justify-end mt-4">
          <Button
            width="none"
            border="lightgrey"
            radius="none"
            color="darkgrey"
            padding="px-8"
            bg="white"
            margin="mx-2"
            onClick={() => setAddOnModal(false)}
          >
            Cancel
          </Button>

          <Button
            width="none"
            border="lightgrey"
            radius="none"
            padding="px-8"
            onClick={() => {
              handleAddOn();
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};
