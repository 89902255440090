import { useParams } from "react-router-dom";
import { Header, SideBar, MainLayout, ContainerLayout } from "@common";
import { ShopOrders } from "@components";

export const ShopOrdersView = () => {
  let params: any = useParams();

  return (
    <>
      <MainLayout>
        <SideBar />
        <Header />

        <ContainerLayout>
          <ShopOrders id={params.id} />
        </ContainerLayout>
      </MainLayout>
    </>
  );
};
