import { useGetCurrentRoleAccesses } from '@hooks';
import { Header, SideBar, MainLayout, ContainerLayout } from '@common'
import { DriverDashboardPage } from '@containers'

export const DriverDashboard = () => {
    const module_name = 'manage_drivers';
    useGetCurrentRoleAccesses(module_name);
    
    return (
        <>
            <MainLayout>
                <SideBar />  
                <Header />  
                <ContainerLayout>
                    <DriverDashboardPage />
                </ContainerLayout>
            </MainLayout>
        </>
    )
}