/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Span } from "@common";
import { Loader, SearchDisbursement, Table } from "@components";
import { Button, Empty, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { COLUMNS } from "./column";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useIsWindowActive } from "@hooks";

export const DisbursementsPendingPage = () => {
  const isWindowActive = useIsWindowActive();
  const maxSearchInputCount = 2;

  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch<Dispatch>();
  const columns = useMemo(() => COLUMNS, []);

  const disbursementsDataPending: any[] = useSelector(
    (state: RootState) => state.Table.tables.disbursementsPending?.data
  );

  const filters: any = useSelector(
    (state: RootState) => state.Table.tables.disbursementsPending?.filters
  );
  const { isLoading } = useSelector((state: RootState) => state.Table);

  const [searchData, setSearchData] = useState([
    {
      filter: "keyword",
      value: "",
    },
  ]);

  const getData = () => {
    dispatch.Table.getDisbursementsData({
      table: "disbursementsPending",
      status: "pending",
    });
  };

  const setFilters = () => {
    let stringKeyword = "";
    searchData
      ?.filter((s) => s.value !== "")
      ?.forEach((search) => {
        const string =
          search?.value === ""
            ? ""
            : `&${search?.filter === "keyword" ? "keyword" : search?.filter}=${
                search?.value
              }`;
        stringKeyword = stringKeyword + string;
      });
    dispatch.Table.updateKeyword({
      table: "disbursementsPending",
      value: stringKeyword,
    });
    getData();
  };

  useEffect(() => {
    if (searchData?.length !== 1 || searchData[0].value !== "") {
      setFilters();
    }
  }, []);

  useEffect(() => {
    if (isWindowActive) {
      getData();
    }

    return () => {
      dispatch.Table.updateState({ isLoading: true });

      // setInitListener(true);
    };
  }, [filters, isWindowActive]);

  return (
    <div className="relative">
      <Loader isLoading={isLoading} />
      <Container border="grey" padding="none">
        <div className="p-4">
          <Span margin="mt-4" weight="bold" size="2xl">
            Disbursements - Pending
          </Span>
        </div>

        <div className="p-4 w-full flex flex-row items-center">
          {searchData?.map((search, index) => (
            <SearchDisbursement
              search={search}
              searchData={searchData}
              setSearchData={setSearchData}
              index={index}
            />
          ))}

          {!matchDownSm && (
            <Button
              type="primary"
              size="large"
              icon={
                <SearchOutlined
                  style={{ display: "inline-flex", alignItems: "center" }}
                />
              }
              onClick={setFilters}
            >
              Search
            </Button>
          )}

          {maxSearchInputCount != searchData?.length ? (
            <div className="px-2">
              <Button
                type="primary"
                size="large"
                className="px-2"
                icon={
                  <PlusOutlined
                    style={{ display: "inline-flex", alignItems: "center" }}
                  />
                }
                onClick={() => {
                  if (maxSearchInputCount === searchData?.length)
                    return message.error("Already reach search limit!");
                  setSearchData([
                    ...searchData,
                    { filter: "pleaseSelect", value: "" },
                  ]);
                }}
              >
                Add Search
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="overflow-auto">
          {!disbursementsDataPending ? (
            <Empty />
          ) : (
            <Table
              columns={columns}
              data={disbursementsDataPending}
              dataType="disbursementsPending"
              sortName="performAt"
              status="pending"
              descSort={true}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
