/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import { Dispatch } from "@store";
import { useDispatch } from "react-redux";
import { Form, Input, Modal } from "antd";
import { cancelScheduleByRentalItemsId } from "@api";

type Props = {
  setCancelModal: Function;
  cancelModal: boolean;
  rentalId: string;
};

export const CancelRental = ({
  setCancelModal,
  cancelModal,
  rentalId,
  ...props
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form] = Form.useForm();

  const [reason, setReason] = useState("");

  const handleCancel = async () => {
    try {
      setCancelModal(false);
      dispatch.UI.setIsLoading(true);
      const res = await cancelScheduleByRentalItemsId(rentalId, reason);
      if ([201, 200].includes(res.status)) {
        dispatch.UI.setIsLoading(false);
        setReason("");
        dispatch.UI.setAlert({
          message: `Rental item cancelled successfully`,
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setIsLoading(false);
      setReason("");
      const errMsg = err?.response?.data?.message ?? "Unable to cancel rental!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  return (
    <>
      <Modal
        title="Are you sure you want to cancel?"
        visible={cancelModal}
        onOk={form.submit}
        okText="Cancel Schedule"
        cancelText="Discard"
        okButtonProps={{ danger: true }}
        onCancel={() => setCancelModal(false)}
      >
        <Form
          onFinish={handleCancel}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          form={form}
        >
          <Form.Item name="Reason" label="" rules={[{ required: true }]}>
            <Input
              name="reason"
              placeholder="Cancel reason..."
              value={reason}
              onChange={(e: any) => setReason(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
