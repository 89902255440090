/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch, RootState } from "@store";

import { useIsWindowActive } from "@hooks";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";

import Map from "./Map/Map";

import { Container, GridContainer, Span } from "@common"; 
import { getMerchantList } from "@api";
import { Switch } from 'antd';
import Driver from "./Tabs/Driver";
import Merchant from "./Tabs/Merchant";

var UNSUBSCRIBE: any;
var RECONNECT_TIMER: any;

export const DriverDashboardPage = () => {
  const dispatch = useDispatch<Dispatch>();

  const isWindowActive = useIsWindowActive();
  const [driverLocation, setdriverLocation] = useState([]) as any;
  const [merchantLocation, setMerchantLocation] = useState([]) as any; 

  const { isDriverEnabled, isMerchantEnabled } = useSelector((state: RootState) => state.Driver);  

  const getDriverLocation = () => { 
    const db = getFirestore();

    const q = query(collection(db, "drivers_location"));

    UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || [])?.map((ref) => {
        const data = { ...ref.data(), id: ref?.id };
        return data;
      });

      setdriverLocation(listData);

      dispatch.Table.updateState({ driverDetails: listData });
    });
  };

  const getMerchant = async () => {
    try {
     const res = await getMerchantList();
        setMerchantLocation(res); 
    } catch (err) {
      console.log(err)
    }
  };  

  useEffect(() => {
    if (performance.navigation.type === 1) {
      dispatch.UI.updateFormId({ target: "viewDriverDetails", value: null });
      dispatch.UI.updateFormId({ target: "viewMerchantDetails", value: null });
    }
    if (isWindowActive) {
      RECONNECT_TIMER = setTimeout(() => getDriverLocation(), 3000); 
    } else {
      if (UNSUBSCRIBE) UNSUBSCRIBE();
      clearTimeout(RECONNECT_TIMER);
    }

    return () => {
      if (UNSUBSCRIBE) UNSUBSCRIBE();
    };
  }, [isWindowActive]);

  useEffect(() => {
    getMerchant() 
  }, []);
 
  return (
    <>
      <Container padding="py-4">
        <GridContainer grid="twelve" padding="none" gap="none"> 
        <div className="h-screen overflow-auto col-span-2"> 
            <div className="flex justify-between mx-4">
            <Span size="base" weight="medium" align="center">
              Merchant
            </Span>
            <Switch
            checkedChildren="Show"
            unCheckedChildren="Hide"
            checked={isMerchantEnabled ? true : false}
            onChange={(v) =>  
              dispatch.Driver.updateState({ isMerchantEnabled: v ? true : false  })
            }
            />
            </div>
 
            <Merchant merchantLocation={merchantLocation}/> 
          </div>

          <div className="col-span-8 relative px-2">
            <div className="flex w-full h-screen">
              <Map locations={driverLocation} merchantLocation={merchantLocation}/>
            </div>
          </div>

          <div className="h-screen overflow-auto col-span-2"> 
            <div className="flex justify-between mx-4">
            <Span size="base" weight="medium" align="center">
              Driver
            </Span>
            <Switch
            checkedChildren="Show"
            unCheckedChildren="Hide"
            checked={isDriverEnabled ? true : false}
            onChange={(v) =>  
              dispatch.Driver.updateState({ isDriverEnabled: v ? true : false  })
            }
            />
            </div>
 
            <Driver driverLocation={driverLocation}/>  
          </div>
        </GridContainer>
      </Container>
    </>
  );
};
