import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";

import { NoData, Container, Span } from "@common";

import { COLUMNS } from "./column";

import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, message } from "antd";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { BiPlusCircle } from "react-icons/bi";

import {
  AddDriver,
  EditDriver,
  SearchInputDM,
  Loader,
  ViewDriver,
  Table,
} from "@components";

export const AllDriversPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const editDriverId: any = useSelector(
    (state: RootState) => state.UI.forms.editDriver?.id
  );
  const viewDriverId: any = useSelector(
    (state: RootState) => state.UI.forms.viewDriver?.id
  );
  const { filters } = useSelector(
    (state: RootState) => state.Table.tables.allDrivers
  );
  const data: any[] = useSelector(
    (state: RootState) => state.Table.tables.allDrivers.data
  ).reverse();
  const { isLoading } = useSelector((state: RootState) => state.Table);
  const columns = useMemo(() => COLUMNS, []);
  const [searchData, setSearchData] = useState([
    {
      filter: "approvalStatus",
      value: "all",
    },
  ]);

  const maxSearchInputCount = 4;
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  // ADD DRIVER
  const [addDriver, setAddDriver] = useState(false);
  const showDrawer = () => {
    setAddDriver(true);
  };

  const searchCompleted = () => {
    dispatch.Table.getDriversData({ table: "allDrivers", type: "" });
  };

  const setFilters = () => {
    let stringKeyword = "";
      searchData
        ?.filter((s) => s.value !== "")
        ?.forEach((search) => {
        // stringKeyword = `&${search.filter}=${search.value}&approvalStatus=approved&approvalStatus=pending&approvalStatus=rejected`;
        // if (search.filter === "approvalStatus") {
        //   if (search.value === "all") {
        //     stringKeyword = "&approvalStatus=approved&approvalStatus=pending&approvalStatus=rejected";
        //   } else {
        //     stringKeyword = `&approvalStatus=${search.value}`;
        //   }
        // }

        // if (search.filter === "driverStatus") {
        //   if (search.value === "all") {
        //     stringKeyword = "&driverStatus=active&driverStatus=inactive&approvalStatus=approved&approvalStatus=pending&approvalStatus=rejected";
        //   } else {
        //     stringKeyword = `&driverStatus=${search.value}&approvalStatus=approved&approvalStatus=pending&approvalStatus=rejected`;
        //   }
        // } 
        const string =
        search?.value === "all"
          ? "&approvalStatus=approved&approvalStatus=pending&approvalStatus=rejected"
          : `&${search?.filter}=${search?.value}`;
        stringKeyword = stringKeyword + string; 
      }); 
    dispatch.Table.updateKeyword({ table: "allDrivers", value: stringKeyword });
    searchCompleted();

  };

  const getData = async () => {
    dispatch.Table.updateKeyword({
      table: "allDrivers",
      value:
        "&approvalStatus=approved&approvalStatus=pending&approvalStatus=rejected",
    });

    dispatch.Table.getDriversData({
      table: "allDrivers",
      type: "",
      firstView: true,
    });
  };

  useEffect(() => {
    getData();

    return () => {
      dispatch.Table.updateState({ isLoading: true });
    };
  }, [filters]);

  return (
    <div className="relative">
      <Loader isLoading={isLoading} />
      <Container border="grey" padding="none">
        <div className="p-4">
          <div className="flex justify-between">
            <Span margin="mt-4" weight="bold" size="2xl">
              All Drivers
            </Span>
            <div className="flex justify-end px-4 my-4">
              <Button onClick={showDrawer} size="large">
                <div className="flex pr-2 items-center">
                  <BiPlusCircle className="my-auto mr-2 text-2xl items-center inline-flex" />
                  Add Driver
                </div>
              </Button>
            </div>
          </div>
        </div>

        <div className="p-4 w-full flex flex-row items-center">
          {searchData?.map((search, index) => (
            <SearchInputDM
              searchData={searchData}
              setSearchData={setSearchData}
              index={index}
              search={search}
            />
          ))}

          {!matchDownSm && (
            <Button
              type="primary"
              size="large"
              icon={
                <SearchOutlined
                  style={{ display: "inline-flex", alignItems: "center" }}
                />
              }
              onClick={setFilters}
            >
              Search
            </Button>
          )}

          {maxSearchInputCount != searchData?.length ? (
            <div className="px-2">
              <Button
                type="primary"
                size="large"
                className="px-2"
                icon={
                  <PlusOutlined
                    style={{ display: "inline-flex", alignItems: "center" }}
                  />
                }
                onClick={() => {
                  if (maxSearchInputCount === searchData?.length)
                    return message.error("Already reach search limit!");
                  setSearchData([
                    ...searchData,
                    { filter: "pleaseSelect", value: "" },
                  ]);
                }}
              >
                Add Search
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="overflow-auto">
          {data?.length < 0 ? (
            <NoData />
          ) : (
            <Table columns={columns} data={data} dataType="all-drivers" />
          )}
        </div>
      </Container>

      <EditDriver
        driverId={editDriverId}
        getData={getData}
        table="allDrivers"
      />

      <AddDriver
        setAddDriver={setAddDriver}
        addDriver={addDriver}
        getData={getData}
      />

      {/* DRIVER VIEW */}
      <Drawer
        title="Driver info"
        placement="right"
        width={500}
        onClose={() => {
          dispatch.UI.updateFormId({
            target: "viewDriver",
            value: null,
          });
        }}
        visible={viewDriverId}
      >
        <ViewDriver setViewDriver={viewDriverId} />
      </Drawer>
    </div>
  );
};
